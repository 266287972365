import { LeftPane } from '../../../../common/desktop/LeftPane/LeftPane';
import { LeftPaneContent } from '../../../../common/desktop/LeftPaneContent/LeftPaneContent';
import { RoutePanelResult } from '../../mobile/RoutePanel/RoutePanelResult';

export interface DesktopRoutePanelProps {}

export function DesktopRoutePanel(props: DesktopRoutePanelProps) {
  return (
    <LeftPane>
      <LeftPaneContent>
        <RoutePanelResult showFirstStep={true} showStepsList={true} />
      </LeftPaneContent>
    </LeftPane>
  );
}
