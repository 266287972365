import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, RichInput, Select, Space, TextArea } from '@hse-design/react';
import { array, number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getRoomCategoriesOptions, SelectOptions } from '../../types';
import { AuthRoomDto } from '../../data/stores/RoomsStore';
import { Point } from '../../utils';
import { BuildingDto } from '../../../../data/api/BuildingDto';
import { RoomCategoryDto } from '../../../../data/api/RoomCategoryDto';
import { useSyncFormFields } from './useSyncFormFields';
import s from './UpdateRoom.module.scss';

export interface UpdateRoomFormFields {
  category?: string;
  code?: string;
  name_en?: string;
  name_ru?: string;
  map_name_en?: string;
  map_name_ru?: string;
  floor_number?: string;
  building_id?: number;
  description_en?: string;
  description_ru?: string;
  bounds?: AuthRoomDto['bounds'];
}

export interface UpdateRoomFormProps {
  bounds?: Point[][];
  categories?: RoomCategoryDto[];
  floors: number[];
  buildings?: BuildingDto[];
  values: UpdateRoomFormFields;
  syncValues?: boolean;
  children?: React.ReactNode;
  onSubmit: (values: UpdateRoomFormFields) => void;
}

const category = 'category';
const code = 'code';
const name_en = 'name_en';
const name_ru = 'name_ru';
const map_name_en = 'map_name_en';
const map_name_ru = 'map_name_ru';
const description_en = 'description_en';
const description_ru = 'description_ru';
const floor_number = 'floor_number';
const building_id = 'building_id';
const bounds = 'bounds';

const validationSchema = object().shape({
  [category]: number()
    .typeError('Выберите категорию')
    .required('Выберите категорию'),
  [code]: string().required('Введите код'),
  [name_en]: string().required('Введите название'),
  [name_ru]: string().required('Введите название'),
  [map_name_en]: string().required('Введите название'),
  [map_name_ru]: string().required('Введите название'),
  [floor_number]: string().required('Выберите этаж'),
  [building_id]: number().required('Выберите строение'),
  [description_en]: string().nullable(),
  [description_ru]: string().nullable(),
  [bounds]: array()
    .required('Добавьте границы комнаты')
    .min(1, 'Добавьте границы комнаты')
});

export function UpdateRoomForm({
  bounds: boundsProp,
  buildings,
  categories,
  children,
  floors,
  values: defaultValues,
  syncValues,
  onSubmit
}: UpdateRoomFormProps) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue
  } = useForm<UpdateRoomFormFields>({
    // todo Проверить типы
    resolver: yupResolver(validationSchema) as any,
    defaultValues
  });
  setValue(bounds, boundsProp);
  const roomCategories = useMemo<SelectOptions<string, number>>(
    () => getRoomCategoriesOptions(categories),
    [categories]
  );

  const floorsOptions = useMemo<SelectOptions<string, string>>(() => {
    return floors.map((f) => ({ label: String(f), value: String(f) }));
  }, [floors]);

  const buildingsOptions = useMemo(
    () =>
      buildings?.map((b) => ({
        label: b.name,
        value: b.id
      })) || [],
    [buildings]
  );
  useSyncFormFields<UpdateRoomFormFields>(reset, defaultValues, syncValues);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name={category}
        control={control}
        defaultValue={defaultValues[category]}
        render={({ field: { ref, ...props } }) => {
          return (
            <RichInput
              component={Select}
              size={Select.Size.small}
              {...props}
              value={props.value || null}
              options={(roomCategories as unknown) as any}
              label={'Категория'}
              errorMessage={errors[category]?.message}
            />
          );
        }}
      />
      <Space size={Space.Size.small_3x} vertical />
      <Controller
        name={code}
        control={control}
        defaultValue={defaultValues[code]}
        render={({ field: { ref, ...props } }) => (
          <RichInput
            size={Input.Size.small}
            {...props}
            label={'Код'}
            errorMessage={errors[code]?.message}
            inputRef={ref}
          />
        )}
      />
      <Space size={Space.Size.small_3x} vertical />
      <Controller
        name={name_ru}
        control={control}
        defaultValue={defaultValues[name_ru]}
        render={({ field: { ref, ...props } }) => (
          <RichInput
            size={Input.Size.small}
            {...props}
            label={'Название (рус)'}
            errorMessage={errors[name_ru]?.message}
            inputRef={ref}
          />
        )}
      />
      <Space size={Space.Size.small_3x} vertical />
      <Controller
        name={name_en}
        control={control}
        defaultValue={defaultValues[name_en]}
        render={({ field: { ref, ...props } }) => (
          <RichInput
            size={Input.Size.small}
            {...props}
            label={'Название (англ)'}
            errorMessage={errors[name_en]?.message}
            inputRef={ref}
          />
        )}
      />
      <Space size={Space.Size.small_3x} vertical />
      <Controller
        name={map_name_ru}
        control={control}
        defaultValue={defaultValues[map_name_ru]}
        render={({ field: { ref, ...props } }) => (
          <RichInput
            size={Input.Size.small}
            {...props}
            label={'Название для карты (рус)'}
            errorMessage={errors[map_name_ru]?.message}
            inputRef={ref}
          />
        )}
      />
      <Space size={Space.Size.small_3x} vertical />
      <Controller
        name={map_name_en}
        control={control}
        defaultValue={defaultValues[map_name_en]}
        render={({ field: { ref, ...props } }) => (
          <RichInput
            size={Input.Size.small}
            {...props}
            label={'Название для карты (англ)'}
            errorMessage={errors[map_name_en]?.message}
            inputRef={ref}
          />
        )}
      />
      <Space size={Space.Size.small_3x} vertical />
      <Controller
        name={floor_number}
        control={control}
        defaultValue={defaultValues[floor_number]}
        render={({ field: { ref, ...props } }) => (
          <RichInput
            component={Select}
            options={(floorsOptions as unknown) as any}
            size={Select.Size.small}
            {...props}
            value={(props.value as any) as string}
            label={'Этаж'}
            errorMessage={errors[floor_number]?.message}
          />
        )}
      />
      <Space size={Space.Size.small_3x} vertical />
      <Controller
        name={building_id}
        control={control}
        defaultValue={defaultValues[building_id]}
        render={({ field: { ref, ...props } }) => (
          <RichInput
            component={Select}
            options={(buildingsOptions as unknown) as any}
            size={Select.Size.small}
            {...props}
            value={(props.value as any) as string}
            label={'Корпус'}
            errorMessage={errors[building_id]?.message}
          />
        )}
      />
      <Space size={Space.Size.small_3x} vertical />
      <Controller
        name={description_ru}
        control={control}
        defaultValue={defaultValues[description_ru]}
        render={({ field: { ref, ...props } }) => (
          <RichInput
            component={TextArea}
            {...props}
            label={'Описание (рус)'}
            errorMessage={errors[description_ru]?.message}
          />
        )}
      />
      <Space size={Space.Size.small_3x} vertical />
      <Controller
        name={description_en}
        control={control}
        defaultValue={defaultValues[description_en]}
        render={({ field: { ref, ...props } }) => (
          <RichInput
            component={TextArea}
            {...props}
            label={'Описание (англ)'}
            errorMessage={errors[description_en]?.message}
          />
        )}
      />
      <Space size={Space.Size.small_3x} vertical />
      {errors['bounds'] && (
        <div className={s.ErrorMessage}>{errors['bounds']['message']}</div>
      )}
      {children}
    </form>
  );
}
