import { SubscriptionManager, ValueStore } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { map } from 'rxjs/operators';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import { QUERY_KEY_PANEL } from './queryKeys';

export class ActivePanel extends ValueStore<string | null> {
  static PANEL_SEARCH = 'search';
  static PANEL_ROUTE = 'route';
  static PANEL_MENU = 'menu';
  static PANEL_SELECT_ON_MAP = 'selectOnMap';
  static PANEL_ROOM = 'room';
  static PANEL_MY_LOCATION = 'myLocation';

  sub = new SubscriptionManager();

  constructor(private location: LocationStore) {
    super(null);
  }

  onSubscribe() {
    this.sub.subscribe(
      locationStoreGet$(this.location, QUERY_KEY_PANEL).pipe(
        map((query) => query[QUERY_KEY_PANEL] || null)
      ),
      this.state$
    );
  }

  onUnsubscribe() {
    this.sub.destroy();
  }

  setPanel = (panel: string | null, replace?: boolean) => {
    this.location.changeQuery(
      {
        [QUERY_KEY_PANEL]: panel || undefined
      },
      replace
    );
  };

  closePanel = (replace?: boolean) => {
    this.setPanel(null, replace);
  };
}
