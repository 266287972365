/**
 * Находит индекс минимального или максимального элемента массива в соответствии с заданной метрикой
 * @param array - массив
 * @param isMax - если true, то ищет максимум, иначе минимум
 * @param metric - функция, возвращающая число для каждого элемента
 */
export function findArrayExtremeIndex<T>(
  array: readonly T[],
  isMax: boolean,
  metric: (item: T, index: number) => number
) {
  let extMetric: number | null = null;
  let iResult: number | null = null;
  for (let i = 0; i < array.length; i++) {
    const m = metric(array[i], i);
    if (extMetric === null || (isMax ? m > extMetric : m < extMetric)) {
      extMetric = m;
      iResult = i;
    }
  }

  return [iResult, extMetric] as const;
}

/**
 * Находит минимальный или максимальный элемент массива в соответствии с заданной метрикой
 * @param array - массив
 * @param isMax - если true, то ищет максимум, иначе минимум
 * @param metric - функция, возвращающая число для каждого элемента
 */
export function findArrayExtreme<T>(
  array: readonly T[],
  isMax: boolean,
  metric: (item: T, index: number) => number
) {
  const [iResult, extMetric] = findArrayExtremeIndex(array, isMax, metric);
  if (iResult !== null) {
    return [array[iResult], extMetric] as const;
  }
  return [null, null] as const;
}
