class RoomStyle {
  constructor(
    backgroundColor = 'transparent',
    color = 'transparent',
    underlayColor = 'transparent',
    underlayBorderRadius = 0,
    underlayPadding = 0,
    labelZIndex = 3,
    size = 10,
    fontFamily = '"helvetica", "Arial", "Tahoma", sans-serif'
  ) {
    this.backgroundColor = backgroundColor;
    this.color = color;
    this.underlayColor = underlayColor;
    this.underlayBorderRadius = underlayBorderRadius;
    this.underlayPadding = underlayPadding;
    this.labelZIndex = labelZIndex;
    this.size = size;
    this.fontFamily = fontFamily;
  }
}
export const roomStyles = {
  inactive: new RoomStyle('#E1E1E6', '#0F0F14'),
  active: new RoomStyle('#C2DAFF', '#0050CF'),
  selected: new RoomStyle('#3A74D0', '#FFFFFF', '#002D6E', 4, 4, 6)
};
