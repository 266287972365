import { initialRequestState, IRequestState } from '@proscom/prostore';

export interface IExtendedRequestState<Vars, Data>
  extends IRequestState<Vars, Data> {
  /** Variables matching the data */
  loadedVariables: Vars | null;
}

export function extendRequestState<Vars, Data, Ext extends object>(
  state: IRequestState<Vars, Data>,
  extension: Ext
) {
  return {
    data: state.data,
    loading: state.loading,
    loaded: state.loaded,
    error: state.error,
    variables: state.variables,
    ...extension
  };
}

export function extendExtendedRequestState<Vars, Data, Ext extends object>(
  state: IExtendedRequestState<Vars, Data>,
  extension: Ext
) {
  return extendRequestState(state, {
    loadedVariables: state.loadedVariables,
    ...extension
  });
}

export const initialExtendedRequestState = {
  ...initialRequestState,
  loadedVariables: null,
  maxPage: null
};
