import { combineLatest, Observable, Subscribable } from 'rxjs';
import { auditTime, map } from 'rxjs/operators';

export type SubscribableTypes<Subs extends readonly any[]> = {
  [key in keyof Subs]: Subs[key] extends Subscribable<infer T> ? T : unknown;
};

export function combineLatestMap<
  Subs extends readonly Subscribable<any>[],
  Result
>(
  observables: Subs,
  project: (args: SubscribableTypes<Subs>) => Result
): Observable<Result> {
  return combineLatest(observables).pipe(map(project as any));
}

export function combineLatestMapAsync<
  Subs extends readonly Subscribable<any>[],
  Result
>(
  observables: Subs,
  project: (args: SubscribableTypes<Subs>) => Result
): Observable<Result> {
  return combineLatest(observables).pipe(auditTime(0), map(project as any));
}
