import React from 'react';
import clsx from 'clsx';
import { CloseButton } from '../../ui/CloseButton/CloseButton';
import s from './BottomSheetTitle.module.scss';

interface BottomSheetTitleProps {
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
}

export function BottomSheetTitle({
  children,
  onClose,
  className
}: BottomSheetTitleProps) {
  return (
    <div className={clsx(s.BottomSheetTitle, className)}>
      <div className={s.BottomSheetTitle__name}>{children}</div>
      <CloseButton className={s.BottomSheetTitle__close} onClick={onClose} />
    </div>
  );
}
