export const routeLineStyles = {
  lineWidth: 3,
  lineCap: 'round',
  lineJoin: 'round'
};

export const routeLineColors = {
  selected: 'rgb(0, 80, 207)',
  notSelected: 'rgb(0, 80, 207, 0.3)'
};
