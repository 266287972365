export default class CanvasMath {
  constructor() {
    this.maxScale = 2;
    this.minScale = undefined;
    this.canvasCenter = undefined;
    this.dragLimits = {
      xMax: undefined,
      xMin: undefined,
      yMax: undefined,
      yMin: undefined
    };
    this.contentSize = undefined;
    this.scale = undefined;

    this.marginX = 0;
    this.marginY = 0;

    this.clearMath();
  }

  isValidNumber = (x) => x || x === 0;

  clearMath = () => {
    this.layerX = undefined;
    this.layerY = undefined;
  };

  updateLayerX = (value) => {
    if (isNaN(value)) {
      return;
    }

    if (!this.layerX) {
      this.layerX = {
        max: value,
        min: value
      };
    } else {
      this.layerX = {
        max: Math.max(value, this.layerX.max),
        min: Math.min(value, this.layerX.min)
      };
    }
  };

  updateLayerY = (value) => {
    if (isNaN(value)) {
      return;
    }

    if (!this.layerY) {
      this.layerY = {
        max: value,
        min: value
      };
    } else {
      this.layerY = {
        max: Math.max(value, this.layerY.max),
        min: Math.min(value, this.layerY.min)
      };
    }
  };

  getOfTransformX(value) {
    return (value + this.marginX) * this.scale;
  }

  getOfTransformWidth(value) {
    return value * this.scale;
  }

  getOfTransformY(value) {
    return (value + this.marginY) * this.scale;
  }

  getOfTransformHeight(value) {
    return value * this.scale;
  }

  setScale = (value) => {
    if (value <= this.maxScale && value >= this.minScale) this.scale = value;
  };

  updateScale = (coef) => {
    const newScale = this.scale * coef;

    if (newScale <= this.maxScale && newScale >= this.minScale) {
      this.scale = newScale;

      if (
        this.dragLimits.xMax &&
        this.dragLimits.xMin &&
        this.dragLimits.yMax &&
        this.dragLimits.yMin
      ) {
        if (this.marginX > this.dragLimits.xMax) {
          this.marginX = this.dragLimits.xMax;
        } else if (this.marginX < this.dragLimits.xMin) {
          this.marginX = this.dragLimits.xMin;
        }
        if (this.marginY > this.dragLimits.yMax) {
          this.marginY = this.dragLimits.yMax;
        } else if (this.marginY < this.dragLimits.yMin) {
          this.marginY = this.dragLimits.yMin;
        }
      }
    }
  };

  upMarginX = (value) => {
    if (
      this.isValidNumber(this.dragLimits.xMax) &&
      this.isValidNumber(this.dragLimits.xMin)
    ) {
      let newMarginX = Math.max(this.marginX + value, this.dragLimits.xMin);
      newMarginX = Math.min(newMarginX, this.dragLimits.xMax);
      this.marginX = newMarginX;
    }
  };

  upMarginY = (value) => {
    if (
      this.isValidNumber(this.dragLimits.yMax) &&
      this.isValidNumber(this.dragLimits.yMin)
    ) {
      let newMarginY = Math.max(this.marginY + value, this.dragLimits.yMin);
      newMarginY = Math.min(newMarginY, this.dragLimits.yMax);
      this.marginY = newMarginY;
    }
  };

  setX = (value) => {
    if (
      this.isValidNumber(this.dragLimits.xMax) &&
      this.isValidNumber(this.dragLimits.xMin)
    ) {
      let newMarginX = Math.max(value, this.dragLimits.xMin);
      newMarginX = Math.min(newMarginX, this.dragLimits.xMax);
      this.marginX = newMarginX;
    }
  };

  setY = (value) => {
    if (
      this.isValidNumber(this.dragLimits.yMax) &&
      this.isValidNumber(this.dragLimits.yMin)
    ) {
      let newMarginY = Math.max(value, this.dragLimits.yMin);
      newMarginY = Math.min(newMarginY, this.dragLimits.yMax);
      this.marginY = newMarginY;
    }
  };

  getMarginOfCursor = ({ pointer, newScale, oldScale }) => {
    if (!pointer) {
      throw Error('Is not defined pointer');
    }

    const currentX = this.x;
    const currentY = this.y;

    const centerX = pointer.x;
    const centerY = pointer.y;

    const newX = centerX + ((currentX - centerX) * newScale) / oldScale;
    const newY = centerY + ((currentY - centerY) * newScale) / oldScale;

    const newMarginX =
      (newX - (currentX * (newScale / oldScale) - this.marginX * newScale)) *
      (1 / newScale);
    const newMarginY =
      (newY - (currentY * (newScale / oldScale) - this.marginY * newScale)) *
      (1 / newScale);

    return {
      x: newMarginX,
      y: newMarginY
    };
  };

  get x() {
    if (!this.layerX) {
      return undefined;
    }

    return this.layerX.min;
  }

  get y() {
    if (!this.layerY) {
      return undefined;
    }

    return this.layerY.min;
  }

  get width() {
    if (!this.layerX) {
      return undefined;
    }

    return this.layerX.max - this.layerX.min;
  }

  get height() {
    if (!this.layerY) {
      return undefined;
    }

    return this.layerY.max - this.layerY.min;
  }

  /**
   * Трансформации
   */
  getPositionsFromBounds = (elementBounds) => {
    if (
      !elementBounds ||
      !elementBounds.horizontal ||
      !elementBounds.vertical
    ) {
      return;
    }

    const stage_w = this.canvas.width;
    const stage_h = this.canvas.height;

    const element_w =
      (elementBounds.horizontal.right - elementBounds.horizontal.left) *
      this.scale;
    const element_h =
      (elementBounds.vertical.bottom - elementBounds.vertical.top) * this.scale;
    const element_x = elementBounds.horizontal.left;
    const element_y = elementBounds.vertical.top;

    const left_zero = -element_x * this.scale;
    const top_zero = -element_y * this.scale;

    const margin_left_half_offset = (stage_w - element_w) / 2;
    const margin_top_half_offset = (stage_h - element_h) / 2;

    const left_center_offset = left_zero + margin_left_half_offset;
    const top_center_offset = top_zero + margin_top_half_offset;

    const margin_left_half_absolute = (this.viewBoxWidth - element_w) / 2;
    const margin_top_half_absolute = (this.viewBoxHeight - element_h) / 2;

    const left_center_ablolute = margin_left_half_absolute;
    const top_center_ablolute = margin_top_half_absolute;

    const resObj = {
      left: {
        start: {
          absolute: 0,
          offset: left_zero
        },
        center: {
          absolute: left_center_ablolute,
          offset: left_center_offset
        }
      },
      top: {
        start: {
          absolute: 0,
          offset: top_zero
        },
        center: {
          absolute: top_center_ablolute,
          offset: top_center_offset
        }
      },
      width: {
        element: {
          absolute:
            elementBounds.horizontal.right - elementBounds.horizontal.left,
          scale: element_w
        }
      },
      height: {
        element: {
          absolute: elementBounds.vertical.bottom - elementBounds.vertical.top,
          scale: element_h
        }
      }
    };

    return resObj;
  };

  getScaleForBbox = (elementBounds) => {
    const positions = this.getPositionsFromBounds(elementBounds);

    if (!positions) return;

    const width_stage = this.canvas.width;
    const width_element =
      elementBounds.horizontal.right - elementBounds.horizontal.left;

    const height_stage = this.canvas.height;
    const height_element =
      elementBounds.vertical.bottom - elementBounds.vertical.top;

    return {
      diffScaleX:
        width_stage > width_element
          ? 0
          : (width_element - width_stage) / width_element,
      diffScaleY:
        height_stage > height_element
          ? 0
          : (height_element - height_stage) / height_element
    };
  };

  getDistance = (pointA, pointB) => {
    const distanceX = pointA.x - pointB.x;
    const distanceY = pointA.y - pointB.y;
    return Math.hypot(distanceX, distanceY);
  };

  easeInOutCubic = (x) =>
    x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}
