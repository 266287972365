import { LocationStore } from '@proscom/prostore-react-router';
import { AxiosInstance } from 'axios';
import { QUERY_KEY_EVENT } from '../core/queryKeys';
import { LocaleStore } from '../core/LocaleStore';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import { ObservableStore } from '../../utils/prostore/ObservableStore';
import { combineLatestMap } from '../../utils/prostore/combineLatestMap';
import { EVENT_APRIL2020, EVENT_DOD2021 } from './eventKeys';

export interface EventStoreArgs {
  locationStore: LocationStore;
  localeStore: LocaleStore;
}

export interface EventParams {
  hideStaff?: boolean;
  hideRoomExt?: boolean;
  hideQrButton?: boolean;
  headerLink?: {
    text: string;
    href: string;
  };
}

export interface EventStoreState {
  eventCode: string | null;
  params: EventParams;
}

function getEventFromQuery(queryValue: string | undefined) {
  return (
    queryValue || (window.isApril ? EVENT_APRIL2020 : undefined) || undefined
  );
}

function getEventParams(
  eventCode: string | undefined,
  localeStore: LocaleStore
): EventParams {
  if (eventCode === EVENT_APRIL2020) {
    return {
      hideStaff: true,
      hideRoomExt: true,
      hideQrButton: true
    };
  }

  if (eventCode === EVENT_DOD2021) {
    return {
      hideStaff: true,
      headerLink: {
        text: localeStore.t('event.dod2021.title'),
        href: 'https://www.hse.ru/dod2021'
      }
    };
  }

  return {};
}

export class EventStore extends ObservableStore<EventStoreState> {
  constructor(private deps: EventStoreArgs) {
    super(
      () =>
        combineLatestMap(
          [
            locationStoreGet$(this.deps.locationStore, QUERY_KEY_EVENT),
            this.deps.localeStore.state$
          ] as const,
          ([query, locale]) => {
            const eventCode = getEventFromQuery(query[QUERY_KEY_EVENT]);
            return {
              eventCode: eventCode || null,
              params: getEventParams(eventCode, this.deps.localeStore)
            };
          }
        ),
      {
        eventCode: null,
        params: {}
      }
    );
  }
}

export function addAxiosEventInterceptor(
  client: AxiosInstance,
  eventStore: EventStore
) {
  // Добавляет идентификатор события в каждый запрос к бекенду
  client.interceptors.request.use(function (response) {
    const eventCode = eventStore.state.eventCode;
    if (!eventCode) {
      return response;
    }

    let newUrl = response.url;

    if (newUrl?.indexOf('?') === -1) {
      newUrl += '?event=' + eventCode;
    } else {
      newUrl += '&event=' + eventCode;
    }

    response.url = newUrl;

    return response;
  });
}
