import { AxiosQueryStore } from '@proscom/prostore-axios';
import { AxiosInstance } from 'axios';
import { skipIfNull } from '@proscom/prostore';
import { GetQueryVars, LocaleQueryParams } from '../api/common';
import { combineLatestMap } from '../../utils/prostore/combineLatestMap';
import { IStoreType } from '../../utils/types';
import { RoomCategoryDto } from '../api/RoomCategoryDto';
import { LocaleStore } from '../core/LocaleStore';
import { CampusStore } from '../stores/CampusStore';

export interface CampusCategoriesVars extends GetQueryVars<LocaleQueryParams> {
  campus_id?: number;
}
export type CampusCategoriesQueryData = RoomCategoryDto[];

export type ICampusCategoriesStore = IStoreType<CampusCategoriesStore>;

export class CampusCategoriesStore extends AxiosQueryStore<
  CampusCategoriesVars | null,
  CampusCategoriesQueryData | null
> {
  constructor(
    client: AxiosInstance,
    localeStore: LocaleStore,
    campusStore: CampusStore
  ) {
    super({
      client,
      query: {
        method: 'get',
        url: '/api/front/categories'
      },
      skipQuery: skipIfNull(null),
      mapData: (result) => result.data,
      variables$: combineLatestMap(
        [localeStore.state$, campusStore.state$] as const,
        ([locale, campus]) => {
          if (!locale.lang || !campus || !campus.id) {
            return {
              variables: null
            };
          }

          return {
            variables: {
              params: {
                locale: locale.lang,
                campus_id: campus.id
              }
            }
          };
        }
      )
    });
  }
}
