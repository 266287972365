import React from 'react';
import clsx from 'clsx';
import { Icon, IconActionLinkOpen, IconSize } from '@hse-design/react';
import { RouteStep as IRouteStep } from '../../../data/RouteStep';
import { ReactComponent as IconPointA } from '../../../assets/img/icons/i-point-a.svg';
import { ReactComponent as IconPointB } from '../../../assets/img/icons/i-point-b.svg';
import { ReactComponent as IconLeft } from '../../../assets/img/icons/i-turn-left.svg';
import { ReactComponent as IconRight } from '../../../assets/img/icons/i-turn-right.svg';
import { ReactComponent as IconForward } from '../../../assets/img/icons/i-route-forward.svg';
import { ReactComponent as IconElUp } from '../../../assets/img/icons/i-elevator-up.svg';
import { ReactComponent as IconElDown } from '../../../assets/img/icons/i-elevator-down.svg';
import { ReactComponent as IconStairsUp } from '../../../assets/img/icons/i-stairs-up.svg';
import { ReactComponent as IconStairsDown } from '../../../assets/img/icons/i-stairs-down.svg';
import { ReactComponent as IconTransfer } from '../../../assets/img/icons/i-transfer.svg';
import { useLocale } from '../../hooks/useLocale';
import { RouteStepType } from '../../../data/RouteStepType';
import s from './RouteInfo.module.scss';

const stepTypeIcons = {
  [RouteStepType.TYPE_START]: <IconPointA />,
  [RouteStepType.TYPE_END]: <IconPointB />,
  [RouteStepType.TYPE_LEFT]: <IconLeft />,
  [RouteStepType.TYPE_RIGHT]: <IconRight />,
  [RouteStepType.TYPE_FORWARD]: <IconForward />,
  [RouteStepType.TYPE_ELEVATOR_UP]: <IconElUp />,
  [RouteStepType.TYPE_ELEVATOR_DOWN]: <IconElDown />,
  [RouteStepType.TYPE_STAIRS_UP]: <IconStairsUp />,
  [RouteStepType.TYPE_STAIRS_DOWN]: <IconStairsDown />,
  [RouteStepType.TYPE_TRANSFER]: <IconTransfer />
};

export interface RouteStepProps {
  type: RouteStepType | null;
  title?: React.ReactNode;
  isActive?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  transferData?: IRouteStep['transferData'];
}

export function RouteStep({
  type,
  title,
  isActive,
  onClick,
  className,
  transferData
}: RouteStepProps) {
  const localeStore = useLocale();
  return (
    <div
      className={clsx(s.RouteStep, className, {
        [s._start]: type === RouteStepType.TYPE_START,
        [s._end]: type === RouteStepType.TYPE_END,
        [s._active]: isActive,
        [s._selectable]: onClick !== undefined
      })}
      onClick={onClick}
    >
      <div className={s.RouteStep__icon}>{type && stepTypeIcons[type]}</div>
      <div className={s.RouteStep__info}>
        <div className={s.RouteStep__title}>
          <p>{title}</p>
        </div>
        {transferData && (
          <div className={s.RouteStep__transferData}>
            <table>
              <tbody>
                <tr>
                  <td className={s.RouteStep__transferStepName}>
                    {localeStore.t('route_step.from')}
                  </td>
                  <td className={s.RouteStep__transferStepAddress}>
                    {transferData.startCampus.address}
                  </td>
                </tr>
                <tr>
                  <td className={s.RouteStep__transferStepName}>
                    {localeStore.t('route_step.to')}
                  </td>
                  <td className={s.RouteStep__transferStepAddress}>
                    {transferData.endCampus.address}
                  </td>
                </tr>
              </tbody>
            </table>
            <a
              href={transferData.yaMapLink}
              target="_blank"
              rel="noopener noreferrer"
              className={s.RouteStep__yaMapLink}
            >
              {localeStore.t('route_step.yandex_link')}
              <Icon
                icon={IconActionLinkOpen}
                size={IconSize.small}
                className={s.RouteStep__yaMapLinkIcon}
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
