import type { AxiosInstance } from 'axios';
import { BehaviorStore, SubscriptionManager } from '@proscom/prostore';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import immer from 'immer';
import { EntityEchoCallbacks, MapEntityMutations } from '../entities/MapEntity';
import { GetQueryVars } from '../../../../data/api/common';
import { IVertexData } from './NodesStore';
import type { AuthStore } from './AuthStore';

const baseUrl = '/api/vertex/qrs';

export interface IQrStore {
  data: Record<IQRData['vertex_id'], IQRData[]> | null;
}

/**
 * Стор с qr кодами от сервера
 */
class QrQueryStore extends AxiosQueryStore<
  GetQueryVars<null> | null,
  IQrStore['data']
> {
  constructor(axiosInstance: AxiosInstance) {
    super({
      client: axiosInstance,
      mapData: ({ data }) => {
        const map: Record<IQRData['vertex_id'], IQRData[]> = {};
        for (const qr of data) {
          if (!map[qr.vertex_id]) {
            map[qr.vertex_id] = [qr];
          } else {
            map[qr.vertex_id].push(qr);
          }
        }
        return map;
      },
      query: {
        url: baseUrl
      },
      initialData: null
    });
  }
}

/**
 * Колбеки для Echo
 */
class QrEchoCallbacks implements EntityEchoCallbacks<IQRData> {
  constructor(public store: BehaviorStore<IQrStore>) {}

  public onAdd(qr: IQRData) {
    this.store.setState({
      data: immer(this.store.state.data, (d) => {
        if (!d) return;
        if (!d[qr.vertex_id]) {
          d[qr.vertex_id] = [qr];
        } else {
          d[qr.vertex_id].push(qr);
        }
      })
    });
  }

  public onUpdate(qr: IQRData) {
    this.store.setState({
      data: immer(this.store.state.data, (d) => {
        if (!d) return;
        const currentIndex = d[qr.vertex_id].findIndex((q) => qr.id === q.id);
        if (currentIndex >= 0) {
          d[qr.vertex_id][currentIndex] = qr;
        } else {
          d[qr.vertex_id].push(qr);
        }
      })
    });
  }

  public onDelete(qr: IQRData) {
    this.store.setState({
      data: immer(this.store.state.data, (d) => {
        if (!d) return;
        const currentIndex = d[qr.vertex_id].findIndex((q) => qr.id === q.id);
        if (currentIndex < 0) return;
        d[qr.vertex_id].splice(currentIndex, 1);
      })
    });
  }
}

export interface IQRData {
  id: number;
  vertex_id: IVertexData['id'];
  qr: string;
}

function qrObjectToDto(qr: Partial<IQRData>) {
  return JSON.stringify(qr);
}

function qrObjectToAddDto({ id, ...qr }: Partial<IQRData>) {
  return qrObjectToDto(qr);
}

class QrMutations extends MapEntityMutations<IQRData> {
  constructor(private readonly axiosClient: AxiosInstance) {
    super();
  }

  public async add(qr: Partial<IQRData>) {
    const { data } = await this.axiosClient.post(baseUrl, qrObjectToAddDto(qr));

    return data;
  }

  public async update(qrData: IQRData) {
    const { id, qr, vertex_id } = qrData;
    const { data } = await this.axiosClient.put(
      `${baseUrl}/${id}`,
      qrObjectToDto({ qr, vertex_id })
    );

    return data;
  }

  public async delete(id: IQRData['id']) {
    const { data } = await this.axiosClient.delete(`${baseUrl}/${id}`);

    return data;
  }
}

export class MapperQrStore extends BehaviorStore<IQrStore> {
  /**
   * Методы для мутации данных (отправляют http запросы на сервер)
   */
  public readonly mutations: QrMutations;
  /**
   * Колбеки для подписок Echo (обновляют стейт)
   */
  public readonly echoCallbacks = new QrEchoCallbacks(this);

  /**
   * AxiosQuery стор.
   * Обновляет стейт при изменение этажа/кампуса
   * @protected
   */
  protected readonly query: QrQueryStore;
  protected readonly sub = new SubscriptionManager();

  private readonly authStore: AuthStore;

  constructor({
    authStore,
    axiosClient
  }: {
    authStore: AuthStore;
    axiosClient: AxiosInstance;
  }) {
    super({
      data: null
    });
    this.authStore = authStore;
    this.mutations = new QrMutations(axiosClient);
    this.query = new QrQueryStore(axiosClient);
  }

  onSubscribe() {
    this.sub.subscribe(this.authStore.state$, () => {
      this.query.loadData(null);
    });
    // Подписываемся на изменение квери стора и обновляем стейт
    this.sub.subscribe(this.query.state$, ({ data }) => {
      this.setState({
        data
      });
    });
  }

  onUnsubscribe() {
    this.sub.destroy();
  }

  /**
   * Получить qr коды для вершины
   * @param nodeId id вершины
   */
  getQrCodesByNodeId(nodeId: IQRData['vertex_id']) {
    return this.state.data?.[nodeId] || [];
  }
}
