import React from 'react';
import { Divider } from '@hse-design/react';
import { localesList } from '../../../../../../../../data/core/LocaleStore';
import { LangSwitch } from './LangSwitch/LangSwitch';
import s from './LangSwitch/LangSwitch.module.scss';

export interface LangSwitchesProps {
  onLocaleSelect?: (localeId) => void;
  selectedLocaleId?: string;
}

export function LangSwitches({
  onLocaleSelect,
  selectedLocaleId
}: LangSwitchesProps) {
  return (
    <ul className={s.LangSwitches}>
      {localesList.map((lang, i) => (
        <li key={lang.id}>
          <div>
            <LangSwitch
              id={lang.id}
              text={lang.text}
              icon={lang.icon}
              isActive={lang.id === selectedLocaleId}
              onClick={() => onLocaleSelect?.(lang.id)}
            />
          </div>
          {i !== localesList.length - 1 && (
            <Divider className={s.LangSwitch__divider} />
          )}
        </li>
      ))}
    </ul>
  );
}
