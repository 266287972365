import React from 'react';
import { useStoreState } from '@proscom/prostore-react';
import { EventStoreState } from '../../../data/stores/EventStore';
import { STORE_EVENT } from '../../../data/stores';
import { RoomPerson } from '../RoomPerson/RoomPerson';
import s from './RoomExtStaff.module.scss';

export interface RoomExtStaffProps {
  staff?: any[];
}

export function RoomExtStaff({ staff }: RoomExtStaffProps) {
  const eventStoreState = useStoreState<EventStoreState>(STORE_EVENT);
  return !eventStoreState.params.hideStaff && staff && staff.length > 0 ? (
    <ul className={s.RoomExtStaff}>
      {staff.map((item, i) => (
        <li key={i} className={s.RoomExtStaff__item}>
          <RoomPerson {...item} />
        </li>
      ))}
    </ul>
  ) : null;
}
