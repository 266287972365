import { IQueryTransformer } from '@proscom/prostore-react-router';
import { tryNumber } from '@proscom/ui-utils';

export const stringifySimple = <T>(x: T) => (x ? String(x) : undefined);

export const stringifyNumber = <T>(x: T) =>
  typeof x === 'number' ? String(x) : undefined;

const single = <T>(x: T | T[]) => (Array.isArray(x) ? x[0] : x);

export const stringTransformer: IQueryTransformer<string | undefined> = {
  parse(value) {
    return single(value) || undefined;
  },
  stringify: stringifySimple
};

export const numberTransformer: IQueryTransformer<number | undefined> = {
  parse(value) {
    return tryNumber(value, undefined) ?? undefined;
  },
  stringify: stringifyNumber
};

export const floorTransformer: IQueryTransformer<number> = {
  parse(queryValue) {
    return tryNumber(queryValue, 1);
  },
  stringify: stringifyNumber
};

export const booleanTransformer: IQueryTransformer<boolean> = {
  parse(value) {
    return value === 'true';
  },
  stringify: stringifySimple
};
