import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObservableStore } from '@proscom/prostore';
import { IQRData, MapperQrStore } from './MapperQrStore';
import { SelectedNode } from './SelectedNode';

export class SelectedNodeQrs extends ObservableStore<IQRData[] | null> {
  constructor(
    private deps: {
      mapperQr: MapperQrStore;
      selectedNode: SelectedNode;
    }
  ) {
    super(
      combineLatest([deps.mapperQr.state$, deps.selectedNode.state$]).pipe(
        map(([mapper, selectedNode]) => {
          if (!mapper.data) return null;
          const id = selectedNode?.id;
          return id ? deps.mapperQr.getQrCodesByNodeId(id) : null;
        })
      ),
      null
    );
  }
}
