import inside from 'point-in-polygon';
import { getBbox } from '../utils/geometry/getBbox';
import { Polygon, TuplePoint2D } from '../utils/geometry/types';
import { getRingArea } from '../utils/geometry/getRingArea';
import { RoomDto } from './api/RoomDto';
import { LocaleStore } from './core/LocaleStore';

export class Room {
  id: number;
  building_id: number;
  floor_number: number;
  icon: string | null;
  bounds: Polygon;
  bounds_rings_directions: number[];
  shown_min_scale: number;
  name: string;
  map_name: string;
  room_category: {
    id: number;
    code: string;
    name: string;
  } | null;
  center: TuplePoint2D;
  position: TuplePoint2D;
  label_position: TuplePoint2D;
  is_object: boolean;
  campus_id: number;
  color?: string;

  constructor(room: RoomDto) {
    this.id = room.id;
    this.floor_number = room.floor_number;
    this.building_id = room.building_id;
    this.icon = room.icon;
    this.bounds = room.bounds;
    this.room_category = room.room_category && {
      id: room.room_category.id,
      code: room.room_category.code,
      name: room.room_category.name
    };
    this.campus_id = room.campus_id;
    this.name = room.name;
    this.color = room.color;

    /**
     * Если массив room.bounds состоит более чем из одного элемента, элементы
     * массива делятся на 2 типа: массивы, представляющие полигоны, из которых
     * состоит комната, и массивы, пресдтавляющие "дырки" в полигонах, из
     * которых состоит комната.
     *
     * Отличаются массивы этих двух типов направлением, в котором в них
     * перечислены вершины полигона: по часовой стрелке и против (не имеет
     * значения, у каого типа какое конкретно направление, нужно только их
     * отличать).
     *
     * Направления этих массивов, если их больше одного, записываются в массив
     * this.bounds_rings_directions значениями -1, 1 (также 0, если полигон,
     * оказался плоским, т.е. с нулевой площадью).
     *
     * Для определения направления используется значение суммарного угла
     * поворота между парами векторов, соответствующих сторонам полигона.
     * Оно вычисляется следующим образом:
     * 1. Для каждой очередной точки выбираются следующая и предыдущая точки
     * полигона - prevPoint и nextPoint.
     * 2. Из точек составляется пара векторов vectors: из предыдущей в текущую
     * и из текущей в следующую.
     * 3. Вычисляются длины векторов vectorsLength.
     * 4. Если длина одного из векторов нулевая, угол поворота не меняется.
     * 6. Вычисляется скалярное произведение dotProduct, из него и длин векторов
     * вычисляется угол между векторами.
     * 7. Для определения направления угла поворота используется векторное
     * произведение векторов: т.к. оба лежат в плоскости осей x и y, поэтому x-
     * и y-координаты веторного произведения равны 0 (оно перпендикулярно
     * плоскости осей x и y), достаточно посчитать знак его z-координаты
     * crossProductZ - он будет отличать поворот по часовой стрелке от поворота
     * против.
     * 8. В this.bounds_rings_directions записывается знак суммарного угла
     * поворота rotateAngle.
     *
     * this.bounds_rings_directions можно использовать:
     * 1. Для вычисления площади комнаты: площади полигонов домножаются на
     * коэффициенты из this.bounds_rings_directions и суммируются, площадь
     * равна абсолютному значению суммы (таким образом из площадей полигонов
     * вычитаются площади дырок).
     * 2. Для определения нажатия на комнату: для комнаты с несколькими
     * элементами вычисляется значение isClickedIndicator. В this.bounds
     * алгоритм point-in-polygon определяет для каждого элемента this.bounds,
     * находится ли точка нажатия в полигоне, если да, прибавляет значение
     * соответствующего элемента из this.bounds_rings_directions к индикатору
     * isClickedIndicator. Если в итоге isClickedIndicator === 0, значит, точка
     * "вошла" в полигон комнаты столько же раз, сколько в "дырку" - в итоге
     * не "вошла" в комнату; если isClickedIndicator !== 0 - значит, "вошла".
     */
    this.bounds_rings_directions = [];
    let area;
    if (this.bounds.length > 1) {
      this.bounds_rings_directions = this.bounds.map((ring) =>
        Math.sign(
          ring.reduce((rotateAngle, point, pointI) => {
            const prevPoint =
              pointI === 0 ? ring[ring.length - 1] : ring[pointI - 1];
            const nextPoint =
              pointI === ring.length - 1 ? ring[0] : ring[pointI + 1];

            const vectors = [
              [point[0] - prevPoint[0], point[1] - prevPoint[1]],
              [nextPoint[0] - point[0], nextPoint[1] - point[1]]
            ];
            const vectorsLength = [
              Math.hypot(vectors[0][0], vectors[0][1]),
              Math.hypot(vectors[1][0], vectors[1][1])
            ];
            if (vectorsLength[0] === 0 || vectorsLength[1] === 0) {
              return rotateAngle;
            }

            const dotProduct =
              vectors[0][0] * vectors[1][0] + vectors[0][1] * vectors[1][1];
            let cos = dotProduct / (vectorsLength[0] * vectorsLength[1]);
            if (cos > 1) cos = 1;
            else if (cos < -1) cos = -1;
            const angle = Math.acos(cos);

            const crossProductZ =
              vectors[0][0] * vectors[1][1] - vectors[0][1] * vectors[1][0];

            return rotateAngle + angle * Math.sign(crossProductZ);
          }, 0)
        )
      );
      area = Math.abs(
        this.bounds.reduce(
          (area, ring, boundsItemI) =>
            area +
            getRingArea(ring) * this.bounds_rings_directions[boundsItemI],
          0
        )
      );
    } else if (this.bounds[0]) {
      area = getRingArea(this.bounds[0]);
    }

    /**
     * Минимальный масштаб, при котором на карте отрисовывается название
     * комнаты, линейно зависит от площади комнаты, при этом коэффициенты
     * линейной функции различны для комнат с площадью меньшей и большей 17000.
     *
     * Коэффициенты прямых подобраны по 3 комнатам:
     * - N115, самая маленькая комната
     * (площадь ~ 395, показывается при масштабе ~ 0.815);
     * - Центральный Атриум, вторая по площади комната
     * (площадь ~ 557000, показывается при масштабе 0.1);
     * - L112 - комната, на которой сменяется функция расчета минимального масштаба
     * (площадь ~ 16700, показывается при масштабе ~ 0.146).
     */
    if (area > 17000) this.shown_min_scale = -1.76e-7 * area + 0.198;
    else this.shown_min_scale = -0.0000366 * area + 0.828;

    this.map_name = room.map_name || '';

    const bbox = getBbox(this.bounds);
    this.center = [(bbox.minX + bbox.maxX) / 2, (bbox.minY + bbox.maxY) / 2];
    this.label_position = room.label_position || this.center;
    this.is_object = !!room.position;
    this.position = room.position || this.center;
  }

  isPointInside = (point: [number, number]) => {
    if (this.bounds.length === 1) return inside(point, this.bounds[0]);
    else {
      let isClickedIndicator = 0;
      this.bounds.forEach((roomPolygon, roomPolygonI) => {
        if (inside(point, roomPolygon)) {
          isClickedIndicator += this.bounds_rings_directions[roomPolygonI];
        }
      });
      return isClickedIndicator !== 0;
    }
  };

  getState = (
    selectedRoomId,
    selectedCategoryId,
    isRouteSelected,
    isRouteStartOrEnd
  ) => {
    if (isRouteSelected) {
      if (isRouteStartOrEnd || this.id === selectedRoomId) return 'active';
      else return 'inactive';
    }
    if (!this.icon && this.id === selectedRoomId) return 'selected';
    if (this.icon && this.id === selectedRoomId) return 'active';
    if (this.room_category && this.room_category.id === selectedCategoryId) {
      return 'active';
    }
    return 'inactive';
  };

  getIconState = (selectedRoomId, selectedCategoryId, isRouteSelected) => {
    if (isRouteSelected) {
      if (this.id === selectedRoomId) return 'active';
      else return 'inactive';
    }
    if (this.id === selectedRoomId) return 'selected';
    if (
      this.id !== selectedRoomId &&
      this.room_category &&
      this.room_category.id !== selectedCategoryId &&
      selectedCategoryId !== -1
    ) {
      return 'inactive';
    }
    return 'active';
  };
}

export const getCategoryName = (
  room: RoomDto,
  localeStore?: LocaleStore
): string => {
  const emptyCategoryName = ' ';
  if (!room || !room.room_category) return emptyCategoryName;
  let categoryName;
  if (room.room_category.code === 'other') {
    categoryName = 'Room';
  } else if (room.room_category.code === 'atrium') {
    categoryName = emptyCategoryName;
  } else if (room.room_category.code === 'entrance') {
    categoryName = emptyCategoryName;
  } else {
    categoryName = room.room_category.name;
  }
  // todo убрать этот костыль
  if (localeStore && localeStore.t(categoryName)) {
    categoryName = localeStore.t(categoryName);
  }
  return categoryName;
};

export const getRoomName = (
  room: RoomDto | { name: string },
  withCategory: boolean,
  localeStore?: LocaleStore
) => {
  if (!withCategory) return room.name;
  if (!(room as any).room_category) return room.name;
  const croom = room as RoomDto;
  const frontCategoryName = getCategoryName(croom, localeStore);
  return `${frontCategoryName} ${room.name}`;
};
