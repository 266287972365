import { VertexType } from './VertexType';
import { RoomDto } from './api/RoomDto';
import { CampusDto } from './api/CampusDto';

export interface IVertex {
  id: number;
  type: VertexType;
  room_id: number;
  campus_id: number;
  building_id: number | null;
  x: number;
  y: number;
  floor_number: number;
  room?: RoomDto;
  campus?: CampusDto;
}

export function isFloorChangingVertexType(type: VertexType) {
  return [VertexType.stairs, VertexType.elevator].includes(type);
}
