import React from 'react';
import clsx from 'clsx';
import { DivPropsWithoutRef } from '@hse-design/react';
import s from './PopupProp.module.scss';

export interface PopupPropProps extends DivPropsWithoutRef {
  label?: React.ReactNode;
}

export const PopupProp = React.memo(function PopupProp({
  className,
  children,
  label,
  ...props
}: PopupPropProps) {
  return (
    <div className={clsx(s.PopupProp, className)} {...props}>
      {label && <div className={s.PopupProp__Label}>{label}</div>}
      {children && <div className={s.PopupProp__Value}>{children}</div>}
    </div>
  );
});

export const PopupGrid = React.memo(function PopupGrid({
  className,
  children,
  ...props
}: DivPropsWithoutRef) {
  return (
    <div className={clsx(s.PopupGrid, className)} {...props}>
      {children}
    </div>
  );
});
