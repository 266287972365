import { useLocationQuery as useProstoreLocationQuery } from '@proscom/prostore-react-router/lib/useLocationQuery';
import { LocationStore } from '@proscom/prostore-react-router';
import { map } from 'rxjs/operators';
import { TransformedQueryParams } from '../../data/core/queryKeys';
import { STORE_LOCATION } from '../../data/stores';

export function locationStoreGet$<
  Keys extends readonly string[] = readonly string[]
>(locationStore: LocationStore, ...keys: Keys) {
  return locationStore.get$<TransformedQueryParams, Keys>(...keys);
}

export function locationStoreGetOne$<Key extends string>(
  location: LocationStore,
  key: Key
) {
  return locationStoreGet$(location, key).pipe(map((query) => query[key]));
}

export function useLocationQuery<
  Keys extends readonly string[] = readonly string[]
>(keys: Keys) {
  return useProstoreLocationQuery<TransformedQueryParams, Keys>(
    STORE_LOCATION,
    keys
  );
}
