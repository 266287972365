export function sum<T>(
  array: T[],
  selector: (item: T, iItem: number) => number
) {
  let result = 0;
  for (let i = 0; i < array.length; i++) {
    result += selector(array[i], i);
  }
  return result;
}
