import { ValueStore } from '@proscom/prostore';
import { Effect } from '../../../../utils/prostore/Effect';
import { IVertexData } from './NodesStore';

export class ModeSetRoom
  extends ValueStore<IVertexData | null>
  implements Effect {
  constructor() {
    super(null);
  }

  on() {
    this.setState(null);
  }

  off() {
    this.setState(null);
  }
}
