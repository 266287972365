import { AxiosInstance } from 'axios';
import { BehaviorStore, SubscriptionManager } from '@proscom/prostore';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { RouteStore } from '../stores/RouteStore';

export interface StatisticsStoreArgs {
  client: AxiosInstance;
  routeStore: RouteStore;
}

export class StatisticsStore extends BehaviorStore<{}> {
  client: AxiosInstance;
  routeStore: RouteStore;
  sub = new SubscriptionManager();

  constructor({ client, routeStore }: StatisticsStoreArgs) {
    super({});
    this.client = client;
    this.routeStore = routeStore;
  }

  register() {
    this.sub.subscribe(
      this.routeStore.state$.pipe(
        map((state) => state.routeStartPoint?.id),
        distinctUntilChanged()
      ),
      this.recordRoomFrom
    );
    this.sub.subscribe(
      this.routeStore.state$.pipe(
        map((state) => state.routeEndPoint?.id),
        distinctUntilChanged()
      ),
      this.recordRoomTo
    );
  }

  destroy() {
    this.sub.destroy();
  }

  recordRoomFrom = (roomId?: number) => {
    if (roomId) {
      this.client
        .post('/api/front/route/from', {
          room_id: roomId
        })
        .catch(() => {});
    }
  };

  recordRoomTo = (roomId?: number) => {
    if (roomId) {
      this.client
        .post('/api/front/route/to', {
          room_id: roomId
        })
        .catch(() => {});
    }
  };
}
