import type { AxiosInstance } from 'axios';
import type { LocationStore } from '@proscom/prostore-react-router';
import {
  BehaviorStore,
  skipIfNull,
  SubscriptionManager
} from '@proscom/prostore';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { combineLatest } from 'rxjs';
import immer from 'immer';
import { isNil } from 'lodash';
import type { CampusStore } from '../../../../data/stores/CampusStore';
import { EntityEchoCallbacks, MapEntityMutations } from '../entities/MapEntity';
import {
  QUERY_KEY_CAMPUS,
  QUERY_KEY_FLOOR
} from '../../../../data/core/queryKeys';
import { Point } from '../../utils';
import { GetQueryVars } from '../../../../data/api/common';
import { locationStoreGet$ } from '../../../../common/hooks/useLocationQuery';
import type { AuthStore } from './AuthStore';
import { MapperFloorsStore } from './MapperFloorsStore';

type I18nLanguages = 'ru' | 'en';
type I18nRecord<ValueType> = Partial<Record<I18nLanguages, ValueType>>;

export interface AuthRoomDto {
  id: number;
  code: string;
  bounds?: Point[][];
  icon?: string;
  label_position?: number[];
  position?: number[];
  name?: I18nRecord<string>;
  map_name?: I18nRecord<string>;
  description?: I18nRecord<string>;
  floor_number: number;
  floor_id?: number;
  building_id?: number;
  campus_id: number;
  room_category_id: number;
}

const baseUrl = '/api/rooms';

export interface IRoomsStoreState {
  campus_id: number | null;
  floor: number | null;
  data: Record<AuthRoomDto['id'], AuthRoomDto> | null;
}

/**
 * Стор с комнатами от сервера
 */
class RoomsQueryStore extends AxiosQueryStore<
  GetQueryVars<{
    floor: number;
    campus_id: number;
  }> | null,
  AuthRoomDto[] | null
> {
  constructor({ axiosClient }: { axiosClient: AxiosInstance }) {
    super({
      client: axiosClient,
      skipQuery: skipIfNull(null),
      mapData: ({ data }: { data: AuthRoomDto[] }) => {
        return data;
      },
      query: {
        url: baseUrl
      },
      initialData: null
    });
  }
}

/**
 * Колбеки для Echo
 */
class RoomsEchoCallbacks implements EntityEchoCallbacks<AuthRoomDto> {
  store: BehaviorStore<IRoomsStoreState>;
  mapperFloorsStore: MapperFloorsStore;

  constructor({
    store,
    mapperFloorsStore
  }: {
    store: BehaviorStore<IRoomsStoreState>;
    mapperFloorsStore: MapperFloorsStore;
  }) {
    this.store = store;
    this.mapperFloorsStore = mapperFloorsStore;
  }

  public onAdd(room: AuthRoomDto) {
    // Проверяем, относится ли добавленная вершина к текущему кампусу и этажу
    if (room.campus_id && room.campus_id !== this.store.state.campus_id) return;
    if (room.floor_number && room.floor_number !== this.store.state.floor) {
      return;
    }
    this.addBuildingIdAndFloorNumber(room);
    this.store.setState({
      data: immer(this.store.state.data, (d) => {
        if (!d) return;
        d[room.id] = room;
      })
    });
  }

  public onUpdate(room: AuthRoomDto) {
    this.addBuildingIdAndFloorNumber(room);
    this.store.setState({
      data: immer(this.store.state.data, (d) => {
        if (!d) return;
        d[room.id] = room;
      })
    });
  }

  public onDelete(room: AuthRoomDto) {
    this.store.setState({
      data: immer(this.store.state.data, (d) => {
        if (!d) return;
        delete d[room.id];
      })
    });
  }

  addBuildingIdAndFloorNumber(room: AuthRoomDto) {
    const floor =
      room.floor_id && this.mapperFloorsStore.findById(room.floor_id);
    if (!floor) return room;
    room.building_id = floor.building_id;
    room.floor_number = floor.number;
  }
}

class RoomsMutations extends MapEntityMutations<AuthRoomDto> {
  axiosClient: AxiosInstance;
  mapperFloorsStore: MapperFloorsStore;

  constructor({
    axiosClient,
    mapperFloorsStore
  }: {
    axiosClient: AxiosInstance;
    mapperFloorsStore: MapperFloorsStore;
  }) {
    super();
    this.axiosClient = axiosClient;
    this.mapperFloorsStore = mapperFloorsStore;
  }

  roomObjectToDto(room: Partial<AuthRoomDto>) {
    const foundFloor =
      (room.building_id &&
        room.floor_number !== undefined &&
        this.mapperFloorsStore.getFloor({
          building_id: room.building_id,
          number: room.floor_number
        })) ||
      undefined;
    room.floor_id = foundFloor?.id;
    return JSON.stringify(room);
  }

  roomObjectToAddDto({ id, ...room }: Partial<AuthRoomDto>) {
    return this.roomObjectToDto(room);
  }

  public async add(room: Partial<AuthRoomDto>) {
    const { data } = await this.axiosClient.post(
      baseUrl,
      this.roomObjectToAddDto(room)
    );

    return data;
  }

  public async update(room: Partial<AuthRoomDto>) {
    const { data } = await this.axiosClient.post(
      baseUrl,
      this.roomObjectToDto(room)
    );

    return data;
  }

  public async delete(id: AuthRoomDto['id']) {
    const { data } = await this.axiosClient.delete(`${baseUrl}/${id}`);

    return data;
  }
}

export class RoomsStore extends BehaviorStore<IRoomsStoreState> {
  /**
   * Методы для мутации данных (отправляют http запросы на сервер)
   */
  public readonly mutations: RoomsMutations;
  /**
   * Колбеки для подписок Echo (обновляют стейт)
   */
  public readonly echoCallbacks: RoomsEchoCallbacks;

  /**
   * AxiosQuery стор.
   * Обновляет стейт при изменение этажа/кампуса
   * @protected
   */
  protected readonly query: RoomsQueryStore;
  protected readonly sub = new SubscriptionManager();

  private readonly authStore: AuthStore;
  private readonly locationStore: LocationStore;
  private readonly campusStore: CampusStore;
  private readonly mapperFloorsStore: MapperFloorsStore;

  constructor({
    authStore,
    axiosClient,
    locationStore,
    campusStore,
    mapperFloorsStore
  }: {
    axiosClient: AxiosInstance;
    authStore: AuthStore;
    locationStore: LocationStore;
    campusStore: CampusStore;
    mapperFloorsStore: MapperFloorsStore;
  }) {
    super({
      campus_id: null,
      floor: null,
      data: null
    });
    this.authStore = authStore;
    this.locationStore = locationStore;
    this.campusStore = campusStore;
    this.mapperFloorsStore = mapperFloorsStore;
    this.query = new RoomsQueryStore({ axiosClient });
    this.mutations = new RoomsMutations({
      axiosClient,
      mapperFloorsStore
    });
    this.echoCallbacks = new RoomsEchoCallbacks({
      store: this,
      mapperFloorsStore
    });
  }

  onSubscribe() {
    // Храним актуальные значения floor и campus_id
    this.sub.subscribe(
      combineLatest([
        locationStoreGet$(
          this.locationStore,
          QUERY_KEY_FLOOR,
          QUERY_KEY_CAMPUS
        ),
        this.campusStore.state$,
        this.authStore.state$
      ]),
      ([query, campus]) => {
        const floor = query[QUERY_KEY_FLOOR];
        const campus_id = campus?.id;
        this.setState({
          floor,
          campus_id
        });
        this.query.loadData(
          !isNil(floor) && campus_id
            ? {
                params: {
                  floor,
                  campus_id
                }
              }
            : null
        );
      }
    );

    // Подписываемся на изменение квери стора и обновляем стейт
    this.sub.subscribe(
      combineLatest([this.query.state$, this.mapperFloorsStore.state$]),
      ([{ data, variables }, floors]) => {
        const map: IRoomsStoreState['data'] = {};
        if (data && variables) {
          for (const room of data) {
            // Бэк возвращает комнаты для всех кампусов. Фильтруем нужный
            if (room.campus_id !== variables.params?.campus_id) {
              continue;
            }
            // Бэк возвращает неверные building_id и floor_number, костыляем
            const floor =
              room.floor_id && this.mapperFloorsStore.findById(room.floor_id);
            if (!floor) continue;
            map[room.id] = {
              ...room,
              building_id: floor.building_id,
              floor_number: floor.number
            };
          }
        }
        this.setState({
          data: map
        });
      }
    );
  }

  onUnsubscribe() {
    this.sub.destroy();
  }
}
