import { useStoreState } from '@proscom/prostore-react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import clsx from 'clsx';
import { DivPropsWithoutRef } from '@hse-design/react';
import {
  STORE_CAMPUS,
  STORE_CAMPUSES,
  STORE_WINDOW_SIZE
} from '../../../../data/stores';
import { CampusesStoreState } from '../../../../data/stores/CampusesStore';
import {
  IWindowSizeStoreState,
  WindowSizeBreakpoint
} from '../../../../data/core/WindowSizeStore';
import { QUERY_KEY_FLOOR } from '../../../../data/core/queryKeys';
import { useLocationQuery } from '../../../hooks/useLocationQuery';
import { CampusDto } from '../../../../data/api/CampusDto';
import s from './FloorControl.module.scss';

const queryKeys = [QUERY_KEY_FLOOR] as const;
interface FloorControlProps extends DivPropsWithoutRef {
  additionalBottomMargin?: boolean;
}

export function FloorControl({
  className,
  additionalBottomMargin,
  ...props
}: FloorControlProps) {
  const [query, locationStore] = useLocationQuery(queryKeys);
  const selectedFloor = query[QUERY_KEY_FLOOR];
  const windowSize = useStoreState<IWindowSizeStoreState>(STORE_WINDOW_SIZE);
  const campusesStoreState = useStoreState<CampusesStoreState>(STORE_CAMPUSES);
  const selectedCampus = useStoreState<CampusDto>(STORE_CAMPUS);
  const campusesFloors = campusesStoreState.data?.floors;

  const onFloorSelect = useCallback(
    (floor) => {
      if (selectedFloor !== floor) {
        locationStore.changeQuery({
          [QUERY_KEY_FLOOR]: floor
        });
      }
    },
    [locationStore, selectedFloor]
  );

  const floors = useMemo(() => {
    if (!campusesFloors || !selectedCampus) return null;
    const floors = campusesFloors[selectedCampus.code];
    if (!floors) return null;
    return floors.slice().sort((floorA, floorB) => floorB - floorA);
  }, [campusesFloors, selectedCampus]);

  const activeItemRef = useRef<HTMLElement | null>(null);
  const scrollWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollWrapper = scrollWrapperRef.current;
    if (!scrollWrapper) return;

    if (windowSize.breakpoint !== WindowSizeBreakpoint.desktop) {
      scrollWrapper.style.maxHeight = '';
    }
    const headerHeight = 41;
    const maxMapControlOffset = 124;
    const additionalMargin = 16;
    scrollWrapper.style.maxHeight =
      windowSize.height -
      headerHeight -
      maxMapControlOffset * 2 -
      additionalMargin * 2 +
      'px';
  });

  useEffect(() => {
    const currentElement = activeItemRef.current;
    const scrollElement = scrollWrapperRef.current;

    if (!currentElement || !scrollElement) {
      return;
    }

    const currentItemHeight = currentElement.offsetHeight;
    const wrapperOffsetHeight = scrollElement.offsetHeight;
    const currentItemTop = currentElement.offsetTop;

    const centerScrollTop =
      currentItemTop - (wrapperOffsetHeight - currentItemHeight) / 2;

    scrollElement.scrollTo(0, centerScrollTop);
  }, [selectedFloor]);

  if (!floors || !floors.length) return null;

  return (
    <div
      className={clsx(
        s.FloorControl,
        additionalBottomMargin && s.FloorControl_additionalBottomMargin,
        className
      )}
      {...props}
    >
      <div className={s.FloorControl__scroll} ref={scrollWrapperRef}>
        <ul>
          {floors &&
            floors.map((item, i) => {
              return (
                <li
                  key={i}
                  className={s.FloorControl__item}
                  ref={(ref) => {
                    if (item === selectedFloor) {
                      activeItemRef.current = ref;
                    }
                  }}
                >
                  <div
                    className={classNames(s.FloorControlItem, {
                      [s._active]: item === selectedFloor
                    })}
                    onClick={() => onFloorSelect(item)}
                  >
                    <span>{item}</span>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
