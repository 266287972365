import { useBeforeunload } from 'react-beforeunload';
import { useStore, useStoreState } from '@proscom/prostore-react';
import { useLatestCallbackRef } from '@proscom/ui-react';
import React from 'react';
import { IQRData } from '../../data/stores/MapperQrStore';
import {
  ACTION_SELECT_EDGE,
  ACTION_SELECT_NODE,
  ACTION_SELECT_ROOM,
  ACTION_START_CONNECT_NODE,
  ACTION_START_SET_ROOM,
  STORE_NODE_REMOVE_OPERATION,
  STORE_NODE_UPDATE_OPERATION,
  STORE_SELECTED_NODE,
  STORE_SELECTED_NODE_QRS
} from '../../data/stores/stores';
import { IVertexData } from '../../data/stores/NodesStore';
import { NodeUpdateOperation } from '../../data/stores/NodeUpdateOperation';
import { NodeRemoveOperation } from '../../data/stores/NodeRemoveOperation';
import { SelectedCampusFloorsState } from '../../../../data/stores/SelectedCampusFloors';
import {
  STORE_FLOOR_DATA,
  STORE_SELECTED_CAMPUS_FLOORS
} from '../../../../data/stores';
import { FloorDataStore } from '../../../../data/online/FloorDataStore';
import { useDi } from '../../../../utils/prostore/DiContext';
import { SelectEdge } from '../../data/stores/actions/SelectEdge';
import { SelectNode } from '../../data/stores/actions/SelectNode';
import { SelectRoom } from '../../data/stores/actions/SelectRoom';
import { StartSetRoom } from '../../data/stores/actions/StartSetRoom';
import { StartConnectNode } from '../../data/stores/actions/StartConnectNode';
import { NodePopup } from './NodePopup';

export function NodePopupWrapper() {
  const selectedNode = useStoreState<IVertexData>(STORE_SELECTED_NODE);
  const selectedNodeQrs = useStoreState<IQRData[] | null>(
    STORE_SELECTED_NODE_QRS
  );
  const [updateNode, updateNodeOp] = useStore<NodeUpdateOperation>(
    STORE_NODE_UPDATE_OPERATION
  );
  const [removeNode, removeNodeOp] = useStore<NodeRemoveOperation>(
    STORE_NODE_REMOVE_OPERATION
  );
  const floorsNumbers = useStoreState<SelectedCampusFloorsState>(
    STORE_SELECTED_CAMPUS_FLOORS
  );
  const [floorsDataState] = useStore<FloorDataStore>(STORE_FLOOR_DATA);

  const actionSelectNode = useDi<SelectNode>(ACTION_SELECT_NODE);
  const actionSelectEdge = useDi<SelectEdge>(ACTION_SELECT_EDGE);
  const actionSelectRoom = useDi<SelectRoom>(ACTION_SELECT_ROOM);
  const actionStartSetRoom = useDi<StartSetRoom>(ACTION_START_SET_ROOM);
  const startConnectNode = useDi<StartConnectNode>(ACTION_START_CONNECT_NODE);

  const active = selectedNode && selectedNodeQrs;

  // Показываем alert перед закрытием страницы, если открыт попап с редактированием данных
  useBeforeunload(
    useLatestCallbackRef((e) => {
      if (process.env.NODE_ENV !== 'development' && active) {
        e.preventDefault();
      }
    })
  );

  if (!active) return null;
  return (
    <NodePopup
      key={selectedNode?.id || 'none'}
      loading={updateNode.loading > 0 || removeNode.loading > 0}
      floors={floorsNumbers}
      buildings={floorsDataState.data?.buildings}
      node={selectedNode}
      qrCodes={selectedNodeQrs || undefined}
      onOpenEdge={actionSelectEdge.run}
      onOpenRoom={actionSelectRoom.run}
      onClose={() => actionSelectNode.run(null)}
      onRemove={removeNodeOp.run}
      onEditNode={updateNodeOp.run}
      onSetRoom={actionStartSetRoom.run}
      onConnect={startConnectNode.run}
    />
  );
}
