import React from 'react';
import { ClickableIcon, IconActionArrowBack } from '@hse-design/react';
import clsx from 'clsx';
import s from './MobileScreen.module.scss';

export interface MobileScreenProps {
  title?: string;
  children?: React.ReactNode;
  onBackClick?: () => void;
  actions?: React.ReactNode;
}

export function MobileScreen({
  title,
  onBackClick,
  children,
  actions
}: MobileScreenProps) {
  return (
    <div
      className={clsx(s.MobileScreen, {
        [s.MobileScreen_withActions]: !!actions
      })}
    >
      <ClickableIcon
        iconProps={{ icon: IconActionArrowBack }}
        className={s.MobileScreen__arrowBack}
        onClick={onBackClick}
      />
      <h1 className={s.MobileScreen__title}>{title}</h1>
      <div>{children}</div>
      {actions && <div className={s.MobileScreen__actions}>{actions}</div>}
    </div>
  );
}
