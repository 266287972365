import {
  QUERY_KEY_EDGE,
  QUERY_KEY_MY_LOCATION,
  QUERY_KEY_NODE,
  QUERY_KEY_ROOM
} from '../../../../../data/core/queryKeys';

export const cleanQuery = {
  [QUERY_KEY_MY_LOCATION]: undefined,
  [QUERY_KEY_ROOM]: undefined,
  [QUERY_KEY_NODE]: undefined,
  [QUERY_KEY_EDGE]: undefined
};
