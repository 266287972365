import { DivPropsWithoutRef } from '@hse-design/react';
import clsx from 'clsx';
import s from './MobileScreenContext.module.scss';

export function MobileScreenContent({
  className,
  ...props
}: DivPropsWithoutRef) {
  return <div {...props} className={clsx(className, s.MobileScreenContext)} />;
}
