import React, { useCallback } from 'react';
import { Button } from '@hse-design/react';
import { isNil } from 'lodash';
import { IVertexData } from '../.././data/stores/NodesStore';
import type { IGraph } from '../.././data/entities/IGraph';
import { dangerColor } from '../../data/theme';
import { PopupGrid, PopupProp } from '../../PopupProp';
import { AuthRoomDto } from '../../data/stores/RoomsStore';
import { UpdateRoomForm } from '../forms/UpdateRoom';
import { Building } from '../../../../data/Building';
import { Point } from '../../utils';
import { RoomCategoryDto } from '../../../../data/api/RoomCategoryDto';
import { BasePopup, BasePopupProps } from './BasePopup';
import s from '../../MapperPage.module.scss';

export interface RoomPopupProps extends BasePopupProps {
  bounds?: Point[][];
  floorsNumbers?: number[];
  buildings?: Building[];
  categories?: RoomCategoryDto[];
  room?: Partial<AuthRoomDto>;
  graph: IGraph;
  onOpenNode?: (node: IVertexData) => void;
  onSaveRoom?: (room: Partial<AuthRoomDto>) => void;
  onDeleteRoom?: (room: Partial<AuthRoomDto>) => void;
}

export function RoomPopup({
  loading,
  buildings,
  bounds,
  floorsNumbers,
  categories,
  room = {},
  graph,
  onClose,
  onOpenNode,
  onSaveRoom,
  onDeleteRoom
}: RoomPopupProps) {
  const roomDoorNodes = Object.values(graph.nodes).filter(
    (node) => node.room_id === room.id
  );

  const onSave = useCallback(
    (newRoomData) => {
      const updatedRoom = { ...room, ...newRoomData };
      const {
        // Remove fields
        label_position,
        floor_id,
        // Remove unused fields
        updated_at,
        created_at,
        deleted_at,
        // Fields that needs to be transformed
        code: newCode,
        name_ru,
        name_en,
        description_ru,
        description_en,
        category,
        map_name,
        map_name_ru,
        map_name_en,
        // Add rest
        ...data
      } = updatedRoom;
      if (room.code !== newCode) data.code = newCode;
      data.name = { ru: name_ru, en: name_en };
      data.mapName = { ru: map_name_ru, en: map_name_en };
      data.description = { ru: description_ru, en: description_en };
      data.room_category_id = category;
      data.color = data.color || [];
      data.floor_number = +data.floor_number;
      onSaveRoom?.(data);
    },
    [room, onSaveRoom]
  );

  return (
    <BasePopup
      title={room.id ? `Комната ${room.id}` : 'Новая комната'}
      loading={loading}
      onClose={onClose}
    >
      {floorsNumbers && (
        <UpdateRoomForm
          bounds={bounds}
          buildings={buildings}
          categories={categories}
          floors={floorsNumbers}
          values={{
            category: String(room.room_category_id || ''),
            code: room.code || '',
            name_en: room.name?.en || '',
            name_ru: room.name?.ru || '',
            map_name_en: room.map_name?.en || '',
            map_name_ru: room.map_name?.ru || '',
            description_en: room.description?.en || '',
            description_ru: room.description?.ru || '',
            floor_number: isNil(room.floor_number)
              ? undefined
              : String(room.floor_number),
            building_id: room.building_id
          }}
          syncValues={!!room.code}
          onSubmit={onSave}
        >
          <PopupProp label={'Двери'}>
            {!roomDoorNodes.length ? (
              'У комнаты нет дверей'
            ) : (
              <PopupGrid>
                {roomDoorNodes.map((node, iNode) => {
                  return (
                    <div key={iNode} className={s.PopupProp__Value}>
                      <Button
                        size={Button.Size.small}
                        variant={Button.Variant.tertiary}
                        onClick={() => onOpenNode?.(node)}
                      >
                        {node.id}
                      </Button>
                    </div>
                  );
                })}
              </PopupGrid>
            )}
          </PopupProp>
          <div className={s.NodePopup__Actions}>
            <Button
              className={s.NodePopup__Action}
              type={'submit'}
              variant={Button.Variant.secondary}
            >
              {room.id ? 'Применить изменения' : 'Создать комнату'}
            </Button>
            {room.id && (
              <Button
                className={s.NodePopup__Action}
                color={dangerColor}
                variant={Button.Variant.secondary}
                onClick={() => onDeleteRoom?.(room)}
              >
                Удалить комнату
              </Button>
            )}
          </div>
        </UpdateRoomForm>
      )}
    </BasePopup>
  );
}
