import React, { useEffect, useMemo } from 'react';
import {
  useContextStore,
  useStore,
  useStoreState
} from '@proscom/prostore-react';
import { ActivePanel } from '../../../../data/core/ActivePanel';
import { BottomSheetContent } from '../../../../common/mobile/BottomSheetContent/BottomSheetContent';
import { BottomSheetNew } from '../../../../common/mobile/BottomSheetNew/BottomSheetNew';
import {
  STORE_ACTIVE_PANEL,
  STORE_ROOM_SEARCH_RESULTS,
  STORE_ROUTE_ACTIVE
} from '../../../../data/stores';
import { onScrollBottom } from '../../../../utils/onScrollBottom';
import { RoomSearchResults as RoomSearchResultsStore } from '../../../../data/online/RoomSearchResults';
import { useLocationQuery } from '../../../../common/hooks/useLocationQuery';
import {
  QUERY_KEY_END_ROOM,
  QUERY_KEY_END_VERTEX,
  QUERY_KEY_START_ROOM,
  QUERY_KEY_START_VERTEX
} from '../../../../data/core/queryKeys';
import { useLocale } from '../../../../common/hooks/useLocale';
import { RoutePanelBuilder, RoutePanelBuilderProps } from './RoutePanelBuilder';
import { RoutePanelResult } from './RoutePanelResult';

export interface RoutePanelProps extends RoutePanelBuilderProps {}

export function RoutePanel({
  onSelectOnMap,
  onSelectCurrentLocation
}: RoutePanelProps) {
  const locale = useLocale();
  const isRouteActive = useStoreState<boolean>(STORE_ROUTE_ACTIVE);
  const [query] = useLocationQuery([
    QUERY_KEY_START_ROOM,
    QUERY_KEY_START_VERTEX,
    QUERY_KEY_END_ROOM,
    QUERY_KEY_END_VERTEX
  ] as const);
  const queryStartRoom = query[QUERY_KEY_START_ROOM];
  const queryEndRoom = query[QUERY_KEY_END_ROOM];
  const queryStartVertex = query[QUERY_KEY_START_VERTEX];
  const queryEndVertex = query[QUERY_KEY_END_VERTEX];

  const roomSearchResults = useContextStore<RoomSearchResultsStore>(
    STORE_ROOM_SEARCH_RESULTS
  );
  const onScroll = useMemo(
    () =>
      isRouteActive
        ? undefined
        : onScrollBottom(roomSearchResults.requestNextPage),
    [isRouteActive, roomSearchResults.requestNextPage]
  );

  const [activePanel, activePanelStore] = useStore<ActivePanel>(
    STORE_ACTIVE_PANEL
  );
  useEffect(() => {
    if (queryStartRoom || queryEndRoom || queryStartVertex || queryEndVertex) {
      setTimeout(() => {
        if (!activePanelStore.state) {
          activePanelStore.setPanel(ActivePanel.PANEL_ROUTE, true);
        }
      }, 0);
    }
  }, [
    activePanelStore,
    queryEndRoom,
    queryEndVertex,
    queryStartRoom,
    queryStartVertex
  ]);

  return (
    <BottomSheetNew
      open={activePanel === ActivePanel.PANEL_ROUTE}
      onDismiss={activePanelStore.closePanel}
      headerTitle={
        isRouteActive ? undefined : locale.t('routePanelBuild.title')
      }
      onScroll={onScroll}
      snapPoints={
        isRouteActive
          ? ({ minHeight }) => [minHeight]
          : ({ maxHeight }) => [maxHeight]
      }
      blocking={!isRouteActive}
    >
      <BottomSheetContent>
        {isRouteActive ? (
          <RoutePanelResult showFirstStep={false} showStepsList={false} />
        ) : (
          <RoutePanelBuilder {...{ onSelectCurrentLocation, onSelectOnMap }} />
        )}
      </BottomSheetContent>
    </BottomSheetNew>
  );
}
