import { RouteStep } from '../../../data/RouteStep';
import { getRoomName } from '../../../data/Room';
import { LocaleStore } from '../../../data/core/LocaleStore';
import { CampusDto } from '../../../data/api/CampusDto';
import { RoomDto } from '../../../data/api/RoomDto';
import { RouteStepType } from '../../../data/RouteStepType';
import { IRouteEdge } from '../../../data/RouteEdge';
import { IRouteSegment } from '../../../data/RouteSegment';

export interface PseudoRoom {
  name: string;
}

interface IConvertRouteParams {
  route: IRouteEdge[];
  directions: IRouteSegment[];
  campuses: CampusDto[];
  startRoom: RoomDto | PseudoRoom;
  endRoom: RoomDto | PseudoRoom;
  localeStore: LocaleStore;
}

export function convertRoute({
  route,
  directions,
  campuses,
  startRoom,
  endRoom,
  localeStore
}: IConvertRouteParams): RouteStep[] {
  const routeSteps: RouteStep[] = [];

  routeSteps.push(
    new RouteStep({
      route,
      title: getRoomName(startRoom as RoomDto, true, localeStore),
      type: RouteStepType.TYPE_START,
      room: startRoom,
      index: 0
    })
  );
  directions?.forEach((direction, directionI) =>
    routeSteps.push(
      new RouteStep({
        route,
        campuses,
        title: direction.hint,
        type: direction.type,
        index: directionI + 1,
        startIndex: direction.start_index,
        endIndex: direction.end_index,
        startFloor: direction.start_floor,
        endFloor: direction.end_floor,
        yaMapLink: direction.ya_map_link
      })
    )
  );
  routeSteps.push(
    new RouteStep({
      route,
      title: getRoomName(endRoom as RoomDto, true, localeStore),
      type: RouteStepType.TYPE_END,
      room: endRoom,
      index: routeSteps.length
    })
  );

  return routeSteps;
}
