import { Effect } from './Effect';

export class FunctionEffect implements Effect {
  private unsub?: () => void;
  constructor(private fn: () => () => void) {}
  on() {
    this.unsub = this.fn();
  }
  off() {
    this.unsub?.();
  }
}
