import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AuthStore } from '../stores/AuthStore';

export const createAuthClient = (
  authStore: AuthStore,
  config: AxiosRequestConfig = {}
): AxiosInstance => {
  const axiosClient = axios.create(config);
  axiosClient.interceptors.response.use(undefined, (error) => {
    if (error.response?.status === 401) authStore.logout();
    return Promise.reject(error);
  });
  axiosClient.interceptors.request.use((config) => {
    return authStore
      .getToken()
      .then((token) => ({
        ...config,
        headers: {
          ...config.headers,
          'Content-Type': config.headers['Content-Type'] || 'application/json',
          Accept: config.headers['Accept'] || 'application/json',
          ...(token && { Authorization: token })
        }
      }))
      .catch((e) => {
        throw new axios.Cancel(e);
      });
  });

  return axiosClient;
};
