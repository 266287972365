import { LocationStore } from '@proscom/prostore-react-router';
import { useEffect } from 'react';
import { QUERY_KEY_FLOOR } from '../../../data/core/queryKeys';
import { CampusDto } from '../../../data/api/CampusDto';

export function useCampusChangeEffect({
  campusesFloors,
  floorNumber,
  locationStore,
  selectedCampus
}: {
  campusesFloors: { [key: string]: number[] } | null;
  floorNumber: number | undefined;
  locationStore: LocationStore;
  selectedCampus: CampusDto;
}) {
  useEffect(() => {
    if (
      selectedCampus &&
      campusesFloors &&
      floorNumber !== undefined &&
      campusesFloors[selectedCampus.code] &&
      !campusesFloors[selectedCampus.code].includes(floorNumber)
    ) {
      locationStore.changeQuery({ [QUERY_KEY_FLOOR]: 1 }, true);
    }
  }, [selectedCampus, campusesFloors, floorNumber, locationStore]);
}
