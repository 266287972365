import { useStore, useStoreState } from '@proscom/prostore-react';
import { IRequestState } from '@proscom/prostore';
import React from 'react';
import clsx from 'clsx';
import {
  STORE_CATEGORIES_VISIBLE,
  STORE_SEARCH
} from '../../../../data/stores';
import { requestLoader } from '../../../../common/utils/requestLoader';
import { SearchStore } from '../../../../data/stores/SearchStore';
import { RoomCategoryDto } from '../../../../data/api/RoomCategoryDto';
import s from './SearchCategories.module.scss';

export interface SearchCategoriesBaseProps {
  categories: RoomCategoryDto[] | null;
  activeCategoryId?: number;
  onCategoryClick?: (category: RoomCategoryDto) => void;
}

export interface SearchCategoriesProps {
  className?: string;
  base: React.ComponentType<SearchCategoriesBaseProps>;
}

export function SearchCategories({
  className,
  base: Base
}: SearchCategoriesProps) {
  const categories = useStoreState<IRequestState<any, RoomCategoryDto[]>>(
    STORE_CATEGORIES_VISIBLE
  );
  const [search, searchStore] = useStore<SearchStore>(STORE_SEARCH);

  return (
    <div className={clsx(s.CategoriesList, className)}>
      {requestLoader(categories) || (
        <Base
          categories={categories.data}
          activeCategoryId={search.selectedCategoryId}
          onCategoryClick={(cat) => {
            if (search.selectedCategoryId === cat.id) {
              searchStore.unsetSelectedCategory();
            } else {
              searchStore.setSelectedCategory(cat.code);
            }
          }}
        />
      )}
    </div>
  );
}
