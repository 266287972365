import { groupMapBy } from './groupMapBy';

export type FieldFns<T, Keys extends any[]> = {
  [Key in keyof Keys]: (x: T) => Keys[Key];
};

export type DeepGroupMap<T, Keys extends any[]> = Keys extends [
  infer Key,
  ...infer Left
]
  ? Map<Key, DeepGroupMap<T, Left>>
  : T[];

export function groupMapByMulti<T, Key, Keys extends any[]>(
  collection: T[],
  fieldFn: (x: T) => Key,
  ...fieldFns: FieldFns<T, Keys>
): DeepGroupMap<T, [Key, ...Keys]> {
  if (fieldFns.length === 0) {
    // todo try to remove as any
    return groupMapBy<T, Key>(collection, fieldFn) as any;
  }

  const result1 = groupMapBy(collection, fieldFn);
  const result2 = new Map<Key, DeepGroupMap<T, Keys>>();

  for (const [key, items] of result1) {
    const [first, ...left] = fieldFns;
    result2.set(key, groupMapByMulti(items, first, ...left) as any);
  }

  return result2 as any;
}
