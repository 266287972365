import { useStore } from '@proscom/prostore-react';
import { useCallback, useEffect } from 'react';
import { STORE_ACTIVE_PANEL } from '../../../../data/stores';
import { BottomSheetNew } from '../../../../common/mobile/BottomSheetNew/BottomSheetNew';
import { ActivePanel } from '../../../../data/core/ActivePanel';
import { BottomSheetContent } from '../../../../common/mobile/BottomSheetContent/BottomSheetContent';
import { SelectOnMapPanelContent } from '../../components/SelectOnMapPanelContent/SelectOnMapPanelContent';

export interface SelectOnMapPanelProps {
  selectInMap?: 'A' | 'B';
  setStateSelectMap?: (state: 'A' | 'B' | undefined) => void;
}

export function SelectOnMapPanel({
  selectInMap,
  setStateSelectMap
}: SelectOnMapPanelProps) {
  const [activePanel, activePanelStore] = useStore<ActivePanel>(
    STORE_ACTIVE_PANEL
  );
  const isActive = activePanel === ActivePanel.PANEL_SELECT_ON_MAP;

  useEffect(() => {
    if (!selectInMap && isActive) {
      activePanelStore.setPanel(ActivePanel.PANEL_ROUTE, true);
    }
  }, [activePanelStore, isActive, selectInMap]);

  const handleDismiss = useCallback(() => {
    activePanelStore.setPanel(ActivePanel.PANEL_ROUTE);
  }, [activePanelStore]);

  return (
    <BottomSheetNew
      open={isActive}
      header={false}
      onDismiss={handleDismiss}
      blocking={false}
    >
      {selectInMap && (
        <BottomSheetContent>
          <SelectOnMapPanelContent
            selectInMap={selectInMap}
            setStateSelectMap={setStateSelectMap}
          />
        </BottomSheetContent>
      )}
    </BottomSheetNew>
  );
}
