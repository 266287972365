import React, { useCallback, useState } from 'react';
import { History } from 'history';
import { useStoreState } from '@proscom/prostore-react';
import { ILocationStoreState } from '@proscom/prostore-react-router';
import { Button, ButtonSize, ButtonVariant } from '@hse-design/react';
import { MobileScreen } from '../../../../../../common/mobile/MobileScreen/MobileScreen';
import { useLocale } from '../../../../../../common/hooks/useLocale';
import { STORE_LOCATION } from '../../../../../../data/stores';
import { LangSwitches } from './components/LangSwitches/LangSwitches';

export interface LanguagePageProps {
  history: History;
}

export function LanguagePage({ history }: LanguagePageProps) {
  const localeStore = useLocale();
  const { location } = useStoreState<ILocationStoreState>(STORE_LOCATION);
  const [selectedLocaleId, setSelectedLocaleId] = useState<string>(
    localeStore.state.localeId!
  );

  const onLocaleSelect = useCallback(
    (localeId) => setSelectedLocaleId(localeId),
    [setSelectedLocaleId]
  );

  const handleBack = () => history.goBack();

  const handleConfirm = useCallback(() => {
    localeStore.setLocale(selectedLocaleId, true);
    history.push('/' + location!.search);
  }, [selectedLocaleId, localeStore, history, location]);

  return (
    <MobileScreen
      title={localeStore.t('page.language.title')}
      onBackClick={handleBack}
      actions={
        <Button
          variant={ButtonVariant.primary}
          size={ButtonSize.large}
          onClick={handleConfirm}
        >
          {localeStore.t('button.confirm')}
        </Button>
      }
    >
      <LangSwitches
        selectedLocaleId={selectedLocaleId}
        onLocaleSelect={onLocaleSelect}
      />
    </MobileScreen>
  );
}
