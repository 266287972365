import IconMapPin from '../../../../assets/img/icons/i-pin.svg';

export class CanvasPin {
  constructor({ x, y }) {
    const icon = new Image();

    icon.src = IconMapPin;

    return {
      zIndex: 11,
      icon,
      width: 48,
      height: 48,
      x,
      centered: true,
      y,
      rectBackgroundColor: 'transparent',
      iconStyle: {
        width: 48,
        height: 48,
        iconColor: 'transparent',
        zIndex: 11,
        borderRadius: 6
      },
      type: 'icon',
      isPin: true
    };
  }
}
