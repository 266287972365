import { LocationStore, QueryPart } from '@proscom/prostore-react-router';
import { IRequestState, IStoreState } from '@proscom/prostore';
import { ObservableStore } from '../../utils/prostore/ObservableStore';
import { RouteStep } from '../RouteStep';
import { IRouteQueryStore } from '../online/RouteQueryStore';
import { combineLatestMap } from '../../utils/prostore/combineLatestMap';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import {
  QUERY_KEY_END_VERTEX,
  QUERY_KEY_START_VERTEX,
  TransformedQueryParams
} from '../core/queryKeys';
import { convertRoute } from '../../routes/index/utils/convertRoute';
import { LocaleStore } from '../core/LocaleStore';
import { RouteStore, RouteStoreState } from './RouteStore';
import {
  CurrentLocationState,
  CurrentLocationStore
} from './CurrentLocationStore';
import {
  CampusesQueryData,
  CampusesQueryVars,
  CampusesStore
} from './CampusesStore';

export type RouteStepsState = RouteStep[] | null;

export class RouteSteps extends ObservableStore<RouteStepsState> {
  constructor(
    routeQuery: IRouteQueryStore,
    routeStore: RouteStore,
    campuses: CampusesStore,
    currentLocation: CurrentLocationStore,
    private readonly locale: LocaleStore,
    locationStore: LocationStore
  ) {
    super(() => {
      return combineLatestMap(
        [
          routeQuery.state$,
          routeStore.state$,
          campuses.state$,
          currentLocation.state$,
          locationStoreGet$(
            locationStore,
            QUERY_KEY_START_VERTEX,
            QUERY_KEY_END_VERTEX
          ),
          // Needed to re-run when localeId changes
          locale.state$
        ] as const,
        ([routeQuery, routeParams, campuses, currentLocation, query]) => {
          return this.process(
            routeQuery,
            routeParams,
            campuses,
            currentLocation,
            query
          );
        }
      );
    }, null);
  }

  private process(
    routeQuery: IStoreState<IRouteQueryStore>,
    routeParams: RouteStoreState,
    campusesQuery: IRequestState<
      CampusesQueryVars | null,
      CampusesQueryData | null
    >,
    currentLocation: CurrentLocationState,
    query: QueryPart<
      TransformedQueryParams,
      [typeof QUERY_KEY_START_VERTEX, typeof QUERY_KEY_END_VERTEX]
    >
  ) {
    const campuses = campusesQuery.data?.data || undefined;
    const route = routeQuery.data?.route;
    const directions = routeQuery.data?.directions;
    const startRoom = routeParams.routeStartPoint;
    const endRoom = routeParams.routeEndPoint;
    const startVertex = query[QUERY_KEY_START_VERTEX];
    const endVertex = query[QUERY_KEY_END_VERTEX];

    const isStartVertexPin = Boolean(
      startVertex && currentLocation.location?.id === startVertex
    );
    const isEndVertexPin = Boolean(
      endVertex && currentLocation.location?.id === endVertex
    );

    const startRoomData = isStartVertexPin
      ? { name: this.locale.t('currentLocation.title') }
      : startRoom;

    const endRoomData = isEndVertexPin
      ? { name: this.locale.t('currentLocation.title') }
      : endRoom;

    if (
      route &&
      route.length &&
      directions &&
      campuses &&
      startRoomData &&
      endRoomData
    ) {
      return convertRoute({
        route,
        directions,
        campuses,
        startRoom: startRoomData,
        endRoom: endRoomData,
        localeStore: this.locale
      });
    } else {
      return null;
    }
  }
}
