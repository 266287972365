import { LocationStore } from '@proscom/prostore-react-router';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObservableStore } from '@proscom/prostore';
import { locationStoreGet$ } from '../../../../common/hooks/useLocationQuery';
import { QUERY_KEY_NODE } from '../../../../data/core/queryKeys';
import { IVertexData, NodesStore } from './NodesStore';

export class SelectedNode extends ObservableStore<IVertexData | undefined> {
  constructor(
    private deps: {
      nodes: NodesStore;
      location: LocationStore;
    }
  ) {
    super(
      combineLatest([
        deps.nodes.state$,
        locationStoreGet$(deps.location, QUERY_KEY_NODE)
      ]).pipe(
        map(([nodes, query]) => {
          const queryNode = query[QUERY_KEY_NODE];
          return (queryNode && nodes.data?.[queryNode]) || undefined;
        })
      ),
      undefined
    );
  }
}
