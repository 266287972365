import React from 'react';
import { IRequestState } from '@proscom/prostore';
import { Loader } from '../ui/Loader/Loader';
import { QueryErrorMessage } from '../ui/QueryErrorMessage/QueryErrorMessage';

export function requestLoader(
  query: IRequestState<any, any>,
  options?: {
    loader?: JSX.Element;
    onReload?: () => void;
  }
) {
  return !query.loaded || query.loading ? (
    options?.loader || <Loader />
  ) : query.error ? (
    <QueryErrorMessage error={query.error} onReload={options?.onReload} />
  ) : null;
}
