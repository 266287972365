import { LocationStore } from '@proscom/prostore-react-router';
import { useEffect } from 'react';
import { RouteStep } from '../../../data/RouteStep';
import { CampusDto } from '../../../data/api/CampusDto';

export function useStepChangeEffect({
  floorNumber,
  campuses,
  selectedCampusId,
  selectedRouteStep,
  locationStore
}: {
  floorNumber: number | undefined;
  campuses: CampusDto[] | undefined;
  selectedCampusId: number;
  locationStore: LocationStore;
  selectedRouteStep: RouteStep | null | undefined;
}) {
  useEffect(() => {
    if (selectedRouteStep) {
      const queryChanges: any = {};
      const stepCampus =
        campuses && campuses.find((c) => c.id === selectedRouteStep.campusId);
      if (stepCampus && selectedCampusId !== selectedRouteStep.campusId) {
        queryChanges.campus = stepCampus.code;
      }
      if (selectedRouteStep.floorNumber !== floorNumber) {
        queryChanges.floor = selectedRouteStep.floorNumber;
      }
      if (Object.keys(queryChanges).length) {
        locationStore.changeQuery(queryChanges, true);
      }
    }
  }, [selectedRouteStep]); // eslint-disable-line react-hooks/exhaustive-deps
}
