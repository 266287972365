import { parse as parseUrl } from 'url';
import {
  createBrowserHistory,
  Location,
  LocationDescriptorObject
} from 'history';
import { define, getParentUrl } from '@proscom/ui-utils';

export type LocationState = { depth?: number };
export type UrlOrLocation = string | Location<LocationState>;

function increaseDepth<T extends { depth?: number }>(state?: T): T {
  if (!state) {
    return {
      depth: 1
    } as T;
  }
  return {
    ...state,
    depth: (state.depth || 0) + 1
  } as T;
}

export function getLocationDepth(location: Location<LocationState>): number {
  return location.state?.depth || 0;
}

const defineDescriptor = define<LocationDescriptorObject<LocationState>>();

export function createAppHistory(
  onChange:
    | ((params: { location: Location; depth: number }) => any)
    | undefined = undefined
) {
  const baseHistory = createBrowserHistory<LocationState>();

  if (onChange) {
    baseHistory.listen((location: Location<LocationState>) => {
      onChange({
        location,
        depth: getLocationDepth(location)
      });
    });
  }

  function getLocation() {
    return baseHistory.location;
  }

  function resolveParentUrl() {
    return getParentUrl(getLocation().pathname);
  }

  function goBack(parent: null | UrlOrLocation = null) {
    const curLocation = getLocation();
    const appDepth = getLocationDepth(curLocation);
    if (appDepth > 0) {
      baseHistory.goBack();
    } else {
      let url = parent;
      if (url === null) {
        url = resolveParentUrl();
      }

      if (typeof url === 'string') {
        const parsed = parseUrl(url);
        baseHistory.push(
          defineDescriptor({
            state: curLocation.state,
            pathname: parsed.pathname || undefined,
            search: parsed.search || undefined,
            hash: parsed.hash || undefined
          })
        );
      } else {
        baseHistory.push(
          defineDescriptor({
            // state: curLocation.state,
            ...url
          })
        );
      }
    }
  }

  function pushState(state: LocationState) {
    const curLocation = getLocation();
    baseHistory.push({
      state: {
        ...increaseDepth(curLocation.state),
        ...(state || {})
      }
    });
  }

  function push(location: UrlOrLocation) {
    const curLocation = getLocation();
    const state = increaseDepth(curLocation.state);
    if (typeof location === 'string') {
      const parsed = parseUrl(location);
      baseHistory.push(
        defineDescriptor({
          state,
          pathname: parsed.pathname || undefined,
          search: parsed.search || undefined,
          hash: parsed.hash || undefined
        })
      );
    } else {
      baseHistory.push(
        defineDescriptor({
          // state,
          ...location
        })
      );
    }
  }

  function replace(location: UrlOrLocation) {
    const curLocation = getLocation();
    const state = curLocation.state;
    if (typeof location === 'string') {
      const parsed = parseUrl(location);
      baseHistory.replace(
        defineDescriptor({
          state,
          pathname: parsed.pathname || undefined,
          search: parsed.search || undefined,
          hash: parsed.hash || undefined
        })
      );
    } else {
      baseHistory.replace(
        defineDescriptor({
          // state,
          ...location
        })
      );
    }
  }

  return {
    ...baseHistory,
    goBack,
    push,
    replace,
    getLocation,
    pushState
  };
}
