import { useEffect } from 'react';
import { EchoEvents } from '../data/echo/events';
import type { EchoStore } from '../data/stores/EchoStore';
import type { NodesStore } from '../data/stores/NodesStore';
import type { EdgesStore } from '../data/stores/EdgesStore';
import type { RoomsStore } from '../data/stores/RoomsStore';
import type { MapperQrStore } from '../data/stores/MapperQrStore';

export interface useEchoMutationsCallbacksProps {
  echoStore: EchoStore;
  nodesStore: NodesStore;
  edgesStore: EdgesStore;
  roomsStore: RoomsStore;
  mapperQrStore: MapperQrStore;
}

export function useEchoMutationsCallbacks({
  echoStore,
  nodesStore,
  edgesStore,
  roomsStore,
  mapperQrStore
}: useEchoMutationsCallbacksProps) {
  useEffect(() => {
    if (!echoStore.isConnected) return;
    const eventCallbackMap: Record<EchoEvents, Function> = {
      [EchoEvents.VERTEX_CREATED]: (node) => {
        nodesStore.echoCallbacks.onAdd(node);
      },
      [EchoEvents.VERTEX_UPDATED]: (node) => {
        nodesStore.echoCallbacks.onUpdate(node);
      },
      [EchoEvents.VERTEX_DELETED]: (node) => {
        nodesStore.echoCallbacks.onDelete(node);
      },
      [EchoEvents.EDGE_CREATED]: (edge) => {
        edgesStore.echoCallbacks.onAdd(edge);
      },
      [EchoEvents.EDGE_UPDATED]: (edge) => {
        edgesStore.echoCallbacks.onUpdate(edge);
      },
      [EchoEvents.EDGE_DELETED]: (edge) => {
        edgesStore.echoCallbacks.onDelete(edge);
      },
      [EchoEvents.ROOM_CREATED]: (room) => {
        roomsStore.echoCallbacks.onAdd(room);
      },
      [EchoEvents.ROOM_UPDATED]: (room) => {
        roomsStore.echoCallbacks.onUpdate(room);
      },
      [EchoEvents.ROOM_DELETED]: (room) => {
        roomsStore.echoCallbacks.onDelete(room);
      },
      [EchoEvents.QR_CREATED]: (qr) => {
        mapperQrStore.echoCallbacks.onAdd(qr);
      },
      [EchoEvents.QR_UPDATED]: (qr) => {
        mapperQrStore.echoCallbacks.onUpdate(qr);
      },
      [EchoEvents.QR_DELETED]: (qr) => {
        mapperQrStore.echoCallbacks.onDelete(qr);
      }
    };
    Object.entries(eventCallbackMap).forEach(([event, cb]) => {
      echoStore.once(event, cb);
    });
    return () => {
      Object.entries(eventCallbackMap).forEach(([event, cb]) => {
        echoStore.off(event, cb);
      });
    };
  }, [
    echoStore.isConnected,
    echoStore,
    nodesStore,
    edgesStore,
    roomsStore,
    mapperQrStore
  ]);
}
