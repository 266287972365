import React, { Fragment } from 'react';
import { Button, ButtonPropsDefault } from '@hse-design/react';
import {
  RouteGeneral,
  RouteGeneralProps
} from '../../../../common/components/RouteInfo/RouteGeneral';
import { RouteGeneralActions } from '../../../../common/components/RouteInfo/RouteGeneralActions';
import { useLocale } from '../../../../common/hooks/useLocale';

export interface RouteSettingHeadProps {
  onStartClick: ButtonPropsDefault['onClick'];
  onCloseClick: RouteGeneralProps['onCloseClick'];
  routeDist: number;
  routeTime: number;
  showActions?: boolean;
}

export function RouteSettingHead({
  routeDist,
  routeTime,
  showActions,
  onStartClick,
  onCloseClick
}: RouteSettingHeadProps) {
  const localeStore = useLocale();
  return (
    <Fragment>
      <RouteGeneral
        routeDist={routeDist}
        routeTime={routeTime}
        onCloseClick={onCloseClick}
      />
      {showActions && (
        <RouteGeneralActions>
          <Button size={Button.Size.large} onClick={onStartClick}>
            {localeStore.t('route.lets_go')}
          </Button>
        </RouteGeneralActions>
      )}
    </Fragment>
  );
}
