import React, { useCallback } from 'react';
import { ImageOverlay, MapContainer, useMapEvents } from 'react-leaflet';

import L, {
  LeafletEventHandlerFnMap,
  LeafletMouseEventHandlerFn
} from 'leaflet';
import immer from 'immer';
import { Space } from '@hse-design/react';
import 'leaflet/dist/leaflet.css';
import './styles/leaflet.scss';
import { Building } from '../../data/Building';
import { Bounds } from '../../utils/geometry/types';
import { Point } from './utils';
import type { AuthRoomDto } from './data/stores/RoomsStore';
import type { IVertexData } from './data/stores/NodesStore';
import type { IGraph } from './data/entities/IGraph';
import type { IEdgeData } from './data/stores/EdgesStore';
import { useCircleMarkerToFront } from './components/Map/CircleMarker';
import { ResizablePolygon } from './ResizablePolygon';
import { MapRoom } from './MapRoom';
import { MapEdge } from './MapEdge';
import { MapNode } from './MapNode';
import { MapBuilding } from './MapBuilding';
import s from './MapperPage.module.scss';

function MapEvents(events: LeafletEventHandlerFnMap) {
  useMapEvents(events);
  return null;
}

export interface MapperMapProps {
  backgrounds?: { bounds?: Bounds; src: string; zIndex?: number }[];
  bounds?: Bounds;
  graph: IGraph;
  onNodeSelect: (node: IVertexData) => void;
  onEdgeSelect: (edge: IEdgeData) => void;
  selectedNode?: IVertexData;
  selectedEdge?: IEdgeData;
  selectedRoom?: AuthRoomDto;
  onMapClick: LeafletMouseEventHandlerFn;
  onRoomSelect: (room: AuthRoomDto) => void;
  selectedFloor?: number;
  addRoomPoints?: Point[][];
  addRoomSelectedPointIndex?: number;
  showPolygonPopup?: boolean;
  onAddRoomPointsChange: (newPoints: Point[][]) => void;
  onNodeChange: (changedNode: IVertexData) => void;
  buildings?: Building[];
}

// todo: компонент слишком перегружен, нужно разбивать
export const MapperMap = React.memo(
  ({
    backgrounds,
    bounds,
    graph,
    onNodeSelect,
    onEdgeSelect,
    selectedNode,
    selectedEdge,
    selectedRoom,
    onMapClick,
    onRoomSelect,
    selectedFloor,
    addRoomPoints,
    showPolygonPopup,
    onAddRoomPointsChange,
    onNodeChange,
    buildings
  }: MapperMapProps) => {
    const { nodes, edges, rooms } = graph;

    const isNodeSelected = useCallback(
      (node: IVertexData) => {
        return (
          (selectedNode && node?.id === selectedNode.id) ||
          (selectedEdge &&
            (selectedEdge.start_vertex_id === node?.id ||
              selectedEdge.end_vertex_id === node?.id)) ||
          (selectedRoom && node?.room_id === selectedRoom.id) ||
          false
        );
      },
      [selectedNode, selectedEdge, selectedRoom]
    );

    const isEdgeSelected = useCallback(
      (edge: IEdgeData) => {
        return (
          (selectedEdge && selectedEdge.id === edge?.id) ||
          (selectedNode &&
            (selectedNode.id === edge?.start_vertex_id ||
              selectedNode.id === edge?.end_vertex_id)) ||
          false
        );
      },
      [selectedEdge, selectedNode]
    );

    const isRoomSelected = useCallback(
      (room: AuthRoomDto) => {
        return (
          (selectedRoom && selectedRoom.id === room?.id) ||
          (selectedNode && selectedNode.room_id === room?.id) ||
          false
        );
      },
      [selectedRoom, selectedNode]
    );

    const { onLayerAdd } = useCircleMarkerToFront();
    const onMapNodeChange = useCallback(
      ([x, y]) => {
        selectedNode &&
          onNodeChange?.(
            immer(selectedNode, (draft) => {
              draft.x = x;
              draft.y = y;
            })
          );
      },
      [onNodeChange, selectedNode]
    );

    return (
      <MapContainer
        preferCanvas={true}
        zoomControl={true}
        minZoom={-3}
        maxZoom={5}
        bounds={bounds as any}
        className={s.MapperPage__Map}
        crs={L.CRS.Simple}
      >
        <MapEvents click={onMapClick} layeradd={onLayerAdd} />
        {backgrounds?.map((b, bK) => (
          <ImageOverlay
            key={bK}
            url={b.src}
            bounds={b.bounds || (bounds as any)}
            zIndex={b.zIndex || bK}
          />
        ))}
        {addRoomPoints?.map((positions, index) => (
          <ResizablePolygon
            key={index}
            positions={positions}
            polygonProps={{
              fillOpacity: 0.7
            }}
            showPopup={showPolygonPopup}
            onChange={(point) => {
              if (!point && addRoomPoints.length === 1) {
                return onAddRoomPointsChange?.([]);
              }
              const newPoints = immer(addRoomPoints, (d) => {
                if (!point) {
                  d.splice(index, 1);
                } else {
                  d[index] = point as any;
                }
              });
              onAddRoomPointsChange?.(newPoints);
            }}
          >
            <Space vertical size={Space.Size.small_3x} />
          </ResizablePolygon>
        ))}
        {selectedNode && (
          <MapNode
            node={selectedNode}
            edges={graph.edges}
            isNodeSelected={isNodeSelected}
            draggable={true}
            onMove={onMapNodeChange}
            onClick={onNodeSelect}
          />
        )}
        {Object.values(nodes)
          .filter(
            (node) =>
              node.floor_number === selectedFloor &&
              node.id !== selectedNode?.id
          )
          .map((node) => {
            return (
              <MapNode
                key={node.id}
                node={node}
                edges={graph.edges}
                isNodeSelected={isNodeSelected}
                onClick={onNodeSelect}
              />
            );
          })}
        {Object.values(edges)
          .filter(
            (edge) =>
              edge.start_floor === edge.end_floor &&
              edge.start_floor === selectedFloor
          )
          .map((edge) => {
            return (
              <MapEdge
                key={edge.id}
                edge={edge}
                nodes={graph.nodesMap}
                isEdgeSelected={isEdgeSelected}
                onClick={onEdgeSelect}
              />
            );
          })}
        {Object.values(rooms).map((room: AuthRoomDto) => {
          return (
            <MapRoom
              key={room.id}
              room={room}
              onRoomSelect={onRoomSelect}
              isRoomSelected={isRoomSelected}
            />
          );
        })}
        {buildings?.map((building) => {
          return <MapBuilding key={building.id} building={building} />;
        })}
      </MapContainer>
    );
  }
);
