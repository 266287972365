import { get, set } from 'idb-keyval';
import { runAbortable } from './runAbortable';

export interface TryCacheFirstOptions<T> {
  onCacheHit?: (cached: T) => Promise<void>;
  shouldUseCache?: (cached: T) => Promise<boolean>;
  signal?: AbortSignal;
}

export async function tryCacheFirst<T>(
  cacheName: string,
  loadData: () => Promise<T>,
  options: TryCacheFirstOptions<T> = {}
) {
  return runAbortable(options.signal, async (state) => {
    const cache = await get<T>(cacheName);

    if (cache) {
      const shouldUse = options.shouldUseCache
        ? await options.shouldUseCache(cache)
        : true;
      if (shouldUse) {
        await options.onCacheHit?.(cache);
        return cache;
      }
    }

    const response = await loadData();
    if (state.isActive) {
      await set(cacheName, response);
    }

    return response;
  });
}
