import { Observable } from 'rxjs';
import {
  initialRequestState,
  IStoreState,
  ObservableStore
} from '@proscom/prostore';
import { LocaleStore } from '../../core/LocaleStore';
import { IRoomQuery } from '../../online/RoomQuery';
import { combineLatestMap } from '../../../utils/prostore/combineLatestMap';
import { RoomQueryVars } from '../../api/common';
import { CurrentCampusStoreOffline } from './CurrentCampusStoreOffline';

export class RoomQueryOffline extends ObservableStore<IStoreState<IRoomQuery>> {
  constructor(
    locale: LocaleStore,
    currentCampus: CurrentCampusStoreOffline,
    roomId$: Observable<number | undefined>
  ) {
    super(
      combineLatestMap(
        [currentCampus.state$, locale.state$, roomId$] as const,
        ([campus, locale, roomId]) => {
          const floors = campus?.floors;
          const variables: RoomQueryVars = {
            params: {
              locale: locale.lang
            },
            url: `/api/front/room/${roomId}`
          };

          if (!roomId) {
            return {
              ...initialRequestState,
              variables,
              data: null,
              loaded: true
            };
          }

          if (!floors) {
            return {
              ...initialRequestState,
              variables,
              loading: true
            };
          }

          for (const { rooms } of floors.values()) {
            const targetRoom = rooms.find((r) => r.id === roomId);
            if (targetRoom) {
              return {
                ...initialRequestState,
                data: targetRoom,
                loaded: true,
                variables
              };
            }
          }

          return {
            ...initialRequestState,
            data: null,
            loaded: true,
            variables
          };
        }
      ),
      { ...initialRequestState }
    );
  }
}
