// Ивенты начинаются с точки
export enum EchoEvents {
  VERTEX_CREATED = '.vertex.created',
  VERTEX_DELETED = '.vertex.deleted',
  VERTEX_UPDATED = '.vertex.updated',
  EDGE_CREATED = '.edge.created',
  EDGE_DELETED = '.edge.deleted',
  EDGE_UPDATED = '.edge.updated',
  ROOM_CREATED = '.room.created',
  ROOM_DELETED = '.room.deleted',
  ROOM_UPDATED = '.room.updated',
  QR_CREATED = '.qr.created',
  QR_DELETED = '.qr.deleted',
  QR_UPDATED = '.qr.updated'
}
