import { ValueStore } from '@proscom/prostore';
import { Effect } from './Effect';

export class EffectHolder<T extends Effect = Effect> extends ValueStore<
  T | undefined
> {
  constructor() {
    super(undefined);
  }

  public setState(effect: T | undefined) {
    this.state?.off();
    super.setState(effect);
    this.state?.on();
  }

  public get() {
    return this.state;
  }
}
