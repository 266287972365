import React, { useCallback } from 'react';
import {
  ClickableIcon,
  Divider,
  DividerVariant,
  Header as HeaderComponent,
  HeaderLanguageSelect,
  Icon,
  IconActionArrowBack,
  IconGlobalMessage,
  Link,
  Logo
} from '@hse-design/react';
import { useStoreState } from '@proscom/prostore-react';
import { useLocationQuery } from '../../hooks/useLocationQuery';
import { useLocale } from '../../hooks/useLocale';
import { QUERY_KEY_BACK_LINK } from '../../../data/core/queryKeys';
import { localesList } from '../../../data/core/LocaleStore';
import { FEED_EMAIL } from '../../../utils/consts';
import { EventStoreState } from '../../../data/stores/EventStore';
import {
  STORE_EVENT,
  STORE_OFFLINE_MODE_AVAILABLE
} from '../../../data/stores';
import { DesktopOfflineModeStatus } from '../DesktopOfflineModeStatus/DesktopOfflineModeStatus';
import { OfflineModeAvailableState } from '../../../data/offline/stores/OfflineModeAvailable';
import s from './Header.module.scss';

const locationKeys = [QUERY_KEY_BACK_LINK] as const;

const languages = localesList.map((l) => ({ label: l.textShort, value: l.id }));

export default function Header() {
  const locale = useLocale();
  const [query] = useLocationQuery(locationKeys);
  const queryBackLink = query[QUERY_KEY_BACK_LINK];
  const eventStoreState = useStoreState<EventStoreState>(STORE_EVENT);
  const headerLink = eventStoreState.params.headerLink;
  const handleLocaleChange = useCallback(
    (localeId) => locale.setLocale(localeId, true),
    [locale]
  );
  const offlineAvailable = useStoreState<OfflineModeAvailableState>(
    STORE_OFFLINE_MODE_AVAILABLE
  );

  return (
    <div className={s.Header}>
      <HeaderComponent
        logoIcon={Logo}
        left={
          <>
            {queryBackLink && (
              <a href={queryBackLink} className={s.Header__backlink}>
                <ClickableIcon iconProps={{ icon: IconActionArrowBack }} />
              </a>
            )}
            {headerLink ? (
              <Link
                className={s.Header__titleLink}
                href={headerLink.href}
                variant={Link.Variant.secondary}
                target={'_blank'}
              >
                {headerLink.text}
              </Link>
            ) : (
              locale.t('header.title')
            )}
          </>
        }
        right={
          <>
            {offlineAvailable.value === true && (
              <>
                <DesktopOfflineModeStatus />
                <Divider
                  className={s.Header__divider}
                  variant={DividerVariant.vertical}
                />
              </>
            )}
            <HeaderLanguageSelect
              options={languages}
              value={locale.state.lang}
              onChange={handleLocaleChange}
            />
            <Divider
              className={s.Header__divider}
              variant={DividerVariant.vertical}
            />
            <a
              href={`mailto:${FEED_EMAIL}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon className={s.Header__icon} icon={IconGlobalMessage} />
            </a>
          </>
        }
      />
    </div>
  );
}
