import shabolovkaFloor0 from '../../../assets/map/shabolovka/floor0.svg';
import shabolovkaFloor1 from '../../../assets/map/shabolovka/floor1.svg';
import shabolovkaFloor2 from '../../../assets/map/shabolovka/floor2.svg';
import shabolovkaFloor3 from '../../../assets/map/shabolovka/floor3.svg';
import shabolovkaFloor4 from '../../../assets/map/shabolovka/floor4.svg';
import shabolovkaRoads from '../../../assets/map/shabolovka/roads.svg';
import shabolovkaCampusDefault from '../../../assets/map/shabolovka/campus.svg';
import shabolovkaCampus0 from '../../../assets/map/shabolovka/campus0.svg';
import pokrovkaFloorM2 from '../../../assets/map/pokrovka/floor-2.svg';
import pokrovkaFloorM1 from '../../../assets/map/pokrovka/floor-1.svg';
import pokrovkaFloor0 from '../../../assets/map/pokrovka/floor0.svg';
import pokrovkaFloor1 from '../../../assets/map/pokrovka/floor1.svg';
import pokrovkaFloor2 from '../../../assets/map/pokrovka/floor2.svg';
import pokrovkaFloor3 from '../../../assets/map/pokrovka/floor3.svg';
import pokrovkaFloor4 from '../../../assets/map/pokrovka/floor4.svg';
import pokrovkaFloor5 from '../../../assets/map/pokrovka/floor5.svg';
import pokrovkaFloor6 from '../../../assets/map/pokrovka/floor6.svg';
import pokrovkaFloor7 from '../../../assets/map/pokrovka/floor7.svg';
import pokrovkaFloor8 from '../../../assets/map/pokrovka/floor8.svg';
import pokrovkaFloor9 from '../../../assets/map/pokrovka/floor9.svg';
import pokrovkaFloor10 from '../../../assets/map/pokrovka/floor10.svg';
import pokrovkaRoads from '../../../assets/map/pokrovka/roads.svg';
import pokrovkaCampusDefault from '../../../assets/map/pokrovka/campus.svg';
import myasnitskayaCampus from '../../../assets/map/myasnitskaya/campus.svg';
import myasnitskayaFloor0 from '../../../assets/map/myasnitskaya/floor0.svg';
import myasnitskayaFloor1 from '../../../assets/map/myasnitskaya/floor1.svg';
import myasnitskayaFloor2 from '../../../assets/map/myasnitskaya/floor2.svg';
import myasnitskayaFloor3 from '../../../assets/map/myasnitskaya/floor3.svg';
import myasnitskayaFloor4 from '../../../assets/map/myasnitskaya/floor4.svg';
import myasnitskayaFloor5 from '../../../assets/map/myasnitskaya/floor5.svg';
import myasnitskayaFloor6 from '../../../assets/map/myasnitskaya/floor6.svg';
import myasnitskayaRoads from '../../../assets/map/myasnitskaya/roads.svg';
import ordynkaFloor0 from '../../../assets/map/ordynka/floor0.svg';
import ordynkaFloor1 from '../../../assets/map/ordynka/floor1.svg';
import ordynkaFloor2 from '../../../assets/map/ordynka/floor2.svg';
import ordynkaFloor3 from '../../../assets/map/ordynka/floor3.svg';
import ordynkaFloor4 from '../../../assets/map/ordynka/floor4.svg';
import ordynkaFloor5 from '../../../assets/map/ordynka/floor5.svg';
import ordynkaRoads from '../../../assets/map/ordynka/roads.svg';
import {
  CAMPUS_MYASNITSKAYA,
  CAMPUS_ORDYNKA,
  CAMPUS_POKROVKA,
  CAMPUS_SHABOLOVKA
} from '../../../data/Campus';

export const floorBackgrounds = {
  [CAMPUS_POKROVKA]: {
    [-2]: pokrovkaFloorM2,
    [-1]: pokrovkaFloorM1,
    0: pokrovkaFloor0,
    1: pokrovkaFloor1,
    2: pokrovkaFloor2,
    3: pokrovkaFloor3,
    4: pokrovkaFloor4,
    5: pokrovkaFloor5,
    6: pokrovkaFloor6,
    7: pokrovkaFloor7,
    8: pokrovkaFloor8,
    9: pokrovkaFloor9,
    10: pokrovkaFloor10
  },
  [CAMPUS_SHABOLOVKA]: {
    0: shabolovkaFloor0,
    1: shabolovkaFloor1,
    2: shabolovkaFloor2,
    3: shabolovkaFloor3,
    4: shabolovkaFloor4
  },
  [CAMPUS_MYASNITSKAYA]: {
    0: myasnitskayaFloor0,
    1: myasnitskayaFloor1,
    2: myasnitskayaFloor2,
    3: myasnitskayaFloor3,
    4: myasnitskayaFloor4,
    5: myasnitskayaFloor5,
    6: myasnitskayaFloor6
  },
  [CAMPUS_ORDYNKA]: {
    0: ordynkaFloor0,
    1: ordynkaFloor1,
    2: ordynkaFloor2,
    3: ordynkaFloor3,
    4: ordynkaFloor4,
    5: ordynkaFloor5
  }
};

export const roadsBackgrounds = {
  [CAMPUS_POKROVKA]: pokrovkaRoads,
  [CAMPUS_SHABOLOVKA]: shabolovkaRoads,
  [CAMPUS_MYASNITSKAYA]: myasnitskayaRoads,
  [CAMPUS_ORDYNKA]: ordynkaRoads
};

export const campusBackgrounds = {
  [CAMPUS_POKROVKA]: {
    default: pokrovkaCampusDefault
  },
  [CAMPUS_SHABOLOVKA]: {
    default: shabolovkaCampusDefault,
    0: shabolovkaCampus0
  },
  [CAMPUS_MYASNITSKAYA]: {
    default: myasnitskayaCampus
  },
  [CAMPUS_ORDYNKA]: {
    default: null
  }
};
