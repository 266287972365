import { IStorageValueAdapter } from '@proscom/prostore-local-storage';
import { defer } from 'rxjs';
import { rnMessenger } from '../../rnMessenger';
import { NativeMethods } from '../../utils/ReactNativeMessenger';

export class LocaleNativeAdapter implements IStorageValueAdapter<string> {
  value$ = defer(() => {
    return rnMessenger.call(NativeMethods.getLocale).then((result) => {
      if (typeof result === 'string') return result;
      return null;
    });
  });

  async setValue(value: string | null) {
    return rnMessenger.call(NativeMethods.setLocale, value);
  }
}
