import clsx from 'clsx';
import React from 'react';
import s from './SectionDivider.module.scss';

export interface SectionDividerProps {
  className?: string;
}

export function SectionDivider({ className }: SectionDividerProps) {
  return <div className={clsx(s.SectionDivider, className)} />;
}
