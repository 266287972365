import { combineLatest } from 'rxjs';
import { skipIfNull, SubscriptionManager } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { map } from 'rxjs/operators';
import { defaultClient } from '../../Client';
import { RoomQueryData, RoomQueryVars } from '../api/common';
import { IStoreType } from '../../utils/types';
import { LocaleStore } from '../core/LocaleStore';
import { QUERY_KEY_CODE, QUERY_KEY_ROOM } from '../core/queryKeys';

export interface RoomStoreState {
  data: RoomQueryData | null;
  loading: boolean;
}

export interface RoomStoreArgs {
  localeStore: LocaleStore;
  locationStore: LocationStore;
}

export type IRoomStore = IStoreType<RoomStore>;

export class RoomStore extends AxiosQueryStore<
  RoomQueryVars | null,
  RoomQueryData | null
> {
  sub = new SubscriptionManager();

  constructor(protected deps: RoomStoreArgs) {
    super({
      client: defaultClient,
      query: {
        method: 'get'
      },
      skipQuery: skipIfNull(null),
      mapData: (data) => data.data,
      variables$: combineLatest([
        deps.localeStore.state$,
        deps.locationStore.get$(QUERY_KEY_ROOM, QUERY_KEY_CODE)
      ]).pipe(
        map(([locale, query]) => {
          const queryRoom = query[QUERY_KEY_ROOM];
          const queryCode = query[QUERY_KEY_CODE];

          if (queryRoom) {
            return {
              variables: {
                params: {
                  locale: locale.lang
                },
                url: `/api/front/room/${queryRoom}`
              }
            };
          }

          if (queryCode) {
            return {
              variables: {
                params: {
                  locale: locale.lang,
                  code: queryCode
                },
                url: `/api/front/room`
              }
            };
          }

          return {
            variables: null
          };
        })
      )
    });
  }
}
