import { ObservableStore } from '@proscom/prostore';
import { combineLatestMap } from '../../../../utils/prostore/combineLatestMap';
import { EdgesStore, IEdgeData } from './EdgesStore';
import { HoverItem, HoverItemType } from './HoverItem';
import { getAdjacentEdges } from './getAdjacentEdges';

export type HoverNodeEdgesState = IEdgeData[];

export class HoverNodeEdges extends ObservableStore<HoverNodeEdgesState> {
  constructor(edges: EdgesStore, hoverItem: HoverItem) {
    super(
      combineLatestMap(
        [edges.state$, hoverItem.state$] as const,
        ([edges, hoverItem]) => {
          if (
            !edges.data ||
            !hoverItem ||
            hoverItem.type !== HoverItemType.node
          ) {
            return [];
          }
          return getAdjacentEdges(Object.values(edges.data), hoverItem.id);
        }
      ),
      []
    );
  }
}
