import React from 'react';
import clsx from 'clsx';
import { MobileNavButton } from './MobileNavButton';
import s from './MobileNavBar.module.scss';

export interface MobileNavSection {
  id: string;
  icon: React.ReactNode;
  text: string;
}

export interface MobileNavProps {
  isActive?: boolean;
  activeSectionId?: string | null;
  onSectionChange?: (section: string | null) => void;
  sections: MobileNavSection[];
}

export function MobileNavBar({
  isActive,
  activeSectionId,
  onSectionChange,
  sections
}: MobileNavProps) {
  return (
    <div
      className={clsx(s.MobileNav, {
        [s.MobileNav_active]: isActive
      })}
    >
      <div className={s.MobileNav__list}>
        {sections.map((section, i) => (
          <div key={i} className={s.MobileNav__item}>
            <MobileNavButton
              icon={section.icon}
              text={section.text}
              isActive={section.id === activeSectionId}
              onClick={() =>
                onSectionChange?.(
                  section.id === activeSectionId ? null : section.id
                )
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
}
