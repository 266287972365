import React from 'react';
import clsx from 'clsx';
import { DivPropsWithoutRef } from '@hse-design/react';
import s from './LeftPane.module.scss';

export interface LeftPaneProps extends DivPropsWithoutRef {
  className?: string;
  children?: React.ReactNode;
}

export function LeftPane({ className, children, ...props }: LeftPaneProps) {
  return (
    <div className={clsx(s.LeftPane, className)} {...props}>
      {children}
    </div>
  );
}
