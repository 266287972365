import React from 'react';
import { ClickableIcon, IconNavigationSwapVertical } from '@hse-design/react';
import clsx from 'clsx';
import s from './RouteSearch.module.scss';

export interface RouteSearchProps {
  className?: string;
  actions?: React.ReactNode;
  points?: React.ReactNode;
  onSwapClick?: () => void;
}

export function RouteSearch({
  className,
  actions,
  points,
  onSwapClick
}: RouteSearchProps) {
  return (
    <div className={clsx(s.RouteSearch, className)}>
      <div className={s.RouteSearch__head}>
        <div className={s.RouteSearch__points}>{points}</div>
        <div className={s.RouteSearch__swap}>
          <ClickableIcon
            iconProps={{
              icon: IconNavigationSwapVertical
            }}
            onClick={onSwapClick}
          />
        </div>
      </div>
      {actions && <div className={s.RouteSearch__actions}>{actions}</div>}
    </div>
  );
}
