import { IOfflineCampusInfo } from '../../data/offline/stores/CampusesStoreOffline';
import { IRouteEdge } from '../../data/RouteEdge';
import { IPathToItem } from './findPath';

export const restructurePath = (
  path: IPathToItem[],
  currentCampus: IOfflineCampusInfo
): IRouteEdge[] => {
  const { graph } = currentCampus;
  const resPath: IRouteEdge[] = [];
  if (path) {
    let sumCost = 0;

    for (let i = 1; i < path.length; i++) {
      const edgeId = path[i].edgeId;
      if (!edgeId) continue;
      const edge = graph.ensureEdge(edgeId);
      sumCost += +edge.cost;
      resPath.push({
        order: i,
        cost: String(edge.cost),
        cost_time: String(edge.cost_time),
        vertex_id: edge.start_vertex_id,
        vertex: edge.start_vertex,
        agg_cost: String(sumCost),
        edge_id: edge.id,
        edge: {
          ...edge,
          start_id: edge.start_vertex_id,
          end_id: edge.end_vertex_id
        }
      });
    }

    const lastEdge = resPath[resPath.length - 1];
    if (lastEdge) {
      const lastEdgeEdge = graph.ensureEdge(lastEdge.edge_id);
      const endVertex = graph.ensureVertex(lastEdgeEdge.end_vertex_id);
      resPath.push({
        order: path.length,
        cost: null,
        cost_time: null,
        vertex_id: endVertex.id,
        vertex: endVertex,
        agg_cost: String(sumCost),
        edge: null,
        edge_id: null
      });
    }
  }

  return resPath;
};
