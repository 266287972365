export function booleanEnv(variable: string | undefined) {
  const value = env(variable, false);
  return value === 'true';
}

export function env<T = string | undefined>(
  variable: string | undefined,
  defaultValue?: T
) {
  if (typeof variable === 'undefined') {
    return defaultValue;
  }
  return variable;
}

export function requireEnv(variable: string | undefined, name: string) {
  if (typeof variable === 'undefined') {
    throw new Error(`Variable ${name} is required`);
  }
  return variable;
}
