import { alg, Graph } from '@dagrejs/graphlib';
import randomColor from 'randomcolor';
import { Point2D } from '../utils/geometry/types';
import { GraphEdgeDto } from './api/GraphEdgeDto';

const colors = randomColor({ count: 100, seed: 200 });

export interface RouteGraphEdge {
  start_vertex: Point2D;
  end_vertex: Point2D;
}

export interface RouteGraphComponent {
  edges: RouteGraphEdge[];
  color: string;
}

export class RouteGraph {
  graph: Graph;
  components: RouteGraphComponent[];

  constructor(edges: GraphEdgeDto[]) {
    this.graph = new Graph();

    edges.forEach((edge) =>
      this.graph
        .setNode(edge.start_vertex.id, edge.start_vertex.id)
        .setNode(edge.end_vertex.id, edge.end_vertex.id)
        .setEdge(edge.start_vertex.id, edge.end_vertex.id, edge.id)
    );

    this.components = alg
      .components(this.graph)
      .sort(
        (componentANodes, componentBNodes) =>
          componentBNodes.length - componentANodes.length
      )
      .map((componentNodes, componentI) => {
        const componentEdges: RouteGraphEdge[] = [];
        componentNodes.forEach((node) =>
          edges.forEach((edge) => {
            if (
              edge.start_vertex.id === parseInt(node) ||
              edge.end_vertex.id === parseInt(node)
            ) {
              componentEdges.push({
                start_vertex: {
                  x: parseFloat(edge.start_vertex.x),
                  y: parseFloat(edge.start_vertex.y)
                },
                end_vertex: {
                  x: parseFloat(edge.end_vertex.x),
                  y: parseFloat(edge.end_vertex.y)
                }
              });
            }
          })
        );

        const componentColor = colors[componentI];

        return {
          edges: componentEdges,
          color: componentColor
        };
      });
  }
}
