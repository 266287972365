import React, { useMemo } from 'react';
import { divIcon } from 'leaflet';
import { Marker, MarkerProps } from 'react-leaflet';
import { renderToString } from 'react-dom/server';

export interface JsxMarkerProps extends MarkerProps {
  className?: string;
  content?: React.ReactNode;
  iconProps?: any;
}

export const JsxMarker = React.forwardRef<any, JsxMarkerProps>(
  ({ content, className, iconProps, ...props }, ref) => {
    // todo try using portals instead
    const html = renderToString(<>{content}</>);

    const icon = useMemo(
      () =>
        divIcon({
          className: className,
          html,
          ...iconProps
        }),
      [className, html, iconProps]
    );

    return <Marker ref={ref} icon={icon} {...props} />;
  }
);
