import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { ReactComponent as IconClose } from '../../../../assets/img/icons/i-close-round.svg';
import { RouteStep } from '../../../../common/components/RouteInfo/RouteStep';
import { IRouteStepListItem } from '../../../../common/components/RouteInfo/RouteStepList';
import s from '../../IndexPage.module.scss';

const stepsSliderCfg = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0
};

export interface RouteStepsHeadProps {
  routeSteps: IRouteStepListItem[];
  activeStepIndex: number;
  onCloseClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onStepChangeHandler?: (nextSlide: number) => void;
  skipFirstStep?: boolean;
}

export function RouteStepsHead({
  routeSteps,
  activeStepIndex,
  onCloseClick,
  onStepChangeHandler,
  skipFirstStep
}: RouteStepsHeadProps) {
  const sliderRef = useRef<Slider | null>(null);

  const onStepChange = (nextSlide: number) => {
    onStepChangeHandler?.(nextSlide);
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(
        skipFirstStep ? activeStepIndex - 1 : activeStepIndex
      );
    }
  }, [activeStepIndex, skipFirstStep]);

  return (
    <div className={s.RouteStepsHead}>
      <Slider
        ref={sliderRef}
        className={s.RouteStepsHead__slider}
        afterChange={(iSlide) =>
          onStepChange(skipFirstStep ? iSlide + 1 : iSlide)
        }
        {...stepsSliderCfg}
      >
        {routeSteps?.map(
          (step, iStep) =>
            step.shown && (
              <RouteStep key={iStep} className={s.RouteStep} {...step} />
            )
        )}
      </Slider>
      <div className={s.RouteStepsHead__close} onClick={onCloseClick}>
        <IconClose />
      </div>
    </div>
  );
}
