import { ValueStore } from '@proscom/prostore';

export interface OfflineModeAvailableState {
  loaded: boolean;
  value: boolean;
}

export class OfflineModeAvailable extends ValueStore<OfflineModeAvailableState> {
  constructor() {
    super({
      loaded: true,
      value: false
    });
  }

  public setValue(value: boolean) {
    this.setState({ loaded: true, value });
  }
}
