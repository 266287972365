import { ValueStore } from '@proscom/prostore';
import { Action } from '../../../../../utils/prostore/Action';
import { IVertexData } from '../NodesStore';
import { EditMode } from '../../types';
import { ModeSetRoom } from '../ModeSetRoom';

export class StartSetRoom implements Action {
  constructor(
    private readonly mode: ValueStore<EditMode>,
    private readonly modeSetRoomNode: ModeSetRoom
  ) {}

  public run = (node: IVertexData) => {
    this.mode.setState(EditMode.SET_ROOM);
    this.modeSetRoomNode.setState(node);
  };
}
