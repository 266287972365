import CanvasControls from './CanvasControls';
import { CanvasPin } from './CanvasPin';

export default class CanvasDraw extends CanvasControls {
  constructor(canvas) {
    super();

    this.canvas = canvas;
    this.context = this.canvas.getContext('2d');

    this.polygons = [];
    this.texts = [];
    this.images = [];
    this.icons = [];
    this.lines = [];
    this.circles = [];
    this.pin = null;

    this.needRender = false;
    this.cameraMoving = false;
    this.moveAnimationInterval = null;
    this.zoomAnimationInterval = null;
    this.animationTime = 350;
    this.animationIntervalTime = 10;
    this.raf = requestAnimationFrame(this.processFrame);
  }

  pixelRatioScale = () => {
    const devicePixelRatio = window.devicePixelRatio || 1,
      backingStoreRatio =
        this.context.webkitBackingStorePixelRatio ||
        this.context.mozBackingStorePixelRatio ||
        this.context.msBackingStorePixelRatio ||
        this.context.oBackingStorePixelRatio ||
        this.context.backingStorePixelRatio ||
        1,
      ratio = devicePixelRatio / backingStoreRatio;

    if (devicePixelRatio !== backingStoreRatio) {
      const oldWidth = this.canvas.width;
      const oldHeight = this.canvas.height;

      this.canvas.width = oldWidth * ratio;
      this.canvas.height = oldHeight * ratio;

      this.canvas.style.width = oldWidth + 'px';
      this.canvas.style.height = oldHeight + 'px';

      this.context.scale(ratio, ratio);
    }
  };

  goToPin = () => {
    if (this.pin) {
      this.animateMove(this.pin.x, this.pin.y, () =>
        this.animateZoom(0.6, () =>
          this.updateMapAfterZoom().then(() => (this.needRender = true))
        )
      );
    }
  };

  setPin = (pin) => {
    if (pin) {
      this.pin = new CanvasPin(pin);
      this.needRender = true;
    } else {
      this.icons = this.icons.filter((ic) => !ic.isPin);
      this.pin = pin;
      this.needRender = true;
    }
  };

  setSize = ({ width, height }) => {
    this.canvas.width = width;
    this.canvas.height = height;

    this.pixelRatioScale();
    this.needRender = true;
  };

  setFocus(x, y) {
    if (this.scale) {
      const { width, height } = this.canvas.getBoundingClientRect();
      const newMargin = {
        x: (0.5 * width) / this.scale - x,
        y: (0.5 * height) / this.scale - y
      };
      this.marginX = newMargin.x;
      this.marginY = newMargin.y;
    }
  }

  addPolygon = ({ bounds, zIndex = 0, fill, ...polygonProps }) => {
    this.polygons.push({
      type: 'polygon',
      bounds,
      zIndex,
      fill,
      ...polygonProps
    });
  };

  addImage = ({ zIndex = 0, ...imageProps }) => {
    this.images.push({
      zIndex,
      ...imageProps,
      type: 'image'
    });
  };

  addText = ({ zIndex = 0, fontWeight = 'normal', ...textProps }) => {
    this.texts.push({
      zIndex,
      fontWeight,
      ...textProps,
      type: 'text'
    });
  };

  addLine = ({ zIndex = 0, ...lineProps }) => {
    this.lines.push({
      zIndex,
      ...lineProps,
      type: 'line'
    });
  };

  addCircle = ({ zIndex = 0, ...circleProps }) => {
    this.circles.push({
      zIndex,
      ...circleProps,
      type: 'circle'
    });
  };

  addIcon = ({ zIndex = 0, ...iconProps }) => {
    this.icons.push({
      zIndex,
      ...iconProps,
      type: 'icon'
    });
  };

  drawObject = (object) => {
    switch (object.type) {
      case 'polygon':
        this.drawPolygon(object);
        break;

      case 'image':
        this.drawImage(object);
        break;

      case 'icon':
        this.drawIcon(object);
        break;

      case 'text':
        this.drawText(object);
        break;

      case 'line':
        this.drawLine(object);
        break;

      case 'circle':
        this.drawCircle(object);
        break;

      default:
        console.log('Didn`t find the right type. Current type:', object.type);
    }
  };

  drawPolygon = ({
    bounds,
    fill,
    borderColor = 'transparent',
    borderWidth = 0
  }) => {
    this.context.fillStyle = fill;
    this.context.beginPath();

    for (let oneNesting = 0; oneNesting < bounds.length; oneNesting++) {
      const currentBounds = bounds[oneNesting];
      const x1 = this.getOfTransformX(parseFloat(currentBounds[0][0]));
      const y1 = this.getOfTransformY(parseFloat(currentBounds[0][1]));

      this.context.moveTo(x1, y1);

      this.updateLayerX(x1);
      this.updateLayerY(y1);

      for (
        let twoNesting = 1;
        twoNesting < currentBounds.length;
        twoNesting++
      ) {
        const currentBound = currentBounds[twoNesting];
        const x2 = this.getOfTransformX(parseFloat(currentBound[0]));
        const y2 = this.getOfTransformY(parseFloat(currentBound[1]));

        this.updateLayerX(x2);
        this.updateLayerY(y2);

        this.context.lineTo(x2, y2);
      }
    }

    this.context.closePath();
    this.context.fill();

    if (borderWidth) {
      this.context.strokeStyle = borderColor;
      this.context.lineWidth = borderWidth * this.scale;
      this.context.stroke();
    }
  };

  drawImage = (data) => {
    const width = this.getOfTransformWidth(data.width);
    const height = this.getOfTransformHeight(data.height);
    const x = this.getOfTransformX(data.x);
    const y = this.getOfTransformY(data.y);

    this.context.drawImage(data.image, x, y, width, height);

    this.updateLayerX(x);
    this.updateLayerX(width);
    this.updateLayerY(y);
    this.updateLayerY(height);
  };

  createRoundedRectPath = ({ x, y, width, height, radius = 0 }) => {
    this.context.beginPath();
    this.context.moveTo(x, y + radius);
    this.context.arcTo(x, y, x + radius, y, radius);
    this.context.arcTo(x + width, y, x + width, y + radius, radius);
    this.context.arcTo(
      x + width,
      y + height,
      x + width - radius,
      y + height,
      radius
    );
    this.context.arcTo(x, y + height, x, y + height - radius, radius);
    this.context.closePath();
  };

  drawRoundedRect = ({
    x,
    y,
    width,
    height,
    radius = 0,
    backgroundColor = 'transparent'
  }) => {
    this.createRoundedRectPath({ x, y, width, height, radius });
    this.context.fillStyle = backgroundColor;
    this.context.fill();
  };

  drawRoundedRectStroke = ({
    x,
    y,
    width,
    height,
    radius = 0,
    lineWidth = 0,
    color = 'transparent'
  }) => {
    this.createRoundedRectPath({ x, y, width, height, radius });
    this.context.lineWidth = lineWidth;
    this.context.strokeStyle = color;
    this.context.stroke();
  };

  drawIcon = async (data) => {
    const {
      icon,
      width,
      height,
      centered,
      iconStyle,
      rectBackgroundColor
    } = data;
    const rectProps = {
      x: this.getOfTransformX(data.x),
      y: this.getOfTransformY(data.y),
      width,
      height,
      radius: iconStyle.borderRadius
    };
    if (centered) {
      rectProps.x -= width / 2;
      rectProps.y -= height / 2;
    }
    this.drawRoundedRectStroke({
      lineWidth: iconStyle.borderWidth,
      color: iconStyle.borderColor,
      ...rectProps
    });
    this.context.globalCompositeOperation = 'destination-out';
    this.drawRoundedRect({
      backgroundColor: rectBackgroundColor || 'black',
      ...rectProps
    });
    this.context.globalCompositeOperation = 'source-over';
    this.context.drawImage(icon, rectProps.x, rectProps.y, width, height);
    this.context.globalCompositeOperation = 'source-atop';
    this.drawRoundedRect({
      backgroundColor: iconStyle.iconColor,
      ...rectProps
    });
    this.context.globalCompositeOperation = 'destination-over';
    this.drawRoundedRect({
      backgroundColor: iconStyle.backgroundColor,
      ...rectProps
    });
    this.context.globalCompositeOperation = 'source-over';
  };

  drawText = (data) => {
    if (data && data.text) {
      const fontSize = data.size;
      this.context.font = `${data.fontWeight} ${fontSize}px ${data.fontFamily}`;
      const textWidth = this.context.measureText(data.text).width;
      const x = this.getOfTransformX(data.x) - textWidth / 2;
      const y = this.getOfTransformY(data.y) + fontSize / 2;

      const padding = data.underlayPadding;
      this.drawRoundedRect({
        x: x - padding,
        y: y - fontSize * 0.6 - padding,
        width: textWidth + padding * 2,
        height: fontSize * 0.6 + padding * 2,
        radius: data.underlayBorderRadius,
        backgroundColor: data.underlayColor
      });

      this.context.fillStyle = data.color;
      this.context.fillText(data.text, x, y);
    }
  };

  drawLine = ({ vertexes, ...lineStyles }) => {
    if (vertexes && vertexes.length > 1 && typeof lineStyles === 'object') {
      this.context.strokeStyle = lineStyles.strokeStyle;
      this.context.lineWidth = lineStyles.lineWidth;
      this.context.lineCap = lineStyles.lineCap;
      this.context.lineJoin = lineStyles.lineJoin;
      this.context.beginPath();

      const startX = this.getOfTransformX(parseFloat(vertexes[0].x));
      const startY = this.getOfTransformY(parseFloat(vertexes[0].y));
      this.context.moveTo(startX, startY);

      for (let vertexId = 1; vertexId < vertexes.length; vertexId++) {
        const x = this.getOfTransformX(parseFloat(vertexes[vertexId].x));
        const y = this.getOfTransformY(parseFloat(vertexes[vertexId].y));

        this.context.lineTo(x, y);

        this.updateLayerX(x);
        this.updateLayerY(y);
      }

      this.context.stroke();
    }
  };

  drawCircle = ({ x, y, radius, color }) => {
    this.context.beginPath();
    this.context.arc(
      this.getOfTransformX(x),
      this.getOfTransformY(y),
      radius,
      0,
      Math.PI * 2
    );
    this.context.fillStyle = color;
    this.context.fill();
  };

  render = () => {
    const objects = [
      ...this.images,
      ...this.icons,
      ...this.polygons,
      ...this.texts,
      ...this.lines,
      ...this.circles
    ].sort((objectA, objectB) => objectA.zIndex - objectB.zIndex);

    if (this.pin) {
      objects.push(this.pin);
    }

    this.clearMath();
    this.clearCanvas();

    objects.forEach(this.drawObject);
  };

  deleteAll = () => {
    this.images = [];
    this.icons = [];
    this.polygons = [];
    this.texts = [];
    this.lines = [];
    this.circles = [];
  };

  clearCanvas = () => {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  };

  requestFrame = () => {
    this.raf = requestAnimationFrame(this.processFrame);
  };

  processFrame = () => {
    if (this.needRender) {
      this.render();
      this.needRender = false;
    } else if (this.cameraMoving) this.render();

    this.requestFrame();
  };

  clearFrame = () => {
    cancelAnimationFrame(this.raf);
  };

  animateMove = (targetX, targetY, afterMoveHandler) => {
    if (
      this.scale &&
      this.isValidNumber(this.dragLimits.xMax) &&
      this.isValidNumber(this.dragLimits.xMin) &&
      this.isValidNumber(this.dragLimits.yMax) &&
      this.isValidNumber(this.dragLimits.yMin)
    ) {
      this.cancelMoveAnimation();
      this.cancelZoomAnimation();

      const oldX = this.marginX;
      const oldY = this.marginY;
      const { width, height } = this.canvas.getBoundingClientRect();
      let newX = (0.5 * width) / this.scale - targetX;
      if (newX > this.dragLimits.xMax) newX = this.dragLimits.xMax;
      else if (newX < this.dragLimits.xMin) newX = this.dragLimits.xMin;
      let newY = (0.5 * height) / this.scale - targetY;
      if (newY > this.dragLimits.yMax) newY = this.dragLimits.yMax;
      else if (newY < this.dragLimits.yMin) newY = this.dragLimits.yMin;
      const xDiff = newX - oldX;
      const yDiff = newY - oldY;

      let moveAnimationProcess = 0;
      const moveAnimationStepsNumber =
        this.animationTime / this.animationIntervalTime;
      const moveAnimationProcessStep = 1 / moveAnimationStepsNumber;

      this.cameraMoving = true;
      this.moveAnimationInterval = setInterval(() => {
        if (moveAnimationProcess < 1) {
          this.cameraMoving = true;
          moveAnimationProcess += moveAnimationProcessStep;
          this.marginX =
            oldX + xDiff * this.easeInOutCubic(moveAnimationProcess);
          this.marginY =
            oldY + yDiff * this.easeInOutCubic(moveAnimationProcess);
        } else {
          this.cancelMoveAnimation();
          if (afterMoveHandler) {
            afterMoveHandler();
          }
        }
      }, this.animationIntervalTime);
    }
  };

  cancelMoveAnimation = () => {
    this.cameraMoving = false;
    clearInterval(this.moveAnimationInterval);
  };

  animateZoom = (targetScale, afterZoomHandler) => {
    if (this.scale && this.minScale && this.maxScale && this.canvasCenter) {
      this.cancelMoveAnimation();
      this.cancelZoomAnimation();

      let newScale = Math.max(this.minScale, targetScale);
      newScale = Math.min(this.maxScale, newScale);
      const oldScale = this.scale;
      const scaleDiff = newScale - oldScale;

      /**
       * Координаты фокуса камеры в координатах карты, относительно начала
       * подложки кампуса
       */
      const relativeX = this.canvasCenter.x / oldScale - this.marginX;
      const relativeY = this.canvasCenter.y / oldScale - this.marginY;

      let zoomAnimationProcess = 0;
      const zoomAnimationStepsNumber =
        this.animationTime / this.animationIntervalTime;
      const zoomAnimationProcessStep = 1 / zoomAnimationStepsNumber;

      this.cameraMoving = true;
      this.zoomAnimationInterval = setInterval(() => {
        if (zoomAnimationProcess < 1) {
          this.cameraMoving = true;
          zoomAnimationProcess += zoomAnimationProcessStep;
          this.scale =
            oldScale + scaleDiff * this.easeInOutCubic(zoomAnimationProcess);
          this.marginX = this.canvasCenter.x / this.scale - relativeX;
          this.marginY = this.canvasCenter.y / this.scale - relativeY;
          afterZoomHandler();
        } else this.cancelZoomAnimation();
      }, this.animationIntervalTime);
    }
  };

  cancelZoomAnimation = () => {
    this.cameraMoving = false;
    clearInterval(this.zoomAnimationInterval);
  };
}
