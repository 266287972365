import { Subscribable } from 'rxjs';
import { useObservableState } from '@proscom/prostore-react';
import { useMemo } from 'react';
import {
  combineLatestMap,
  SubscribableTypes
} from '../prostore/combineLatestMap';

export function useCombineLatestMap<
  Subs extends readonly Subscribable<any>[],
  Result
>(
  observables: Subs,
  project: (args: SubscribableTypes<Subs>) => Result
): Result | null {
  return useObservableState(
    useMemo(
      () => combineLatestMap(observables, project),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [project, ...observables]
    )
  );
}
