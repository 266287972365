import { AxiosInstance } from 'axios';
import { QueryResult } from '../api/common';
import { IQrInfo } from '../stores/LocationQrEffect';
import { parseVertexDto, VertexDto } from '../api/VertexDto';

interface QrInfoDto extends Omit<IQrInfo, 'vertex'> {
  vertex?: VertexDto;
}

export interface IQrCodeResolver {
  load(qr: string): Promise<IQrInfo | null>;
}

export class QrCodeResolver implements IQrCodeResolver {
  constructor(private readonly client: AxiosInstance) {}

  public async load(qr: string) {
    const response = await this.client.request<QueryResult<QrInfoDto>>({
      url: '/api/front/vertex/qrInfo',
      method: 'get',
      params: { qr }
    });

    const data = response.data.data || null;

    if (data) {
      return {
        ...data,
        vertex: data.vertex && parseVertexDto(data.vertex)
      };
    }

    return data;
  }
}
