import { useContextStore, useStoreState } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { RoomStoreState } from '../../../../data/online/RoomStore';
import { STORE_LOCATION, STORE_ROOM } from '../../../../data/stores';
import { LeftPane } from '../../../../common/desktop/LeftPane/LeftPane';
import { QUERY_KEY_ROOM } from '../../../../data/core/queryKeys';
import { RoomPanelContent } from '../../../../common/components/RoomPanelContent/RoomPanelContent';
import { Loader } from '../../../../common/ui/Loader/Loader';
import { LeftPaneTitle } from '../../../../common/desktop/LeftPaneTitle/LeftPaneTitle';
import { LeftPaneContent } from '../../../../common/desktop/LeftPaneContent/LeftPaneContent';
import s from './DesktopRoomPanel.module.scss';

export function DesktopRoomPanel() {
  const locationStore = useContextStore<LocationStore>(STORE_LOCATION);
  const roomStoreState = useStoreState<RoomStoreState>(STORE_ROOM);
  const room = roomStoreState.data;

  const handleClose = () => {
    locationStore.changeQuery({
      [QUERY_KEY_ROOM]: undefined
    });
  };

  return (
    <LeftPane>
      <LeftPaneContent>
        <LeftPaneTitle onClose={handleClose}>
          <span className={s.DesktopRoomPanel__title}>
            {!roomStoreState.loading && room?.name}
          </span>
        </LeftPaneTitle>
        {roomStoreState.loading ? (
          <Loader />
        ) : (
          room && <RoomPanelContent room={room} />
        )}
      </LeftPaneContent>
    </LeftPane>
  );
}
