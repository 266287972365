import { LocationStore } from '@proscom/prostore-react-router';
import { useEffect } from 'react';
import { QUERY_KEY_FLOOR } from '../../../data/core/queryKeys';

export function useRoomChangeEffect({
  floorNumber,
  room,
  locationStore
}: {
  floorNumber: number | undefined;
  room: { floor_number: number } | undefined;
  locationStore: LocationStore;
}) {
  useEffect(() => {
    if (room && room.floor_number !== floorNumber) {
      locationStore.changeQuery({ [QUERY_KEY_FLOOR]: room.floor_number }, true);
    }
  }, [room]); // eslint-disable-line react-hooks/exhaustive-deps
}
