import React, { useCallback } from 'react';
import {
  ClickableIcon,
  Icon,
  IconActionDelete,
  IconSize,
  Input,
  InputSize
} from '@hse-design/react';
import clsx from 'clsx';
import { useBindCallback } from '../../../../utils/hooks/useBindCallback';
import s from './RouteSearchPoint.module.scss';

export interface RouteSearchPointProps {
  value?: string;
  className?: string;
  icon?: React.ComponentType<any>;
  placeholder?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
}

export function RouteSearchPoint({
  value,
  className,
  icon: ThisIcon,
  placeholder,
  onChange,
  onFocus
}: RouteSearchPointProps) {
  const handleClear = useBindCallback(onChange, '');

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    },
    [onChange]
  );

  return (
    <div className={clsx(s.RouteSearchPoint, className)}>
      <Icon
        className={s.RouteSearchPoint__icon}
        icon={ThisIcon}
        size={IconSize.large}
      />
      <Input
        className={s.RouteSearchPoint__search}
        classes={{
          input: s.RouteSearchPoint__search__input
        }}
        type={'search'}
        placeholder={placeholder}
        size={InputSize.medium}
        value={value}
        onChange={handleChange}
        onFocus={onFocus}
        right={
          value && (
            <ClickableIcon
              iconProps={{ icon: IconActionDelete, size: IconSize.small }}
              onClick={handleClear}
            />
          )
        }
      />
    </div>
  );
}
