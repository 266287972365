import React, { useCallback } from 'react';
import {
  ClickableIcon,
  Divider,
  IconActionMinus,
  IconActionPlus
} from '@hse-design/react';
import { zoomActions } from '../MapControls';
import s from './ZoomAction.module.scss';

export interface ZoomActionProps {
  mapRef: React.RefObject<zoomActions>;
}

export function ZoomAction({ mapRef }: ZoomActionProps) {
  const onClick = useCallback(
    (direction) => {
      if (mapRef && mapRef.current) {
        if (direction === 'in') {
          mapRef.current.zoomIn();
        } else {
          mapRef.current.zoomOut();
        }
      }
    },
    [mapRef]
  );

  return (
    <div className={s.ZoomAction}>
      <ClickableIcon
        className={s.ZoomAction__button}
        iconProps={{ icon: IconActionPlus }}
        onClick={() => onClick('in')}
      />
      <Divider className={s.ZoomAction__divider} />
      <ClickableIcon
        className={s.ZoomAction__button}
        iconProps={{ icon: IconActionMinus }}
        onClick={() => onClick('out')}
      />
    </div>
  );
}
