import { ProgressBar, ProgressBarSize } from '@hse-design/react';
import React from 'react';
import s from './MapperPage.module.scss';

export interface MapperProgressBarProps {
  loading?: boolean;
}

export function MapperProgressBar({ loading }: MapperProgressBarProps) {
  return (
    <div className={s.MapperPage__progressBar}>
      <ProgressBar size={ProgressBarSize.medium} indeterminate={loading} />
    </div>
  );
}
