import { isFloorChangingVertexType } from '../../../data/Vertex';
import { LocaleStore } from '../../../data/core/LocaleStore';
import { SegmentType } from '../../../data/SegmentType';
import { IRouteEdge } from '../../../data/RouteEdge';
import { Segment } from './Segment';

export class SegmentCorridor extends Segment {
  type = SegmentType.corridor;

  costMinimum = 50;

  processNext(part: IRouteEdge) {
    if (this.cost >= this.costMinimum) {
      return false;
    }

    if (+part.agg_cost === 0) {
      return false;
    }

    if (isFloorChangingVertexType(part.vertex.type)) {
      return false;
    }

    // todo not typesafe
    this.cost += +part.cost!;
    this.cost_time += +part.cost_time!;

    return true;
  }

  setHint(locale: LocaleStore) {
    const cost = String(Math.round(this.cost));
    this.hint = locale.t('route.segment.corridor', { cost });
  }

  setEndNode(part: IRouteEdge) {}
}
