import React, { useCallback, useMemo } from 'react';
import { LatLngExpression } from 'leaflet';
import { Tooltip } from 'react-leaflet';
import { useContextStore } from '@proscom/prostore-react';
import { useLatestCallbacksRef } from '@proscom/ui-react';
import { useBindCallback } from '../../utils/hooks/useBindCallback';
import { useCombineLatestMap } from '../../utils/hooks/useCombineLatestMap';
import { IEdgeData } from './data/stores/EdgesStore';
import { tooltipOffset } from './utils';
import { HoverItem, HoverItemType } from './data/stores/HoverItem';
import { STORE_HOVER_ITEM, STORE_HOVER_NODE_EDGES } from './data/stores/stores';
import { HoverNodeEdges } from './data/stores/HoverNodeEdges';
import { EdgePolyline } from './components/Map/EdgePolyline';
import { IVertexData } from './data/stores/NodesStore';

export interface MapEdgeProps {
  nodes: Record<number, IVertexData>;
  edge: IEdgeData;
  isEdgeSelected: (edge: IEdgeData) => boolean;
  nodeRef?: React.Ref<any>;
  onClick: (edge: IEdgeData) => void;
}

function useIsHovered(
  edgeId: number,
  hoverItemStore: HoverItem,
  hoverNodeEdgesStore: HoverNodeEdges
) {
  return useCombineLatestMap(
    [hoverItemStore.state$, hoverNodeEdgesStore.state$] as const,
    useCallback(
      ([hoverItem, hoverNodeEdges]) => {
        return (
          (hoverItem &&
            hoverItem.type === HoverItemType.edge &&
            hoverItem.id === edgeId) ||
          hoverNodeEdges.some((edge) => edge.id === edgeId)
        );
      },
      [edgeId]
    )
  );
}

export function MapEdge({
  nodes,
  edge,
  isEdgeSelected,
  nodeRef,
  onClick
}: MapEdgeProps) {
  const edgeId = edge.id;
  const start = nodes[edge.start_vertex_id];
  const end = nodes[edge.end_vertex_id];
  const positions = useMemo<LatLngExpression[]>(
    () => [
      [-start?.y || 0, +start?.x || 0],
      [-end?.y || 0, +end?.x || 0]
    ],
    [start, end]
  );

  const isSelected = isEdgeSelected(edge);

  const hoverItemStore = useContextStore<HoverItem>(STORE_HOVER_ITEM);
  const hoverNodeEdgesStore = useContextStore<HoverNodeEdges>(
    STORE_HOVER_NODE_EDGES
  );

  const isHovered = useIsHovered(edgeId, hoverItemStore, hoverNodeEdgesStore);

  const handleMouseOver = useBindCallback(hoverItemStore.setHoverEdge, edgeId);
  const handleMouseOut = hoverItemStore.reset;
  const handleClick = useBindCallback(onClick, edge);

  const eventHandlers = useLatestCallbacksRef({
    mouseover: handleMouseOver,
    mouseout: handleMouseOut,
    click: handleClick
  });

  return (
    <EdgePolyline
      nodeRef={nodeRef}
      isSelected={isSelected || isHovered || false}
      positions={positions}
      eventHandlers={eventHandlers}
      edge={edge}
    >
      <Tooltip direction="top" offset={tooltipOffset} opacity={1}>
        <div>Ребро {edge.id}</div>
        <div>Тип: {edge.type}</div>
        <div>
          Вершины {edge.start_vertex_id}, {edge.end_vertex_id}
        </div>
      </Tooltip>
    </EdgePolyline>
  );
}
