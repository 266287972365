import { VertexDto } from './api/VertexDto';
import { IVertex } from './Vertex';

export class Portal {
  id: number;
  x: number;
  y: number;
  iconName: string;

  constructor(portalData: VertexDto | IVertex) {
    this.id = portalData.id;
    this.x = +portalData.x;
    this.y = +portalData.y;
    this.iconName = portalData.type;
  }
}
