import { WebStorageValueStore } from '@proscom/prostore-local-storage';

export interface IConfigStoreState {
  showConfig: boolean;
  showNodes: boolean;
  showEdges: boolean;
  showRooms: boolean;
  showHints: boolean;
  showBuildings: boolean;
}

export const MAPPER_CONFIG_STORE_KEY = 'navigationMapperConfig';

export class ConfigStore extends WebStorageValueStore<IConfigStoreState> {
  static defaultStateValue: IConfigStoreState = {
    showConfig: false,
    showNodes: true,
    showEdges: true,
    showRooms: true,
    showHints: true,
    showBuildings: false
  };

  constructor() {
    super(
      window.localStorage,
      MAPPER_CONFIG_STORE_KEY,
      (v) => v || { ...ConfigStore.defaultStateValue }
    );
  }

  public setPartialValue(value: Partial<IConfigStoreState>) {
    if (!this.state.value) return;
    this.setValue((v) => ({ ...v!, ...value }));
  }
}
