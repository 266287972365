import { initialRequestState, ObservableStore } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { QUERY_KEY_CODE, QUERY_KEY_ROOM } from '../../core/queryKeys';
import { combineLatestMap } from '../../../utils/prostore/combineLatestMap';
import { IRoomStore } from '../../online/RoomStore';
import { RoomQueryVars } from '../../api/common';
import { LocaleStore } from '../../core/LocaleStore';
import { RoomDto } from '../../api/RoomDto';
import { CampusesStoreOffline } from './CampusesStoreOffline';

export interface IRoomStoreOfflineParams {
  localeStore: LocaleStore;
  locationStore: LocationStore;
  campusesStoreOffline: CampusesStoreOffline;
}

export interface IRoomStoreOfflineState {
  loading: boolean;
  loaded: boolean;
  error: any;
  variables: RoomQueryVars | null;
  data: RoomDto | null;
}

export class RoomStoreOffline
  extends ObservableStore<IRoomStoreOfflineState>
  implements IRoomStore {
  constructor({
    localeStore,
    locationStore,
    campusesStoreOffline
  }: IRoomStoreOfflineParams) {
    super(
      combineLatestMap(
        [
          localeStore.state$,
          locationStore.get$(QUERY_KEY_ROOM, QUERY_KEY_CODE),
          campusesStoreOffline.state$
        ] as const,
        ([locale, query, campuses]) => {
          const queryRoom = query[QUERY_KEY_ROOM];
          const queryCode = query[QUERY_KEY_CODE];

          const variables: RoomQueryVars | null = queryRoom
            ? {
                params: {
                  locale: locale.lang
                }
              }
            : queryCode
            ? {
                params: {
                  locale: locale.lang,
                  code: queryCode
                }
              }
            : null;

          if (!variables) {
            return {
              data: null,
              loading: false,
              loaded: false,
              error: null,
              variables
            };
          }

          if (!campuses.campuses) {
            return {
              data: null,
              loading: true,
              loaded: false,
              error: null,
              variables
            };
          }

          for (const campus of campuses.campuses) {
            const targetRoom = campus.rooms.find(
              (r) =>
                (queryRoom && r.id === queryRoom) ||
                (queryCode && r.code === queryCode)
            );

            if (targetRoom) {
              return {
                data: targetRoom,
                loading: false,
                loaded: true,
                error: null,
                variables
              };
            }
          }

          return {
            data: null,
            loading: false,
            loaded: true,
            error: null,
            variables
          };
        }
      ),
      { ...initialRequestState }
    );
  }
}
