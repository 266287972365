import React, { useCallback, useMemo, useRef } from 'react';
import { Marker as LeafletMarker } from 'leaflet';
import { Tooltip } from 'react-leaflet';
import composeRefs from '@seznam/compose-react-refs';
import { useContextStore } from '@proscom/prostore-react';
import { useLatestCallbacksRef } from '@proscom/ui-react';
import { useBindCallback } from '../../utils/hooks/useBindCallback';
import { useCombineLatestMap } from '../../utils/hooks/useCombineLatestMap';
import { IVertexData } from './data/stores/NodesStore';
import { IEdgeData } from './data/stores/EdgesStore';
import { NodeMarker } from './components/Map/NodeMarker';
import { edgeGoesDown, edgeGoesUp } from './utils/edges';
import { Point, tooltipOffset } from './utils';
import { HoverItem, HoverItemType } from './data/stores/HoverItem';
import { STORE_HOVER_EDGE_NODES, STORE_HOVER_ITEM } from './data/stores/stores';
import { getAdjacentEdges } from './data/stores/getAdjacentEdges';
import { HoverEdgeNodes } from './data/stores/HoverEdgeNodes';

export interface MapNodeProps {
  draggable?: boolean;
  nodeRef?: React.Ref<any>;
  node: IVertexData;
  edges: Record<number, IEdgeData>;
  isNodeSelected: (node: IVertexData) => boolean;
  onClick: (node: IVertexData) => void;
  onMove?: (newCoordinates: Point) => void;
}

function useIsHovered(
  nodeId: number,
  hoverItemStore: HoverItem,
  hoverEdgeNodesStore: HoverEdgeNodes
) {
  return useCombineLatestMap(
    [hoverItemStore.state$, hoverEdgeNodesStore.state$] as const,
    useCallback(
      ([hoverItem, hoverEdgeNodes]) => {
        return (
          (hoverItem &&
            hoverItem.type === HoverItemType.node &&
            hoverItem.id === nodeId) ||
          hoverEdgeNodes.some((node) => node.id === nodeId)
        );
      },
      [nodeId]
    )
  );
}

export function MapNode({
  draggable,
  nodeRef,
  node,
  edges,
  isNodeSelected,
  onClick,
  onMove
}: MapNodeProps) {
  const nodeId = node.id;
  const adjacentEdges = useMemo(
    () => getAdjacentEdges(Object.values(edges), nodeId),
    [nodeId, edges]
  );
  const hasUpEdge = useMemo(
    () => adjacentEdges.some((edge) => edgeGoesUp(edge, nodeId)),
    [adjacentEdges, nodeId]
  );
  const hasDownEdge = useMemo(
    () => adjacentEdges.some((edge) => edgeGoesDown(edge, nodeId)),
    [adjacentEdges, nodeId]
  );

  const hoverItemStore = useContextStore<HoverItem>(STORE_HOVER_ITEM);
  const hoverEdgeNodesStore = useContextStore<HoverEdgeNodes>(
    STORE_HOVER_EDGE_NODES
  );
  const isHovered = useIsHovered(nodeId, hoverItemStore, hoverEdgeNodesStore);
  const isSelected = isNodeSelected(node);

  const handleMouseOver = useBindCallback(hoverItemStore.setHoverNode, nodeId);
  const handleMouseOut = hoverItemStore.reset;
  const handleClick = useBindCallback(onClick, node);

  const markerRef = useRef<LeafletMarker | null>(null);
  const handleDragEnd = useCallback(() => {
    const marker = markerRef.current;
    if (marker) {
      const { lat, lng }: { lat: number; lng: number } = marker.getLatLng();
      onMove?.([lng, -lat]);
    }
  }, [onMove]);

  const eventHandlers = useLatestCallbacksRef({
    mouseover: handleMouseOver,
    mouseout: handleMouseOut,
    click: handleClick,
    dragend: handleDragEnd
  });

  return (
    <NodeMarker
      leafletRef={composeRefs(nodeRef, markerRef)}
      node={node}
      selected={isSelected || isHovered || false}
      eventHandlers={eventHandlers}
      hasUpEdge={hasUpEdge}
      hasDownEdge={hasDownEdge}
      draggable={draggable}
    >
      <Tooltip direction="top" offset={tooltipOffset} opacity={1}>
        <div>Вершина {node.id}</div>
        <div>Тип {node.type}</div>
      </Tooltip>
    </NodeMarker>
  );
}
