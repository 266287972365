import { ObservableStore } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { locationStoreGet$ } from '../../../common/hooks/useLocationQuery';
import { QUERY_KEY_CAMPUS } from '../../core/queryKeys';
import { combineLatestMap } from '../../../utils/prostore/combineLatestMap';
import {
  CampusesStoreOffline,
  IOfflineCampusInfo
} from './CampusesStoreOffline';

export interface CurrentCampusStoreOfflineArgs {
  campusesStoreOffline: CampusesStoreOffline;
  locationStore: LocationStore;
}

/**
 * Стор хранящий текущий кампус из CampusesStoreOffline
 * @param locationStore - {@link LocationStore}
 * @param campusesStoreOffline - {@link CampusesStoreOffline}
 */
export class CurrentCampusStoreOffline extends ObservableStore<IOfflineCampusInfo | null> {
  constructor(params: CurrentCampusStoreOfflineArgs) {
    super(
      combineLatestMap(
        [
          params.campusesStoreOffline.state$,
          locationStoreGet$(params.locationStore, QUERY_KEY_CAMPUS)
        ] as const,
        ([campusesState, campusQuery]) =>
          campusesState?.campuses?.find(
            (c) => c.campus === campusQuery.campus
          ) || null
      ),
      null
    );
  }
}
