import React from 'react';
import { Description } from '../../ui/Description/Description';
import s from './RoomExtDescription.module.scss';

export interface RoomExtDescriptionProps {
  description: string;
}

export function RoomExtDescription({ description }: RoomExtDescriptionProps) {
  return (
    <div className={s.RoomExtDescription}>
      <Description text={description} />
    </div>
  );
}
