/**
 * Модуль содержит ключи сторов, специфичных для мэппера
 */

/** Стор с аутентификацией */
export const STORE_AUTH = 'STORE_AUTH';
/** Стор с Echo */
export const STORE_ECHO = 'STORE_ECHO';
/** Стор с комнатами */
export const STORE_ROOMS = 'STORE_ROOMS';
/** Стор с вершинами (нодами, vertices) */
export const STORE_NODES = 'STORE_NODES';
/** Стор с QR кодами */
export const STORE_MAPPER_QR = 'STORE_MAPPER_QR';
/** Стор с гранями (edges) */
export const STORE_EDGES = 'STORE_EDGES';
/** Стор с конфигом */
export const STORE_MAPPER_CONFIG = 'STORE_MAPPER_CONFIG';
/** Стор с этажами */
export const STORE_MAPPER_FLOORS = 'STORE_MAPPER_FLOORS';

export const STORE_SELECTED_ROOM = 'STORE_SELECTED_ROOM';
export const STORE_SELECTED_NODE = 'STORE_SELECTED_NODE';
export const STORE_SELECTED_NODE_QRS = 'STORE_SELECTED_NODE_QRS';
export const STORE_NODE_UPDATE_OPERATION = 'STORE_UPDATE_NODE_OPERATION';
export const STORE_NODE_REMOVE_OPERATION = 'STORE_NODE_REMOVE_OPERATION';
export const STORE_NODE_ADJACENT_EDGES = 'STORE_NODE_ADJACENT_EDGES';
export const STORE_EDITOR_MODE = 'STORE_EDITOR_MODE';
export const STORE_FINISH_SET_ROOM = 'ACTION_FINISH_SET_ROOM';
export const STORE_MODE_SET_ROOM = 'STORE_MODE_SET_ROOM';
export const STORE_MODE_CONNECT_NODES = 'STORE_MODE_CONNECT_NODES';
export const STORE_ACTIVE_EDITOR_MODE = 'STORE_ACTIVE_EDITOR_MODE';
export const STORE_EDGE_ADD_OPERATION = 'STORE_EDGE_ADD_OPERATION';
export const STORE_HOVER_ITEM = 'STORE_HOVER_ITEM';
export const STORE_HOVER_NODE_EDGES = 'STORE_HOVER_NODE_EDGES';
export const STORE_HOVER_EDGE_NODES = 'STORE_HOVER_EDGE_NODES';

export const ACTION_SELECT_NODE = 'ACTION_SELECT_NODE';
export const ACTION_SELECT_EDGE = 'ACTION_SELECT_EDGE';
export const ACTION_SELECT_ROOM = 'ACTION_SELECT_ROOM';
export const ACTION_SELECT_FLOOR = 'ACTION_SELECT_FLOOR';
export const ACTION_START_SET_ROOM = 'ACTION_START_SET_ROOM';
export const ACTION_START_CONNECT_NODE = 'ACTION_START_CONNECT_NODE';
