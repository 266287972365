import { useStore } from '@proscom/prostore-react';
import React, { useCallback } from 'react';
import { LocaleStore } from '../../../../data/core/LocaleStore';
import { STORE_CURRENT_LOCATION, STORE_LOCALE } from '../../../../data/stores';
import { CurrentLocationStore } from '../../../../data/stores/CurrentLocationStore';
import { RoomDto } from '../../../../data/api/RoomDto';
import { getFieldLocale } from '../../../../data/offline/api/LocalizedField';
import { MyLocationActions } from './MyLocationActions';
import s from './MyLocationPanelContent.module.scss';

export function MyLocationPanelContent() {
  const [localeStoreState] = useStore<LocaleStore>(STORE_LOCALE);
  const [
    currentLocationState,
    currentLocationStore
  ] = useStore<CurrentLocationStore>(STORE_CURRENT_LOCATION);

  const handleRemove = useCallback(
    () => currentLocationStore.setLocation(undefined),
    [currentLocationStore]
  );

  const { location } = currentLocationState;
  if (!location) return null;

  return (
    <div className={s.MyLocationPanelContent}>
      {location.room &&
        ((room: RoomDto) => {
          // todo надо разобраться откуда берется name типа object в онлайн режиме
          const isObject = typeof room.name === 'object';
          return (
            <div className={s.MyLocationPanelContent__room}>
              {isObject
                ? getFieldLocale(room.name as any, localeStoreState.lang)
                : room.name}
            </div>
          );
        })(location.room)}
      <div className={s.MyLocationPanelContent__actions}>
        <MyLocationActions onRemove={handleRemove} location={location} />
      </div>
    </div>
  );
}
