import React, { useCallback } from 'react';
import { useContextStore, useStoreState } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { ReactComponent as IconToPoint } from '../../../../assets/img/icons/i-point-b.svg';
import { ReactComponent as IconFromPoint } from '../../../../assets/img/icons/i-point-a.svg';
import {
  QUERY_KEY_END_ROOM,
  QUERY_KEY_END_SEARCH,
  QUERY_KEY_END_VERTEX,
  QUERY_KEY_START_ROOM,
  QUERY_KEY_START_SEARCH,
  QUERY_KEY_START_VERTEX,
  TransformedQueryParams
} from '../../../../data/core/queryKeys';
import {
  STORE_CURRENT_LOCATION,
  STORE_LOCATION,
  STORE_ROUTE
} from '../../../../data/stores';
import { RouteStoreState } from '../../../../data/stores/RouteStore';
import { useLocale } from '../../../../common/hooks/useLocale';
import { useBindCallback } from '../../../../utils/hooks/useBindCallback';
import { CurrentLocationState } from '../../../../data/stores/CurrentLocationStore';
import { RouteSearch } from './RouteSearch';
import { RouteSearchAction } from './RouteSearchAction';
import { RouteSearchPointConnected } from './RouteSearchPointConnected';
import s from '../../desktop/DesktopSearchPanel/DesktopSearchPanel.module.scss';

export interface RouteSearchConnectedProps {
  activePoint: 'A' | 'B' | undefined;
  onChangeActivePoint: (point: 'A' | 'B' | undefined) => void;
  onSelectOnMap?: (activePoint: 'A' | 'B') => void;
  onSelectCurrentLocation?: (activePoint: 'A' | 'B') => void;
}

export function RouteSearchConnected({
  activePoint,
  onChangeActivePoint,
  onSelectOnMap,
  onSelectCurrentLocation
}: RouteSearchConnectedProps) {
  const locale = useLocale();
  const locationStore = useContextStore<LocationStore>(STORE_LOCATION);
  const { routeStartPoint, routeEndPoint } = useStoreState<RouteStoreState>(
    STORE_ROUTE
  );
  const currentLocation = useStoreState<CurrentLocationState>(
    STORE_CURRENT_LOCATION
  );

  const handleSwapClick = useCallback(() => {
    const query = locationStore.state.query as TransformedQueryParams;
    locationStore.changeQuery(
      {
        [QUERY_KEY_START_SEARCH]: query[QUERY_KEY_END_SEARCH],
        [QUERY_KEY_START_VERTEX]: query[QUERY_KEY_END_VERTEX],
        [QUERY_KEY_START_ROOM]: query[QUERY_KEY_END_ROOM],
        [QUERY_KEY_END_SEARCH]: query[QUERY_KEY_START_SEARCH],
        [QUERY_KEY_END_VERTEX]: query[QUERY_KEY_START_VERTEX],
        [QUERY_KEY_END_ROOM]: query[QUERY_KEY_START_ROOM]
      },
      true
    );
  }, [locationStore]);

  const handleFocusA = useBindCallback(onChangeActivePoint, 'A');
  const handleFocusB = useBindCallback(onChangeActivePoint, 'B');

  const handleSelectOnMap = useCallback(() => {
    if (activePoint) {
      onSelectOnMap?.(activePoint);
    }
  }, [activePoint, onSelectOnMap]);

  const handleSelectCurrentLocation = useCallback(() => {
    if (activePoint) {
      onSelectCurrentLocation?.(activePoint);
    }
  }, [activePoint, onSelectCurrentLocation]);

  return (
    <RouteSearch
      className={s.DesktopSearchPanel__route}
      onSwapClick={handleSwapClick}
      points={
        <>
          <RouteSearchPointConnected
            queryKeySearch={QUERY_KEY_START_SEARCH}
            queryKeyRoom={QUERY_KEY_START_ROOM}
            queryKeyVertex={QUERY_KEY_START_VERTEX}
            room={routeStartPoint}
            icon={IconFromPoint}
            placeholder={locale.t('routeMapper.from')}
            onFocus={handleFocusA}
          />
          <RouteSearchPointConnected
            queryKeySearch={QUERY_KEY_END_SEARCH}
            queryKeyRoom={QUERY_KEY_END_ROOM}
            queryKeyVertex={QUERY_KEY_END_VERTEX}
            room={routeEndPoint}
            icon={IconToPoint}
            placeholder={locale.t('routeMapper.to')}
            onFocus={handleFocusB}
          />
        </>
      }
      actions={
        activePoint && (
          <>
            <RouteSearchAction
              text={locale.t('routeMapper.selectPointOnMap', {
                activePoint
              })}
              onClick={handleSelectOnMap}
            />
            {Boolean(currentLocation.location) && (
              <RouteSearchAction
                text={locale.t('routeMapper.useMyCurrentLocation', {
                  activePoint
                })}
                onClick={handleSelectCurrentLocation}
              />
            )}
          </>
        )
      }
    />
  );
}
