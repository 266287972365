import React, { useCallback, useEffect, useRef, useState } from 'react';
import immer from 'immer';
import { NBSP } from '@proscom/ui-utils';
import {
  Button,
  IconActionLinkText,
  IconActionPlus,
  IconActionTrash,
  IconButton
} from '@hse-design/react';
import { useStoreState } from '@proscom/prostore-react';
import { edgeGoesDown, edgeGoesUp } from '../../utils/edges';
import type { IVertexData } from '../.././data/stores/NodesStore';
import { IEdgeData } from '../.././data/stores/EdgesStore';
import { dangerColor, successColor } from '../../data/theme';
import { PopupGrid, PopupProp } from '../../PopupProp';
import { UpdateNodeForm, UpdateNodeFormFields } from '../forms/UpdateNode';
import { QrModal } from '../../../../common/components/QrModal/QrModal';
import { IQRData } from '../../data/stores/MapperQrStore';
import { STORE_NODE_ADJACENT_EDGES } from '../../data/stores/stores';
import { BuildingDto } from '../../../../data/api/BuildingDto';
import { BasePopup, BasePopupProps } from './BasePopup';
import s from '../../MapperPage.module.scss';

export interface NodePopupProps extends BasePopupProps {
  floors?: number[];
  buildings?: BuildingDto[];
  node: IVertexData;
  onOpenEdge: (edge: IEdgeData) => void;
  onRemove: (node: IVertexData) => void;
  qrCodes?: IQRData[];
  onEditNode: (node: Partial<IVertexData> & UpdateNodeFormFields) => void;
  onOpenRoom: (room: { id: number; floor_number?: number }) => void;
  onSetRoom: (node: IVertexData) => void;
  onConnect: (node: IVertexData) => void;
}

function qrCodesToOptions(qrCodes?: IQRData[]) {
  return (
    qrCodes?.map((q) => ({
      id: q.id,
      value: q.qr
    })) || []
  );
}

export function NodePopup({
  floors,
  buildings,
  loading,
  onClose,
  node,
  onOpenEdge,
  onRemove,
  qrCodes,
  onEditNode,
  onOpenRoom,
  onSetRoom,
  onConnect
}: NodePopupProps) {
  const nodeId = node.id;
  const adjacentEdges = useStoreState<IEdgeData[]>(STORE_NODE_ADJACENT_EDGES);
  const [qrScannerActive, setQrScannerActive] = useState(false);
  const [values, setValues] = useState({
    type: node.type || '',
    floor_number: node.floor_number,
    building_id: node.building_id,
    qr_codes: qrCodesToOptions(qrCodes)
  });
  useEffect(() => {
    setValues((v) => ({
      ...v,
      qr_codes: qrCodesToOptions(qrCodes)
    }));
  }, [qrCodes]);

  const onSubmit = useCallback(
    (newValues) => {
      onEditNode({ id: nodeId, ...newValues });
    },
    [nodeId, onEditNode]
  );

  const activeScannerQrId = useRef<string | number | null | undefined>(null);
  const showQrScanner = useCallback((qrId?: string | number) => {
    activeScannerQrId.current = qrId;
    setQrScannerActive(true);
  }, []);

  const onQrScanned = useCallback((value: string) => {
    console.log('qr code scanned', value);
    setValues((v) =>
      immer(v, (draft) => {
        if (!activeScannerQrId.current) {
          // @todo fix
          draft.qr_codes.push({ id: null as any, value });
        } else {
          const index = draft.qr_codes.findIndex(
            (q) => q.id === activeScannerQrId.current
          );
          if (index > -1) {
            draft.qr_codes[index].value = value;
          }
        }
        activeScannerQrId.current = null;
      })
    );
    setQrScannerActive(false);
  }, []);

  return (
    <BasePopup loading={loading} title={`Вершина ${node.id}`} onClose={onClose}>
      <QrModal
        isOpen={qrScannerActive}
        onClose={() => {
          setQrScannerActive(false);
        }}
        onQrScanned={onQrScanned}
      />
      <UpdateNodeForm
        floors={floors}
        buildings={buildings}
        values={values}
        showQrScanner={showQrScanner}
        onSubmit={onSubmit}
      />
      <PopupProp label={'Координаты вершины'}>
        {Number(node.x).toFixed(2)}
        {NBSP}
        {Number(node.y).toFixed(2)}
      </PopupProp>
      <PopupProp label={'Комната'}>
        {node.room_id && (
          <Button
            size={Button.Size.small}
            variant={Button.Variant.tertiary}
            onClick={() => node.room_id && onOpenRoom({ id: node.room_id })}
          >
            {node.room_id}
          </Button>
        )}
        {node.room_id ? (
          <IconButton
            title={'Удалить комнату'}
            size={IconButton.Size.small}
            variant={IconButton.Variant.ghost}
            icon={IconActionTrash}
            iconColor={dangerColor}
            /* todo fix */
            onClick={() => onEditNode({ id: nodeId, room_id: null } as any)}
          />
        ) : (
          <Button
            size={Button.Size.small}
            variant={Button.Variant.secondary}
            leftIcon={IconActionPlus}
            color={successColor}
            onClick={() => onSetRoom(node)}
          >
            Добавить комнату
          </Button>
        )}
      </PopupProp>
      <PopupProp label={'Ребра'}>
        {!adjacentEdges.length ? (
          'У вершины нет рёбер'
        ) : (
          <PopupGrid>
            {adjacentEdges.map((edge, iEdge) => {
              const up = edgeGoesUp(edge, nodeId);
              const down = edgeGoesDown(edge, nodeId);
              let text = `${edge.id}`;
              if (up) {
                text += ' ▲';
              }
              if (down) {
                text += ' ▼';
              }

              return (
                <div key={iEdge} className={s.PopupProp__Value}>
                  <Button
                    variant={Button.Variant.tertiary}
                    size={Button.Size.small}
                    onClick={() => onOpenEdge(edge)}
                  >
                    {text}
                  </Button>
                </div>
              );
            })}
          </PopupGrid>
        )}
      </PopupProp>
      <PopupProp label={'Манипуляции'}>
        <div className={s.NodePopup__Actions}>
          <Button
            className={s.NodePopup__Action}
            size={Button.Size.small}
            leftIcon={IconActionLinkText}
            onClick={() => onConnect(node)}
            variant={Button.Variant.secondary}
          >
            Соединить с вершиной
          </Button>
          <Button
            className={s.NodePopup__Action}
            leftIcon={IconActionTrash}
            color={dangerColor}
            size={Button.Size.small}
            variant={Button.Variant.secondary}
            onClick={() => onRemove(node)}
          >
            Удалить вершину
          </Button>
        </div>
      </PopupProp>
    </BasePopup>
  );
}
