import React from 'react';
import classNames from 'classnames';
import arrowBackIcon from '../../../../assets/img/icons/i-back.svg';
import { useLocale } from '../../../../common/hooks/useLocale';
import { Loader } from '../../../../common/ui/Loader/Loader';
import { RoomDto } from '../../../../data/api/RoomDto';
import localStyles from './SelectOnMapView.module.scss';

const mainClass = 'select-in-map';

interface DoneActionProps {
  onClick?: () => void;
}

function DoneAction({ onClick }: DoneActionProps) {
  const localeStore = useLocale();

  return (
    <div className={localStyles.actions} onClick={onClick}>
      <div className={localStyles.actions__button}>
        {localeStore.t('selectInMap.done')}
      </div>
    </div>
  );
}

export interface HeadLayoutProps {
  selectInMap: 'A' | 'B';
  room?: RoomDto;
  onRouteSelect?: () => void;
  setStateSelectMap?: (point: 'A' | 'B' | undefined) => void;
  isLoading?: boolean;
}

export function SelectOnMapView({
  selectInMap,
  room,
  onRouteSelect,
  setStateSelectMap,
  isLoading
}: HeadLayoutProps) {
  const localeStore = useLocale();

  return (
    <div className={localStyles[mainClass]}>
      <div className={localStyles.head}>
        <div
          className={localStyles.head__back}
          onClick={() => setStateSelectMap?.(undefined)}
        >
          <img
            className={localStyles.back__img}
            src={arrowBackIcon}
            alt="Go back"
          />
        </div>
        <div className={localStyles.head__info}>
          <div
            className={classNames([
              localStyles.info__item,
              localStyles.info__title
            ])}
          >
            {localeStore.t('routeMapper.selectPointOnMap', {
              activePoint: selectInMap
            })}
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            !!room && (
              <div
                className={classNames([
                  localStyles.info__item,
                  localStyles.info__desc
                ])}
              >
                {localeStore.t('selectInMap.room')} {room.name}
              </div>
            )
          )}
        </div>
      </div>
      {Boolean(room) && <DoneAction onClick={onRouteSelect} />}
    </div>
  );
}
