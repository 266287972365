import * as findJaroSimilarity from 'jaro-winkler';
import { AnyObject } from '@hse-design/core';
import { StringKeyOf } from '../types';
import { splitStringToLowerWords } from '../splitStringToLowerWords';
import { findArrayExtreme } from '../array/findArrayExtreme';
import { StaffDto } from '../../data/api/StaffDto';
import { RoomDto } from '../../data/api/RoomDto';

export interface RoomSearchResult extends RoomDto {
  similarity: number;
}

function calcSearchSimilarity(
  field: string,
  words: string[],
  wordThreshold: number
) {
  const fieldWords = splitStringToLowerWords(field);

  let similarity = 0;
  for (const word of words) {
    const [wordSimilarity, fieldWord] = (() => {
      for (const fieldWord of fieldWords) {
        const wordSimilarity = findJaroSimilarity(fieldWord, word, {
          caseSensitive: false
        });
        if (wordSimilarity >= wordThreshold) {
          return [wordSimilarity, fieldWord] as const;
        }
      }

      return [0, null] as const;
    })();

    if (fieldWord) {
      // Агрегируем похожесть совпавших слов в строке
      similarity += wordSimilarity;
    } else {
      // Если хотя бы одно слово не найдено в строке, то считаем строку не подходящей
      return 0;
    }
  }

  return similarity / words.length;
}

function searchObjectFields<T extends AnyObject>(
  obj: T,
  fieldNames: readonly StringKeyOf<T>[],
  words: string[],
  threshold: number
) {
  return findArrayExtreme(fieldNames, true, (fieldKey) => {
    if (!obj[fieldKey]) return 0;
    return calcSearchSimilarity(obj[fieldKey], words, threshold);
  });
}

/**
 * Возвращает массив комнат - результат фильтрации комнат на вход по правилу сходства поля комнаты и слова из массива words
 * @param roomsIn - массив комнат, по которым проводится поиск
 * @param search - поисковый запрос
 * @param roomFieldList - массив полей, которые учитываются в поиске
 * @param staffFieldList - массив полей, которые учитываются в поиске
 * @param threshold - порог сходства по Джаро-Винклеру между значением поля fieldList и словом words
 */
export const findRoomsByWords = (
  roomsIn: RoomDto[],
  search: string,
  roomFieldList: readonly StringKeyOf<RoomDto>[],
  staffFieldList: readonly StringKeyOf<StaffDto>[],
  threshold: number
): RoomSearchResult[] => {
  const words = splitStringToLowerWords(search.trim());
  const result: RoomSearchResult[] = [];

  if (words.length < 1) return result;

  for (const room of roomsIn) {
    const filteredStaff =
      room.staff
        ?.map((staff) => {
          const [, similarity] = searchObjectFields(
            staff,
            staffFieldList,
            words,
            threshold
          );

          return {
            ...staff,
            similarity: similarity || 0
          };
        })
        .filter((s) => s.similarity !== null && s.similarity > 0) || [];

    const [, similarity] = searchObjectFields(
      room,
      roomFieldList,
      words,
      threshold
    );

    if (filteredStaff.length > 0) {
      const [, maxSimilarity] = findArrayExtreme(
        filteredStaff,
        true,
        (s) => s.similarity
      );

      result.push({
        ...room,
        staff: filteredStaff,
        similarity: Math.max(maxSimilarity || 0, similarity || 0)
      });
      continue;
    }

    if (similarity !== null && similarity > 0) {
      result.push({
        ...room,
        staff: [],
        similarity
      });
    }
  }

  return result;
};
