import { ObservableStore } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { combineLatestMapAsync } from '../../utils/prostore/combineLatestMap';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import { LocaleStore } from '../core/LocaleStore';
import { QUERY_KEY_BUILDING } from '../core/queryKeys';
import { SelectedFloor } from './SelectedFloor';
import { CampusStore } from './CampusStore';
import { RoomSearchStore } from './RoomSearchStore';
import { SearchStore } from './SearchStore';
import { CurrentLocationStore } from './CurrentLocationStore';
import { RouteStore } from './RouteStore';

export interface RoomSearchVars {
  locale: string;
  page: number;
  perPage: number;
  sort_floor: number;
  sort_building?: number;
  campus_id: number;
  search?: string;
  room_category_id?: number;
  vertex_id?: number;
  room_id?: number;
}

const amountRoomsInPerPage = 20;

export class RoomSearchVarsStore extends ObservableStore<RoomSearchVars | null> {
  constructor(
    locale: LocaleStore,
    floor: SelectedFloor,
    campus: CampusStore,
    location: LocationStore,
    roomSearch: RoomSearchStore,
    search: SearchStore,
    currentLocation: CurrentLocationStore,
    route: RouteStore
  ) {
    super(
      combineLatestMapAsync(
        [
          locale.state$,
          floor.state$,
          campus.state$,
          locationStoreGet$(location, QUERY_KEY_BUILDING),
          roomSearch.state$,
          search.state$,
          currentLocation.state$,
          route.state$
        ] as const,
        ([
          locale,
          floor,
          campus,
          query,
          roomSearch,
          search,
          currentLocation,
          route
        ]) => {
          if (!campus || !locale.lang) return null;

          const res: RoomSearchVars = {
            locale: locale.lang,
            page: roomSearch.page,
            perPage: amountRoomsInPerPage,
            sort_floor: floor,
            sort_building: query[QUERY_KEY_BUILDING],
            campus_id: campus.id
          };

          if (roomSearch.search) {
            res.search = roomSearch.search;
          }

          if (
            search.selectedCategoryId !== null &&
            search.selectedCategoryId !== -1
          ) {
            res.room_category_id = search.selectedCategoryId;
          }

          if (currentLocation.location) {
            res.vertex_id = currentLocation.location.id;
          } else if (route.routeStartPoint) {
            res.room_id = route.routeStartPoint.id;
          }

          return res;
        }
      ).pipe(distinctUntilChanged(isEqual)),
      null
    );
  }
}
