import React from 'react';
import { LatLngExpression, LeafletEventHandlerFnMap } from 'leaflet';
import { Polyline, PolylineProps } from 'react-leaflet';
import { define } from '@proscom/ui-utils';
import {
  accentRedColor,
  dangerColor,
  lighterBrandColor
} from '../../data/theme';
import { IEdgeData } from '../../data/stores/EdgesStore';
import { EdgeType } from '../../../../data/EdgeType';

export interface EdgePolylineProps {
  isSelected?: boolean;
  edge: IEdgeData;
  children?: React.ReactNode;
  nodeRef?: React.Ref<any>;
  positions: LatLngExpression[];
  eventHandlers?: LeafletEventHandlerFnMap;
}

export const defaultLineStyle = (edge: IEdgeData): Partial<PolylineProps> => ({
  color:
    edge.type === EdgeType.corridor
      ? lighterBrandColor
      : edge.type === EdgeType.stairs
      ? dangerColor
      : edge.type === EdgeType.elevator
      ? dangerColor
      : edge.type === EdgeType.transfer
      ? 'yellow'
      : dangerColor,
  weight: 5
});

export const selectedLineStyle = define<Partial<PolylineProps>>()({
  color: accentRedColor,
  weight: 6
});

export function EdgePolyline({
  isSelected,
  edge,
  children,
  nodeRef,
  positions,
  eventHandlers
}: EdgePolylineProps) {
  const baseLineStyle = isSelected ? selectedLineStyle : defaultLineStyle(edge);

  return (
    <Polyline
      ref={nodeRef}
      {...baseLineStyle}
      positions={positions}
      eventHandlers={eventHandlers}
      pathOptions={baseLineStyle}
    >
      {children}
    </Polyline>
  );
}
