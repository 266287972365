import { Routes, RouteType } from '../common/utils/Routes';
import { DefaultLayout } from './DefaultLayout';
import IndexPage from './index/IndexPage';
import NotFoundPage from './notFound/NotFoundPage';
import MapperPage from './mapper/MapperPage';

const routes: RouteType[] = [
  {
    path: '/mapper',
    exact: true,
    component: DefaultLayout(MapperPage)
  },
  {
    path: '/',
    component: DefaultLayout(IndexPage)
  },
  {
    component: NotFoundPage
  }
];

const AppRoutes = Routes(routes);

export default AppRoutes;
