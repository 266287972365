import { from, Observable } from 'rxjs';

export function createObservableFromPromiseDefer<T>(
  callback: (abortController: AbortController) => Promise<T>
) {
  return new Observable<T>((subscriber) => {
    const abortController = new AbortController();
    const promise = callback(abortController);
    const sub = from(promise).subscribe(subscriber);
    return () => {
      sub.unsubscribe();
      abortController.abort();
    };
  });
}
