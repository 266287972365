import React from 'react';

import { DivPropsWithoutRef } from '@hse-design/react';
import clsx from 'clsx';
import s from './ErrorMessage.module.scss';

export interface ErrorMessageProps extends DivPropsWithoutRef {
  classes?: Record<'text' | 'description' | 'children', string>;
  text?: React.ReactNode;
  description?: string;
  children?: React.ReactNode;
}

export function ErrorMessage({
  className,
  classes,
  text,
  description,
  children,
  ...props
}: ErrorMessageProps) {
  return (
    <div className={clsx(className, s.ErrorMessage)}>
      {text && (
        <div className={clsx(s.ErrorMessage__text, classes?.text)}>{text}</div>
      )}
      {description && (
        <div
          className={clsx(s.ErrorMessage__description, classes?.description)}
        >
          {description}
        </div>
      )}
      {children && (
        <div className={clsx(s.ErrorMessage__children, classes?.children)}>
          {children}
        </div>
      )}
    </div>
  );
}
