import React from 'react';
import clsx from 'clsx';
import s from './Loader.module.scss';

export interface LoaderProps {
  className?: string;
  fullWidth?: boolean;
}

export function Loader({ className, fullWidth }: LoaderProps) {
  return (
    <div
      className={clsx(s.Loader, className, {
        [s.Loader_fullWidth]: fullWidth
      })}
    >
      <div className={s.Loader__loader}>
        <div className={s.Loader__loaderInner} />
        <div className={s.Loader__loaderInner} />
        <div className={s.Loader__loaderInner} />
        <div className={s.Loader__loaderInner} />
      </div>
    </div>
  );
}
