export function onScrollBottom(
  callback?: (e: Event) => void,
  margin: number = 200
) {
  return (e: Event) => {
    const scrollNode = e.target as HTMLElement;
    const { scrollTop, scrollHeight, clientHeight } = scrollNode;
    if (scrollTop > scrollHeight - clientHeight - margin) {
      callback?.(e);
    }
  };
}
