import { IVertex } from '../Vertex';

export interface VertexDto extends Omit<IVertex, 'x' | 'y'> {
  x: string;
  y: string;
}

export function parseVertexDto(vertex: VertexDto): IVertex {
  return {
    ...vertex,
    x: +vertex.x,
    y: +vertex.y
  };
}
