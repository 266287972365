import { CustomError } from '@proscom/ui-utils';
import { keyMapBy } from '../utils/array/keyMapBy';
import { ensure } from '../utils/ensure';
import { OfflineEdgeDto } from './offline/api/OfflineEdgeDto';
import { OfflineVertexDto } from './offline/api/OfflineVertexDto';
import { parseVertexDto } from './api/VertexDto';
import { IVertex } from './Vertex';

export interface IEdgeWithVertexes extends OfflineEdgeDto {
  start_vertex: IVertex;
  end_vertex: IVertex;
}

export class EdgeNotFound extends CustomError {
  public readonly name: string = 'EdgeNotFound';
  constructor(public readonly edgeId: number | null | undefined) {
    super(`Edge id='${edgeId}' not found in graph`);
  }
}
export class VertexNotFound extends CustomError {
  public readonly name: string = 'VertexNotFound';
  constructor(public readonly vertexId: number | null | undefined) {
    super(`Vertex id='${vertexId}' not found in graph`);
  }
}

export class PathGraph {
  public edges: IEdgeWithVertexes[];

  /** Array of vertices */
  public vertices: IVertex[];
  /** Map of vertexId => vertex */
  public vertexMap: Map<number, IVertex>;
  /** Map of edgeId => edge */
  public edgesMap: Map<number, IEdgeWithVertexes>;
  /** Map of edges in the form of startVertexId => edgeId[] */
  public edgesGraph: Map<number, Set<number>>;

  constructor(verticesDto: OfflineVertexDto[], edges: OfflineEdgeDto[]) {
    this.vertices = verticesDto.map((v) => parseVertexDto(v));
    this.vertexMap = keyMapBy(this.vertices, (vertex) => vertex.id);

    this.edges = edges
      .map((edge) => {
        const start_vertex = this.vertexMap.get(edge.start_vertex_id);
        const end_vertex = this.vertexMap.get(edge.end_vertex_id);
        return { ...edge, start_vertex, end_vertex };
      })
      .filter(
        (edge): edge is IEdgeWithVertexes =>
          !!(edge.start_vertex && edge.end_vertex)
      );

    this.edgesMap = keyMapBy(this.edges, (edge) => edge.id);

    this.edgesGraph = new Map<number, Set<number>>();
    for (const edge of this.edges) {
      const set = this.edgesGraph.get(edge.start_vertex_id);
      if (set) {
        set.add(edge.id);
      } else {
        this.edgesGraph.set(edge.start_vertex_id, new Set([edge.id]));
      }
    }
  }

  getVertex(id: number) {
    return this.vertexMap.get(id);
  }

  getRoomVertices(roomId: number) {
    return this.vertices.filter((v) => v.room_id === roomId);
  }

  getEdgesIds(vertexId: number) {
    return this.edgesGraph.get(vertexId);
  }

  getEdge(edgeId: number) {
    return this.edgesMap.get(edgeId);
  }

  ensureEdge(edgeId: number | null | undefined) {
    return ensure(
      (edgeId && this.getEdge(edgeId)) || null,
      () => new EdgeNotFound(edgeId)
    );
  }

  ensureVertex(vertexId: number | null | undefined) {
    return ensure(
      (vertexId && this.getVertex(vertexId)) || null,
      () => new VertexNotFound(vertexId)
    );
  }
}
