import { ValueStore } from '@proscom/prostore';
import { Action } from '../../../../../utils/prostore/Action';
import { IVertexData } from '../NodesStore';
import { EditMode } from '../../types';
import { ModeConnectNodes } from '../ModeConnectNodes';

export class StartConnectNode implements Action<IVertexData> {
  constructor(
    private readonly editorMode: ValueStore<EditMode>,
    private readonly modeConnectNodes: ModeConnectNodes
  ) {}

  run = (node: IVertexData) => {
    this.editorMode.setState(EditMode.ADD_EDGE);
    this.modeConnectNodes.setFirstNode(node);
  };
}
