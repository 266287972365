import { useEffect } from 'react';
import type { DefaultValues, UseFormReset } from 'react-hook-form';

/**
 * Синхронизирует пропы и значения в форме
 * @param reset {UseFormReset} функция react-hook-form
 * @param values {DefaultValues} объект с полями
 * @param sync {boolean} синхронизация включена
 */
export function useSyncFormFields<FieldValues>(
  reset: UseFormReset<FieldValues>,
  values: DefaultValues<FieldValues>,
  sync = true
) {
  useEffect(() => {
    if (sync) reset(values);
  }, [reset, values, sync]);
}
