import { LocationStore } from '@proscom/prostore-react-router';
import { SelectedCampusFloors } from '../../../../../data/stores/SelectedCampusFloors';
import { Action } from '../../../../../utils/prostore/Action';

export class SelectFloor implements Action<number> {
  constructor(
    private readonly location: LocationStore,
    private readonly selectCampusFloors: SelectedCampusFloors
  ) {}

  public run = (floor: number) => {
    const floorsNumbers = this.selectCampusFloors.state;
    this.location.changeQuery({
      floor: floorsNumbers.includes(floor) ? floor : floorsNumbers[0]
    });
  };
}
