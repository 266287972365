export async function runAbortable<T>(
  signal: AbortSignal | undefined,
  callback: (state: { isActive: boolean }) => Promise<T>
) {
  const state: { isActive: boolean } = { isActive: true };
  const abortHandler = () => (state.isActive = false);
  signal?.addEventListener('abort', abortHandler);

  try {
    return await callback(state);
  } finally {
    signal?.removeEventListener('abort', abortHandler);
  }
}
