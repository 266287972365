import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ProstoreContext } from '@proscom/prostore-react';
import { useFactoryRef } from '@proscom/ui-react';
import {
  AxiosClientsContext,
  AxiosClientsManager
} from '@proscom/prostore-axios-react';
import { RouteComponentProps } from 'react-router';
import { ValueStore } from '@proscom/prostore';
import { CustomError } from '@proscom/ui-utils';
import { useClassEffect } from '../../utils/hooks/useClassEffect';
import { STORE_SHOW_HIDDEN_CAMPUSES } from '../../data/stores';
import { DiContext } from '../../utils/prostore/DiContext';
import { STORE_ACTIVE_EDITOR_MODE, STORE_ECHO } from './data/stores/stores';
import { Mapper } from './Mapper';
import { initializeMapperPageContext } from './data/stores/initializeMapperPageContext';

export interface MapperPageProps extends RouteComponentProps {
  loading?: boolean;
}

export class InvalidMapperContextError extends CustomError {
  public readonly name: string = 'InvalidMapperContextError';
}

export default function MapperPage(props: RouteComponentProps) {
  const [loading, setLoading] = useState(0);

  const axiosManager = useContext(AxiosClientsContext);
  const axiosClient = axiosManager?.getClient();

  const parentStores = useContext(ProstoreContext);

  if (!axiosManager || !axiosClient || !parentStores) {
    throw new InvalidMapperContextError();
  }

  const { effects, stores, axiosAuthClient, actions } = useFactoryRef(() => {
    return initializeMapperPageContext({
      setLoading,
      axiosClient,
      stores: parentStores
    });
  });

  useClassEffect(effects.logoutOnPusherFail);
  useClassEffect(stores[STORE_ACTIVE_EDITOR_MODE]);

  const prostoresExtended = useMemo(
    () => ({
      ...parentStores,
      ...stores
    }),
    [parentStores, stores]
  );

  const { [STORE_ECHO]: echoStore } = stores;
  useEffect(() => {
    echoStore.register();
    return () => {
      echoStore.destroy();
    };
  }, [echoStore]);

  const hiddenCampuses = parentStores[
    STORE_SHOW_HIDDEN_CAMPUSES
  ] as ValueStore<boolean>;
  useEffect(() => {
    hiddenCampuses.setState(true);
    return () => {
      hiddenCampuses.setState(false);
    };
  }, [hiddenCampuses]);

  const axiosClientsNew = useMemo(() => {
    return new AxiosClientsManager({
      ...axiosManager.clients,
      axiosAuthClient: axiosAuthClient
    });
  }, [axiosManager.clients, axiosAuthClient]);

  return (
    <ProstoreContext.Provider value={prostoresExtended}>
      <AxiosClientsContext.Provider value={axiosClientsNew}>
        <DiContext.Provider value={actions}>
          <Mapper loading={loading > 0} {...props} />
        </DiContext.Provider>
      </AxiosClientsContext.Provider>
    </ProstoreContext.Provider>
  );
}
