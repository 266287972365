import React, { useCallback, useState } from 'react';
import { useContextStore, useStoreState } from '@proscom/prostore-react';
import { Button, ButtonSize, ButtonVariant } from '@hse-design/react';
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import { MobileScreen } from '../../../../common/mobile/MobileScreen/MobileScreen';
import { useLocale } from '../../../../common/hooks/useLocale';
import { CampusesList } from '../../../../common/components/MapControls/CampusControl/CampusesList/CampusesList';
import {
  STORE_CAMPUS,
  STORE_CAMPUSES,
  STORE_LATEST_CAMPUS_STORE
} from '../../../../data/stores';
import { CampusesStoreState } from '../../../../data/stores/CampusesStore';
import { CampusDto } from '../../../../data/api/CampusDto';
import { AppHistory } from '../../../../appHistory';
import { LatestCampusStore } from '../../../../data/stores/LatestCampusStore';
import { QUERY_KEY_CAMPUS } from '../../../../data/core/queryKeys';

export interface SelectCampusPageProps {
  history: AppHistory;
}

export function SelectCampusPage({ history }: SelectCampusPageProps) {
  const locale = useLocale();
  const latestCampus = useContextStore<LatestCampusStore>(
    STORE_LATEST_CAMPUS_STORE
  );
  const campusesStoreState = useStoreState<CampusesStoreState>(STORE_CAMPUSES);
  const campuses = campusesStoreState.data && campusesStoreState.data.data;
  const selectedCampus = useStoreState<CampusDto>(STORE_CAMPUS);
  const [newCampusCode, setNewCampusCode] = useState<string>(
    selectedCampus ? selectedCampus.code : ''
  );

  const handleCampusSelect = useCallback(
    (campusCode) => setNewCampusCode(campusCode),
    [setNewCampusCode]
  );

  const handleBack = () => history.goBack();

  const handleConfirm = useCallback(() => {
    const search = history.getLocation().search;
    const searchParams = parseQuery(search);
    searchParams[QUERY_KEY_CAMPUS] = newCampusCode;
    const newUrl = '/?' + stringifyQuery(searchParams);
    history.goBack();
    setTimeout(() => {
      history.push(newUrl);
    }, 0);
    latestCampus.setValue(newCampusCode);
  }, [history, latestCampus, newCampusCode]);

  if (!campuses || !campuses.length || !selectedCampus) return null;

  return (
    <MobileScreen
      title={locale.t('map_controls.campus')}
      onBackClick={handleBack}
      actions={
        <Button
          variant={ButtonVariant.primary}
          size={ButtonSize.large}
          onClick={handleConfirm}
        >
          {locale.t('button.confirm')}
        </Button>
      }
    >
      <CampusesList
        onCampusSelect={handleCampusSelect}
        selectedCampusCode={newCampusCode}
        campuses={campuses}
      />
    </MobileScreen>
  );
}
