import axios, { AxiosInstance } from 'axios';
import { BehaviorStore, SubscriptionManager } from '@proscom/prostore';
import { isEqual } from 'lodash';
import { EffectHolder } from '../../utils/prostore/EffectHolder';
import { FunctionEffect } from '../../utils/prostore/FunctionEffect';
import {
  IExtendedRequestState,
  initialExtendedRequestState
} from '../../utils/prostore/ExtendedRequestStore';
import { IStoreType } from '../../utils/types';
import { RoomDto } from '../api/RoomDto';
import { RoomSearchStore } from '../stores/RoomSearchStore';
import { PaginatedResponse } from '../api/PaginatedResponse';
import {
  RoomSearchVars,
  RoomSearchVarsStore
} from '../stores/RoomSearchVarsStore';

export type RoomSearchData = RoomDto[];

export type ApiFrontRoomsResponse = PaginatedResponse<RoomDto[]>;

export interface RoomSearchResultsState
  extends IExtendedRequestState<RoomSearchVars, RoomSearchData> {
  maxPage: number | null;
}

export interface IRoomSearchResults extends IStoreType<RoomSearchResults> {
  requestNextPage: () => void;
}

export class RoomSearchResults extends BehaviorStore<RoomSearchResultsState> {
  readonly sub = new SubscriptionManager();
  readonly loader = new EffectHolder();

  constructor(
    private readonly roomSearchVars: RoomSearchVarsStore,
    private readonly roomSearch: RoomSearchStore,
    private readonly client: AxiosInstance
  ) {
    super({
      ...initialExtendedRequestState
    });
  }

  onSubscribe() {
    super.onSubscribe();

    this.sub.subscribe(this.roomSearchVars.state$, this.handleVarsChange);
  }

  onUnsubscribe() {
    super.onUnsubscribe();
    this.sub.destroy();
  }

  protected handleVarsChange = (vars: RoomSearchVars | null) => {
    if (!vars) {
      this.setState({
        ...initialExtendedRequestState
      });
      return;
    }

    const { page, ...newVars } = vars;
    const { page: oldPage, ...oldVars } = this.state.loadedVariables || {};

    if (isEqual(newVars, oldVars)) {
      if (oldPage === undefined) {
        this.loadPages(vars, 0);
      } else if (page > oldPage) {
        this.loadPages(vars, oldPage);
      }
    } else {
      this.reload(vars);
    }
  };

  protected loadPages(vars: RoomSearchVars, startPage: number = 0) {
    this.loader.setState(
      new FunctionEffect(() => {
        let active = true;
        let cancelSource = axios.CancelToken.source();

        this.setState({
          variables: vars,
          loading: true,
          error: null
        });

        const promises: Promise<ApiFrontRoomsResponse>[] = [];

        for (let page = startPage + 1; page <= vars.page; page++) {
          promises.push(
            this.client
              .request<ApiFrontRoomsResponse>({
                url: '/api/front/rooms',
                method: 'get',
                params: {
                  ...vars,
                  page
                },
                cancelToken: cancelSource.token
              })
              .then((result) => result.data)
          );
        }

        (async () => {
          try {
            const results = await Promise.all(promises);
            if (!active) return;
            const newRooms = results.flatMap((result) => result.data);
            const newData = [...(this.state.data || []), ...newRooms];
            const maxPage = results[results.length - 1].meta.last_page;
            this.setState({
              loadedVariables: vars,
              loading: false,
              loaded: true,
              error: null,
              data: newData,
              maxPage
            });
          } catch (error) {
            console.error(error);
            if (active) {
              this.setState({
                loadedVariables: vars,
                loading: false,
                loaded: true,
                error
              });
            }
          } finally {
            if (active) {
              this.loader.setState(undefined);
            }
          }
        })().catch(() => {});

        return () => {
          active = false;
          cancelSource.cancel();
        };
      })
    );
  }

  protected reload = (vars: RoomSearchVars | null = this.state.variables) => {
    this.setState({
      data: null,
      error: null,
      loadedVariables: null,
      maxPage: null
    });

    if (vars) {
      this.loadPages(vars);
    }
  };

  public requestNextPage = () => {
    if (this.state.loading || this.state.error) return;
    const { page } = this.roomSearch.state;
    if (this.state.maxPage && page >= this.state.maxPage) return;
    this.roomSearch.setPage(this.roomSearch.state.page + 1);
  };
}
