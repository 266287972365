import React, { useCallback } from 'react';
import {
  CircleMarker as LeafletCircleMarker,
  CircleMarkerProps as LeafletCircleMarkerProps
} from 'react-leaflet';
import { brandColor, lightBrandColor } from '../../data/theme';

export interface CircleMarkerProps extends LeafletCircleMarkerProps {
  leafletRef?: React.ComponentProps<typeof LeafletCircleMarker>['ref'];
  draggable?: boolean;
  selected?: boolean;
  className?: string;
}

/**
 * CircleMarker рендерится на канвасе и существенно увеличивает производительность карты
 * Если не требуется добавлять содержимое в маркер – используйте его
 */
export const CircleMarker = React.memo(function JsxMarker({
  className,
  selected,
  leafletRef,
  ...props
}: CircleMarkerProps) {
  const color = selected ? brandColor : lightBrandColor;

  return (
    <LeafletCircleMarker
      fill={true}
      opacity={1}
      fillOpacity={1}
      stroke={false}
      ref={leafletRef}
      color={color}
      pathOptions={{ color }}
      radius={10}
      {...props}
    />
  );
});

export function useCircleMarkerToFront() {
  const onLayerAdd = useCallback((e) => {
    // Нам нужно, чтобы circle маркеры были выше всего. У CircleMarker нет возможности поставить zIndex, поэтому костыляем
    // По наличию радиуса мы определяем, что добавлен именно CircleMarker
    const { layer } = e;
    if (layer._radius) {
      layer.bringToFront();
    } else {
      layer.bringToBack?.();
    }
  }, []);
  return { onLayerAdd };
}
