import { IStoreState, SubscriptionManager } from '@proscom/prostore';
import { AsyncOperationStore } from '../../../../utils/prostore/AsyncOperationStore';
import { UpdateNodeFormFields } from '../../components/forms/UpdateNode';
import { ToastStore } from '../../../../data/core/ToastStore';
import { tryOperation } from '../../utils/tryOperation';
import { IVertexData, NodesStore } from './NodesStore';
import { SelectedNodeQrs } from './SelectedNodeQrs';
import { MapperQrStore } from './MapperQrStore';

export class NodeUpdateOperation extends AsyncOperationStore<
  [Partial<IVertexData> & UpdateNodeFormFields],
  void
> {
  sub = new SubscriptionManager();

  selectedNodeQrs: IStoreState<SelectedNodeQrs> = null;

  constructor(
    private deps: {
      toast: ToastStore;
      nodes: NodesStore;
      selectedNodeQrs: SelectedNodeQrs;
      mapperQr: MapperQrStore;
    }
  ) {
    super((data) => this.perform(data));
  }

  onSubscribe() {
    super.onSubscribe();
    this.sub.subscribe(
      this.deps.selectedNodeQrs.state$,
      (value) => (this.selectedNodeQrs = value)
    );
  }

  onUnsubscribe() {
    super.onUnsubscribe();
    this.sub.destroy();
  }

  async perform(data: Partial<IVertexData> & UpdateNodeFormFields) {
    const { toast, mapperQr } = this.deps;
    await tryOperation(
      async () => {
        const { qr_codes, ...node } = data;
        const updateDataPromises = [this.deps.nodes.mutations.update(node)];
        if (qr_codes) {
          qr_codes.forEach((qr) => {
            const qrFromState = this.selectedNodeQrs?.find(
              (q) => q.id === +qr.id || q.qr === qr.value
            );
            const isChanged = qrFromState && qrFromState.qr !== qr.value;
            if (!qrFromState) {
              updateDataPromises.push(
                mapperQr.mutations.add({
                  vertex_id: node.id,
                  qr: qr.value
                })
              );
            } else if (isChanged && node.id) {
              updateDataPromises.push(
                mapperQr.mutations.update({
                  id: qr.id,
                  vertex_id: node.id,
                  qr: qr.value
                })
              );
            }
          });
          this.selectedNodeQrs?.forEach((qr) => {
            if (!qr_codes.find((q) => +q.id === qr.id)) {
              updateDataPromises.push(mapperQr.mutations.delete(qr.id));
            }
          });
        }
        await Promise.all(updateDataPromises);
      },
      {
        name: this.constructor.name,
        toast: this.deps.toast,
        successMsg: 'Вершина обновлена',
        errorMsg: 'Ошибка при обновлении вершины'
      }
    );
  }
}
