import { initialRequestState, ObservableStore } from '@proscom/prostore';
import { AxiosInstance } from 'axios';
import { switchMap } from 'rxjs/operators';
import { combineLatest, concat, of } from 'rxjs';
import { CampusesStore } from '../../stores/CampusesStore';
import { tryCacheFirst } from '../../../utils/tryCacheFirst';
import { createObservableFromPromiseDefer } from '../../../utils/prostore/createObservableFromPromiseDefer';
import { CampusDto } from '../../api/CampusDto';
import { IApiOfflineCampusResponse } from '../api/ApiOfflineCampusResponse';
import { ModeStoreModeEnum } from '../../core/ModeContext';
import { OfflineModeEnabled } from './OfflineModeEnabled';

export interface DataStoreOfflineArgs {
  campusesStore: CampusesStore;
  offlineModeEnabled: OfflineModeEnabled;
  client: AxiosInstance;
}

export interface DataStoreOfflineState {
  data: IApiOfflineCampusResponse[] | null;
  loading: boolean;
  loaded: boolean;
  error: any;
}

/**
 * Стор хранит необходимые данные для работы приложения,
 * сигнатура хранимых полей - {@link DataStoreOfflineState}
 */
export class DataStoreOffline extends ObservableStore<DataStoreOfflineState> {
  constructor(params: DataStoreOfflineArgs) {
    super(
      combineLatest([
        params.campusesStore.state$,
        params.offlineModeEnabled.state$
      ]).pipe(
        switchMap(([campuses, enabled]) => {
          const campusesData = campuses.data;
          if (!campusesData || !enabled.loaded) {
            return of({ ...initialRequestState, loading: true });
          }

          if (enabled.value !== ModeStoreModeEnum.offline) {
            return of({ ...initialRequestState, loaded: true });
          }

          const campuses$ = createObservableFromPromiseDefer((abort) => {
            const campusesPromises: Promise<IApiOfflineCampusResponse>[] = campusesData.data.map(
              (campus) => this.loadCampus(campus, abort)
            );

            return Promise.all(campusesPromises)
              .then((data) => ({ data }))
              .catch((error) => ({ error }))
              .then((data) => ({
                ...initialRequestState,
                loaded: true,
                ...data
              }));
          });

          return concat(
            of({ ...initialRequestState, loading: true }),
            campuses$
          );
        })
      ),
      { ...initialRequestState }
    );
  }

  private async loadCampus(campus: CampusDto, abort: AbortController) {
    const url = `/api/offline/campus/${campus.id}/json?force_update=1`;
    return tryCacheFirst(
      `campuses.${campus.id}`,
      async () => {
        const response = await fetch(url, {
          signal: abort.signal
        });
        const campus = (await response.json()) as IApiOfflineCampusResponse;
        console.log('[DataStoreOffline] saving cache', campus);
        return campus;
      },
      {
        signal: abort.signal,
        shouldUseCache: async (cached) => {
          const cachedDate = cached.campus.offline_last_update;
          const newDate = campus.offline_last_update;
          console.log('[DataStoreOffline] check dates', cachedDate, newDate);
          return cachedDate === newDate;
        }
      }
    );
  }
}
