export interface LocalizedField {
  ru: string;
  en: string;
}

export function getFieldLocale(
  prop: LocalizedField | undefined,
  language: string | undefined
): string {
  return (language && prop?.[language]) || prop?.en || '';
}
