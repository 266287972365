import { SubscriptionManager } from '@proscom/prostore';
import { EchoStore, PusherState } from '../EchoStore';
import { AuthStore } from '../AuthStore';
import { Effect } from '../../../../../utils/prostore/Effect';

export class LogoutOnPusherFail implements Effect {
  sub = new SubscriptionManager();

  constructor(
    private deps: {
      echo: EchoStore;
      auth: AuthStore;
    }
  ) {}

  on() {
    this.sub.subscribe(this.deps.echo.state$, (echoState) => {
      if (
        echoState.pusherState === PusherState.Unavailable ||
        echoState.pusherState === PusherState.Disconnected ||
        echoState.pusherState === PusherState.Failed
      ) {
        this.deps.auth.logout();
      }
    });
  }

  off() {
    this.sub.destroy();
  }
}
