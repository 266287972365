import React from 'react';
import { BaseCheckboxIcon, Icon } from '@hse-design/react';
import s from './LangSwitch.module.scss';

export interface LangSwitchProps {
  id?: string;
  icon?: React.ElementType;
  text?: React.ReactNode;
  isActive?: boolean;
  onClick?: (id: string) => void;
}

export function LangSwitch({
  id,
  icon,
  text,
  isActive,
  onClick
}: LangSwitchProps) {
  return (
    <div
      className={s.LangSwitch}
      onClick={() => (onClick ? onClick(id as string) : null)}
    >
      <Icon icon={icon} />
      <p className={s.LangSwitch__text}>{text}</p>
      <BaseCheckboxIcon
        className={s.LangSwitch__radio}
        checked={isActive}
        radio={true}
      />
    </div>
  );
}
