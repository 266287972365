import { useContextStore, useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import React, { useCallback } from 'react';
import { Button, ButtonVariant } from '@hse-design/react';
import { STORE_ACTIVE_PANEL, STORE_LOCATION } from '../../../../data/stores';
import {
  QUERY_KEY_CODE,
  QUERY_KEY_END_SEARCH,
  QUERY_KEY_END_VERTEX,
  QUERY_KEY_MY_LOCATION,
  QUERY_KEY_ROOM,
  QUERY_KEY_START_ROOM,
  QUERY_KEY_START_SEARCH,
  QUERY_KEY_START_VERTEX,
  TransformedQueryParams
} from '../../../../data/core/queryKeys';
import { useLocale } from '../../../../common/hooks/useLocale';
import { ReactComponent as IconFromToPin } from '../../../../assets/img/icons/i-pin-from-to.svg';
import { ReactComponent as IconRemovePin } from '../../../../assets/img/icons/i-remove-pin.svg';
import { ActivePanel } from '../../../../data/core/ActivePanel';
import { ActionsList } from '../../../../common/components/ActionsList/ActionsList';
import { IVertex } from '../../../../data/Vertex';

function getSearchLocationQuery(keys: {
  [key: string]: string | number | undefined;
}) {
  return {
    [QUERY_KEY_ROOM]: undefined,
    [QUERY_KEY_CODE]: undefined,
    [QUERY_KEY_MY_LOCATION]: undefined,
    ...keys
  };
}

export interface MyLocationActionsProps {
  location: IVertex;
  onRemove: () => void;
}

export function MyLocationActions({
  location,
  onRemove
}: MyLocationActionsProps) {
  const [locationStoreState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const activePanelStore = useContextStore<ActivePanel>(STORE_ACTIVE_PANEL);

  const query = locationStoreState.query as TransformedQueryParams;
  const startRoom = query[QUERY_KEY_START_ROOM];
  const startVertex = query[QUERY_KEY_START_VERTEX];
  const locale = useLocale();

  const onRouteStartSelect = useCallback(() => {
    activePanelStore.setPanel(ActivePanel.PANEL_ROUTE);
    locationStore.changeQuery(
      getSearchLocationQuery({
        [QUERY_KEY_START_VERTEX]: location.id,
        [QUERY_KEY_START_SEARCH]: undefined
      })
    );
  }, [locationStore, location.id, activePanelStore]);
  const onRouteEndSelect = useCallback(() => {
    activePanelStore.setPanel(ActivePanel.PANEL_ROUTE);
    locationStore.changeQuery(
      getSearchLocationQuery({
        [QUERY_KEY_END_VERTEX]: location.id,
        [QUERY_KEY_END_SEARCH]: undefined
      })
    );
  }, [locationStore, location.id, activePanelStore]);

  const isFromSelected = Boolean(startRoom || startVertex);

  return (
    <ActionsList>
      <Button
        leftIcon={IconFromToPin}
        size={Button.Size.large}
        onClick={isFromSelected ? onRouteEndSelect : onRouteStartSelect}
      >
        {isFromSelected
          ? locale.t('location.toHere')
          : locale.t('location.fromHere')}
      </Button>
      <Button
        leftIcon={IconRemovePin}
        size={Button.Size.large}
        onClick={onRemove}
        variant={ButtonVariant.secondary}
      >
        {locale.t('currentLocation.remove')}
      </Button>
    </ActionsList>
  );
}
