import React, {
  Ref,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react';
import { Polygon as LeafletPolygon } from 'leaflet';
import { Polygon, Tooltip } from 'react-leaflet';
import composeRefs from '@seznam/compose-react-refs';
import {
  defaultRoomColor,
  flipBoundsToLeaflet,
  selectedRoomColor,
  tooltipOffset
} from './utils';
import { AuthRoomDto } from './data/stores/RoomsStore';

export interface MapRoomProps {
  room: AuthRoomDto;
  nodeRef?: Ref<LeafletPolygon<any>>;
  onRoomSelect: (room: AuthRoomDto) => void;
  isRoomSelected: (room: AuthRoomDto) => boolean;
}

export function MapRoom({
  room,
  nodeRef,
  onRoomSelect,
  isRoomSelected
}: MapRoomProps) {
  const isSelected = useMemo(() => isRoomSelected(room), [
    isRoomSelected,
    room
  ]);
  const color = isSelected ? selectedRoomColor : defaultRoomColor;

  const onClick = useCallback(() => {
    onRoomSelect(room);
  }, [onRoomSelect, room]);

  const positions = useMemo(() => {
    return room.bounds?.map((poly) => poly.map(flipBoundsToLeaflet)) || [];
  }, [room]);

  const eventHandlers = useMemo(
    () => ({
      click: onClick
    }),
    [onClick]
  );

  const polygonRef = useRef<LeafletPolygon | null>(null);
  useLayoutEffect(() => {
    polygonRef.current?.bringToBack();
  });

  const roomName = room.name?.ru?.replace(/&nbsp;/g, ' ');

  return (
    <Polygon
      ref={composeRefs(nodeRef, polygonRef)}
      pathOptions={{ color }}
      positions={positions}
      eventHandlers={eventHandlers}
    >
      <Tooltip direction="top" offset={tooltipOffset} opacity={1}>
        <div>Комната {roomName}</div>
      </Tooltip>
    </Polygon>
  );
}
