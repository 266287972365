import { BehaviorStore } from '@proscom/prostore';
import {
  AsyncSingletonError,
  UseAsyncOperationOptions,
  UseAsyncOperationState
} from '@proscom/prostore-react';
import { SingleTimeoutManager } from '@proscom/ui-utils';

export interface AsyncOperationState {
  loading: number;
  finished: boolean;
}

const changeLoading = (change: number) => (state: UseAsyncOperationState) => ({
  ...state,
  loading: state.loading + change
});

const changeFinished = (finished: boolean) => (
  state: UseAsyncOperationState
) => ({
  ...state,
  finished
});

export class AsyncOperationStore<
  Args extends any[] = any[],
  Result = any
> extends BehaviorStore<AsyncOperationState> {
  timeout = new SingleTimeoutManager();

  constructor(
    private callback: (...args: Args) => Promise<Result>,
    private options: UseAsyncOperationOptions = {}
  ) {
    super({
      loading: 0,
      finished: false
    });
  }

  setCallback(callback: (...args: Args) => Promise<Result>) {
    this.callback = callback;
  }

  run = async (...args: Args): Promise<Result> => {
    const { loading } = this.state;
    const { options } = this;

    if (options.singleton && loading > 0) {
      throw new AsyncSingletonError(
        'AsyncOperationStore.run called for the singleton operation ' +
          'while previous instance is still running. ' +
          'Usually you should catch and swallow this error.'
      );
    }

    this.setState(changeLoading(1));

    try {
      const result = await this.callback(...args);

      if (
        options.finishedTimeout !== undefined &&
        options.finishedTimeout > 0
      ) {
        this.setState(changeFinished(true));
        if (options.finishedTimeout !== Infinity) {
          this.timeout.set(() => {
            this.setState(changeFinished(false));
          }, options.finishedTimeout);
        }
      }

      return result;
    } finally {
      this.setState(changeLoading(-1));
    }
  };

  setFinished = (finished: boolean) => {
    this.setState(changeFinished(finished));
  };
}
