import { useContextStore, useStore } from '@proscom/prostore-react';
import React, { useCallback, useEffect, useRef } from 'react';
import { LocationStore } from '@proscom/prostore-react-router';
import { BottomSheetNew } from '../../../../common/mobile/BottomSheetNew/BottomSheetNew';
import { ActivePanel } from '../../../../data/core/ActivePanel';
import { STORE_ACTIVE_PANEL, STORE_LOCATION } from '../../../../data/stores';
import { Loader } from '../../../../common/ui/Loader/Loader';
import { QUERY_KEY_ROOM } from '../../../../data/core/queryKeys';
import { useLocationQuery } from '../../../../common/hooks/useLocationQuery';
import { RoomDto } from '../../../../data/api/RoomDto';
import { BottomSheetContent } from '../../../../common/mobile/BottomSheetContent/BottomSheetContent';
import { RoomPanelContent } from '../../../../common/components/RoomPanelContent/RoomPanelContent';
import s from './RoomPanel.module.scss';

export interface RoomPanelProps {
  selectInMap?: 'A' | 'B';
  room?: RoomDto;
  isLoading?: boolean;
}

export function RoomPanel({ selectInMap, room, isLoading }: RoomPanelProps) {
  const [query] = useLocationQuery([QUERY_KEY_ROOM] as const);
  const queryRoom = query[QUERY_KEY_ROOM];
  const locationStore = useContextStore<LocationStore>(STORE_LOCATION);
  const [activePanel, activePanelStore] = useStore<ActivePanel>(
    STORE_ACTIVE_PANEL
  );

  useEffect(() => {
    if (queryRoom && !selectInMap) {
      if (!activePanel) {
        activePanelStore.setPanel(ActivePanel.PANEL_ROOM, true);
      }
    } else if (activePanel === ActivePanel.PANEL_ROOM) {
      activePanelStore.closePanel(true);
    }
  }, [activePanel, activePanelStore, queryRoom, selectInMap]);

  const handleDismiss = useCallback(() => {
    locationStore.changeQuery({
      [QUERY_KEY_ROOM]: undefined
    });
    activePanelStore.closePanel();
  }, [activePanelStore, locationStore]);

  const headRef = useRef<HTMLDivElement | null>(null);

  return (
    <BottomSheetNew
      open={activePanel === ActivePanel.PANEL_ROOM}
      onDismiss={handleDismiss}
      blocking={false}
      snapPoints={({ headerHeight, minHeight }) => {
        return [headerHeight + (headRef.current?.clientHeight ?? 0), minHeight];
      }}
      headerTitle={
        !isLoading &&
        !!room && <span className={s.RoomPanel__title}>{room.name}</span>
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        room && (
          <BottomSheetContent className={s.RoomPanel__content}>
            <RoomPanelContent room={room} headRef={headRef} />
          </BottomSheetContent>
        )
      )}
    </BottomSheetNew>
  );
}
