import { Button, ButtonSize, ButtonVariant, Icon } from '@hse-design/react';
import React from 'react';
import s from './RouteSearchAction.module.scss';

export interface RouteSearchActionProps {
  icon?: React.ComponentType<any>;
  text?: string;
  onClick?: () => void;
}

export function RouteSearchAction({
  icon,
  text,
  onClick
}: RouteSearchActionProps) {
  return (
    <Button
      className={s.RouteSearchAction}
      variant={ButtonVariant.tertiary}
      size={ButtonSize.small}
      onClick={onClick}
    >
      {icon && <Icon className={s.RouteSearchAction__icon} icon={icon} />}
      <span className={s.RouteSearchAction__text}>{text}</span>
    </Button>
  );
}
