import {
  EDGE_TYPE_ELEVATOR,
  EDGE_TYPE_STAIRS,
  EDGE_TYPE_TRANSFER
} from '../routes/mapper/types';
import { getBbox } from '../utils/geometry/getBbox';
import { Point2D, TuplePoint2D } from '../utils/geometry/types';
import { PseudoRoom } from '../routes/index/utils/convertRoute';
import { CampusDto } from './api/CampusDto';
import { RoomDto } from './api/RoomDto';
import { RouteStepType } from './RouteStepType';
import { SegmentType } from './SegmentType';
import { IRouteEdge } from './RouteEdge';

export enum RouteIconName {
  start = 'start',
  end = 'end',
  elevator = 'elevator',
  stairs = 'stairs',
  entrance = 'entrance'
}

export interface RouteIcon extends Point2D {
  iconName: RouteIconName;
}

export interface RouteStepTransferData {
  startCampus: CampusDto;
  endCampus: CampusDto;
  yaMapLink: string;
}

export interface RouteStepArgs {
  route: IRouteEdge[];
  campuses?: CampusDto[] | null;
  title: string;
  // @todo wat?
  type: RouteStepType | SegmentType;
  index: number;
  startIndex?: number | null;
  endIndex?: number | null;
  startFloor?: number | null;
  endFloor?: number | null;
  // @todo wat?
  room?: RoomDto | PseudoRoom | null;
  yaMapLink?: string | null;
}

export class RouteStep {
  index: number;
  type: RouteStepType | null = null;
  startIndex: number | null;
  endIndex: number | null;
  title: string;
  vertexes: Point2D[] = [];
  icons: RouteIcon[] = [];
  center: TuplePoint2D | null = null;
  campusId: number | null = null;
  floorNumber: number | null = null;
  room: RoomDto | PseudoRoom | null = null;
  transferData: RouteStepTransferData | null = null;

  constructor({
    route,
    campuses = null,
    title,
    type,
    index,
    startIndex = null,
    endIndex = null,
    startFloor = null,
    endFloor = null,
    room = null,
    yaMapLink = null
  }: RouteStepArgs) {
    this.index = index;
    this.startIndex = startIndex;
    this.endIndex = endIndex;
    this.title = title;

    if (
      room &&
      (type === RouteStepType.TYPE_START || type === RouteStepType.TYPE_END)
    ) {
      this.type = type;
      const isRouteStart = type === RouteStepType.TYPE_START;
      const vertexIndex = isRouteStart ? 0 : route.length - 1;
      this.room = route[vertexIndex].vertex.room || null;
      this.campusId = route[vertexIndex].vertex.campus_id;
      this.floorNumber = route[vertexIndex].vertex.floor_number;
      this.icons.push({
        x: route[vertexIndex].vertex.x,
        y: route[vertexIndex].vertex.y,
        iconName: RouteIconName[isRouteStart ? 'start' : 'end']
      });
    } else if (
      type === SegmentType.corridor &&
      startIndex !== null &&
      endIndex !== null
    ) {
      this.type = RouteStepType.TYPE_FORWARD;
      if (this.vertexes.length === 1) {
        this.campusId = route[startIndex].vertex.campus_id;
      } else if (
        route[startIndex].vertex.campus_id === route[endIndex].vertex.campus_id
      ) {
        this.campusId = route[startIndex].vertex.campus_id;
      } else this.campusId = null;
      this.floorNumber = route[startIndex].vertex.floor_number;

      if (
        route[startIndex - 1] &&
        route[startIndex - 1].vertex.type === 'entrance' &&
        route[startIndex - 1].vertex.campus_id ===
          route[startIndex].vertex.campus_id
      ) {
        this.vertexes.push({
          x: route[startIndex - 1].vertex.x,
          y: route[startIndex - 1].vertex.y
        });
        this.icons.push({
          x: route[startIndex - 1].vertex.x,
          y: route[startIndex - 1].vertex.y,
          iconName: RouteIconName[route[startIndex - 1].vertex.type]
        });
      }

      for (let i = startIndex; i <= endIndex; i++) {
        this.vertexes.push({
          x: route[i].vertex.x,
          y: route[i].vertex.y
        });

        if (
          route[i].vertex.type === 'elevator' ||
          route[i].vertex.type === 'stairs' ||
          route[i].vertex.type === 'entrance'
        ) {
          this.icons.push({
            x: route[i].vertex.x,
            y: route[i].vertex.y,
            iconName: RouteIconName[route[i].vertex.type]
          });
        }
      }

      /**
       * Добавляем в массив вершин каждого участка первую вершину
       * следующего, чтобы соединить линией последнюю вершину предыдущего
       * с первой вершиной следующего
       */
      if (
        route[endIndex + 1] &&
        route[endIndex].vertex.campus_id ===
          route[endIndex + 1].vertex.campus_id
      ) {
        this.vertexes.push({
          x: route[endIndex + 1].vertex.x,
          y: route[endIndex + 1].vertex.y
        });
      }
    } else if (
      (type === EDGE_TYPE_STAIRS || type === EDGE_TYPE_ELEVATOR) &&
      (startFloor || startFloor === 0) &&
      (endFloor || endFloor === 0) &&
      startIndex !== null &&
      endIndex !== null
    ) {
      this.campusId = route[startIndex].vertex.campus_id;
      this.floorNumber = route[startIndex].vertex.floor_number;
      this.icons.push({
        x: route[startIndex].vertex.x,
        y: route[startIndex].vertex.y,
        iconName: RouteIconName[route[startIndex].vertex.type]
      });

      if (type === EDGE_TYPE_STAIRS) {
        this.type =
          startFloor > endFloor
            ? RouteStepType.TYPE_STAIRS_DOWN
            : RouteStepType.TYPE_STAIRS_UP;
      } else {
        this.type =
          startFloor > endFloor
            ? RouteStepType.TYPE_ELEVATOR_DOWN
            : RouteStepType.TYPE_ELEVATOR_UP;
      }
    } else if (
      type === EDGE_TYPE_TRANSFER &&
      startIndex !== null &&
      endIndex !== null
    ) {
      this.type = RouteStepType.TYPE_TRANSFER;

      if (campuses && campuses.length && yaMapLink) {
        const prevStepCampusId =
          route[startIndex - 1] && route[startIndex - 1].vertex.campus_id;
        const nextStepCampusId =
          route[endIndex + 1] && route[endIndex + 1].vertex.campus_id;
        const startCampus = campuses.find((c) => c.id === prevStepCampusId);
        const endCampus = campuses.find((c) => c.id === nextStepCampusId);
        if (startCampus && endCampus) {
          this.transferData = { startCampus, endCampus, yaMapLink };
        }
      }
    }

    if (this.vertexes.length || this.icons.length) {
      const bounds = [
        this.vertexes
          .map((vertex) => [vertex.x, vertex.y] as const)
          .concat(this.icons.map((icon) => [icon.x, icon.y] as const))
      ];
      const bbox = getBbox(bounds);
      this.center = [(bbox.minX + bbox.maxX) / 2, (bbox.minY + bbox.maxY) / 2];
    }
  }

  getIconState = (selectedStepI) =>
    this.index === selectedStepI ? 'superactive' : 'selected';
}
