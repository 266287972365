import 'symbol-observable';

// Полифилл для :focus-visible
import 'focus-visible';

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-spring-bottom-sheet/dist/style.css';
import 'reset-css';
import '@hse-design/core/lib/fonts.css';
import '@hse-design/core/lib/index.css';
import '@hse-design/core/lib/react/index.css';
import './assets/fonts/fonts.scss';
import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Router } from 'react-router-dom';
import { registerServiceWorker } from './registerServiceWorker';
import { AppContext } from './AppContext';
import { appHistory } from './appHistory';
import { offlineModeAvailable } from './indexStores';
import { offlineMock } from './config';

window.isApril = [
  process.env.REACT_APP_APRIL_URL_TEST,
  process.env.REACT_APP_APRIL_URL_PROD
].includes(window.location.hostname);

const appElement = document.getElementById('root');

Modal.setAppElement(appElement);

ReactDOM.render(
  <Router history={appHistory}>
    <AppContext />
  </Router>,
  appElement
);

registerServiceWorker()
  .then((registration) => {
    if (!registration) {
      offlineModeAvailable.setValue(offlineMock);
    } else {
      offlineModeAvailable.setValue(true);
    }
  })
  .catch(() => {
    offlineModeAvailable.setValue(false);
  });
