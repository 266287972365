import { BehaviorStore, SubscriptionManager } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import {
  QUERY_KEY_BUILDING,
  QUERY_KEY_CAMPUS,
  QUERY_KEY_FLOOR,
  QUERY_KEY_MY_LOCATION
} from '../core/queryKeys';
import { CanvasManipulationStore } from '../core/CanvasManipulationStore';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import { IVertex } from '../Vertex';
import { LatestCampusStore } from './LatestCampusStore';

export interface CurrentLocationState {
  isSelected: boolean;
  location?: IVertex;
}

const initialState = { isSelected: false, showPin: true };

export class CurrentLocationStore extends BehaviorStore<CurrentLocationState> {
  locationStore: LocationStore;
  canvasManipulationStore: CanvasManipulationStore;
  latestCampus: LatestCampusStore;
  sub = new SubscriptionManager();

  constructor({
    locationStore,
    canvasManipulationStore,
    latestCampus
  }: {
    locationStore: LocationStore;
    canvasManipulationStore: CanvasManipulationStore;
    latestCampus: LatestCampusStore;
  }) {
    super(initialState);
    this.locationStore = locationStore;
    this.canvasManipulationStore = canvasManipulationStore;
    this.latestCampus = latestCampus;
  }

  onSubscribe() {
    super.onSubscribe();
    this.subscribeToMyLocationQueryChange();
  }

  onUnsubscribe() {
    super.onUnsubscribe();
    this.sub.destroy();
  }

  subscribeToMyLocationQueryChange() {
    this.sub.subscribe(
      locationStoreGet$(this.locationStore, QUERY_KEY_MY_LOCATION),
      this.handleLocationChange
    );
  }

  destroy() {
    this.sub.destroy();
  }

  goToPin = () => this.canvasManipulationStore.goToPin();

  selectPin = () => {
    this.locationStore.changeQuery({
      [QUERY_KEY_MY_LOCATION]: true
    });
    this.goToPin();
  };

  setLocation(location?: IVertex) {
    if (location === undefined) {
      this.resetSelection();
    }
    this.setState({
      location
    });
  }

  resetSelection = () => {
    this.locationStore.changeQuery({
      [QUERY_KEY_MY_LOCATION]: undefined
    });
  };

  handleLocationChange = (query: { [QUERY_KEY_MY_LOCATION]?: boolean }) => {
    console.log('CurrentLocationStore handleLocationChange', query);
    if (query[QUERY_KEY_MY_LOCATION]) {
      const location = this.state.location;
      if (location) {
        console.log('CurrentLocationStore handleLocationChange', location);
        this.setState({
          isSelected: true
        });

        const campusCode = location.campus?.code;
        this.locationStore.changeQuery({
          [QUERY_KEY_FLOOR]: location.floor_number,
          [QUERY_KEY_CAMPUS]: campusCode,
          [QUERY_KEY_BUILDING]: location.building_id
        });
        if (campusCode) {
          this.latestCampus.setValue(campusCode);
        }

        this.goToPin();
      } else {
        this.resetSelection();
      }
    } else {
      this.setState({
        isSelected: false
      });
    }
  };
}
