import { AxiosQueryStore } from '@proscom/prostore-axios';
import { GetRequestStoreState, skipIfNull } from '@proscom/prostore';
import { map } from 'rxjs/operators';
import { combineLatest, Subscribable } from 'rxjs';
import { GetQueryVars, LocaleQueryParams } from '../api/common';
import { defaultClient } from '../../Client';
import { CampusDto } from '../api/CampusDto';
import { LocaleStore } from '../core/LocaleStore';

export type CampusesQueryVars = GetQueryVars<LocaleQueryParams>;

export type CampusesQueryData = {
  data: CampusDto[];
  floors: { [campusCode: string]: number[] };
};

export class CampusesStore extends AxiosQueryStore<
  CampusesQueryVars | null,
  CampusesQueryData | null
> {
  constructor(localeStore: LocaleStore, showHidden$: Subscribable<boolean>) {
    super({
      client: defaultClient,
      query: {
        method: 'get',
        url: `/api/front/campuses`
      },
      skipQuery: skipIfNull(null),
      variables$: combineLatest([localeStore.state$, showHidden$]).pipe(
        map(([locale, showHidden]) => {
          return {
            variables: {
              params: {
                showHidden: showHidden ? 1 : 0,
                locale: locale.lang
              }
            }
          };
        })
      )
    });
  }
}

export type CampusesStoreState = GetRequestStoreState<CampusesStore>;
