import { LocationStore } from '@proscom/prostore-react-router';
import { AxiosClientsManager } from '@proscom/prostore-axios-react';
import { ValueStore } from '@proscom/prostore';
import { appHistory } from './appHistory';
import {
  QUERY_KEY_END_ROOM,
  QUERY_KEY_START_ROOM,
  queryTransformers
} from './data/core/queryKeys';
import { LayoutStore } from './data/core/LayoutStore';
import { SearchStore } from './data/stores/SearchStore';
import { IRoomStore, RoomStore } from './data/online/RoomStore';
import { RouteStore } from './data/stores/RouteStore';
import { LocaleStore } from './data/core/LocaleStore';
import { NavStore } from './data/core/NavStore';
import { CampusesStore } from './data/stores/CampusesStore';
import { CampusStore } from './data/stores/CampusStore';
import { ShowGraphStore } from './data/core/ShowGraphStore';
import { RouteGraphStore } from './data/online/RouteGraphStore';
import { FloorDataStore, IFloorDataStore } from './data/online/FloorDataStore';
import { StatisticsStore } from './data/online/StatistictsStore';
import { defaultClient } from './Client';
import {
  EFFECT_LOCATION_QR,
  STORE_ACTIVE_PANEL,
  STORE_CAMPUS,
  STORE_CAMPUS_CATEGORIES,
  STORE_CAMPUSES,
  STORE_CANVAS_MANIPULATION,
  STORE_CATEGORIES,
  STORE_CATEGORIES_VISIBLE,
  STORE_CURRENT_LOCATION,
  STORE_DATA_OFFLINE,
  STORE_EVENT,
  STORE_FLOOR_DATA,
  STORE_LATEST_CAMPUS_STORE,
  STORE_LAYOUT,
  STORE_LOCALE,
  STORE_LOCATION,
  STORE_MODE,
  STORE_NAV,
  STORE_OFFLINE_MODE_AVAILABLE,
  STORE_QR_SCANNER,
  STORE_ROOM,
  STORE_ROOM_SEARCH,
  STORE_ROOM_SEARCH_RESULTS,
  STORE_ROOM_SEARCH_RESULTS_GROUPED,
  STORE_ROUTE,
  STORE_ROUTE_ACTIVE,
  STORE_ROUTE_GRAPH,
  STORE_ROUTE_GRAPH_DIRECTION,
  STORE_ROUTE_INFO,
  STORE_ROUTE_STEPS,
  STORE_SEARCH,
  STORE_SELECTED_CAMPUS_FLOORS,
  STORE_SELECTED_FLOOR,
  STORE_SHOW_GRAPH,
  STORE_SHOW_HIDDEN_CAMPUSES,
  STORE_STATISTICS,
  STORE_TOAST,
  STORE_WINDOW_SIZE
} from './data/stores';
import { rnMessenger } from './rnMessenger';
import { WindowSizeStore } from './data/core/WindowSizeStore';
import { CategoriesStore } from './data/stores/CategoriesStore';
import { addAxiosEventInterceptor, EventStore } from './data/stores/EventStore';
import { ToastStore } from './data/core/ToastStore';
import { CurrentLocationStore } from './data/stores/CurrentLocationStore';
import { CanvasManipulationStore } from './data/core/CanvasManipulationStore';
import { SelectedCampusFloors } from './data/stores/SelectedCampusFloors';
import { SelectedFloor } from './data/stores/SelectedFloor';
import { RequestProjectionStore } from './utils/prostore/RequestProjectionStore';
import { ActivePanel } from './data/core/ActivePanel';
import { RoomSearchStore } from './data/stores/RoomSearchStore';
import { RoomSearchResults } from './data/online/RoomSearchResults';
import { RoomSearchResultsGrouped } from './data/stores/RoomSearchResultsGrouped';
import { FloorDataStoreOffline } from './data/offline/stores/FloorDataStoreOffline';
import { RouteStoreOffline } from './data/offline/stores/RouteStoreOffline';
import { RoomStoreOffline } from './data/offline/stores/RoomStoreOffline';
import { DataStoreOffline } from './data/offline/stores/DataStoreOffline';
import { CampusesStoreOffline } from './data/offline/stores/CampusesStoreOffline';
import { CampusCategoriesStoreOffline } from './data/offline/stores/CampusCategoriesStoreOffline';
import { CurrentCampusStoreOffline } from './data/offline/stores/CurrentCampusStoreOffline';
import { ModeContext, ModeStoreModeEnum } from './data/core/ModeContext';
import {
  IRouteQueryStore,
  RouteQueryStore
} from './data/online/RouteQueryStore';
import { LocationQrEffect } from './data/stores/LocationQrEffect';
import { CampusDefaultEffect } from './data/stores/CampusDefaultEffect';
import { CampusRoomEffect } from './data/stores/CampusRoomEffect';
import {
  CampusCategoriesStore,
  ICampusCategoriesStore
} from './data/online/CampusCategoriesStore';
import { RoomCodeEffect } from './data/stores/RoomCodeEffect';
import { RoomQuery } from './data/online/RoomQuery';
import { locationStoreGetOne$ } from './common/hooks/useLocationQuery';
import { RoomQueryOffline } from './data/offline/stores/RoomQueryOffline';
import { RoomSearchVarsStore } from './data/stores/RoomSearchVarsStore';
import { RoomSearchResultsOffline } from './data/offline/stores/RoomSearchResultsOffline';
import { QrScannerStore } from './data/stores/QrScannerStore';
import { QrCodeResolver } from './data/online/QrCodeResolver';
import { QrCodeResolverOffline } from './data/stores/QrCodeResolverOffline';
import { RouteSteps } from './data/stores/RouteSteps';
import { RouteActive } from './data/stores/RouteActive';
import { RouteInfo } from './data/stores/RouteInfo';
import { ModeStore } from './data/stores/ModeStore';
import { OfflineModeAvailable } from './data/offline/stores/OfflineModeAvailable';
import { OfflineModeEnabled } from './data/offline/stores/OfflineModeEnabled';
import { LatestCampusStore } from './data/stores/LatestCampusStore';

export const modeStore = new ModeStore();

export const offlineModeAvailable = new OfflineModeAvailable();

export const offlineModeEnabled = new OfflineModeEnabled(
  modeStore,
  offlineModeAvailable
);

const locationStore = new LocationStore({
  history: appHistory,
  transformers: queryTransformers
});

const layoutStore = new LayoutStore();

const localeStore = new LocaleStore();
localeStore.register();

const eventStore = new EventStore({ locationStore, localeStore });
eventStore.state$.subscribe();

const canvasManipulationStore = new CanvasManipulationStore();

const latestCampus = new LatestCampusStore();
latestCampus.on();

const currentLocationStore = new CurrentLocationStore({
  locationStore,
  canvasManipulationStore,
  latestCampus
});

const showHiddenCampuses = new ValueStore(false);
window.showHidden = (show: boolean) => showHiddenCampuses.setState(show);

const campusesStore = new CampusesStore(localeStore, showHiddenCampuses.state$);

const categoriesStore = new CategoriesStore(defaultClient, localeStore);

const categoriesVisible = new RequestProjectionStore(
  categoriesStore.state$,
  (data) => data.filter((cat) => cat.default_visible)
);

const searchStore = new SearchStore({ locationStore, categoriesStore });

const dataStoreOffline = new DataStoreOffline({
  campusesStore,
  offlineModeEnabled,
  client: defaultClient
});

const campusesStoreOffline = new CampusesStoreOffline({
  dataStoreOffline,
  eventStore,
  localeStore,
  categoriesStore
});

const currentCampusStoreOffline = new CurrentCampusStoreOffline({
  campusesStoreOffline,
  locationStore
});

const navStore = new NavStore();

const campusStore = new CampusStore({
  campusesStore,
  locationStore
});

const showGraphStore = new ShowGraphStore();
window.showGraph = showGraphStore.setShowGraph;

const routeGraphStore = new RouteGraphStore({
  locationStore,
  campusStore,
  showGraphStore
});

const routeActive = new RouteActive(locationStore);

const toastStore = new ToastStore();

const selectedCampusFloors = new SelectedCampusFloors({
  campus: campusStore,
  campuses: campusesStore
});

const selectedFloor = new SelectedFloor({
  campusFloors: selectedCampusFloors,
  location: locationStore
});

const windowSizeStore = new WindowSizeStore();
windowSizeStore.state$.subscribe();

const activePanel = new ActivePanel(locationStore);
const roomSearch = new RoomSearchStore(locationStore);

const qrScannerStore = new QrScannerStore(rnMessenger, locationStore);
qrScannerStore.initialize();

export const modeContext = new ModeContext({
  locationStore,
  offlineModeEnabled,
  factory: (mode) => {
    const roomStore: IRoomStore =
      mode === ModeStoreModeEnum.online
        ? new RoomStore({ locationStore, localeStore })
        : new RoomStoreOffline({
            localeStore,
            locationStore,
            campusesStoreOffline
          });

    const roomCodeEffect = new RoomCodeEffect(roomStore, locationStore);
    roomCodeEffect.on();

    const startRoom =
      mode === ModeStoreModeEnum.online
        ? new RoomQuery(
            defaultClient,
            localeStore,
            locationStoreGetOne$(locationStore, QUERY_KEY_START_ROOM)
          )
        : new RoomQueryOffline(
            localeStore,
            currentCampusStoreOffline,
            locationStoreGetOne$(locationStore, QUERY_KEY_START_ROOM)
          );
    const endRoom =
      mode === ModeStoreModeEnum.online
        ? new RoomQuery(
            defaultClient,
            localeStore,
            locationStoreGetOne$(locationStore, QUERY_KEY_END_ROOM)
          )
        : new RoomQueryOffline(
            localeStore,
            currentCampusStoreOffline,
            locationStoreGetOne$(locationStore, QUERY_KEY_END_ROOM)
          );

    const routeStore = new RouteStore({
      locationStore,
      searchStore,
      startRoom,
      endRoom
    });

    const campusDefaultEffect = new CampusDefaultEffect({
      campusesStore,
      roomStore,
      locationStore,
      campusStore,
      routeStore,
      latestCampus
    });
    campusDefaultEffect.on();

    const campusRoomEffect = new CampusRoomEffect({
      campusesStore,
      roomStore,
      locationStore,
      campusStore,
      routeStore
    });
    campusRoomEffect.on();

    const routeQueryStore: IRouteQueryStore =
      mode === ModeStoreModeEnum.online
        ? new RouteQueryStore({
            locationStore,
            localeStore
          })
        : new RouteStoreOffline({
            locationStore,
            currentCampusStoreOffline,
            localeStore
          });

    const routeSteps = new RouteSteps(
      routeQueryStore,
      routeStore,
      campusesStore,
      currentLocationStore,
      localeStore,
      locationStore
    );

    const routeInfo = new RouteInfo(routeQueryStore);

    const campusCategoriesStore: ICampusCategoriesStore =
      mode === ModeStoreModeEnum.online
        ? new CampusCategoriesStore(defaultClient, localeStore, campusStore)
        : new CampusCategoriesStoreOffline({
            currentCampusStoreOffline,
            categoriesStore,
            eventStore
          });

    const floorDataStore: IFloorDataStore =
      mode === ModeStoreModeEnum.online
        ? new FloorDataStore({
            locationStore,
            localeStore,
            campusStore,
            campusesStore
          })
        : new FloorDataStoreOffline({
            locationStore,
            currentCampusStoreOffline
          });

    const qrCodeResolver =
      mode === ModeStoreModeEnum.online
        ? new QrCodeResolver(defaultClient)
        : new QrCodeResolverOffline(campusesStoreOffline, campusesStore);

    const locationQrEffect = new LocationQrEffect(
      localeStore,
      toastStore,
      locationStore,
      currentLocationStore,
      qrCodeResolver
    );
    locationQrEffect.on();

    const statisticsStore = new StatisticsStore({
      client: defaultClient,
      routeStore
    });
    statisticsStore.register();

    const roomSearchVars = new RoomSearchVarsStore(
      localeStore,
      selectedFloor,
      campusStore,
      locationStore,
      roomSearch,
      searchStore,
      currentLocationStore,
      routeStore
    );

    const roomSearchResults =
      mode === ModeStoreModeEnum.online
        ? new RoomSearchResults(roomSearchVars, roomSearch, defaultClient)
        : new RoomSearchResultsOffline(
            roomSearch,
            roomSearchVars,
            currentCampusStoreOffline
          );

    const roomSearchResultsGrouped = new RoomSearchResultsGrouped(
      roomSearchResults,
      routeStore,
      campusStore,
      campusesStore
    );

    const stores = {
      [STORE_MODE]: modeStore,
      [STORE_LAYOUT]: layoutStore,
      [STORE_SEARCH]: searchStore,
      [STORE_LOCALE]: localeStore,
      [STORE_NAV]: navStore,
      [STORE_LOCATION]: locationStore,
      [STORE_STATISTICS]: statisticsStore,
      [STORE_SHOW_GRAPH]: showGraphStore,
      [STORE_CAMPUSES]: campusesStore,
      [STORE_CAMPUS]: campusStore,
      [STORE_ROUTE_GRAPH]: routeGraphStore,
      [STORE_CANVAS_MANIPULATION]: canvasManipulationStore,
      [STORE_CURRENT_LOCATION]: currentLocationStore,
      [STORE_WINDOW_SIZE]: windowSizeStore,
      [STORE_EVENT]: eventStore,
      [STORE_TOAST]: toastStore,
      [STORE_SELECTED_CAMPUS_FLOORS]: selectedCampusFloors,
      [STORE_SELECTED_FLOOR]: selectedFloor,
      [STORE_SHOW_HIDDEN_CAMPUSES]: showHiddenCampuses,
      [STORE_CATEGORIES_VISIBLE]: categoriesVisible,
      [STORE_ACTIVE_PANEL]: activePanel,
      [STORE_ROOM_SEARCH]: roomSearch,
      [STORE_ROOM_SEARCH_RESULTS]: roomSearchResults,
      [STORE_ROOM_SEARCH_RESULTS_GROUPED]: roomSearchResultsGrouped,
      [STORE_CATEGORIES]: categoriesStore,
      [STORE_ROOM]: roomStore,
      [STORE_ROUTE_GRAPH_DIRECTION]: routeQueryStore,
      [STORE_CAMPUS_CATEGORIES]: campusCategoriesStore,
      [STORE_FLOOR_DATA]: floorDataStore,
      [EFFECT_LOCATION_QR]: locationQrEffect as any,
      [STORE_ROUTE]: routeStore,
      [STORE_QR_SCANNER]: qrScannerStore,
      [STORE_ROUTE_STEPS]: routeSteps,
      [STORE_ROUTE_ACTIVE]: routeActive,
      [STORE_ROUTE_INFO]: routeInfo,
      [STORE_DATA_OFFLINE]: dataStoreOffline,
      [STORE_OFFLINE_MODE_AVAILABLE]: offlineModeAvailable,
      [STORE_LATEST_CAMPUS_STORE]: latestCampus
    };

    const teardown = () => {
      roomCodeEffect.off();
      campusDefaultEffect.off();
      campusRoomEffect.off();
    };

    return {
      stores,
      teardown
    };
  }
});

addAxiosEventInterceptor(defaultClient, eventStore);

export const clients = new AxiosClientsManager({
  default: defaultClient
});
