import { BehaviorStore } from '@proscom/prostore';

export interface LayoutStoreState {
  width: number;
  height: number;
  offsetHeight: number;
  isDesktop: boolean;
}

const initialState = {
  width: window.innerWidth,
  height: window.innerHeight,
  isDesktop: isDesktop(window.innerWidth),
  offsetHeight: window.innerHeight
};

function isDesktop(width) {
  return width >= 1024;
}

export class LayoutStore extends BehaviorStore<LayoutStoreState> {
  constructor() {
    super(initialState);
  }

  onSubscribe() {
    super.onSubscribe();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  onUnsubscribe() {
    super.onUnsubscribe();
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      isDesktop: isDesktop(window.innerWidth)
    });
  };

  setOffsetHeight(height: number) {
    this.setState({
      offsetHeight: height
    });
  }
}
