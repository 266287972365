import { SubscriptionManager, ValueStore } from '@proscom/prostore';
import { EditMode } from '../types';
import { EffectHolder } from '../../../../utils/prostore/EffectHolder';
import { Effect } from '../../../../utils/prostore/Effect';

export class ActiveEditorMode extends EffectHolder implements Effect {
  sub = new SubscriptionManager();

  constructor(
    private readonly editorMode: ValueStore<EditMode>,
    private readonly modeFactory: (mode: EditMode) => Effect
  ) {
    super();
  }

  public on() {
    this.sub.subscribe(this.editorMode.state$, (mode) => {
      const effect = this.modeFactory(mode);
      this.setState(effect);
    });
  }

  public off() {
    this.sub.destroy();
    this.setState(undefined);
  }
}
