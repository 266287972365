import {
  Button,
  ButtonVariant,
  Icon,
  IconActionCheck,
  Spinner,
  SpinnerSize
} from '@hse-design/react';
import React from 'react';
import { useStore, useStoreState } from '@proscom/prostore-react';
import { useToggle } from '@proscom/ui-react';
import { STORE_DATA_OFFLINE, STORE_MODE } from '../../../data/stores';
import { ModeStoreModeEnum } from '../../../data/core/ModeContext';
import { DataStoreOfflineState } from '../../../data/offline/stores/DataStoreOffline';
import { useLocale } from '../../hooks/useLocale';
import { IModeStore } from '../../../data/stores/ModeStore';
import { OfflineModeModal } from './OfflineModeModal';

export function DesktopOfflineModeStatus() {
  const [mode, modeStore] = useStore<IModeStore>(STORE_MODE);
  const dataStoreOffline = useStoreState<DataStoreOfflineState>(
    STORE_DATA_OFFLINE
  );
  const modalOpen = useToggle();
  const locale = useLocale();

  return (
    <>
      <Button
        variant={ButtonVariant.tertiary}
        onClick={modalOpen.set}
        right={
          mode.value === ModeStoreModeEnum.offline ? (
            dataStoreOffline.loading ? (
              <Spinner size={SpinnerSize.small} indeterminate />
            ) : (
              <Icon icon={IconActionCheck} />
            )
          ) : undefined
        }
      >
        {mode.value === ModeStoreModeEnum.offline ? (
          dataStoreOffline.loading ? (
            <>{locale.t('offline.status.loading')}</>
          ) : (
            <>{locale.t('offline.status.offline')}</>
          )
        ) : (
          <>{locale.t('offline.status.online')}</>
        )}
      </Button>
      <OfflineModeModal isOpen={modalOpen.value} onClose={modalOpen.unset} />
    </>
  );
}
