import { BehaviorStore } from '@proscom/prostore';
import { ReactNativeMessenger } from '@proscom/rn-webview-messenger-web';
import cmp from 'semver-compare';
import { CustomError } from '@proscom/ui-utils';
import { LocationStore } from '@proscom/prostore-react-router';
import { NativeMethods } from '../../utils/ReactNativeMessenger';
import { qrMock } from '../../config';
import { QUERY_KEY_QR } from '../core/queryKeys';

export interface QrScannerStoreState {
  available?: boolean;
}

export class QrScannerUnavailable extends CustomError {
  public readonly name: string = 'QrScannerUnavailable';
}

export class QrScannerStore extends BehaviorStore<QrScannerStoreState> {
  public readonly canScanQrAppVersion = '1.2.0';

  constructor(
    protected readonly rnMessenger: ReactNativeMessenger,
    private readonly location: LocationStore
  ) {
    super({});
  }

  public initialize() {
    if (qrMock) {
      this.setState({ available: true });
      return;
    }

    if (!this.rnMessenger.isActive) {
      this.setState({ available: false });
      return;
    }

    this.rnMessenger.ready
      .then((info) => {
        if (cmp(info.nativeInfo.appVersion, this.canScanQrAppVersion) >= 0) {
          this.setState({ available: true });
        } else {
          this.setState({ available: false });
        }
      })
      .catch(() => {
        this.setState({ available: false });
      });
  }

  public scan = () => {
    if (qrMock) {
      setTimeout(() => {
        this.handleScanned('UNQR1881');
      }, 1000);
      return;
    }

    if (!this.rnMessenger.isActive) {
      throw new QrScannerUnavailable();
    }

    this.rnMessenger
      .call(NativeMethods.scanQrCode, null)
      .then((result) => {
        this.handleScanned(result as string);
      })
      .catch((e) => {
        console.error('[QrScannerStore] Scan failed', e);
      });
  };

  protected handleScanned(qr: string) {
    console.log('QrScannerStore handleScanned', qr);
    this.location.changeQuery({
      [QUERY_KEY_QR]: qr
    });
  }
}
