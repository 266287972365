import { BehaviorStore } from '@proscom/prostore';

export interface NavStoreState {
  isActive: boolean;
  activeSectionId: string | null;
  activeNavStep: any;
}

const initialState: NavStoreState = {
  isActive: true,
  activeSectionId: null,
  activeNavStep: null
};

export class NavStore extends BehaviorStore<NavStoreState> {
  constructor() {
    super(initialState);
  }

  setActive(isActive: boolean) {
    this.setState({
      isActive
    });
  }

  setSectionId(id: string | null) {
    this.setState({
      activeSectionId: id
    });
  }

  setNavStep(step) {
    this.setState({
      activeNavStep: step
    });
  }
}
