import ym, { YMInitializer } from 'react-yandex-metrika';
import { useHistory } from 'react-router';
import React, { useEffect } from 'react';

const yandexMetrikaId = process.env.REACT_APP_YANDEX_METRIKA_ID as string;

const metrikaOptions = {
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true
};

export function YandexMetrika() {
  const history = useHistory();
  const isActive = !!yandexMetrikaId && process.env.NODE_ENV !== 'development';

  useEffect(() => {
    if (!isActive) return;
    return history.listen(() => {
      ym?.('hit', window.location.href);
    });
  }, [isActive, history]);

  return isActive ? (
    <YMInitializer accounts={[+yandexMetrikaId]} options={metrikaOptions} />
  ) : null;
}
