import React, { MouseEventHandler, useMemo } from 'react';
import { Button, DivPropsWithoutRef } from '@hse-design/react';
import clsx from 'clsx';
import { dangerColor, successColor } from './data/theme';
import { EditMode } from './data/types';
import s from './MapperHints.module.scss';

export interface MapperHintsProps extends DivPropsWithoutRef {
  mode?: EditMode;
  onCancel?: MouseEventHandler<'button'>;
  onOk?: MouseEventHandler<'button'>;
}

export function MapperHints({
  mode,
  onCancel,
  onOk,
  ...props
}: MapperHintsProps) {
  const { hintTitle, hintText } = useMemo(() => {
    switch (mode) {
      case EditMode.SET_ROOM:
        return {
          hintTitle: 'Привязать комнату к вершине',
          hintText: 'Нажмите на комнату для привязки к ней вершины'
        };
      case EditMode.MOVE_NODE:
        return {
          hintTitle: 'Передвинуть вершину',
          hintText: 'Нажмите на новое расположение вершины'
        };
      case EditMode.ADD_NODE:
        return {
          hintTitle: 'Добавление вершины',
          hintText: 'Нажмите на свободное место на карте'
        };
      case EditMode.ADD_EDGE:
        return {
          hintTitle: 'Соединение вершин',
          hintText: `Нажмите на вершину для создания ребра`
        };
      case EditMode.ADD_ROOM:
        return {
          hintTitle: 'Добавление комнаты',
          hintText: `Нажимайте на карту для добавления границ комнаты. 
          Для добавления дополнительных полигонов нажмите "Добавить полигон"`
        };
      case EditMode.ADD_ROOM_ADD_POLYGON:
        return {
          hintTitle: 'Добавление полигонов',
          hintText: `Для добавления дополнительных полигонов нажимайте на свободное место`
        };
      default:
        return { hintTitle: undefined, hintText: undefined };
    }
  }, [mode]);
  const hasHint = hintTitle || hintText;

  return (
    <div
      className={clsx(s.MapperHints, {
        [s.MapperHints_isActive]: hasHint
      })}
      {...props}
    >
      {hasHint && (
        <>
          {hintTitle && <div className={s.MapperHints__title}>{hintTitle}</div>}
          {hintText && (
            <div className={s.MapperHints__description}>{hintText}</div>
          )}
          {onCancel && (
            <Button
              className={s.MapperHints__cancelButton}
              type="button"
              size={Button.Size.small}
              color={dangerColor}
              variant={Button.Variant.secondary}
              onClick={onCancel}
            >
              Отменить
            </Button>
          )}
          {onOk && (
            <Button
              className={s.MapperHints__cancelButton}
              type="button"
              size={Button.Size.small}
              color={successColor}
              variant={Button.Variant.secondary}
              onClick={onOk}
            >
              Ок
            </Button>
          )}
        </>
      )}
    </div>
  );
}
