import type { AxiosInstance } from 'axios';
import { BehaviorStore, SubscriptionManager } from '@proscom/prostore';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { compareSimple, makeComparator, Optional } from '@proscom/ui-utils';
import { GetQueryVars } from '../../../../data/api/common';
import { Point } from '../../utils';
import type { AuthStore } from './AuthStore';

const baseUrl = '/api/floors';

export interface AuthFloorDto {
  id: number;
  bounds: Point[][];
  building_id: number;
  number: number;
  created_at: string;
  updated_at: Optional<string>;
  deleted_at: Optional<string>;
  label_position: Point;
  svg: Optional<string>;
}

export interface IMapperFloorsStore {
  data: AuthFloorDto[];
}

/**
 * Стор с этажами
 */
class MapperFloorsQueryStore extends AxiosQueryStore<
  GetQueryVars<null> | null,
  AuthFloorDto[]
> {
  constructor({ axiosClient }: { axiosClient: AxiosInstance }) {
    super({
      client: axiosClient,
      mapData: ({ data }) => {
        return data.sort(MapperFloorsQueryStore.floorComparator);
      },
      initialData: [],
      query: {
        url: baseUrl
      }
    });
  }

  static get floorComparator() {
    return makeComparator(compareSimple(1), (f: AuthFloorDto) => f.number);
  }
}

export class MapperFloorsStore extends BehaviorStore<IMapperFloorsStore> {
  /**
   * AxiosQuery стор
   * Обновляет стейт при изменение этажа/кампуса
   */
  protected readonly query: MapperFloorsQueryStore;

  protected readonly sub = new SubscriptionManager();

  private readonly authStore: AuthStore;

  constructor({
    authStore,
    axiosClient
  }: {
    axiosClient: AxiosInstance;
    authStore: AuthStore;
  }) {
    super({
      data: []
    });
    this.authStore = authStore;
    this.query = new MapperFloorsQueryStore({ axiosClient });
  }

  onSubscribe() {
    this.sub.subscribe(this.authStore.state$, async () => {
      this.query.loadData(null);
    });
    // Подписываемся на изменение квери стора и обновляем стейт
    this.sub.subscribe(this.query.state$, ({ data }) => {
      this.setState({
        data: data || undefined
      });
    });
  }

  onUnsubscribe() {
    this.sub.destroy();
  }

  getFloor({
    building_id,
    number
  }: {
    building_id: AuthFloorDto['building_id'];
    number: AuthFloorDto['number'];
  }): AuthFloorDto | undefined {
    return this.state.data.find(
      (f) => f.building_id === building_id && f.number === number
    );
  }

  getFloorsByBuildingId(
    buildingId: AuthFloorDto['building_id']
  ): AuthFloorDto[] {
    return this.state.data.filter((f) => f.building_id !== buildingId);
  }

  findById(floorId: AuthFloorDto['id']): AuthFloorDto | undefined {
    return this.state.data.find((f) => f.id === floorId);
  }
}
