import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, RichInput, Select, Space } from '@hse-design/react';
import { number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOptions } from '../../types';
import { EdgeType } from '../../../../data/EdgeType';
import { useSyncFormFields } from './useSyncFormFields';

interface UpdateEdgeFormFields {
  type?: EdgeType;
  cost?: number;
}

export interface UpdateEdgeFormProps {
  values: UpdateEdgeFormFields;
  children?: React.ReactNode;
  onSubmit: (values: UpdateEdgeFormFields) => void;
}

const edgeTypeOptions: SelectOptions<EdgeType> = Object.values(
  EdgeType
).map((t) => ({ value: t, label: t }));

const type = 'type';
const cost = 'cost';

const validationSchema = object().shape({
  [type]: string().required('Выберите тип'),
  [cost]: number().nullable()
});

export function UpdateEdgeForm({
  children,
  values,
  onSubmit
}: UpdateEdgeFormProps) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<UpdateEdgeFormFields>({
    // todo Проверить типы
    resolver: yupResolver(validationSchema) as any,
    defaultValues: values
  });

  useSyncFormFields<UpdateEdgeFormFields>(reset, values);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name={type}
        control={control}
        defaultValue={values[type]}
        render={({ field: { ref, ...props } }) => {
          return (
            <RichInput
              component={Select}
              size={Select.Size.small}
              {...props}
              value={props.value || null}
              options={edgeTypeOptions}
              label={'Тип'}
              errorMessage={errors[type]?.message}
            />
          );
        }}
      />
      <Space size={Space.Size.small_3x} vertical />
      <Controller
        name={cost}
        control={control}
        defaultValue={values[cost]}
        render={({ field: { ref, ...props } }) => (
          <>
            <RichInput
              size={Input.Size.small}
              {...props}
              label={'Cost (в метрах)'}
              errorMessage={errors[cost]?.message}
            />
            {/*<span style={{ fontSize: '12px', opacity: '.5' }}>(бэк пока такого не умеет) Оставьте пустым для автоматического расчета</span>*/}
          </>
        )}
      />
      <Space size={Space.Size.small_3x} vertical />
      {children}
    </form>
  );
}
