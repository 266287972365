import React from 'react';
import { RoomSearchResultsGroupedData } from '../../../../data/stores/RoomSearchResultsGrouped';
import { RoomListItem } from '../../../../common/components/RoomListItem/RoomListItem';
import { RoomDto } from '../../../../data/api/RoomDto';
import s from './RoomList.module.scss';

export interface RoomsListProps {
  roomGroups: RoomSearchResultsGroupedData;
  searchValue?: string;
  onRoomClick?: (room: RoomDto) => void;
}

export function RoomList({
  roomGroups,
  searchValue,
  onRoomClick
}: RoomsListProps) {
  return (
    <div className={s.RoomList}>
      {roomGroups.map((group) => (
        <div className={s.RoomList__group} key={group.id}>
          <p className={s.RoomList__campusName}>{group.name}</p>
          <ul className={s.RoomList__list}>
            {group.rooms.map((room, iRoom) => (
              <li key={iRoom} className={s.RoomList__listItem}>
                <RoomListItem
                  room={room}
                  searchValue={searchValue}
                  onClickHandler={() => onRoomClick?.(room)}
                />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
