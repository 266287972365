import { ObservableStore } from '../../../utils/prostore/ObservableStore';
import { ModeStoreModeEnum } from '../../core/ModeContext';
import { IModeStore } from '../../stores/ModeStore';
import { combineLatestMap } from '../../../utils/prostore/combineLatestMap';
import { OfflineModeAvailable } from './OfflineModeAvailable';

export interface OfflineModeEnabledState {
  loaded: boolean;
  value: ModeStoreModeEnum | null;
}

export class OfflineModeEnabled extends ObservableStore<OfflineModeEnabledState> {
  constructor(mode: IModeStore, available: OfflineModeAvailable) {
    super(
      () =>
        combineLatestMap(
          [mode.state$, available.state$] as const,
          ([mode, available]) => {
            const loaded = mode.loaded && available.loaded;
            return {
              loaded,
              value: loaded
                ? available.value
                  ? mode.value
                  : ModeStoreModeEnum.online
                : null
            };
          }
        ),
      { loaded: false, value: null }
    );
  }
}
