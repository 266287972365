import { SubscriptionManager } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Effect } from '../../utils/prostore/Effect';
import {
  QUERY_KEY_CAMPUS,
  QUERY_KEY_END_ROOM,
  QUERY_KEY_FLOOR,
  QUERY_KEY_ROOM,
  QUERY_KEY_START_ROOM
} from '../core/queryKeys';
import { IRoomStore } from '../online/RoomStore';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import { CampusesStore } from './CampusesStore';
import { RouteStore } from './RouteStore';
import { CampusStore } from './CampusStore';

export interface CampusRoomEffectArgs {
  campusesStore: CampusesStore;
  locationStore: LocationStore;
  roomStore: IRoomStore;
  routeStore: RouteStore;
  campusStore: CampusStore;
}

function log<T extends Observable<any>>(obs$: T, name: string) {
  return obs$.pipe(tap(console.log.bind(null, name)));
}

export class CampusRoomEffect implements Effect {
  sub = new SubscriptionManager();

  constructor(private deps: CampusRoomEffectArgs) {}

  public on() {
    // Меняем кампус для соответствия выбранной комнате или маршруту
    this.sub.subscribeAsync(
      combineLatest([
        this.deps.campusesStore.state$,
        this.deps.roomStore.state$,
        this.deps.routeStore.state$,
        locationStoreGet$(
          this.deps.locationStore,
          QUERY_KEY_START_ROOM,
          QUERY_KEY_END_ROOM,
          QUERY_KEY_ROOM,
          QUERY_KEY_CAMPUS
        )
      ]),
      ([campusesQuery, roomQuery, route, query]) => {
        if (!campusesQuery.data) return;
        if (query[QUERY_KEY_CAMPUS]) return;

        let roomCampusId: number | null = null;
        let roomFloor: number | null = null;

        if (query[QUERY_KEY_ROOM]) {
          if (!roomQuery || !roomQuery.data) return;
          roomCampusId = roomQuery.data.campus_id;
          roomFloor = roomQuery.data.floor_number;
        } else if (query[QUERY_KEY_START_ROOM] && query[QUERY_KEY_END_ROOM]) {
          if (!route.routeStartPoint) return;
          roomCampusId = route.routeStartPoint.campus_id;
          roomFloor = route.routeStartPoint.floor_number;
        }

        if (roomCampusId) {
          const roomCampus = campusesQuery.data.data.find(
            (c) => c.id === roomCampusId
          );
          if (roomCampus && query[QUERY_KEY_CAMPUS] !== roomCampus.code) {
            this.deps.locationStore.changeQuery(
              {
                [QUERY_KEY_CAMPUS]: roomCampus.code,
                [QUERY_KEY_FLOOR]: roomFloor
              },
              true
            );
          }
        }
      }
    );
  }

  public off() {
    this.sub.destroy();
  }
}
