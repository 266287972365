import React from 'react';
import { BaseCheckboxIcon, Divider } from '@hse-design/react';
import { CampusDto } from '../../../../../data/api/CampusDto';
import s from './CampusesList.module.scss';

export interface CampusControlProps {
  onCampusSelect: (campusCode: string) => void;
  selectedCampusCode: string;
  campuses: CampusDto[];
}

export const CampusesList = ({
  onCampusSelect,
  selectedCampusCode,
  campuses
}: CampusControlProps) => (
  <ul>
    {campuses.map((campus, campusI) => (
      <li key={campus.code}>
        <div
          onClick={() => onCampusSelect(campus.code)}
          className={s.CampusesListItem}
        >
          <p className={s.CampusesListItem__name}>{campus.name}</p>
          <p className={s.CampusesListItem__address}>{campus.address}</p>
          <BaseCheckboxIcon
            className={s.CampusesListItem__radio}
            checked={selectedCampusCode === campus.code}
            radio={true}
          />
        </div>
        {campusI !== campuses.length - 1 && (
          <Divider className={s.CampusesListItem__divider} />
        )}
      </li>
    ))}
  </ul>
);
