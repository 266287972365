import { ObservableStore } from '@proscom/prostore';
import { isTruthy } from '@proscom/ui-utils';
import { combineLatestMap } from '../../../../utils/prostore/combineLatestMap';
import { EdgesStore } from './EdgesStore';
import { HoverItem, HoverItemType } from './HoverItem';
import { IVertexData, NodesStore } from './NodesStore';

export type HoverEdgeNodesState = IVertexData[];

export class HoverEdgeNodes extends ObservableStore<HoverEdgeNodesState> {
  constructor(nodes: NodesStore, edges: EdgesStore, hoverItem: HoverItem) {
    super(
      combineLatestMap(
        [nodes.state$, edges.state$, hoverItem.state$] as const,
        ([nodes, edges, hoverItem]) => {
          if (
            !nodes.data ||
            !edges.data ||
            !hoverItem ||
            hoverItem.type !== HoverItemType.edge
          ) {
            return [];
          }

          const edge = edges.data[hoverItem.id];

          return [
            nodes.data[edge.start_vertex_id],
            nodes.data[edge.end_vertex_id]
          ].filter(isTruthy);
        }
      ),
      []
    );
  }
}
