import React from 'react';
import clsx from 'clsx';
import s from './LeftPaneContent.module.scss';

export interface LeftPaneContentProps {
  className?: string;
  children?: React.ReactNode;
}

export function LeftPaneContent({ className, children }: LeftPaneContentProps) {
  return <div className={clsx(s.LeftPaneContent, className)}>{children}</div>;
}
