import React from 'react';
import clsx from 'clsx';
import {
  TableCell as HseTableCell,
  TableCellProps,
  TableHeadCell as HseTableHeadCell,
  TableHeadCellProps
} from '@hse-design/react';
import s from './Table.module.scss';

export function TableSmallHeadCell({
  className,
  ...props
}: TableHeadCellProps) {
  return (
    <HseTableHeadCell
      className={clsx(s.TableSmallHeadCell, className)}
      {...props}
    />
  );
}

export function TableSmallCell({ className, ...props }: TableCellProps) {
  return (
    <HseTableCell className={clsx(s.TableSmallCell, className)} {...props} />
  );
}

export { Table, TableHead, TableBody, TableRow } from '@hse-design/react';
