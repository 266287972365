import { distinctUntilChanged, map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { isEqual } from 'lodash';
import { AxiosInstance } from 'axios';
import { RoomQueryData, RoomQueryVars } from '../api/common';
import { AxiosSimpleQuery } from '../../utils/prostore/AxiosSimpleQuery';
import { IStoreType } from '../../utils/types';
import { LocaleStore } from '../core/LocaleStore';

export type IRoomQuery = IStoreType<RoomQuery>;

export class RoomQuery extends AxiosSimpleQuery<RoomQueryVars, RoomQueryData> {
  constructor(
    client: AxiosInstance,
    locale: LocaleStore,
    roomId$: Observable<number | undefined>
  ) {
    super(
      client,
      combineLatest([locale.state$, roomId$]).pipe(
        map(([locale, roomId]) => {
          if (!roomId) {
            return null;
          }
          return {
            params: { locale: locale.lang },
            url: `/api/front/room/${roomId}`
          };
        }),
        distinctUntilChanged(isEqual)
      )
    );
  }
}
