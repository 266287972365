import money from './money.svg';
import entrance from './entrance.svg';
import accessibleWC from './accessibleWC.svg';
import elevator from './elevator.svg';
import start from './start.svg';
import end from './end.svg';
import purifier from './purifier.svg';
import stairs from './stairs.svg';
import wc from './wc.svg';
import cloakroom from './cloakroom.svg';
import info from './info.svg';
import medicine from './medicine.svg';
import restaurant from './restaurant.svg';
import startFlag from './startFlag.svg';

export default {
  money,
  entrance,
  accessibleWC,
  elevator,
  end,
  purifier,
  stairs,
  start,
  wc,
  cloakroom,
  info,
  medicine,
  restaurant,
  startFlag
};
