class IconStyle {
  constructor(
    width = 0,
    height = 0,
    iconColor = 'transparent',
    backgroundColor = 'transparent',
    zIndex = 0,
    borderWidth = 0,
    borderColor = 'transparent'
  ) {
    this.width = width;
    this.height = height;
    this.iconColor = iconColor;
    this.backgroundColor = backgroundColor;
    this.zIndex = zIndex;
    this.borderWidth = borderWidth;
    this.borderColor = borderColor;
  }
}
export const iconStyles = {
  inactive: new IconStyle(24, 24, '#6B7A99', '#EEEFF4', 4),
  active: new IconStyle(24, 24, '#0050CF', '#F0F5FF', 5),
  selected: new IconStyle(24, 24, '#FFFFFF', '#0050CF', 6),
  superactive: new IconStyle(32, 32, '#FFFFFF', '#0050CF', 10)
};
