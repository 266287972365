import { History } from 'history';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Spinner,
  SpinnerSize
} from '@hse-design/react';
import React from 'react';
import { useStore, useStoreState } from '@proscom/prostore-react';
import { MobileScreen } from '../../../../../../common/mobile/MobileScreen/MobileScreen';
import { useLocale } from '../../../../../../common/hooks/useLocale';
import { STORE_DATA_OFFLINE, STORE_MODE } from '../../../../../../data/stores';
import { ModeStoreModeEnum } from '../../../../../../data/core/ModeContext';
import { DataStoreOfflineState } from '../../../../../../data/offline/stores/DataStoreOffline';
import { MobileScreenContent } from '../../../../../../common/mobile/MobileScreen/MobileScreenContent';
import { IModeStore } from '../../../../../../data/stores/ModeStore';
import s from '../../../../../../common/desktop/DesktopOfflineModeStatus/OfflineModeModal.module.scss';

export interface OfflinePageProps {
  history: History;
}

export function OfflinePage({ history }: OfflinePageProps) {
  const locale = useLocale();
  const handleBack = () => history.goBack();
  const [mode, modeStore] = useStore<IModeStore>(STORE_MODE);
  const handleSetup = () => modeStore.setValue(ModeStoreModeEnum.offline);
  const handleDisable = () => modeStore.setValue(ModeStoreModeEnum.online);
  const dataStoreOffline = useStoreState<DataStoreOfflineState>(
    STORE_DATA_OFFLINE
  );

  return (
    <MobileScreen
      title={locale.t('offline.modal.title')}
      onBackClick={handleBack}
      actions={
        mode.value === ModeStoreModeEnum.offline ? (
          <>
            <Button
              variant={ButtonVariant.secondary}
              size={ButtonSize.large}
              onClick={handleDisable}
            >
              {locale.t('offline.modal.disable')}
            </Button>
            <Button
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              onClick={handleBack}
            >
              {locale.t('offline.modal.ok')}
            </Button>
          </>
        ) : (
          <>
            <Button
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              onClick={handleSetup}
            >
              {locale.t('offline.modal.setup')}
            </Button>
          </>
        )
      }
    >
      <MobileScreenContent>
        {mode.value === ModeStoreModeEnum.offline ? (
          dataStoreOffline.loading ? (
            <>
              <p>{locale.t('offline.modal.loading')}</p>
              <Spinner
                className={s.OfflineModeModal__spinner}
                size={SpinnerSize.XL}
                indeterminate
              />
            </>
          ) : (
            <p>{locale.t('offline.modal.offline')}</p>
          )
        ) : (
          <p>{locale.t('offline.modal.introduction')}</p>
        )}
      </MobileScreenContent>
    </MobileScreen>
  );
}
