import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStoreState } from '@proscom/prostore-react';
import { MapControls } from '../MapControls/MapControls';
import {
  STORE_CAMPUS,
  STORE_CAMPUSES,
  STORE_CURRENT_LOCATION,
  STORE_FLOOR_DATA,
  STORE_ROUTE
} from '../../../data/stores';
import { useLocationQuery } from '../../hooks/useLocationQuery';
import {
  QUERY_KEY_CAMPUS,
  QUERY_KEY_FLOOR,
  QUERY_KEY_STEP
} from '../../../data/core/queryKeys';
import { RouteStepType } from '../../../data/RouteStepType';
import { CampusCanvasComponent } from './CampusCanvasComponent';
import {
  campusBackgrounds,
  floorBackgrounds,
  roadsBackgrounds
} from './overlayImages';
import { Transfer } from './Transfer';
import s from './MapBox.module.scss';

export const MapBox = ({ route, routeSteps, isDesktopVersion }) => {
  const [query] = useLocationQuery([
    QUERY_KEY_STEP,
    QUERY_KEY_CAMPUS,
    QUERY_KEY_FLOOR
  ]);
  const routeStoreState = useStoreState(STORE_ROUTE);
  const floorDataStoreState = useStoreState(STORE_FLOOR_DATA);
  const selectedCampus = useStoreState(STORE_CAMPUS);
  const campusesStoreState = useStoreState(STORE_CAMPUSES);
  const currentLocationStoreState = useStoreState(STORE_CURRENT_LOCATION);

  const campuses = campusesStoreState.data && campusesStoreState.data.data;

  const mapRef = useRef();

  const selectedStepIndex =
    route && routeStoreState.isOnRoute && +query[QUERY_KEY_STEP];
  const selectedFloor = query[QUERY_KEY_FLOOR];

  const selectedFloorRouteSteps = useMemo(() => {
    if (!routeSteps || !selectedCampus) return [];

    return routeSteps.filter(
      (s) =>
        (s.campusId === selectedCampus.id && s.floorNumber === selectedFloor) ||
        s.type === RouteStepType.TYPE_TRANSFER
    );
  }, [routeSteps, selectedCampus, selectedFloor]);

  const transferData = useMemo(() => {
    if (
      campuses &&
      routeSteps &&
      routeSteps[selectedStepIndex] &&
      routeSteps[selectedStepIndex].type === RouteStepType.TYPE_TRANSFER
    ) {
      const prevStep = routeSteps[selectedStepIndex - 1];
      const startCampus = campuses.find(
        (c) => c.id === (prevStep && prevStep.campusId)
      );
      const nextStep = routeSteps[selectedStepIndex + 1];
      const endCampus = campuses.find(
        (c) => c.id === (nextStep && nextStep.campusId)
      );
      if (startCampus && endCampus) {
        return {
          startCampusMapPosition: startCampus.map_position,
          endCampusMapPosition: endCampus.map_position
        };
      }
    }
    return null;
  }, [routeSteps, selectedStepIndex, campuses]);

  const [isOnTransfer, setIsOnTransfer] = useState(false);
  useEffect(() => {
    if (
      routeSteps &&
      routeSteps[selectedStepIndex] &&
      routeSteps[selectedStepIndex].type === RouteStepType.TYPE_TRANSFER
    ) {
      setIsOnTransfer(true);
    } else setIsOnTransfer(false);
  }, [selectedStepIndex, routeSteps, setIsOnTransfer]);

  const pin = useMemo(() => {
    if (!currentLocationStoreState) return null;
    const { location } = currentLocationStoreState;
    if (!location) return null;

    const showPin =
      selectedCampus &&
      location.campus_id === selectedCampus.id &&
      location.floor_number === selectedFloor;

    if (!showPin) return null;

    return {
      x: parseFloat(currentLocationStoreState.location.x),
      y: parseFloat(currentLocationStoreState.location.y)
    };
  }, [currentLocationStoreState, selectedCampus, selectedFloor]);

  const overlayImages = useMemo(() => {
    if (!selectedCampus) return null;
    return [
      {
        src:
          campusBackgrounds[selectedCampus.code][selectedFloor] ||
          campusBackgrounds[selectedCampus.code].default,
        size: {
          x: selectedCampus.bounds[0][1],
          y: selectedCampus.bounds[0][0],
          width: selectedCampus.bounds[1][1],
          height: selectedCampus.bounds[1][0]
        },
        zIndex: 0
      },
      {
        src: roadsBackgrounds[selectedCampus.code],
        size: {
          x: selectedCampus.road_bounds[0][1],
          y: selectedCampus.road_bounds[0][0],
          width: selectedCampus.road_bounds[1][1],
          height: selectedCampus.road_bounds[1][0]
        },
        zIndex: 1
      },
      {
        src: floorBackgrounds[selectedCampus.code][selectedFloor],
        size: {
          x: selectedCampus.bounds[0][1],
          y: selectedCampus.bounds[0][0],
          width: selectedCampus.bounds[1][1],
          height: selectedCampus.bounds[1][0]
        },
        zIndex: 2
      }
    ];
  }, [selectedFloor, selectedCampus]);

  return (
    <div className={s.MapBox}>
      {isOnTransfer ? (
        <Transfer
          transferData={transferData}
          isDesktopVersion={isDesktopVersion}
        />
      ) : (
        <>
          <CampusCanvasComponent
            pin={pin}
            isCurrentLocationSelected={currentLocationStoreState.isSelected}
            selectedFloor={selectedFloor}
            rooms={floorDataStoreState.data && floorDataStoreState.data.rooms}
            routeSteps={selectedFloorRouteSteps}
            mapRef={mapRef}
            buildings={
              floorDataStoreState.data && floorDataStoreState.data.buildings
            }
            portals={
              floorDataStoreState.data && floorDataStoreState.data.portals
            }
            overlayImages={overlayImages}
          />
          <MapControls mapRef={mapRef} isDesktopVersion={isDesktopVersion} />
        </>
      )}
    </div>
  );
};
