import getArea from 'area-polygon';
import { Ring } from './types';

export function getRingArea(ring: Ring) {
  if (ring.length < 3) {
    return 0;
  }

  try {
    return getArea(ring.map((p) => ({ x: p[0], y: p[1] })));
  } catch (e) {
    console.error(e);
    return 0;
  }
}
