import { LocaleStore } from './core/LocaleStore';

export class Distance {
  static formatTime(localeStore: LocaleStore, time: number) {
    return time + ' ' + localeStore.t('unit.minutes');
  }

  static formatDistance(localeStore: LocaleStore, distance: number) {
    let roundedDist = distance;
    let distUnit = 'unit.meters';
    if (roundedDist >= 1000) {
      roundedDist = Math.round(roundedDist / 100) / 10;
      distUnit = 'unit.kilometers';
    }
    return (
      new Intl.NumberFormat(localeStore.state.localeId, {
        maximumFractionDigits: 1
      }).format(roundedDist) +
      ' ' +
      localeStore.t(distUnit)
    );
  }
}
