import { ObservableStore } from '@proscom/prostore';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, from } from 'rxjs';
import { delayPromise } from '@proscom/ui-utils';
import { IterableElement } from 'type-fest';
import { EventStore, EventStoreState } from '../../stores/EventStore';
import { LocaleStore } from '../../core/LocaleStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { PathGraph } from '../../PathGraph';
import { groupMapByMulti } from '../../../utils/array/keyMapByMulti';
import { BuildingFloorDto } from '../../api/BuildingFloorDto';
import { StaffDto } from '../../api/StaffDto';
import { RoomDto } from '../../api/RoomDto';
import { RoomCategoryDto } from '../../api/RoomCategoryDto';
import { VertexType } from '../../VertexType';
import { getFieldLocale } from '../api/LocalizedField';
import { OfflineEntityType } from '../api/OfflineEntityType';
import { OfflineEventRoomDto } from '../api/OfflineEventRoomDto';
import { OfflineVertexDto } from '../api/OfflineVertexDto';
import { OfflineCategoriesCampusesEventDto } from '../api/OfflineCategoriesCampusesEventDto';
import { OfflineQrDto } from '../api/OfflineQrDto';
import { IApiOfflineCampusResponse } from '../api/ApiOfflineCampusResponse';
import { DataStoreOffline } from './DataStoreOffline';

export interface IOfflineFloor {
  buildings: BuildingFloorDto[];
  portals: OfflineVertexDto[];
  rooms: RoomDto[];
}

export interface IOfflineCampusInfo {
  qrs: OfflineQrDto[];
  categories: OfflineCategoriesCampusesEventDto[];
  campus: string;
  floors: Map<number, IOfflineFloor>;
  graph: PathGraph;
  rooms: RoomDto[];
}

export interface CampusesStoreOfflineState {
  campuses: IOfflineCampusInfo[] | null;
}

export interface CampusesStoreOfflineArgs {
  dataStoreOffline: DataStoreOffline;
  eventStore: EventStore;
  localeStore: LocaleStore;
  categoriesStore: CategoriesStore;
}

/**
 * Преобразует данные стора DataStoreOffline в структуру, передаваемую на вход других offline сторов
 * @param dataStoreOffline - {@link DataStoreOffline}
 * @param eventStore -  {@link EventStore}
 * @param localeStore -  {@link LocaleStore}
 */
export class CampusesStoreOffline extends ObservableStore<CampusesStoreOfflineState> {
  constructor(params: CampusesStoreOfflineArgs) {
    super(
      combineLatest([
        params.dataStoreOffline.state$,
        params.eventStore.state$,
        params.localeStore.state$,
        params.categoriesStore.state$
      ]).pipe(
        switchMap(([dataState, event, location, categories]) => {
          return from(
            this.handleCampusesStoreStateChange(
              dataState.data,
              event,
              location.lang,
              categories.data
            )
          );
        }),
        map((campuses) => ({ campuses }))
      ),
      { campuses: null }
    );
  }

  async handleCampusesStoreStateChange(
    data: IApiOfflineCampusResponse[] | null,
    event: EventStoreState,
    language: string | undefined,
    categories: RoomCategoryDto[] | null
  ) {
    if (!data || !language || !categories) return null;

    return await Promise.all(
      data.map((campus) =>
        this.processCampus(campus, event, language, categories)
      )
    );
  }

  async processCampus(
    campus: IApiOfflineCampusResponse,
    event: EventStoreState,
    language: string,
    categories: RoomCategoryDto[]
  ): Promise<IOfflineCampusInfo> {
    const floorSet = new Set<number>();
    for (const floor of campus.floors) {
      floorSet.add(+floor.number);
    }
    const floorNumbers = Array.from(floorSet.values()).sort((a, b) => a - b);

    let eventRooms: OfflineEventRoomDto[] = [];
    if (event.eventCode) {
      // todo подумать что на самом деле campus.events не должно быть
      const eventInfo = campus.events.find((ev) => ev.code === event.eventCode);
      eventRooms = campus.event_rooms.filter(
        (er) => er.event_id === eventInfo?.id
      );
    }

    const localesMap = groupMapByMulti(
      campus.locales,
      (l) => l.entity_type,
      (l) => l.entity_id
    );

    function getLocalizedField(
      entityType: OfflineEntityType,
      id: number,
      field: string
    ) {
      return localesMap
        .get(entityType)
        ?.get(id)
        ?.find(
          (locale) =>
            locale.entity_type === entityType &&
            locale.entity_id === id &&
            locale.field === field
        );
    }

    function getEntityFieldLocale(
      entityType: OfflineEntityType,
      id: number,
      field: string
    ) {
      return getFieldLocale(getLocalizedField(entityType, id, field), language);
    }

    function getEntityFieldsLocale<Keys extends readonly string[]>(
      entityType: OfflineEntityType,
      id: number,
      fields: Keys
    ) {
      type KeysUnion = Pick<Keys, keyof Keys>[number];

      const result: { [key in KeysUnion]?: string } = {};
      for (const field of fields) {
        result[field] = getEntityFieldLocale(entityType, id, field);
      }

      return result as Required<typeof result>;
    }

    const staff = campus.staff.map(
      (staff, iStaff): StaffDto => {
        if (iStaff % 100 === 0) {
          console.log(
            `[CampusesStoreOffline] staff ${iStaff}/${campus.staff.length}`
          );
        }
        return {
          ...staff,
          ...getEntityFieldsLocale(OfflineEntityType.staff, staff.id, [
            'first_name',
            'last_name',
            'surname',
            'title',
            'description',
            'structure',
            'name',
            'mapName'
          ] as const)
        };
      }
    );

    const floors = new Map<number, IOfflineFloor>();
    for (const floorNumber of floorNumbers) {
      floors.set(
        floorNumber,
        this.processFloor(
          campus,
          floorNumber,
          eventRooms,
          language,
          categories,
          staff,
          getEntityFieldLocale
        )
      );
      await delayPromise(0);
    }

    const graph = new PathGraph(campus.vertices, campus.edges);

    const rooms = [...floors.values()].flatMap((f) => f.rooms);

    return {
      qrs: campus.qrs,
      campus: campus.campus.code,
      categories: campus.categories_campuses_events,
      floors,
      graph,
      rooms
    };
  }

  processFloor(
    campus: IApiOfflineCampusResponse,
    floorNumber: number,
    eventRooms: OfflineEventRoomDto[],
    language: string,
    categories: RoomCategoryDto[],
    staff: StaffDto[],
    getEntityFieldLocale: (
      entityType: OfflineEntityType,
      id: number,
      field: string
    ) => string
  ): IOfflineFloor {
    const buildingFloors = campus.floors.filter(
      (floor) => floor.number === floorNumber
    );

    const portals = campus.vertices.filter(
      (vertex) =>
        vertex.floor_number === floorNumber &&
        (vertex.type === VertexType.stairs ||
          vertex.type === VertexType.elevator)
    );

    const floorRooms = campus.rooms.filter((room) => {
      // todo adjust for current event
      const eventRoom = eventRooms.find(
        (eventRoom) =>
          eventRoom.room_id === room.id && eventRoom.event_id === null
      );
      return (
        room.floor_number === floorNumber &&
        (eventRoom ? !eventRoom.hide : room.default_visible)
      );
    });

    const rooms = floorRooms.map(
      (room): RoomDto => {
        const category = categories.find(
          (cat) => cat.id === room.room_category_id
        );

        const room_category = category
          ? {
              ...category,
              name: getEntityFieldLocale(
                OfflineEntityType.roomCategory,
                category.id,
                'name'
              )
            }
          : null;

        return {
          ...room,
          staff: staff.filter((staff) => staff.room_id === room.id),
          room_category,
          map_name: getFieldLocale(room.map_name, language),
          color: getFieldLocale(room.color, language),
          name: getFieldLocale(room.name, language),
          description: getFieldLocale(room.description, language)
        };
      }
    );

    const cbuildings = buildingFloors.map((buildingFloor) => {
      return {
        ...buildingFloor,
        building: campus.buildings.find(
          (building) => building.id === buildingFloor.building_id
        )
      };
    });

    type CBuilding = IterableElement<typeof cbuildings>;
    const buildings = cbuildings.filter(
      (floor: CBuilding): floor is BuildingFloorDto & CBuilding => {
        return !!floor.building;
      }
    );

    return {
      rooms,
      buildings,
      portals
    };
  }
}
