class BuildingStyle {
  constructor(
    backgroundColor = 'transparent',
    color = 'transparent',
    underlayColor = 'transparent',
    underlayBorderRadius = 0,
    underlayPadding = 0,
    zIndex = 0,
    labelZIndex = 0,
    size = 21,
    fontFamily = '"helvetica", "Arial", "Tahoma", sans-serif'
  ) {
    this.backgroundColor = backgroundColor;
    this.color = color;
    this.underlayColor = underlayColor;
    this.underlayBorderRadius = underlayBorderRadius;
    this.underlayPadding = underlayPadding;
    this.zIndex = zIndex;
    this.labelZIndex = labelZIndex;
    this.size = size;
    this.fontFamily = fontFamily;
  }
}

export const buildingStyles = {
  building: {
    inactive: new BuildingStyle(
      '#A2A9B8',
      '#FFFFFF',
      'transparent',
      0,
      0,
      7,
      8
    ),
    active: new BuildingStyle('#C2DAFF', '#FFFFFF', 'transparent', 0, 0, 7, 8)
  },
  atrium: {
    inactive: new BuildingStyle(
      '#D3D8E6',
      '#FFFFFF',
      'transparent',
      0,
      0,
      7,
      8
    ),
    active: new BuildingStyle('#C2DAFF', '#FFFFFF', 'transparent', 0, 0, 7, 8)
  },
  highScale: new BuildingStyle(
    'transparent',
    '#FFFFFF',
    '#002D6E',
    8,
    11,
    0,
    6,
    16
  )
};
