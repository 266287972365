import clsx from 'clsx';
import React from 'react';
import { SectionDivider } from '../SectionDivider/SectionDivider';
import s from './PanelSection.module.scss';

export interface PanelSectionProps {
  className?: string;
  contentClassName?: string;
  dividerClassName?: string;
  dividerOutset?: boolean;
  children?: React.ReactNode;
}

export function PanelSection({
  className,
  contentClassName,
  dividerClassName,
  dividerOutset,
  children
}: PanelSectionProps) {
  return (
    <div className={clsx(s.PanelSection, className)}>
      <SectionDivider
        className={clsx(dividerClassName, {
          [s.PanelSection__divider_outset]: dividerOutset
        })}
      />
      <div className={clsx(s.PanelSection__content, contentClassName)}>
        {children}
      </div>
    </div>
  );
}
