import React from 'react';
import { BehaviorStore, SubscriptionManager } from '@proscom/prostore';
import {
  StorageValueState,
  StorageValueStore
} from '@proscom/prostore-local-storage';
import { IconLanguageEn, IconLanguageRu } from '@hse-design/react';
import getUserLocale from 'get-user-locale';
import lngs from '../../lngs';
import { LocaleStorageAdapter } from './LocaleStorageAdapter';

export interface LocaleStoreState {
  localeId?: string;
  lang?: string;
}

const initialState: LocaleStoreState = {};

function getLang<T extends string | undefined | null>(locale: T): T | string {
  if (!locale) {
    return locale;
  }
  return locale.split('-')[0];
}

export class LocaleStore extends BehaviorStore<LocaleStoreState> {
  locales = lngs;
  sub = new SubscriptionManager();
  defaultLocale = 'en-US';
  defaultLang = getLang(this.defaultLocale);
  userLocale = getUserLocale();
  userLang = getLang(this.userLocale);
  adapter = new LocaleStorageAdapter();
  storedLng = new StorageValueStore<string>(this.adapter, (x) => x);

  constructor() {
    super(initialState);
  }

  register() {
    this.adapter.on();
    this.sub.subscribe(this.storedLng.state$, this.handleLocalStorageChange);
  }

  unregister() {
    this.sub.destroy();
    this.adapter.off();
  }

  handleLocalStorageChange = (locale: StorageValueState<string>) => {
    if (locale.loaded) {
      this.setLocale(locale.value);
    }
  };

  setLocale(locale: string | null, save?: boolean) {
    const setLang = getLang(locale);
    const localeId =
      locale && setLang && this.locales[setLang]
        ? locale
        : this.locales[this.userLang]
        ? this.userLocale
        : this.defaultLocale;
    const lang = getLang(localeId);

    this.setState({
      localeId,
      lang
    });

    if (save) {
      this.storedLng.setValue(locale);
    }
  }

  t(
    key: string,
    insertionObj: { [key: string]: string } | undefined = undefined
  ) {
    const stateLang = this.state.lang;
    const lang =
      stateLang && this.locales[stateLang] ? stateLang : this.defaultLang;
    const messages = this.locales[lang];

    let resStr = messages[key];

    if (insertionObj && resStr) {
      for (const [keyInsert, valueInsert] of Object.entries(insertionObj)) {
        resStr = resStr.split(`{${keyInsert}}`).join(valueInsert);
      }
    }

    return resStr;
  }
}

export interface ILocaleInfo {
  id: string;
  textShort: string;
  text: string;
  icon: React.ComponentType<any>;
}

export const localesList: ILocaleInfo[] = [
  {
    id: 'en-US',
    textShort: 'EN',
    text: 'English',
    icon: IconLanguageEn
  },
  {
    id: 'ru-RU',
    textShort: 'RU',
    text: 'Русский',
    icon: IconLanguageRu
  }
];
