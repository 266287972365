import { useEffect, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';

/**
 * BottomSheet не умеет в onScroll и не даёт ref на корень,
 * поэтому приходится немножко подкостылять
 */
export function useBottomSheetOnScroll(
  handler?: (e: Event) => void,
  idProp?: string
) {
  const [scrollNode, setScrollNode] = useState<HTMLDivElement | null>(null);
  const id = useMemo(() => idProp || 'BottomSheet_' + nanoid(10), [idProp]);

  // Должно выполняться после каждого рендера
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const sheetNode = document.getElementById(id);
    const scrollNode = sheetNode?.querySelector(
      '[data-rsbs-scroll]'
    ) as HTMLDivElement;
    setScrollNode(scrollNode || null);
  });

  useEffect(() => {
    if (!scrollNode || !handler) return;

    scrollNode.addEventListener('scroll', handler, {
      passive: true
    });

    return () => {
      scrollNode.removeEventListener('scroll', handler);
    };
  }, [scrollNode, handler]);

  return id;
}
