import clsx from 'clsx';
import React from 'react';
import s from './RouteInfo.module.scss';

export interface RouteGeneralActionsProps {
  className?: string;
  children?: React.ReactNode;
}

export function RouteGeneralActions({
  className,
  children
}: RouteGeneralActionsProps) {
  return (
    <div className={clsx(s.RouteGeneralActions, className)}>
      {React.Children.map(children, (item, i) => (
        <div key={i} className={s.RouteGeneralActions__action}>
          {item}
        </div>
      ))}
    </div>
  );
}
