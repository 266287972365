import React, { FormEventHandler, useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  ModalForm,
  ModalProps,
  RichInput,
  Space
} from '@hse-design/react';
import { useHistory } from 'react-router';
import type { AuthStore } from './data/stores/AuthStore';
import s from './MapperAuth.module.scss';

export interface MapperAuthProps extends ModalProps {
  loading?: boolean;
  error?: string | null;
  onSubmit?: FormEventHandler;
}

const clientId = process.env.REACT_APP_MAPPER_AUTH_CLIENT_ID;
const clientSecret = process.env.REACT_APP_MAPPER_AUTH_CLIENT_SECRET;
const prefillEmail = process.env.REACT_APP_MAPPER_AUTH_PREFILL_EMAIL;
const prefillPassword = process.env.REACT_APP_MAPPER_AUTH_PREFILL_PASSWORD;

export function MapperAuth({
  className,
  error,
  isOpen,
  loading,
  onSubmit,
  ...props
}: MapperAuthProps) {
  const history = useHistory();
  return (
    <ModalForm
      className={clsx(className, s.MapperAuth__container)}
      overlayClassName={s.ModalOverlay}
      isOpen={isOpen}
      title={'Аутентификация'}
      onCloseClick={() => history.push('/')}
      {...props}
    >
      <form onSubmit={onSubmit}>
        <RichInput
          required
          name={'mapper_client_id'}
          label={'Client ID'}
          placeholder={'Client ID'}
          defaultValue={clientId}
          fullWidth
        />
        <Space size={Space.Size.small_3x} vertical />
        <RichInput
          required
          style={{ marginTop: 8 }}
          name={'mapper_client_secret'}
          type={'password'}
          label={'Client Password'}
          placeholder={'Client Password'}
          defaultValue={clientSecret}
          fullWidth
        />
        <Space size={Space.Size.small_3x} vertical />
        <RichInput
          required
          label={'E-mail'}
          name={'mapper_email'}
          type={'email'}
          placeholder={'E-mail'}
          defaultValue={prefillEmail}
          fullWidth
        />
        <Space size={Space.Size.small_3x} vertical />
        <RichInput
          required
          label={'Password'}
          name={'mapper_password'}
          type={'password'}
          placeholder={'Password'}
          defaultValue={prefillPassword}
          fullWidth
        />
        {error && <div className={s.MapperAuth__error}>{error}</div>}
        <Button
          className={s.MapperAuth__loginButton}
          spinner={loading}
          type="submit"
        >
          Войти
        </Button>
      </form>
    </ModalForm>
  );
}

export function useMapperAuth(authStore: AuthStore) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      const clientId = e.target.elements.mapper_client_id.value;
      const clientSecret = e.target.elements.mapper_client_secret.value;
      const email = e.target.elements.mapper_email.value;
      const password = e.target.elements.mapper_password.value;
      authStore
        .login({
          clientId,
          clientSecret,
          email,
          password
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setError(e?.message);
        });
    },
    [authStore]
  );
  return { error, loading, onSubmit };
}
