import React from 'react';
import Helmet from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { ToastCloseButton } from '@hse-design/react';
import AppRoutes from './routes';
import { YandexMetrika } from './common/utils/YandexMetrika';
import { useLocale } from './common/hooks/useLocale';

export default function App() {
  const localeStore = useLocale();

  return (
    <>
      <Helmet title={localeStore.t('html.title')} />
      <ToastContainer
        newestOnTop={true}
        closeButton={ToastCloseButton}
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={false}
      />
      <AppRoutes />
      <YandexMetrika />
    </>
  );
}
