import { IStorageValueAdapter } from '@proscom/prostore-local-storage';
import { defer } from 'rxjs';
import { rnMessenger } from '../../rnMessenger';
import { NativeMethods } from '../../utils/ReactNativeMessenger';

export const canUseNativeStorageAppVersion = '1.3.0';

export class NativeStorageAdapter implements IStorageValueAdapter<string> {
  constructor(protected readonly key: string) {}

  value$ = defer(() => {
    return rnMessenger
      .call(NativeMethods.getStoredValue, this.key)
      .then((result) => {
        if (typeof result === 'string') return result;
        return null;
      });
  });

  async setValue(value: string | null) {
    return rnMessenger.call(NativeMethods.setStoredValue, this.key, value);
  }
}
