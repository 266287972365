import { Tag, TagSize } from '@hse-design/react';
import React from 'react';
import clsx from 'clsx';
import { SearchCategoriesBaseProps } from '../SearchCategories/SearchCategories';
import s from './CategoriesList.module.scss';

export interface CategoriesListProps extends SearchCategoriesBaseProps {
  className?: string;
}

export function CategoriesList({
  className,
  categories,
  activeCategoryId,
  onCategoryClick
}: CategoriesListProps) {
  return (
    <div className={clsx(s.CategoriesList, className)}>
      {categories?.map((category) => {
        return (
          <Tag
            key={category.id}
            className={s.CategoriesList__item}
            component={'button'}
            type={'button'}
            size={TagSize.medium}
            clickable={true}
            selected={category.id === activeCategoryId}
            onClick={() => onCategoryClick?.(category)}
          >
            {category.name}
          </Tag>
        );
      })}
    </div>
  );
}
