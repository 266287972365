import React, { useCallback, useState } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { LeftPane } from '../../../../common/desktop/LeftPane/LeftPane';
import { SearchInput } from '../../mobile/SearchInput';
import { CategoriesList } from '../../components/CategoriesList/CategoriesList';
import { SearchCategories } from '../../components/SearchCategories/SearchCategories';
import { RoomSearchResults } from '../../components/RoomSearchResults/RoomSearchResults';
import { IRoomSearchResults } from '../../../../data/online/RoomSearchResults';
import {
  STORE_LOCATION,
  STORE_ROOM_SEARCH_RESULTS
} from '../../../../data/stores';
import {
  QUERY_KEY_END_ROOM,
  QUERY_KEY_END_SEARCH,
  QUERY_KEY_END_VERTEX,
  QUERY_KEY_ROOM,
  QUERY_KEY_SEARCH,
  QUERY_KEY_START_ROOM,
  QUERY_KEY_START_SEARCH,
  QUERY_KEY_START_VERTEX
} from '../../../../data/core/queryKeys';
import { RouteSearchConnected } from '../../components/RouteSearch/RouteSearchConnected';
import { RoomDto } from '../../../../data/api/RoomDto';
import { useOnScrollBottom } from '../../../../utils/hooks/useOnScrollBottom';
import { LeftPaneContent } from '../../../../common/desktop/LeftPaneContent/LeftPaneContent';
import s from './DesktopSearchPanel.module.scss';

export interface DesktopSearchPanelProps {
  onSelectOnMap?: (activePoint: 'A' | 'B') => void;
  onSelectCurrentLocation?: (activePoint: 'A' | 'B') => void;
}

export function DesktopSearchPanel({
  onSelectOnMap,
  onSelectCurrentLocation
}: DesktopSearchPanelProps) {
  const roomSearchResults = useContextStore<IRoomSearchResults>(
    STORE_ROOM_SEARCH_RESULTS
  );
  const locationStore = useContextStore<LocationStore>(STORE_LOCATION);
  const [activePoint, setActivePoint] = useState<'A' | 'B' | undefined>(
    undefined
  );

  const onScroll = useOnScrollBottom(roomSearchResults.requestNextPage);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => onScroll(event.nativeEvent),
    [onScroll]
  );

  const handleRoomClick = useCallback(
    (room: RoomDto) => {
      if (activePoint === 'A') {
        locationStore.changeQuery({
          [QUERY_KEY_START_ROOM]: room.id,
          [QUERY_KEY_START_VERTEX]: undefined,
          [QUERY_KEY_START_SEARCH]: undefined,
          [QUERY_KEY_SEARCH]: undefined
        });
      } else if (activePoint === 'B') {
        locationStore.changeQuery({
          [QUERY_KEY_END_ROOM]: room.id,
          [QUERY_KEY_END_VERTEX]: undefined,
          [QUERY_KEY_END_SEARCH]: undefined,
          [QUERY_KEY_SEARCH]: undefined
        });
      } else {
        locationStore.changeQuery({ [QUERY_KEY_ROOM]: room.id });
      }
    },
    [activePoint, locationStore]
  );

  return (
    <LeftPane className={s.DesktopSearchPanel} onScroll={handleScroll}>
      <LeftPaneContent>
        <SearchInput
          className={s.DesktopSearchPanel__search}
          onFocus={() => setActivePoint(undefined)}
        />
        <RouteSearchConnected
          activePoint={activePoint}
          onChangeActivePoint={setActivePoint}
          onSelectOnMap={onSelectOnMap}
          onSelectCurrentLocation={onSelectCurrentLocation}
        />
        <SearchCategories
          className={s.DesktopSearchPanel__categories}
          base={CategoriesList}
        />
        <RoomSearchResults onRoomClick={handleRoomClick} />
      </LeftPaneContent>
    </LeftPane>
  );
}
