import {
  ClickableIcon,
  ClickableIconProps,
  IconActionDelete
} from '@hse-design/react';
import React from 'react';

export function CloseButton(props: ClickableIconProps) {
  return (
    <ClickableIcon
      iconProps={{ icon: IconActionDelete }}
      color="#8691A8"
      {...props}
    />
  );
}
