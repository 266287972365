import {
  ClickableIcon,
  IconActionSearch,
  IconSize,
  Input,
  InputProps,
  InputSize
} from '@hse-design/react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useStore } from '@proscom/prostore-react';
import { useLocale } from '../../../common/hooks/useLocale';
import { RoomSearchStore } from '../../../data/stores/RoomSearchStore';
import { STORE_QR_SCANNER, STORE_ROOM_SEARCH } from '../../../data/stores';
import { QrScannerStore } from '../../../data/stores/QrScannerStore';
import { ReactComponent as IconQr } from '../../../assets/img/icons/i-qr-scan.svg';

export interface SearchInputProps extends InputProps {
  className?: string;
}

function useSearch() {
  const [roomSearch, roomSearchStore] = useStore<RoomSearchStore>(
    STORE_ROOM_SEARCH
  );

  const [search, setSearch] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    roomSearchStore.setSearch(search);
  };

  useEffect(() => {
    setSearch(roomSearch.search || '');
  }, [roomSearch.search]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value || '';
    setSearch(search);
    roomSearchStore.setSearch(search);
  };

  return {
    search,
    handleSubmit,
    handleChange
  };
}

function useQrScannerButton() {
  const [qrScannerState, qrScanner] = useStore<QrScannerStore>(
    STORE_QR_SCANNER
  );
  return (
    qrScannerState.available && (
      <ClickableIcon
        iconProps={{
          icon: IconQr,
          size: IconSize.medium
        }}
        onClick={qrScanner.scan}
      />
    )
  );
}

export function SearchInput({ className, ...props }: SearchInputProps) {
  const locale = useLocale();
  const right = useQrScannerButton();
  const { search, handleSubmit, handleChange } = useSearch();

  return (
    <form className={className} onSubmit={handleSubmit}>
      <Input
        type={'search'}
        leftIcon={IconActionSearch}
        placeholder={locale.t('search.placeholder')}
        size={InputSize.large}
        fullWidth={true}
        value={search}
        onChange={handleChange}
        right={right}
        {...props}
      />
    </form>
  );
}
