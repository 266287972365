import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObservableStore } from '@proscom/prostore';
import { CampusesStore } from './CampusesStore';
import { CampusStore } from './CampusStore';

export type SelectedCampusFloorsState = number[];

export class SelectedCampusFloors extends ObservableStore<SelectedCampusFloorsState> {
  constructor(
    private readonly deps: {
      campuses: CampusesStore;
      campus: CampusStore;
    }
  ) {
    super(
      combineLatest([deps.campuses.state$, deps.campus.state$]).pipe(
        map(([campuses, campus]) => {
          return (campus && campuses.data?.floors[campus.code]) || [];
        })
      ),
      []
    );
  }
}
