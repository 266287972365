import { createElement } from 'react';
import { toast } from 'react-toastify';
import { BehaviorStore } from '@proscom/prostore';
import { Toast, ToastVariant } from '@hse-design/react';

export type IToastId = string | number;
export type ToastProviderFn = (({
  title,
  description,
  variant
}: {
  title: string;
  description?: string;
  variant: ToastVariant;
}) => IToastId) & { dismiss?: (toastId?: IToastId) => void };

export interface ToastStoreState {
  toast?: ToastProviderFn;
}

export type ShowToast = {
  title: string;
  description?: string;
};
export type ToastMessageOrObject = ShowToast | string;

const toastify: ToastProviderFn = ({ title, description, variant }) =>
  toast(
    createElement(Toast, {
      title,
      description,
      variant
    })
  );
toastify.dismiss = toast.dismiss;

export class ToastStore extends BehaviorStore<ToastStoreState> {
  constructor() {
    super({
      toast: toastify
    });
  }

  message(
    variant: ToastVariant = ToastVariant.info,
    args: ToastMessageOrObject
  ): void {
    let title, description;
    if (typeof args === 'string') {
      title = args;
    } else {
      title = args.title;
      description = args.description;
    }
    this.state.toast?.({ title, description, variant });
  }

  error(args: ToastMessageOrObject) {
    this.message(ToastVariant.error, args);
  }

  success(args: ToastMessageOrObject) {
    this.message(ToastVariant.success, args);
  }

  warning(args: ToastMessageOrObject) {
    this.message(ToastVariant.warning, args);
  }

  info(args: ToastMessageOrObject) {
    this.message(ToastVariant.info, args);
  }

  dismiss(toastId: IToastId) {
    this.state.toast?.dismiss?.(toastId);
  }
}
