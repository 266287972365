import { ValueStore } from '@proscom/prostore';

export enum HoverItemType {
  edge = 'edge',
  node = 'node'
}

export interface HoverItemInfo {
  type: HoverItemType;
  id: number;
}

export type HoverItemState = HoverItemInfo | null;

export class HoverItem extends ValueStore<HoverItemState> {
  constructor() {
    super(null);
  }

  public reset = () => {
    this.setState(null);
  };

  public setHoverEdge = (id: number) => {
    this.setState({
      type: HoverItemType.edge,
      id
    });
  };

  public setHoverNode = (id: number) => {
    this.setState({
      type: HoverItemType.node,
      id
    });
  };
}
