import { SubscriptionManager, ValueStore } from '@proscom/prostore';
import { EditMode } from '../types';
import { ToastStore } from '../../../../data/core/ToastStore';
import { Effect } from '../../../../utils/prostore/Effect';
import { IVertexData } from './NodesStore';
import { EdgeAddOperation } from './EdgeAddOperation';

export class ModeConnectNodes
  extends ValueStore<IVertexData[]>
  implements Effect {
  sub = new SubscriptionManager();

  constructor(
    private readonly editorMode: ValueStore<EditMode>,
    private readonly toast: ToastStore,
    private readonly edgeAddOperation: EdgeAddOperation
  ) {
    super([]);
    this.state$.subscribe(console.log);
  }

  on() {
    this.setState([]);
  }

  off() {
    this.setState([]);
  }

  public setFirstNode(node: IVertexData) {
    this.setState([node]);
  }

  public setSecondNode(node: IVertexData) {
    if (this.state.length !== 1) {
      this.toast.error({
        title: 'Вершина не была выбрана',
        description: 'Попробуйте ещё раз'
      });
      this.setState([]);
      return;
    }

    const firstNode = this.state[0];

    if (node.id === firstNode.id) {
      this.toast.error({
        title: 'Выберите другую вершину',
        description: 'Вершина должна быть отличной от текущей'
      });
      return;
    }

    this.setState([firstNode, node]);
    this.edgeAddOperation.run(this.state);
    this.editorMode.setState(EditMode.DEFAULT);
    this.setState([]);
  }

  public handleClick(node: IVertexData) {
    if (this.state.length === 0) {
      this.setFirstNode(node);
      return;
    }
    this.setSecondNode(node);
  }

  public clear() {
    this.setState([]);
  }
}
