import { map } from 'rxjs/operators';
import { ObservableStore } from '../../utils/prostore/ObservableStore';
import { IRouteQueryStore } from '../online/RouteQueryStore';
import { sum } from '../../utils/sum';

export interface RouteInfoInfo {
  distance: number;
  time: number;
}

export type RouteInfoState = RouteInfoInfo | null;

export class RouteInfo extends ObservableStore<RouteInfoState> {
  constructor(routeStore: IRouteQueryStore) {
    super(() => {
      return routeStore.state$.pipe(
        map((route) => {
          const directions = route.data?.directions;
          if (directions) {
            const distance = Math.ceil(
              sum(directions, (segment) => +segment.cost)
            );
            const time = Math.ceil(
              sum(directions, (segment) => +segment.cost_time)
            );

            return { distance, time };
          }

          return null;
        })
      );
    }, null);
  }
}
