import { useContextStore, useStoreState } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { useCallback } from 'react';
import {
  STORE_LOCATION,
  STORE_ROOM,
  STORE_SEARCH
} from '../../../../data/stores';
import { SearchStore } from '../../../../data/stores/SearchStore';
import {
  QUERY_KEY_CODE,
  QUERY_KEY_END_ROOM,
  QUERY_KEY_END_SEARCH,
  QUERY_KEY_END_VERTEX,
  QUERY_KEY_ROOM,
  QUERY_KEY_START_ROOM,
  QUERY_KEY_START_SEARCH,
  QUERY_KEY_START_VERTEX
} from '../../../../data/core/queryKeys';
import { RoomStoreState } from '../../../../data/online/RoomStore';
import { SelectOnMapView } from './SelectOnMapView';

export interface SelectOnMapPanelContentProps {
  selectInMap: 'A' | 'B';
  setStateSelectMap?: (state: 'A' | 'B' | undefined) => void;
}

export function SelectOnMapPanelContent({
  selectInMap,
  setStateSelectMap
}: SelectOnMapPanelContentProps) {
  const roomState = useStoreState<RoomStoreState>(STORE_ROOM);
  const room = roomState.data;
  const isLoading = roomState.loading;
  const locationStore = useContextStore<LocationStore>(STORE_LOCATION);
  const searchStore = useContextStore<SearchStore>(STORE_SEARCH);

  const onRouteSelect = useCallback(() => {
    const fnsSettingRoute = {
      A: () =>
        locationStore.changeQuery({
          [QUERY_KEY_START_ROOM]: room?.id,
          [QUERY_KEY_START_VERTEX]: undefined,
          [QUERY_KEY_START_SEARCH]: undefined,
          [QUERY_KEY_ROOM]: undefined,
          [QUERY_KEY_CODE]: undefined
        }),
      B: () =>
        locationStore.changeQuery({
          [QUERY_KEY_END_ROOM]: room?.id,
          [QUERY_KEY_END_VERTEX]: undefined,
          [QUERY_KEY_END_SEARCH]: undefined,
          [QUERY_KEY_ROOM]: undefined,
          [QUERY_KEY_CODE]: undefined
        })
    };

    if (selectInMap) {
      fnsSettingRoute[selectInMap]();
    }

    setStateSelectMap?.(undefined);
  }, [room, selectInMap, locationStore, setStateSelectMap]);

  return (
    <SelectOnMapView
      setStateSelectMap={setStateSelectMap}
      onRouteSelect={onRouteSelect}
      selectInMap={selectInMap}
      room={room || undefined}
      isLoading={isLoading}
    />
  );
}
