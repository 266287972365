import {
  BehaviorStore,
  IRequestState,
  SubscriptionManager
} from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { distinctUntilChanged, filter, map, skip } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { RoomQueryData, RoomQueryVars } from '../api/common';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import { RoomDto } from '../api/RoomDto';
import {
  QUERY_KEY_END_ROOM,
  QUERY_KEY_END_VERTEX,
  QUERY_KEY_START_ROOM,
  QUERY_KEY_START_VERTEX,
  QUERY_KEY_STEP
} from '../core/queryKeys';
import { IRoomQuery } from '../online/RoomQuery';
import { SearchStore } from './SearchStore';

export interface RouteStoreState {
  routeStartPoint: RoomDto | undefined;
  routeEndPoint: RoomDto | undefined;
  isOnRoute: boolean;
}

const initialState: RouteStoreState = {
  routeStartPoint: undefined,
  routeEndPoint: undefined,
  isOnRoute: false
};

export interface RouteStoreArgs {
  locationStore: LocationStore;
  searchStore: SearchStore;
  startRoom: IRoomQuery;
  endRoom: IRoomQuery;
}

export class RouteStore extends BehaviorStore<RouteStoreState> {
  sub = new SubscriptionManager();
  locationStore: LocationStore;
  searchStore: SearchStore;
  startRoomQuery: IRoomQuery;
  endRoomQuery: IRoomQuery;

  constructor({
    locationStore,
    searchStore,
    startRoom,
    endRoom
  }: RouteStoreArgs) {
    super(initialState);
    this.locationStore = locationStore;
    this.searchStore = searchStore;

    this.startRoomQuery = startRoom;
    this.endRoomQuery = endRoom;
  }

  onSubscribe() {
    // Определяем факт просмотра маршрута через наличие квери-параметра step
    this.sub.subscribe(
      locationStoreGet$(
        this.locationStore,
        QUERY_KEY_START_ROOM,
        QUERY_KEY_START_VERTEX,
        QUERY_KEY_END_ROOM,
        QUERY_KEY_END_VERTEX,
        QUERY_KEY_STEP
      ).pipe(
        map((query) => {
          return (
            query[QUERY_KEY_STEP] !== undefined &&
            (!!query[QUERY_KEY_START_ROOM] ||
              !!query[QUERY_KEY_START_VERTEX]) &&
            (!!query[QUERY_KEY_END_ROOM] || !!query[QUERY_KEY_END_VERTEX])
          );
        }),
        distinctUntilChanged()
      ),
      (isOnRoute) => {
        this.setState({
          isOnRoute
        });
      }
    );

    // Когда выбираем стартовую или конечную комнаты, сбрасываем выбранную категорию.
    // Это нужно для двух целей:
    // 1. Чтобы при вводе поискового запроса точки B после точки A не было ограничения по категории
    // 2. Чтобы при переходе к маршруту после выбора точки B убрать подсветку комнат категории
    this.sub.subscribe(
      locationStoreGet$(
        this.locationStore,
        QUERY_KEY_START_ROOM,
        QUERY_KEY_END_ROOM
      ).pipe(
        distinctUntilChanged(isEqual),
        // Ждём загрузки данных
        filter((value) => Object.keys(value).length > 0),
        // Пропускаем первоначальные значения
        skip(1)
      ),
      (query) => {
        if (query[QUERY_KEY_START_ROOM] || query[QUERY_KEY_END_ROOM]) {
          this.searchStore.unsetSelectedCategory();
        }
      }
    );

    // Копируем результаты запросов в общее состояние стора
    this.sub.subscribe(
      this.startRoomQuery.state$,
      this.handleStartRoomQueryChange
    );
    this.sub.subscribe(this.endRoomQuery.state$, this.handleEndRoomQueryChange);
  }

  onUnsubscribe() {
    this.sub.destroy();
  }

  handleStartRoomQueryChange = (
    state: IRequestState<RoomQueryVars | null, RoomQueryData | null>
  ) => {
    if (!state.loaded) {
      return;
    }
    this.setState({
      routeStartPoint: state.data || undefined
    });
  };

  handleEndRoomQueryChange = (
    state: IRequestState<RoomQueryVars | null, RoomQueryData | null>
  ) => {
    if (!state.loaded) {
      return;
    }
    this.setState({
      routeEndPoint: state.data || undefined
    });
  };

  unsetRoute = () => {
    const { query } = this.locationStore.state;
    if (
      query &&
      (!!query[QUERY_KEY_START_ROOM] ||
        !!query[QUERY_KEY_START_VERTEX] ||
        !!query[QUERY_KEY_END_ROOM] ||
        !!query[QUERY_KEY_END_VERTEX] ||
        query[QUERY_KEY_STEP] !== undefined)
    ) {
      this.locationStore.changeQuery({
        startRoom: undefined,
        endRoom: undefined,
        startVertex: undefined,
        endVertex: undefined,
        step: undefined
      });
    }
  };

  changeRouteStep = (step: number | undefined) => {
    const queryStep = this.locationStore.state.query?.[QUERY_KEY_STEP];
    if (step !== queryStep) {
      this.locationStore.changeQuery({ [QUERY_KEY_STEP]: step });
    }
  };

  startRoute = () => {
    this.changeRouteStep(1);
  };

  closeRoute = () => {
    this.unsetRoute();
    this.changeRouteStep(undefined);
  };
}
