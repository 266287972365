import { SubscriptionManager } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { combineLatest } from 'rxjs';
import { Effect } from '../../utils/prostore/Effect';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import { QUERY_KEY_CODE, QUERY_KEY_ROOM } from '../core/queryKeys';
import { IRoomStore } from '../online/RoomStore';

export class RoomCodeEffect implements Effect {
  protected sub = new SubscriptionManager();

  constructor(
    private readonly room: IRoomStore,
    private readonly location: LocationStore
  ) {}

  public on() {
    // Если в адресной строке задан код комнаты, то меняем его на id как только его узнаем
    this.sub.subscribe(
      combineLatest([
        this.room.state$,
        locationStoreGet$(this.location, QUERY_KEY_ROOM, QUERY_KEY_CODE)
      ]),
      ([state, query]) => {
        if (state.loading) return;
        const queryRoom = query[QUERY_KEY_ROOM];
        const queryCode = query[QUERY_KEY_CODE];
        if (!(queryCode && !queryRoom)) return;

        const room = state.data;
        const roomCode = state.variables?.params?.code;

        if (state.loaded && roomCode === queryCode) {
          if (room && room.code === queryCode) {
            this.location.changeQuery(
              {
                [QUERY_KEY_ROOM]: room.id,
                [QUERY_KEY_CODE]: undefined
              },
              true
            );
          } else {
            console.error(
              `RoomCodeEffect. Room with code '${queryCode}' not found`
            );
            this.location.changeQuery(
              {
                [QUERY_KEY_CODE]: undefined
              },
              true
            );
          }
        }
      }
    );
  }

  public off() {
    this.sub.destroy();
  }
}
