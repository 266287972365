import { StoreOrName, useStore } from '@proscom/prostore-react';
import { AsyncOperationStore } from '../prostore/AsyncOperationStore';

export function useClassAsyncOperation<Store extends AsyncOperationStore>(
  operation: StoreOrName<Store>
) {
  const [state, store] = useStore<Store>(operation);
  return {
    finished: state.finished,
    loading: state.loading,
    run: store.run,
    setFinished: store.setFinished
  };
}
