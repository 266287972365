import { useContextStore } from '@proscom/prostore-react';
import React from 'react';
import { LeftPane } from '../../../../common/desktop/LeftPane/LeftPane';
import { LeftPaneTitle } from '../../../../common/desktop/LeftPaneTitle/LeftPaneTitle';
import { CurrentLocationStore } from '../../../../data/stores/CurrentLocationStore';
import { STORE_CURRENT_LOCATION } from '../../../../data/stores';
import { MyLocationPanelContent } from '../../components/MyLocationPanelContent/MyLocationPanelContent';
import { useLocale } from '../../../../common/hooks/useLocale';
import { LeftPaneContent } from '../../../../common/desktop/LeftPaneContent/LeftPaneContent';

export function DesktopMyLocationPanel() {
  const locale = useLocale();
  const currentLocationStore = useContextStore<CurrentLocationStore>(
    STORE_CURRENT_LOCATION
  );
  const handleClose = currentLocationStore.resetSelection;

  return (
    <LeftPane>
      <LeftPaneContent>
        <LeftPaneTitle onClose={handleClose}>
          {locale.t('currentLocation.title')}
        </LeftPaneTitle>
        <MyLocationPanelContent />
      </LeftPaneContent>
    </LeftPane>
  );
}
