import React, { useCallback, useState } from 'react';
import {
  useContextStore,
  useObservableState,
  useStore,
  useStoreState
} from '@proscom/prostore-react';
import { EMDASH } from '@proscom/ui-utils';
import { Route, Switch } from 'react-router';
import Helmet from 'react-helmet';
import {
  STORE_CAMPUS,
  STORE_CAMPUSES,
  STORE_CURRENT_LOCATION,
  STORE_ROOM,
  STORE_ROUTE,
  STORE_ROUTE_ACTIVE,
  STORE_ROUTE_GRAPH_DIRECTION,
  STORE_ROUTE_STEPS,
  STORE_WINDOW_SIZE
} from '../../data/stores';
import { getRoomName } from '../../data/Room';
import { Page } from '../../common/components/Page/Page';
import {
  QUERY_KEY_END_VERTEX,
  QUERY_KEY_EVENT,
  QUERY_KEY_FLOOR,
  QUERY_KEY_START_VERTEX,
  QUERY_KEY_STEP
} from '../../data/core/queryKeys';
import { useLocationQuery } from '../../common/hooks/useLocationQuery';
import { RouteStore } from '../../data/stores/RouteStore';
import { CampusesStoreState } from '../../data/stores/CampusesStore';
import { RoomStoreState } from '../../data/online/RoomStore';
import { MapBox } from '../../common/components/MapBox/MapBox';
import Header from '../../common/desktop/Header/Header';
import { MobileNavigation } from '../../common/mobile/MobileNavigation/MobileNavigation';
import {
  WindowSizeBreakpoint,
  WindowSizeStore
} from '../../data/core/WindowSizeStore';
import { CurrentLocationState } from '../../data/stores/CurrentLocationStore';
import { IRouteQueryStore } from '../../data/online/RouteQueryStore';
import { useLocale } from '../../common/hooks/useLocale';
import { CampusDto } from '../../data/api/CampusDto';
import { RouteStepsState } from '../../data/stores/RouteSteps';
import { useCampusChangeEffect } from './hooks/useCampusChangeEffect';
import { useRoomChangeEffect } from './hooks/useRoomChangeEffect';
import { useStepChangeEffect } from './hooks/useStepChangeEffect';
import { LanguagePage } from './routes/settings/routes/language/LanguagePage';
import { SelectCampusPage } from './routes/selectCampus/SelectCampusPage';
import { SearchPanel } from './mobile/SearchPanel/SearchPanel';
import { RoutePanel } from './mobile/RoutePanel/RoutePanel';
import { SelectOnMapPanel } from './mobile/SelectOnMapPanel/SelectOnMapPanel';
import { RoomPanel } from './mobile/RoomPanel/RoomPanel';
import { MyLocationPanel } from './mobile/MyLocationPanel/MyLocationPanel';
import { DesktopSearchPanel } from './desktop/DesktopSearchPanel/DesktopSearchPanel';
import { DesktopRoomPanel } from './desktop/DesktopRoomPanel/DesktopRoomPanel';
import { DesktopMyLocationPanel } from './desktop/DesktopCurrentLocationPanel/DesktopMyLocationPanel';
import { DesktopSelectOnMapPanel } from './desktop/DesktopSelectOnMapPanel/DesktopSelectOnMapPanel';
import { DesktopRoutePanel } from './desktop/DesktopRoutePanel/DesktopRoutePanel';
import { OfflinePage } from './routes/settings/routes/offline/OfflinePage';

const IndexPageComponent = React.memo(function IndexPageComponent() {
  const localeStore = useLocale();
  const [routeStoreState] = useStore<RouteStore>(STORE_ROUTE);
  const campusesStoreState = useStoreState<CampusesStoreState>(STORE_CAMPUSES);
  const campuses =
    (campusesStoreState.data && campusesStoreState.data.data) || undefined;
  const campusesFloors =
    campusesStoreState.data && campusesStoreState.data.floors;
  const selectedCampus = useStoreState<CampusDto>(STORE_CAMPUS);
  const roomStoreState = useStoreState<RoomStoreState>(STORE_ROOM);
  const currentLocationStoreState = useStoreState<CurrentLocationState>(
    STORE_CURRENT_LOCATION
  );
  const roomLoading = roomStoreState.loading;
  const room = roomStoreState.data;

  const [query, locationStore] = useLocationQuery([
    QUERY_KEY_EVENT,
    QUERY_KEY_FLOOR,
    QUERY_KEY_STEP
  ] as const);

  const windowSizeStore = useContextStore<WindowSizeStore>(STORE_WINDOW_SIZE);
  const breakpoint = useObservableState(
    windowSizeStore.breakpoint$,
    windowSizeStore.state.breakpoint
  );
  const isDesktopVersion = breakpoint === WindowSizeBreakpoint.desktop;

  // Какой пункт меню сейчас выбираем 'A' или 'B'
  const [selectInMap, setStateSelectMap] = useState<'A' | 'B' | undefined>(
    undefined
  );

  // ROUTE
  const [routeDirections] = useStore<IRouteQueryStore>(
    STORE_ROUTE_GRAPH_DIRECTION
  );

  const route = routeDirections.data?.route;
  const isRouteActive = useStoreState<boolean>(STORE_ROUTE_ACTIVE);
  const routeSteps = useStoreState<RouteStepsState>(STORE_ROUTE_STEPS);

  const hasRoute =
    routeStoreState.routeStartPoint && routeStoreState.routeEndPoint;
  const hasRoomSelected = !!room;

  const floorNumber = query[QUERY_KEY_FLOOR];
  const selectedRouteStep =
    route && routeStoreState.isOnRoute
      ? routeSteps?.find((s) => s.index === query[QUERY_KEY_STEP])
      : null;

  // Смена этажа на первый при смене кампуса
  useCampusChangeEffect({
    campusesFloors,
    floorNumber,
    locationStore,
    selectedCampus
  });
  // Смена этажа и кампуса под выбранную комнату
  useRoomChangeEffect({
    floorNumber,
    locationStore,
    room: room || undefined
  });
  // Смена этажа и кампуса под выбранный участок маршрута
  useStepChangeEffect({
    floorNumber,
    locationStore,
    campuses,
    selectedRouteStep,
    selectedCampusId: selectedCampus && selectedCampus.id
  });

  const overrideTitle = room
    ? getRoomName(room, true, localeStore) +
      ' ' +
      EMDASH +
      ' ' +
      localeStore.t('html.title')
    : localeStore.t('html.title');

  const setCurrentPinAsActivePoint = useCallback(
    (activePoint: 'A' | 'B') => {
      if (activePoint === 'A') {
        locationStore.changeQuery({
          [QUERY_KEY_START_VERTEX]: currentLocationStoreState?.location?.id
        });
      } else if (activePoint === 'B') {
        locationStore.changeQuery({
          [QUERY_KEY_END_VERTEX]: currentLocationStoreState?.location?.id
        });
      }
    },
    [currentLocationStoreState, locationStore]
  );

  return (
    <Page>
      {overrideTitle && <Helmet title={overrideTitle} />}
      {isDesktopVersion && <Header />}
      <MapBox
        route={route}
        routeSteps={routeSteps}
        isDesktopVersion={isDesktopVersion}
      />
      {!roomLoading && selectInMap && isDesktopVersion && (
        <DesktopSelectOnMapPanel
          setStateSelectMap={setStateSelectMap}
          selectInMap={selectInMap}
        />
      )}
      {!roomLoading &&
        !selectInMap &&
        !hasRoute &&
        !hasRoomSelected &&
        isDesktopVersion && (
          <DesktopSearchPanel
            onSelectOnMap={setStateSelectMap}
            onSelectCurrentLocation={setCurrentPinAsActivePoint}
          />
        )}
      {isRouteActive && isDesktopVersion && <DesktopRoutePanel />}
      {isDesktopVersion &&
        currentLocationStoreState?.isSelected &&
        Boolean(currentLocationStoreState?.location) && (
          <DesktopMyLocationPanel />
        )}
      {(roomLoading || hasRoomSelected) && !selectInMap && isDesktopVersion && (
        <DesktopRoomPanel />
      )}

      {!isDesktopVersion && (
        <>
          <Switch>
            <Route path={'/select-campus'} component={SelectCampusPage} />
            <Route path={'/settings/language'} component={LanguagePage} />
            <Route path={'/settings/offline'} component={OfflinePage} />
          </Switch>
          <SearchPanel />
          <RoutePanel
            onSelectOnMap={setStateSelectMap}
            onSelectCurrentLocation={setCurrentPinAsActivePoint}
          />
          <SelectOnMapPanel
            selectInMap={selectInMap}
            setStateSelectMap={setStateSelectMap}
          />
          <RoomPanel
            selectInMap={selectInMap}
            room={room || undefined}
            isLoading={roomLoading}
          />
          <MyLocationPanel />
          <MobileNavigation />
        </>
      )}
    </Page>
  );
});

export default function IndexPage() {
  return <IndexPageComponent />;
}
