import CanvasMath from './CanvasMath';

export default class CanvasControls extends CanvasMath {
  leftButtonPressed = false;
  isSingleTouch = false;

  cursorOffset = null;
  moveStartCursorOffset = null;

  pinchAmplitude = undefined;

  baseZoomCoef = 1.1;

  dragBaseUnit = 1;

  getCursorOffset = (event) => {
    if (event.targetTouches && event.targetTouches.length) {
      return {
        x: event.targetTouches[0].clientX,
        y: event.targetTouches[0].clientY
      };
    } else if (event.changedTouches) {
      return {
        x: event.changedTouches[0].clientX,
        y: event.changedTouches[0].clientY
      };
    } else {
      return {
        x: event.offsetX,
        y: event.offsetY
      };
    }
  };

  zoom = (zoomCoef, zoomCenter) => {
    const oldScale = this.scale;
    this.updateScale(zoomCoef);

    if (oldScale !== this.scale) {
      const newMargin = this.getMarginOfCursor({
        pointer: zoomCenter,
        newScale: this.scale,
        oldScale
      });

      this.setX(newMargin.x);
      this.setY(newMargin.y);
    }
  };

  wheelEventHandler = (event, afterZoomHandler) => {
    event.preventDefault();
    const zoomDirection = -event.deltaY / Math.abs(event.deltaY);
    const zoomCoef = Math.pow(this.baseZoomCoef, zoomDirection);
    this.zoom(zoomCoef, { x: event.offsetX, y: event.offsetY });
    afterZoomHandler();
  };

  moveEventHandler = (event) => {
    const newCursorOffset = this.getCursorOffset(event);
    if (this.cursorOffset) {
      const diffX = newCursorOffset.x - this.cursorOffset.x;
      const diffY = newCursorOffset.y - this.cursorOffset.y;
      this.cursorOffset = newCursorOffset;
      this.upMarginX((diffX * this.dragBaseUnit) / this.scale);
      this.upMarginY((diffY * this.dragBaseUnit) / this.scale);
    } else {
      this.cursorOffset = newCursorOffset;
    }
  };

  mouseDownEventHandler = (event, mouseMoveEventHandler) => {
    event.preventDefault();
    this.leftButtonPressed = event.button === 0;
    this.cursorOffset = this.getCursorOffset(event);
    this.moveStartCursorOffset = this.cursorOffset;
    event.target.addEventListener('mousemove', mouseMoveEventHandler);
  };

  mouseUpEventHandler = (event, moveEventHandler, pureClickEventHandler) => {
    event.preventDefault();

    if (this.leftButtonPressed) {
      this.leftButtonPressed = false;
      const newCursorOffset = this.getCursorOffset(event);
      const movedDistance = this.getDistance(
        newCursorOffset,
        this.moveStartCursorOffset
      );
      if (movedDistance <= 10) {
        pureClickEventHandler(event);
      }
    }

    this.cursorOffset = null;
    this.moveStartCursorOffset = null;
    event.target.removeEventListener('mousemove', moveEventHandler);
  };

  touchMoveEventHandler = (event, afterZoomHandler) => {
    if (event.targetTouches.length === 1) {
      this.moveEventHandler(event);
    } else if (event.targetTouches.length === 2) {
      this.isSingleTouch = false;

      const touches = event.targetTouches;

      const newPinchAmplitude = this.getDistance(
        {
          x: touches[0].clientX,
          y: touches[0].clientY
        },
        {
          x: touches[1].clientX,
          y: touches[1].clientY
        }
      );

      if (this.pinchAmplitude) {
        const zoomCenter = {
          x: (touches[0].clientX + touches[1].clientX) / 2,
          y: (touches[0].clientY + touches[1].clientY) / 2
        };
        const zoomCoef = newPinchAmplitude / this.pinchAmplitude;
        this.zoom(zoomCoef, zoomCenter);
        afterZoomHandler();
      }

      this.pinchAmplitude = newPinchAmplitude;
    }
  };

  touchStartEventHandler = (event, touchMoveEventHandler) => {
    event.preventDefault();
    this.isSingleTouch = event.targetTouches.length === 1;
    this.cursorOffset = this.getCursorOffset(event);
    this.moveStartCursorOffset = this.cursorOffset;
    event.target.addEventListener('touchmove', touchMoveEventHandler);
  };

  touchEndEventHandler = (event, moveEventHandler, pureTouchEventHandler) => {
    event.preventDefault();

    if (this.isSingleTouch) {
      this.isSingleTouch = false;
      const newCursorOffset = this.getCursorOffset(event);
      const movedDistance = this.getDistance(
        newCursorOffset,
        this.moveStartCursorOffset
      );
      if (movedDistance <= 10) {
        pureTouchEventHandler(event);
      }
    }

    this.cursorOffset = null;
    this.moveStartCursorOffset = null;
    this.pinchAmplitude = null;
    event.target.removeEventListener('touchmove', moveEventHandler);
  };
}
