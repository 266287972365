import clsx from 'clsx';
import React from 'react';
import s from './MobileNavBar.module.scss';

export interface MobileNavButtonProps {
  text?: React.ReactNode;
  icon?: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

export function MobileNavButton({
  text,
  icon,
  isActive,
  onClick
}: MobileNavButtonProps) {
  return (
    <div
      className={clsx(s.MobileNavButton, {
        [s.MobileNavButton_active]: isActive
      })}
      onClick={onClick}
    >
      <div className={s.MobileNavButton__icon}>{icon}</div>
      <p className={s.MobileNavButton__text}>{text}</p>
    </div>
  );
}
