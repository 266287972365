import { GetRequestStoreState } from '@proscom/prostore';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Truthy } from '@proscom/ui-utils';
import {
  CampusesStoreOffline,
  CampusesStoreOfflineState,
  IOfflineCampusInfo
} from '../offline/stores/CampusesStoreOffline';
import { IQrCodeResolver } from '../online/QrCodeResolver';
import { IQrInfo } from './LocationQrEffect';
import { CampusesStore } from './CampusesStore';

type WithTruthyKey<T extends {}, Key extends string> = {
  [TKey in keyof T]: TKey extends Key ? Truthy<T[TKey]> : T[TKey];
};

export class QrCodeResolverOffline implements IQrCodeResolver {
  constructor(
    private readonly campusesStoreOffline: CampusesStoreOffline,
    private readonly campuses: CampusesStore
  ) {}

  public async load(qr: string): Promise<IQrInfo | null> {
    const campuses = await this.waitFor(
      this.campuses.state$,
      (
        state
      ): state is WithTruthyKey<GetRequestStoreState<CampusesStore>, 'data'> =>
        !!state.data
    );

    const campusesState = await this.waitFor(
      this.campusesStoreOffline.state$,
      (state): state is WithTruthyKey<CampusesStoreOfflineState, 'campuses'> =>
        !!state.campuses
    );

    const info = this.findQrInfo(campusesState.campuses, qr);

    if (!info) {
      return null;
    }

    const campus = campuses.data.data.find((c) => c.code === info[0].campus);
    const vertex = info[0].graph.getVertex(info[1].vertex_id);

    if (!vertex) {
      return null;
    }

    const room = info[0].rooms.find((r) => r.id === vertex.room_id);

    const data: IQrInfo = {
      qr,
      campus,
      vertex: {
        ...vertex,
        room
      }
    };

    return data;
  }

  private findQrInfo(campuses: IOfflineCampusInfo[], qr: string) {
    for (const campus of campuses) {
      for (const campusQr of campus.qrs) {
        if (campusQr.qr === qr) {
          return [campus, campusQr] as const;
        }
      }
    }

    return null;
  }

  private waitFor<T, K extends T>(
    observable: Observable<T>,
    predicate: (x: T) => x is K
  ) {
    return observable.pipe(filter(predicate), take(1)).toPromise();
  }
}
