import React, { useCallback, useMemo } from 'react';
import { useContextStore, useStore } from '@proscom/prostore-react';
import { IconNavigationDirection } from '@hse-design/react';
import { NavStore } from '../../../data/core/NavStore';
import {
  STORE_ACTIVE_PANEL,
  STORE_NAV,
  STORE_QR_SCANNER
} from '../../../data/stores';
import { ActivePanel } from '../../../data/core/ActivePanel';
import { ReactComponent as IconBurger } from '../../../assets/img/icons/i-menu.svg';
import { ReactComponent as IconSearch } from '../../../assets/img/icons/i-search.svg';
import { ReactComponent as IconQr } from '../../../assets/img/icons/i-qr-scan.svg';
import { useLocale } from '../../hooks/useLocale';
import { rnMessenger } from '../../../rnMessenger';
import { QrScannerStore } from '../../../data/stores/QrScannerStore';
import { qrMock } from '../../../config';
import { MobileNavBar } from './MobileNavBar/MobileNavBar';
import { SideMenu } from './SideMenu/SideMenu';

const mobileNavSections = [
  {
    id: ActivePanel.PANEL_MENU,
    icon: <IconBurger />
  },
  {
    id: ActivePanel.PANEL_SEARCH,
    icon: <IconSearch />
  },
  {
    id: ActivePanel.PANEL_ROUTE,
    icon: <IconNavigationDirection size={'medium'} />
  },
  {
    id: 'qr',
    icon: <IconQr />,
    onlyApp: !qrMock
  }
];

export function MobileNavigation() {
  const [activePanel, activePanelStore] = useStore<ActivePanel>(
    STORE_ACTIVE_PANEL
  );
  const qrScanner = useContextStore<QrScannerStore>(STORE_QR_SCANNER);
  const navStore = useContextStore<NavStore>(STORE_NAV);
  const mobileNavActive =
    activePanel === null || activePanel === ActivePanel.PANEL_MENU;

  const handleSectionChange = useCallback(
    (id) => {
      if (id === 'qr') {
        qrScanner.scan();
      } else {
        activePanelStore.setPanel(id);
        navStore.setSectionId(id);
      }
    },
    [activePanelStore, navStore, qrScanner]
  );

  const handleCloseMenu = useCallback(() => {
    handleSectionChange(null);
  }, [handleSectionChange]);

  const locale = useLocale();
  const isApp = rnMessenger.isActive;
  const sections = useMemo(() => {
    return mobileNavSections
      .filter((s) => !s.onlyApp || isApp)
      .map((section) => ({
        ...section,
        text: locale.t(`nav.${section.id}`)
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApp, locale, locale.state.localeId]);

  return (
    <>
      <SideMenu
        isActive={activePanel === ActivePanel.PANEL_MENU}
        onClose={handleCloseMenu}
      />
      <MobileNavBar
        isActive={mobileNavActive}
        activeSectionId={activePanel}
        onSectionChange={handleSectionChange}
        sections={sections}
      />
    </>
  );
}
