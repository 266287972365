import type { AxiosInstance } from 'axios';
import type { LocationStore } from '@proscom/prostore-react-router';
import {
  BehaviorStore,
  skipIfNull,
  SubscriptionManager
} from '@proscom/prostore';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { combineLatest } from 'rxjs';
import immer from 'immer';
import type { CampusStore } from '../../../../data/stores/CampusStore';
import { EntityEchoCallbacks, MapEntityMutations } from '../entities/MapEntity';
import {
  QUERY_KEY_CAMPUS,
  QUERY_KEY_FLOOR
} from '../../../../data/core/queryKeys';
import { GetQueryVars } from '../../../../data/api/common';
import { VertexType } from '../../../../data/VertexType';
import type { AuthStore } from './AuthStore';

const baseUrl = '/api/vertices';

export interface INodesStoreState {
  campus_id: number | null;
  floor: number | null;
  data: Record<IVertexData['id'], IVertexData> | null;
}

/**
 * Стор с нодами от сервера
 */
class NodesQueryStore extends AxiosQueryStore<
  GetQueryVars<{ campus_id: number }> | null,
  INodesStoreState['data']
> {
  constructor(axiosInstance: AxiosInstance) {
    super({
      client: axiosInstance,
      skipQuery: skipIfNull(null),
      mapData: ({ data }: { data: IVertexData[] }) => {
        const map: INodesStoreState['data'] = {};
        for (const node of data) {
          map[node.id] = node;
        }
        return map;
      },
      query: {
        url: baseUrl
      },
      initialData: null
    });
  }
}

/**
 * Колбеки для Echo
 */
class NodesEchoCallbacks implements EntityEchoCallbacks<IVertexData> {
  constructor(public store: BehaviorStore<INodesStoreState>) {}

  public onAdd(node: IVertexData) {
    // Проверяем, относится ли добавленная вершина к текущему кампусу и этажу
    if (node.campus_id && node.campus_id !== this.store.state.campus_id) return;
    if (node.floor_number && node.floor_number !== this.store.state.floor) {
      return;
    }
    this.store.setState({
      data: immer(this.store.state.data, (d) => {
        if (!d) return;
        d[node.id] = node;
      })
    });
  }

  public onUpdate(node: IVertexData) {
    this.store.setState({
      data: immer(this.store.state.data, (d) => {
        if (!d) return;
        d[node.id] = node;
      })
    });
  }

  public onDelete(node: IVertexData) {
    this.store.setState({
      data: immer(this.store.state.data, (d) => {
        if (!d) return;
        delete d[node.id];
      })
    });
  }
}

export interface IVertexData {
  id: number;
  type: VertexType;
  x: number;
  y: number;
  building_id?: number;
  out_building_id?: number;
  floor_number?: number;
  campus_id?: number;
  room_id?: number;
}

function nodeObjectToDto(node: Partial<IVertexData>) {
  return JSON.stringify(node);
}

function nodeObjectToAddDto({ id, ...node }: Partial<IVertexData>) {
  return nodeObjectToDto(node);
}

class NodeMutations extends MapEntityMutations<IVertexData> {
  axiosClient: AxiosInstance;

  constructor(axiosClient: AxiosInstance) {
    super();
    this.axiosClient = axiosClient;
  }

  public async add(node: Partial<IVertexData>) {
    const { data } = await this.axiosClient.post(
      baseUrl,
      nodeObjectToAddDto(node)
    );

    return data;
  }

  public async update(node: Partial<IVertexData>) {
    const { data } = await this.axiosClient.put(
      `${baseUrl}/${node.id}`,
      nodeObjectToDto(node)
    );

    return data;
  }

  public async delete(id: IVertexData['id']): Promise<{ success: boolean }> {
    const { data } = await this.axiosClient.delete(`${baseUrl}/${id}`);

    return data;
  }
}

export class NodesStore extends BehaviorStore<INodesStoreState> {
  /**
   * Методы для мутации данных (отправляют http запросы на сервер)
   */
  public readonly mutations: NodeMutations;
  /**
   * Колбеки для подписок Echo (обновляют стейт)
   */
  public readonly echoCallbacks = new NodesEchoCallbacks(this);

  /**
   * AxiosQuery стор.
   * Обновляет стейт при изменение этажа/кампуса
   * @protected
   */
  protected readonly query: NodesQueryStore;
  protected readonly sub = new SubscriptionManager();

  private readonly authStore: AuthStore;
  private readonly locationStore: LocationStore;
  private readonly campusStore: CampusStore;

  constructor({
    authStore,
    locationStore,
    campusStore,
    axiosClient
  }: {
    authStore: AuthStore;
    locationStore: LocationStore;
    campusStore: CampusStore;
    axiosClient: AxiosInstance;
  }) {
    super({
      campus_id: null,
      floor: null,
      data: null
    });

    this.authStore = authStore;
    this.locationStore = locationStore;
    this.campusStore = campusStore;

    this.mutations = new NodeMutations(axiosClient);
    this.query = new NodesQueryStore(axiosClient);
  }

  onSubscribe() {
    this.sub.subscribe(
      combineLatest([
        this.locationStore.get$(QUERY_KEY_FLOOR, QUERY_KEY_CAMPUS),
        this.campusStore.state$,
        this.authStore.state$
      ]),
      async ([query, campus]) => {
        const floor = query[QUERY_KEY_FLOOR];
        const campus_id = campus?.id;
        // Храним актуальные значения floor и campus_id
        this.setState({
          floor,
          campus_id
        });
        this.query.loadData(
          campus_id
            ? {
                params: {
                  campus_id
                }
              }
            : null
        );
      }
    );

    // Подписываемся на изменение квери стора и обновляем стейт
    this.sub.subscribe(this.query.state$, (queryState) => {
      this.setState({
        data: queryState.data
      });
    });
  }

  onUnsubscribe() {
    this.sub.destroy();
  }
}
