import { WebStorageValueStore } from '@proscom/prostore-local-storage';
import { AxiosInstance } from 'axios';
import { filter, first } from 'rxjs/operators';

export const STORAGE_AUTH_TOKEN = 'STORAGE_AUTH_TOKEN';

export class AuthStore extends WebStorageValueStore<string | null> {
  axiosClient: AxiosInstance;

  constructor({ axiosClient }: { axiosClient: AxiosInstance }) {
    super(localStorage, STORAGE_AUTH_TOKEN, (v) => v);
    this.axiosClient = axiosClient;
  }

  public async login({
    clientId,
    clientSecret,
    email,
    password
  }: {
    clientId: string;
    clientSecret: string;
    email: string;
    password: string;
  }) {
    if (this.state.value !== null) throw new Error('Logout to login again');
    try {
      const { data } = await this.axiosClient.post<{ access_token: string }>(
        '/api/oauth/token',
        JSON.stringify({
          client_id: clientId,
          client_secret: clientSecret,
          grant_type: 'password',
          username: email,
          password: password,
          scope: ''
        }),
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );
      this.setValue(`Bearer ${data.access_token}`);
    } catch (e: any) {
      this.handleLoginError(e);
    }
  }

  public async getToken() {
    let { value } = this.state;
    if (!this.state.loaded) {
      const state = await this.state$
        .pipe(
          filter((state) => state.loaded),
          first()
        )
        .toPromise();
      value = state.value;
    }
    if (this.state.value) return value;
    throw new Error('AuthStore. Token is empty');
  }

  public logout = () => {
    this.setValue(null);
  };

  protected handleLoginError(error: any) {
    const errorResponse = error.response.data;
    const errorCode = errorResponse.error;
    throw new Error(
      errorCode === 'invalid_client'
        ? 'Неверный client id или client password'
        : errorCode === 'invalid_grant'
        ? 'Неверный e-mail или пароль'
        : errorResponse?.message || errorResponse
    );
  }
}
