import React from 'react';
import clsx from 'clsx';
import { CloseButton } from '../../ui/CloseButton/CloseButton';
import s from './LeftPaneTitle.module.scss';

export interface LeftPanelTitleProps {
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
}

export function LeftPaneTitle({
  children,
  onClose,
  className
}: LeftPanelTitleProps) {
  return (
    <div className={clsx(s.LeftPaneTitle, className)}>
      <h2 className={s.LeftPaneTitle__name}>{children}</h2>
      <CloseButton className={s.LeftPaneTitle__close} onClick={onClose} />
    </div>
  );
}
