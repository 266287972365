import React, { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  Button,
  ClickableIcon,
  IconActionDelete,
  IconGlobalQrCode,
  Input,
  RichInput,
  Select,
  Space
} from '@hse-design/react';
import { array, number, object, string } from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOptions } from '../../types';
import { BuildingDto } from '../../../../data/api/BuildingDto';
import { VertexType } from '../../../../data/VertexType';
import { useSyncFormFields } from './useSyncFormFields';

export interface UpdateNodeFormFields {
  type?: string;
  floor_number?: number;
  building_id?: number;
  qr_codes: { id: number; value: string }[];
}

export interface UpdateNodeFormProps {
  floors?: number[];
  buildings?: BuildingDto[];
  values: UpdateNodeFormFields;
  children?: React.ReactNode;
  showQrScanner?: (qrCodeId?: number | string) => void;
  onSubmit: (values: UpdateNodeFormFields) => void;
}

const nodeTypeOptions: SelectOptions<VertexType> = Object.values(
  VertexType
).map((t) => ({
  label: t,
  value: t
}));

const type = 'type';
const floor_number = 'floor_number';
const building_id = 'building_id';
const qr_codes = 'qr_codes';

const validationSchema = object().shape({
  [type]: string().required('Выберите категорию'),
  [floor_number]: number().required('Укажите номер этажа'),
  [qr_codes]: array().of(
    object().shape({
      id: string(),
      value: string().required('Введите qr код')
    })
  )
});

export function UpdateNodeForm({
  floors,
  buildings,
  children,
  values,
  showQrScanner,
  onSubmit
}: UpdateNodeFormProps) {
  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors }
  } = useForm<UpdateNodeFormFields>({
    // todo Проверить типы
    resolver: yupResolver(validationSchema) as any,
    defaultValues: values
  });
  useSyncFormFields<UpdateNodeFormFields>(reset, values);
  const [showQrCodes, setShowQrCodes] = useState(true);
  const {
    fields: qrCodes,
    append: addQrCode,
    remove: removeQrCode
  } = useFieldArray<UpdateNodeFormFields>({
    name: qr_codes,
    control,
    shouldUnregister: true
  });

  const floorsOptions = floors?.map((f) => ({ label: f, value: f })) || [];
  const buildingsOptions =
    buildings?.map((b) => ({ label: b.name, value: b.id })) || [];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name={type}
        control={control}
        defaultValue={values[type]}
        render={({ field: { ref, ...props } }) => {
          return (
            <RichInput
              component={Select}
              size={Select.Size.small}
              {...props}
              value={props.value || null}
              options={nodeTypeOptions}
              label={'Категория'}
              errorMessage={errors[type]?.message}
            />
          );
        }}
      />
      <Space size={Space.Size.small_3x} vertical />
      <Controller
        name={floor_number}
        control={control}
        defaultValue={values[floor_number]}
        render={({ field: { ref, ...props } }) => (
          <RichInput
            component={Select}
            options={(floorsOptions as unknown) as any}
            size={Select.Size.small}
            {...props}
            value={(props.value as unknown) as any}
            label={'Этаж'}
            errorMessage={errors[floor_number]?.message}
          />
        )}
      />
      <Space size={Space.Size.small_3x} vertical />
      <Controller
        name={building_id}
        control={control}
        defaultValue={values[building_id]}
        render={({ field: { ref, ...props } }) => (
          <RichInput
            component={Select}
            options={(buildingsOptions as unknown) as any}
            size={Select.Size.small}
            {...props}
            value={(props.value as unknown) as any}
            label={'Здание'}
            errorMessage={errors[building_id]?.message}
          />
        )}
      />
      {qrCodes?.length > 0 && (
        <>
          <Space size={Space.Size.small_3x} vertical />
          <Button
            size={Button.Size.small}
            variant={Button.Variant.secondary}
            onClick={() => setShowQrCodes((v) => !v)}
          >
            {showQrCodes ? 'Скрыть' : 'Показать'} QR коды
          </Button>
          {showQrCodes &&
            qrCodes.map((qr, qrI) => (
              <React.Fragment key={`qrFragment-${qr.value}`}>
                <Space size={Space.Size.small_3x} vertical />
                <RichInput
                  key={qr.id}
                  size={Input.Size.small}
                  {...register(`${qr_codes}.${qrI as number}.value` as const)}
                  defaultValue={qr.value}
                  errorMessage={errors[`${qr_codes}.[${qrI}]`]?.message}
                  right={
                    <>
                      <ClickableIcon
                        iconProps={{
                          icon: IconGlobalQrCode,
                          size: ClickableIcon.Size.small
                        }}
                        title={'Отсканировать QR код'}
                        onClick={() => showQrScanner?.(qr.id)}
                      />
                      <Space horizontal size={Space.Size.small_3x} />
                      <ClickableIcon
                        iconProps={{
                          icon: IconActionDelete,
                          size: ClickableIcon.Size.small
                        }}
                        title={'Удалить QR код'}
                        onClick={() => {
                          console.log({ qrI });
                          removeQrCode(qrI);
                        }}
                      />
                    </>
                  }
                />
              </React.Fragment>
            ))}
        </>
      )}
      <Space size={Space.Size.small_3x} vertical />
      <Button
        size={Button.Size.small}
        leftIcon={IconGlobalQrCode}
        variant={Button.Variant.secondary}
        onClick={() => {
          addQrCode({ value: '' });
        }}
      >
        Добавить QR код
      </Button>
      <Space size={Space.Size.small_3x} vertical />
      <Button variant={Button.Variant.secondary} type={'submit'}>
        Сохранить
      </Button>
      {children}
    </form>
  );
}
