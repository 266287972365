import { SubscriptionManager } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { filter, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Effect } from '../../utils/prostore/Effect';
import { CAMPUS_POKROVKA } from '../Campus';
import { findArrayExtreme } from '../../utils/array/findArrayExtreme';
import { CampusDto } from '../api/CampusDto';
import {
  QUERY_KEY_CAMPUS,
  QUERY_KEY_CODE,
  QUERY_KEY_ROOM,
  QUERY_KEY_START_ROOM
} from '../core/queryKeys';
import { IRoomStore } from '../online/RoomStore';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import { distanceBetweenLatLng } from '../../utils/geometry/distanceBetweenLatLng';
import { CampusesStore } from './CampusesStore';
import { RouteStore } from './RouteStore';
import { CampusStore } from './CampusStore';
import { LatestCampusStore } from './LatestCampusStore';

export interface CampusDefaultEffectArgs {
  campusesStore: CampusesStore;
  locationStore: LocationStore;
  roomStore: IRoomStore;
  routeStore: RouteStore;
  campusStore: CampusStore;
  latestCampus: LatestCampusStore;
}

export class CampusDefaultEffect implements Effect {
  protected sub = new SubscriptionManager();
  protected defaultCampus = CAMPUS_POKROVKA;
  protected distanceThreshold = 1;

  constructor(private readonly deps: CampusDefaultEffectArgs) {}

  public on() {
    const hasQuery$ = locationStoreGet$(
      this.deps.locationStore,
      QUERY_KEY_CAMPUS,
      QUERY_KEY_START_ROOM,
      QUERY_KEY_ROOM,
      QUERY_KEY_CODE
    ).pipe(
      map(
        (query) =>
          !!(
            query[QUERY_KEY_CAMPUS] ||
            query[QUERY_KEY_ROOM] ||
            query[QUERY_KEY_CODE] ||
            query[QUERY_KEY_START_ROOM]
          )
      )
    );

    const data$ = combineLatest([
      hasQuery$,
      this.deps.campusesStore.state$.pipe(filter((v) => v.loaded)),
      this.deps.latestCampus.state$.pipe(filter((v) => v.loaded))
    ]);

    // Выбираем кампус по умолчанию или по геолокации
    this.sub.subscribeAsync(
      data$,
      ([hasQuery, campusesQuery, latestCampus]) => {
        if (!campusesQuery?.data?.data || hasQuery) return;
        const campuses = campusesQuery.data.data;

        // В некоторых браузерах (Safari) запрос геолокации может зависнуть
        // и не выдать ни ответа ни ошибки. Поэтому на всякий случай выбираем
        // дефолтный кампус, и только потом запрашиваем геолокацию
        this.chooseDefaultCampus(latestCampus.value);
        this.tryGeolocation(campuses);
      }
    );
  }

  public off() {
    this.sub.destroy();
  }

  protected chooseDefaultCampus = (value: string | null) => {
    this.deps.locationStore.changeQuery(
      { [QUERY_KEY_CAMPUS]: value || this.defaultCampus },
      true
    );
  };

  protected tryGeolocation(campuses: CampusDto[]) {
    try {
      if (navigator.geolocation && navigator.geolocation.getCurrentPosition) {
        navigator.geolocation.getCurrentPosition(
          this.createHandleGeolocation(campuses),
          (e) => {
            console.error(e);
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
          }
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  protected createHandleGeolocation(campuses: CampusDto[]) {
    return (position: GeolocationPosition) => {
      const [nearestCampus, minDistance] = findArrayExtreme(
        campuses,
        false,
        (campus) =>
          Math.hypot(
            parseFloat(campus.lat) - position.coords.latitude,
            parseFloat(campus.lng) - position.coords.longitude
          )
      );

      const queryCampus = this.deps.locationStore.state.query?.[
        QUERY_KEY_CAMPUS
      ];

      if (nearestCampus) {
        const distance = distanceBetweenLatLng(
          [position.coords.latitude, position.coords.longitude],
          [parseFloat(nearestCampus.lat), parseFloat(nearestCampus.lng)]
        );

        if (distance < this.distanceThreshold) {
          if (!queryCampus || queryCampus !== nearestCampus.code) {
            this.deps.locationStore.changeQuery({
              [QUERY_KEY_CAMPUS]: nearestCampus.code
            });
          }
          this.deps.latestCampus.setValue(nearestCampus.code);
        }
      }
    };
  }
}
