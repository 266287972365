const wordbook = {
  All: 'Все',
  'search.placeholder': 'Найти помещение или человека',
  From: 'Откуда',
  To: 'Куда',
  'location.fromHere': 'Отсюда',
  'location.toHere': 'Сюда',
  'routeMapper.from': 'Откуда',
  'routeMapper.to': 'Куда',
  'routeMapper.selectPointOnMap': 'Выбрать {activePoint} на карте',
  'routeMapper.useMyCurrentLocation': 'Местоположение',
  'selectInMap.room': 'Комната',
  'selectInMap.done': 'Выбрать',
  'routeResult.tryAgain': 'Попробовать ещё раз',
  'routeResult.notFound': 'Маршрут не найден',
  'routeResult.notFoundCall': 'Может попробовать по-другому?',
  'Scan QR-code': 'Сканировать QR-код',
  Done: 'Готово',
  Room: 'Комната',
  'Nothing found on request': 'По запросу ничего не найдено',
  'qrScanner.cameraNotFound': 'Камера не обнаружена',
  'qrScanner.errorOccurred': 'Произошла ошибка',
  'qrScanner.pleaseGivePermission':
    'Для сканирования QR-кода пожалуйста, предоставьте доступ к камере',
  'qrScanner.badQrCode':
    'Похоже, это не навигационный QR-код. Попробуйте другой',
  'qrScanner.pointAndScan':
    'Направьте камеру вашего телефона на QR-код, чтобы определить местоположение',
  'qrScanner.noVertex':
    'Этот QR-код временно нельзя использовать для определения местоположения. Пожалуйста, попробуйте позднее',
  'qrScanner.loadError':
    'Ошибка загрузки данных QR-кода. Пожалуйста, попробуйте ещё раз',
  'qrScanner.ordynka':
    'Запуск системы Вышка.Навигация в комплексе на Ордынке запланирован в 2022 году',
  'We not found any routes': 'Мы не нашли маршрут',
  'Maybe try searching differently': 'Может попробовать по-другому?',
  'Try again': 'Попробовать еще раз',
  Navigation: 'Навигация',
  'unit.minutes': 'мин',
  'unit.meters': 'м',
  'unit.kilometers': 'км',
  min: 'мин',
  m: 'м',
  Schedule: 'Расписание',
  Profile: 'Профиль',
  'html.title':
    'Навигатор по комплексам Покровка и Шаболовка (веб-версия) – Национальный исследовательский университет «Высшая школа экономики»',
  'header.title': 'ВШЭ - Навигация по комплексу',
  'header.logo_alt': 'Логотип ВШЭ',
  'header.back_alt': 'Ссылка на основной сайт',
  'header.offline': 'Скачать данные для работы без интернета',
  'nav.menu': 'Меню',
  'nav.search': 'Поиск',
  'nav.route': 'Маршрут',
  'nav.qr': 'QR-сканер',
  'menu.icon_alt': 'Логотип',
  'menu.title': 'Навигация ВШЭ',
  'menu.item.language': 'Язык',
  'menu.item.feedback': 'Оставить отзыв',
  'page.language.title': 'Язык',
  'pane.search.placeholder': 'Найти помещение или человека',
  'pane.room.employees_title': 'Сотрудники',
  'pane.room.general_information': 'Основная информация',
  'map_controls.campus': 'Комплекс',
  'button.confirm': 'Подтвердить',
  'event.dod2021.title': 'День открытых дверей 2021',
  'route_step.from': 'Откуда',
  'route_step.to': 'Куда',
  'route_step.yandex_link': 'Перейти в Яндекс.Карты',
  'route.lets_go': 'Отправиться в путь',
  'currentLocation.remove': 'Убрать',
  'currentLocation.title': 'Мое местоположение',
  'roomSearchResults.title': 'Результаты поиска',
  'routePanelBuild.title': 'Построить маршрут',
  'searchPanel.title': 'Поиск',
  'route.segment.corridor': 'Идите {cost} м',
  'route.segment.floor_change.stairs.up':
    'Поднимитесь на {floor} этаж по лестнице',
  'route.segment.floor_change.stairs.down':
    'Спуститесь на {floor} этаж по лестнице',
  'route.segment.floor_change.elevator.up':
    'Поднимитесь на {floor} этаж на лифте',
  'route.segment.floor_change.elevator.down':
    'Спуститесь на {floor} этаж на лифте',
  'offline.status.online': 'Включить оффлайн режим',
  'offline.status.loading': 'Скачивание оффлайн данных',
  'offline.status.offline': 'Работает оффлайн',
  'offline.modal.title': 'Оффлайн режим',
  'offline.modal.introduction':
    'Приложение может работать без интернета, если включить оффлайн режим.\n' +
    'В таком случае данные комплексов будут загружены из интернета (около 50 МБ) и сохранены\n' +
    'на вашем устройстве. Данные будут периодически обновляться при подключении к интернету.',
  'offline.modal.loading':
    'Загрузка данных комплексов. Это может занять несколько минут',
  'offline.modal.offline':
    'Данные комплексов сохранены на вашем устройстве. Приложение может работать без интернета',
  'offline.modal.setup': 'Включить',
  'offline.modal.disable': 'Выключить',
  'offline.modal.ok': 'Ок'
};

export default wordbook;
