import { LocaleStore } from '../../../data/core/LocaleStore';
import { isFloorChangingVertexType } from '../../../data/Vertex';
import { isFloorChangingEdgeType } from '../../../data/Edge';
import { IRouteEdge } from '../../../data/RouteEdge';
import { IRouteSegment } from '../../../data/RouteSegment';
import { Segment } from './Segment';
import { SegmentFloorChange } from './SegmentFloorChange';
import { SegmentCorridor } from './SegmentCorridor';

export class DirectionsBuilder {
  private segment: Segment | null = null;
  private startCampusId: number | null = null;
  private endCampusId: number | null = null;
  private current: number = 0;
  private directions: IRouteSegment[] = [];

  constructor(
    private readonly locale: LocaleStore,
    private readonly route: IRouteEdge[]
  ) {}

  build(): IRouteSegment[] {
    this.initialize();

    for (let iPart = 0; iPart < this.route.length; iPart++) {
      const node = this.route[iPart];
      if (!this.startCampusId) {
        this.startCampusId = node.vertex.campus_id;
        this.endCampusId = node.vertex.campus_id;
      }
      if (this.startCampusId !== node.vertex.campus_id) {
        this.endCampusId = node.vertex.campus_id;
      }

      this.current = iPart;

      if (!this.segment) {
        this.startNewSegment(node);
      } else {
        const processed = this.segment.processNext(node);
        if (!processed) {
          this.finishSegment();
          this.startNewSegment(node);
        }
      }
    }

    if (this.segment) {
      this.current++;
      this.finishSegment();
    }

    return this.directions.filter((d) => d.cost > 0);
  }

  initialize() {
    this.directions = [];
    this.segment = null;
    this.startCampusId = null;
    this.endCampusId = null;
    this.current = 0;
  }

  startNewSegment(node: IRouteEdge) {
    const vertexType = node.vertex.type;
    const edgeType = node.edge?.type;

    if (
      isFloorChangingVertexType(vertexType) &&
      edgeType &&
      isFloorChangingEdgeType(edgeType)
    ) {
      this.segment = new SegmentFloorChange(this.current, node);
    } else if (node.order === 1 && this.current > 0) {
      // todo transfer
    } else {
      this.segment = new SegmentCorridor(this.current, node);
    }
  }

  finishSegment() {
    if (this.segment) {
      const endIndex = this.current - 1;
      if (endIndex >= this.segment.start_index) {
        const node = this.route[this.current] || this.route[endIndex];
        this.segment.end_index = endIndex;
        this.segment.setEndNode(node);
        this.segment.setHint(this.locale);
        this.directions.push(this.segment as any);
      }
    }
    this.segment = null;
  }
}
