import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Key } from 'ts-keycode-enum';
import {
  useAsyncOperation,
  useContextStore,
  useStore,
  useStoreState
} from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { Button, Space } from '@hse-design/react';
import { useBeforeunload } from 'react-beforeunload';
import immer from 'immer';
import { ValueStore } from '@proscom/prostore';
import { FloorDataStore } from '../../data/online/FloorDataStore';
import {
  STORE_CAMPUS,
  STORE_CAMPUSES,
  STORE_CATEGORIES,
  STORE_FLOOR_DATA,
  STORE_LOCATION,
  STORE_SELECTED_CAMPUS_FLOORS,
  STORE_SELECTED_FLOOR,
  STORE_TOAST
} from '../../data/stores';
import { CampusesStoreState } from '../../data/stores/CampusesStore';
import { CategoriesStore } from '../../data/stores/CategoriesStore';
import {
  QUERY_KEY_FLOOR,
  QUERY_KEY_NODE,
  TransformedQueryParams
} from '../../data/core/queryKeys';
import { useCampusChangeEffect } from '../index/hooks/useCampusChangeEffect';
import { useRoomChangeEffect } from '../index/hooks/useRoomChangeEffect';
import { FloorControl } from '../../common/components/MapControls/FloorControl/FloorControl';
import { CampusControl } from '../../common/components/MapControls/CampusControl/CampusControl';
import {
  floorBackgrounds,
  roadsBackgrounds
} from '../../common/components/MapBox/overlayImages';
import type { ToastStore } from '../../data/core/ToastStore';
import { SelectedCampusFloorsState } from '../../data/stores/SelectedCampusFloors';
import { useClassAsyncOperation } from '../../utils/hooks/useClassAsyncOperation';
import { useDi } from '../../utils/prostore/DiContext';
import { useValueRef } from '../../utils/hooks/useValueRef';
import { CampusDto } from '../../data/api/CampusDto';
import { VertexType } from '../../data/VertexType';
import { Bounds } from '../../utils/geometry/types';
import { ConfigStore } from './data/stores/ConfigStore';
import {
  ACTION_SELECT_EDGE,
  ACTION_SELECT_FLOOR,
  ACTION_SELECT_NODE,
  ACTION_SELECT_ROOM,
  ACTION_START_CONNECT_NODE,
  ACTION_START_SET_ROOM,
  STORE_AUTH,
  STORE_ECHO,
  STORE_EDGES,
  STORE_EDITOR_MODE,
  STORE_FINISH_SET_ROOM,
  STORE_MAPPER_CONFIG,
  STORE_MAPPER_QR,
  STORE_MODE_CONNECT_NODES,
  STORE_NODE_REMOVE_OPERATION,
  STORE_NODE_UPDATE_OPERATION,
  STORE_NODES,
  STORE_ROOMS,
  STORE_SELECTED_NODE,
  STORE_SELECTED_NODE_QRS,
  STORE_SELECTED_ROOM
} from './data/stores/stores';
import { EchoStore } from './data/stores/EchoStore';
import { IVertexData, NodesStore } from './data/stores/NodesStore';
import { IQRData, MapperQrStore } from './data/stores/MapperQrStore';
import { EdgesStore, IEdgeData } from './data/stores/EdgesStore';
import { AuthRoomDto, RoomsStore } from './data/stores/RoomsStore';
import { AuthStore } from './data/stores/AuthStore';
import {
  convertCampusBounds,
  flipBackendBoundsToLeaflet,
  flipLeafletBoundsToBackend,
  Point
} from './utils';
import { useEchoMutationsCallbacks } from './hooks/useEchoMutationsCallbacks';
import { latLngToXY } from './utils/coordinates';
import { MapperAuth, useMapperAuth } from './MapperAuth';
import { MapperHints } from './MapperHints';
import { MapperMap } from './MapperMap';
import { EdgePopup } from './components/popups/EdgePopup';
import { RoomPopup } from './components/popups/RoomPopup';
import { MapperConfig } from './MapperConfig';
import { EditMode } from './data/types';
import { MapperProgressBar } from './MapperProgressBar';
import { NodeUpdateOperation } from './data/stores/NodeUpdateOperation';
import { NodeRemoveOperation } from './data/stores/NodeRemoveOperation';
import { NodePopupWrapper } from './components/popups/NodePopupWrapper';
import { SelectEdge } from './data/stores/actions/SelectEdge';
import { SelectNode } from './data/stores/actions/SelectNode';
import { SelectRoom } from './data/stores/actions/SelectRoom';
import { SelectFloor } from './data/stores/actions/SelectFloor';
import { StartSetRoom } from './data/stores/actions/StartSetRoom';
import { FinishSetRoom } from './data/stores/actions/FinishSetRoom';
import { StartConnectNode } from './data/stores/actions/StartConnectNode';
import { ModeConnectNodes } from './data/stores/ModeConnectNodes';
import { cleanQuery } from './data/stores/actions/cleanQuery';
import s from './MapperPage.module.scss';

export interface MapperProps {
  loading?: boolean;
}

export function Mapper({ loading }: MapperProps) {
  const [mode, modeStore] = useStore<ValueStore<EditMode>>(STORE_EDITOR_MODE);
  const setMode = useCallback((mode) => modeStore.setState(mode), [modeStore]);

  // Config store
  const [configStoreState, configStore] = useStore<ConfigStore>(
    STORE_MAPPER_CONFIG
  );

  // Data stores
  const [floorsDataState] = useStore<FloorDataStore>(STORE_FLOOR_DATA);
  const [echoState, echoStore] = useStore<EchoStore>(STORE_ECHO);
  const [nodesState, nodesStore] = useStore<NodesStore>(STORE_NODES);
  const [mapperQrStoreState, mapperQrStore] = useStore<MapperQrStore>(
    STORE_MAPPER_QR
  );
  const [edgesState, edgesStore] = useStore<EdgesStore>(STORE_EDGES);
  const campusesStoreState = useStoreState<CampusesStoreState>(STORE_CAMPUSES);
  const selectedCampus = useStoreState<CampusDto>(STORE_CAMPUS);
  const [roomsStoreState, roomsStore] = useStore<RoomsStore>(STORE_ROOMS);

  // Location store
  const [locationStoreState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const query = locationStoreState.query as TransformedQueryParams;
  const { changeQuery } = locationStore;

  // Auth
  const [authState, authStore] = useStore<AuthStore>(STORE_AUTH);

  const toastStore = useContextStore<ToastStore>(STORE_TOAST);
  const roomCategoriesState = useStoreState<CategoriesStore['state']>(
    STORE_CATEGORIES
  );
  const roomCategories = roomCategoriesState.data || [];
  const rooms = useMemo(() => roomsStoreState.data || [], [roomsStoreState]);

  const selectedFloor = useStoreState<number>(STORE_SELECTED_FLOOR);
  const selectedRoom = useStoreState<AuthRoomDto>(STORE_SELECTED_ROOM);
  const selectedNode = useStoreState<IVertexData>(STORE_SELECTED_NODE);
  const selectedNodeQrs = useStoreState<IQRData[] | null>(
    STORE_SELECTED_NODE_QRS
  );

  const selectedEdge =
    (query.edge && edgesState.data?.[+query.edge]) || undefined;

  const bounds: Bounds = useMemo(
    () => convertCampusBounds(selectedCampus?.bounds),
    [selectedCampus?.bounds]
  );
  const roadBounds: Bounds = useMemo(
    () => convertCampusBounds(selectedCampus?.road_bounds),
    [selectedCampus?.road_bounds]
  );
  const floorsNumbers = useStoreState<SelectedCampusFloorsState>(
    STORE_SELECTED_CAMPUS_FLOORS
  );
  const campusesFloors = campusesStoreState.data?.floors || null;
  const nodePopupActive = selectedNode && selectedNodeQrs;
  const edgePopupActive = !!selectedEdge;
  const roomPopupActive =
    selectedRoom ||
    mode === EditMode.ADD_ROOM ||
    mode === EditMode.ADD_ROOM_ADD_POLYGON;

  // Показываем alert перед закрытием страницы, если открыт попап с редактированием данных
  useBeforeunload((e) => {
    if (
      process.env.NODE_ENV !== 'development' &&
      (nodePopupActive || edgePopupActive || roomPopupActive)
    ) {
      e.preventDefault();
    }
  });

  const graph = useMemo(
    () => ({
      nodes: configStoreState.value?.showNodes ? nodesState.data || {} : {},
      nodesMap: configStoreState.value?.showEdges ? nodesState.data || {} : {},
      edges: configStoreState.value?.showEdges ? edgesState.data || {} : {},
      rooms: configStoreState.value?.showRooms ? rooms || {} : {}
    }),
    [configStoreState, nodesState, edgesState, rooms]
  );
  // Смена этажа на первый при смене кампуса
  useCampusChangeEffect({
    campusesFloors,
    floorNumber: query[QUERY_KEY_FLOOR],
    locationStore,
    selectedCampus
  });
  // Смена этажа и кампуса под выбранную комнату
  useRoomChangeEffect({
    floorNumber: query[QUERY_KEY_FLOOR],
    locationStore,
    room: selectedRoom
  });

  useEchoMutationsCallbacks({
    echoStore,
    mapperQrStore,
    nodesStore,
    edgesStore,
    roomsStore
  });

  const [addRoomPoints, setAddRoomPoints] = useState<Point[][]>([]);

  useEffect(() => {
    if (!selectedRoom) {
      setAddRoomPoints([]);
    } else {
      const bounds = selectedRoom.bounds;
      if (bounds) {
        setAddRoomPoints(() => flipBackendBoundsToLeaflet(bounds));
      }
    }
  }, [selectedRoom]);

  const removeNode = useClassAsyncOperation<NodeRemoveOperation>(
    STORE_NODE_REMOVE_OPERATION
  );

  const removeEdge = useAsyncOperation<[IEdgeData], any>(async (edge) => {
    try {
      await edgesStore.mutations.delete(edge.id);
      toastStore.success('Ребро удалено');
      changeQuery(cleanQuery);
    } catch (e: any) {
      console.error('removeEdge error', e);
      toastStore.error({
        title: 'Ошибка при удалении ребра',
        description: e.message
      });
    }
  });

  const updateEdge = useAsyncOperation<[Partial<IEdgeData>], any>(
    async (edge) => {
      try {
        await edgesStore.mutations.update(edge);
        toastStore.success('Ребро обновлено');
      } catch (e: any) {
        console.error('updateEdge error', e);
        toastStore.error({
          title: 'Ошибка при обновлении ребра',
          description: e.message
        });
      }
    }
  );

  const updateNode = useClassAsyncOperation<NodeUpdateOperation>(
    STORE_NODE_UPDATE_OPERATION
  );

  const addRoom = useAsyncOperation<any, void>(
    async (room: Partial<AuthRoomDto>) => {
      room.floor_number = selectedFloor;
      room.campus_id = selectedCampus?.id;
      room.bounds = addRoomPoints.filter(Boolean).map((b) => {
        return b.map((p) => [p[1], -p[0]]);
      });
      try {
        await roomsStore.mutations.add(room);
        toastStore.success('Комната создана');
        switchModeDefault();
      } catch (e: any) {
        console.error(e);
        toastStore.error({
          title: 'Ошибка при создании комнаты',
          description: e.message
        });
      }
    }
  );

  const updateRoom = useAsyncOperation<[Partial<AuthRoomDto>], void>(
    async (room) => {
      try {
        room.campus_id = selectedCampus?.id;
        room.bounds = addRoomPoints
          ? flipLeafletBoundsToBackend(addRoomPoints.filter(Boolean))
          : room.bounds;
        await roomsStore.mutations.update(room);
        toastStore.success('Комната обновлена');
      } catch (e: any) {
        console.error('saveRoom error', e);
        toastStore.error({
          title: 'Ошибка при обновлении комнаты',
          description: e.message
        });
      }
    }
  );

  const deleteRoom = useAsyncOperation<[Partial<AuthRoomDto>], void>(
    async (room) => {
      try {
        if (room.id) {
          await roomsStore.mutations.delete(room.id);
          toastStore.success('Комната удалена');
        }
        changeQuery(cleanQuery);
      } catch (e: any) {
        console.error('deleteRoom error', e);
        toastStore.error({
          title: 'Ошибка при удалении комнаты',
          description: e.message
        });
      }
    }
  );

  const addNode = useAsyncOperation<[Point], void>(async (coords) => {
    const [x, y] = latLngToXY(coords);
    const newNode = {
      campus_id: selectedCampus?.id,
      x,
      y,
      floor_number: selectedFloor,
      type: VertexType.corridor
    };
    try {
      const { data } = await nodesStore.mutations.add(newNode);
      toastStore.success('Вершина создана');
      changeQuery({ [QUERY_KEY_NODE]: data.id });
    } catch (e: any) {
      console.error('addNode error', e);
      toastStore.error({
        title: 'Ошибка при создании вершины',
        description: e.message
      });
    }
  });

  const actionSelectNode = useDi<SelectEdge>(ACTION_SELECT_NODE);
  const actionSelectEdge = useDi<SelectNode>(ACTION_SELECT_EDGE);
  const actionSelectRoom = useDi<SelectRoom>(ACTION_SELECT_ROOM);
  const actionSelectFloor = useDi<SelectFloor>(ACTION_SELECT_FLOOR);

  const modeConnectNodes = useContextStore<ModeConnectNodes>(
    STORE_MODE_CONNECT_NODES
  );

  const handleNodeSelect = useCallback(
    (node) => {
      if (mode === EditMode.DEFAULT) {
        actionSelectNode.run(node);
      } else if (mode === EditMode.ADD_EDGE) {
        modeConnectNodes.handleClick(node);
      }
    },
    [mode, actionSelectNode, modeConnectNodes]
  );

  const [activePolygonIndex, setActivePolygonIndex] = useState<number>(-1);

  const handleMapClick = useCallback(
    async (e) => {
      const { lat, lng } = e.latlng;
      const coords: Point = [lat, lng];
      const invertedCoords: Point = [lng, lat];
      if (mode === EditMode.ADD_NODE) {
        addNode.run(invertedCoords).catch(() => {});
        setMode(EditMode.DEFAULT);
      } else if (
        mode === EditMode.ADD_ROOM ||
        mode === EditMode.ADD_ROOM_ADD_POLYGON
      ) {
        setAddRoomPoints((points) => {
          return immer(points, (d) => {
            if (!d[activePolygonIndex]) d[activePolygonIndex] = [];
            d[activePolygonIndex].push(coords);
          });
        });
      }
    },
    [mode, addNode, setMode, activePolygonIndex]
  );

  const finishSetRoom = useContextStore<FinishSetRoom>(STORE_FINISH_SET_ROOM);
  const handleRoomSelect = useCallback(
    async (room) => {
      if (mode === EditMode.DEFAULT) {
        actionSelectRoom.run(room);
      } else if (mode === EditMode.SET_ROOM) {
        finishSetRoom.run(room.id).catch(() => {});
      }
    },
    [mode, actionSelectRoom, finishSetRoom]
  );

  const {
    error: loginError,
    loading: loginLoading,
    onSubmit: handleLoginSubmit
  } = useMapperAuth(authStore);

  const switchModeAddEdge = useCallback(() => {
    changeQuery(cleanQuery);
    setMode(EditMode.ADD_EDGE);
  }, [changeQuery, setMode]);

  const switchModeAddNode = useCallback(() => {
    changeQuery(cleanQuery);
    setMode(EditMode.ADD_NODE);
  }, [changeQuery, setMode]);

  const switchModeAddRoom = useCallback(() => {
    changeQuery(cleanQuery);
    setMode(EditMode.ADD_ROOM);
    setActivePolygonIndex(0);
  }, [changeQuery, setMode]);

  const switchModeAddRoomPolygon = () => {
    setMode(EditMode.ADD_ROOM_ADD_POLYGON);
    setActivePolygonIndex(() => addRoomPoints.length + 1);
  };

  const switchModeDefault = useCallback(() => {
    setMode(EditMode.DEFAULT);
    setAddRoomPoints([]);
  }, [setMode]);

  const startConnectNode = useDi<StartConnectNode>(ACTION_START_CONNECT_NODE);
  const actionStartSetRoom = useDi<StartSetRoom>(ACTION_START_SET_ROOM);

  const stateRef = useValueRef({
    selectedFloor,
    selectedNode,
    selectedEdge
  });

  useEffect(() => {
    const handleKey = (e) => {
      if (!e.target) return;
      const tagName = e.target.tagName.toLowerCase();
      if (tagName === 'input' || tagName === 'textarea') return;
      const { selectedFloor, selectedNode, selectedEdge } = stateRef.current;
      if (e.keyCode === Key.Z) {
        // z
        switchModeAddEdge();
      } else if (e.keyCode === Key.X) {
        // x
        switchModeAddNode();
      } else if (e.keyCode === Key.V) {
        // v
        switchModeAddRoom();
      } else if (e.keyCode === Key.Q) {
        // q
        actionSelectFloor.run(selectedFloor - 1);
      } else if (e.keyCode === Key.W) {
        // w
        actionSelectFloor.run(selectedFloor + 1);
      } else if (e.keyCode >= Key.Zero && e.keyCode <= Key.Nine) {
        // 0-9
        const floor = e.keyCode - Key.Zero;
        actionSelectFloor.run(floor || 10);
      } else if (e.keyCode === Key.C) {
        // c
        if (selectedNode) {
          startConnectNode.run(selectedNode);
        }
      } else if (e.keyCode === Key.R) {
        // r
        if (selectedNode) {
          if (selectedNode.room_id) {
            updateNode.run({ id: selectedNode.id, room_id: null });
          } else {
            actionStartSetRoom.run(selectedNode);
          }
        }
      } else if (e.keyCode === Key.Backspace) {
        // backspace
        if (selectedNode) {
          removeNode.run(selectedNode);
        } else if (selectedEdge) {
          removeEdge.run(selectedEdge);
        }
      } else if (e.keyCode === Key.Escape) {
        // esc
        switchModeDefault();
      }
    };
    window.addEventListener('keyup', handleKey);
    return () => window.removeEventListener('keyup', handleKey);
  }, [
    startConnectNode,
    actionStartSetRoom,
    removeNode,
    removeEdge,
    updateNode,
    stateRef,
    switchModeAddEdge,
    switchModeAddNode,
    switchModeDefault,
    switchModeAddRoom,
    actionSelectFloor
  ]);

  const backgrounds = useMemo(
    () =>
      !selectedCampus || typeof selectedFloor !== 'number'
        ? []
        : [
            {
              src: floorBackgrounds[selectedCampus.code][selectedFloor],
              bounds
            },
            {
              src: roadsBackgrounds[selectedCampus.code],
              bounds: roadBounds
            },
            {
              src: floorBackgrounds[selectedCampus.code][selectedFloor],
              bounds
            }
          ],
    [roadBounds, bounds, selectedCampus, selectedFloor]
  );

  const onAddRoomPointsChange = useCallback((newBounds) => {
    setAddRoomPoints(() => newBounds);
  }, []);

  const onConfigChange = useCallback(
    (v) => {
      configStore.setPartialValue(v);
    },
    [configStore]
  );

  const onCancel = useCallback(() => {
    if (mode === EditMode.ADD_ROOM_ADD_POLYGON) {
      setMode(EditMode.ADD_ROOM);
    } else {
      switchModeDefault();
    }
  }, [mode, setMode, switchModeDefault]);

  return (
    <div className={s.MapperPage}>
      {(configStoreState.value?.showHints ?? true) && (
        <MapperHints mode={mode} onCancel={onCancel} />
      )}
      {graph && selectedCampus && (
        <>
          <FloorControl className={s.MapperPage__floorControl} />
          <CampusControl
            className={s.MapperPage__campusControl}
            isDesktopVersion={true}
          />
          <MapperMap
            key={selectedCampus.code}
            backgrounds={backgrounds}
            bounds={bounds}
            graph={graph}
            onNodeSelect={handleNodeSelect}
            onEdgeSelect={actionSelectEdge.run}
            onRoomSelect={handleRoomSelect}
            onMapClick={handleMapClick}
            selectedNode={selectedNode}
            selectedEdge={selectedEdge}
            selectedRoom={selectedRoom}
            selectedFloor={selectedFloor}
            addRoomPoints={addRoomPoints}
            onAddRoomPointsChange={onAddRoomPointsChange}
            onNodeChange={updateNode.run}
            buildings={
              configStoreState.value?.showBuildings
                ? floorsDataState.data?.buildings
                : undefined
            }
          />
          <NodePopupWrapper />
          {edgePopupActive && selectedEdge && (
            <EdgePopup
              key={selectedEdge?.id || 'none'}
              loading={updateEdge.loading || removeEdge.loading}
              edge={selectedEdge}
              nodesMap={graph.nodesMap}
              onOpenNode={actionSelectNode.run}
              onClose={() => actionSelectEdge.run(null)}
              onRemove={removeEdge.run}
              onEditEdge={updateEdge.run}
            />
          )}
          {roomPopupActive && (
            <RoomPopup
              key={selectedRoom?.id || 'none'}
              loading={
                addRoom.loading || updateRoom.loading || deleteRoom.loading
              }
              buildings={floorsDataState.data?.buildings}
              floorsNumbers={floorsNumbers}
              categories={roomCategories}
              room={selectedRoom}
              graph={graph}
              bounds={addRoomPoints ? addRoomPoints : selectedRoom.bounds || []}
              onClose={() => {
                actionSelectRoom.run(null);
                setMode(EditMode.DEFAULT);
              }}
              onOpenNode={actionSelectNode.run}
              onSaveRoom={
                mode === EditMode.ADD_ROOM ? addRoom.run : updateRoom.run
              }
              onDeleteRoom={deleteRoom.run}
            />
          )}
          <div className={s.MapperPage__Menu}>
            {mode === EditMode.DEFAULT ? (
              <>
                <Button
                  type="button"
                  variant={Button.Variant.secondary}
                  onClick={switchModeAddEdge}
                >
                  Добавить ребро
                </Button>
                <Space size={Space.Size.small_2x} horizontal />
                <Button
                  type="button"
                  variant={Button.Variant.secondary}
                  onClick={switchModeAddNode}
                >
                  Добавить вершину
                </Button>
                <Space size={Space.Size.small_2x} horizontal />
                <Button
                  type="button"
                  variant={Button.Variant.secondary}
                  onClick={switchModeAddRoom}
                >
                  Добавить комнату
                </Button>
              </>
            ) : (
              <>
                <Button type="button" onClick={onCancel}>
                  Отмена
                </Button>
                <Space size={Space.Size.small_2x} horizontal />
                {(mode === EditMode.ADD_ROOM ||
                  mode === EditMode.ADD_ROOM_ADD_POLYGON) && (
                  <>
                    {mode === EditMode.ADD_ROOM_ADD_POLYGON ? (
                      <Button
                        type="button"
                        variant={Button.Variant.secondary}
                        onClick={switchModeAddRoom}
                      >
                        Ок
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        variant={Button.Variant.secondary}
                        onClick={switchModeAddRoomPolygon}
                      >
                        Добавить полигон
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {configStoreState.value && (
            <MapperConfig
              isActive={configStoreState.value.showConfig}
              showHints={configStoreState.value.showHints}
              showEdges={configStoreState.value.showEdges}
              showNodes={configStoreState.value.showNodes}
              showRooms={configStoreState.value.showRooms}
              showBuildings={configStoreState.value.showBuildings}
              onChange={onConfigChange}
              onLogout={authStore.logout}
            />
          )}
        </>
      )}
      <MapperProgressBar loading={loading} />
      <MapperAuth
        error={loginError}
        isOpen={authState.loaded ? authState.value === null : false}
        loading={loginLoading}
        onSubmit={handleLoginSubmit}
      />
    </div>
  );
}
