import React from 'react';
import clsx from 'clsx';
import {
  DivPropsWithoutRef,
  IconActionDelete,
  IconButton,
  IconButtonSize
} from '@hse-design/react';
import { Loader } from '../../../../common/ui/Loader/Loader';
import s from './BasePopup.module.scss';

export interface BasePopupProps extends Omit<DivPropsWithoutRef, 'title'> {
  actions?: React.ReactNode;
  header?: React.ReactNode;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  onClose?: (e: MouseEvent) => void;
  loading?: boolean;
}

export function BasePopup({
  actions,
  className,
  children,
  loading,
  header,
  title,
  footer,
  onClose,
  ...props
}: BasePopupProps) {
  return (
    <div
      className={clsx(className, s.BasePopup, {
        [s.BasePopup_loading]: loading
      })}
      {...props}
    >
      <div className={s.BasePopup__CloseContainer}>
        <IconButton
          className={s.BasePopup__CloseButton}
          size={IconButtonSize.small}
          icon={IconActionDelete}
          variant={IconButton.Variant.pagination}
          onClick={onClose}
        />
      </div>
      <div className={s.BasePopup__Container}>
        {loading ? (
          <div className={s.BasePopup__LoaderContainer}>
            <Loader />
          </div>
        ) : (
          <>
            {(header || title) && (
              <div className={s.BasePopup__Header}>
                {header ? (
                  header
                ) : (
                  <div className={s.BasePopup__Title}>{title}</div>
                )}
              </div>
            )}
            {children && <div className={s.BasePopup__Content}>{children}</div>}
            {actions && <div className={s.BasePopup__Actions}>{actions}</div>}
            {footer && <div className={s.BasePopup__Footer}>{footer}</div>}
          </>
        )}
      </div>
    </div>
  );
}
