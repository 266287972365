import { LocationStore } from '@proscom/prostore-react-router';
import {
  QUERY_KEY_FLOOR,
  QUERY_KEY_ROOM
} from '../../../../../data/core/queryKeys';
import { cleanQuery } from './cleanQuery';

export interface SelectRoomArgs {
  id: number;
  floor_number?: number;
}

export class SelectRoom {
  constructor(private readonly location: LocationStore) {}

  public run = (room: SelectRoomArgs | null) => {
    if (!room) {
      return this.location.changeQuery({ [QUERY_KEY_ROOM]: undefined });
    }
    this.location.changeQuery({
      ...cleanQuery,
      [QUERY_KEY_ROOM]: room.id,
      [QUERY_KEY_FLOOR]: room.floor_number
    });
  };
}
