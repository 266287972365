import { useStore } from '@proscom/prostore-react';
import React, { useEffect } from 'react';
import { BottomSheetNew } from '../../../../common/mobile/BottomSheetNew/BottomSheetNew';
import { ActivePanel } from '../../../../data/core/ActivePanel';
import {
  STORE_ACTIVE_PANEL,
  STORE_CURRENT_LOCATION
} from '../../../../data/stores';
import { CurrentLocationStore } from '../../../../data/stores/CurrentLocationStore';
import { BottomSheetContent } from '../../../../common/mobile/BottomSheetContent/BottomSheetContent';
import { MyLocationPanelContent } from '../../components/MyLocationPanelContent/MyLocationPanelContent';
import { useLocale } from '../../../../common/hooks/useLocale';

export function MyLocationPanel() {
  const locale = useLocale();

  const [
    currentLocationState,
    currentLocationStore
  ] = useStore<CurrentLocationStore>(STORE_CURRENT_LOCATION);

  const handleClose = currentLocationStore.resetSelection;

  const [activePanel, activePanelStore] = useStore<ActivePanel>(
    STORE_ACTIVE_PANEL
  );

  const { isSelected } = currentLocationState;

  useEffect(() => {
    if (isSelected) {
      if (!activePanel) {
        activePanelStore.setPanel(ActivePanel.PANEL_MY_LOCATION, true);
      }
    } else if (activePanel === ActivePanel.PANEL_MY_LOCATION) {
      activePanelStore.closePanel(true);
    }
  }, [activePanel, activePanelStore, isSelected]);

  return (
    <BottomSheetNew
      open={activePanel === ActivePanel.PANEL_MY_LOCATION}
      blocking={false}
      onDismiss={handleClose}
      headerTitle={locale.t('currentLocation.title')}
    >
      <BottomSheetContent tightTop>
        <MyLocationPanelContent />
      </BottomSheetContent>
    </BottomSheetNew>
  );
}
