import { LocaleNativeAdapter } from './LocaleNativeAdapter';
import { LOCAL_STORAGE_KEY_LNG } from './storageKeys';
import { AutoStorageAdapter } from './AutoStorageAdapter';

const canStoreLocaleAppVersion = '1.3.0';

export class LocaleStorageAdapter extends AutoStorageAdapter {
  constructor() {
    super(
      LOCAL_STORAGE_KEY_LNG,
      () => new LocaleNativeAdapter(),
      canStoreLocaleAppVersion
    );
  }
}
