export type Point = [number, number];
export type Bound = [Point, Point];

export const flipCampusBounds = ([x, y]: Point): Point => [y, x]; // На бэке так хранятся данные
export const flipBoundsToLeaflet = ([x, y]: Point): Point => [-y, x];

export const flipLeafletBoundsToBackend = (bounds: Point[][]) =>
  bounds.map((b) => {
    return b.map(([x, y]) => [y, -x] as Point);
  });

export const flipBackendBoundsToLeaflet = (bounds: Point[][]) =>
  bounds.map((b) => {
    return b.map(flipBoundsToLeaflet);
  });

export function convertCampusBounds(
  bounds: Bound = [
    [0, 0],
    [0, 0]
  ]
): Bound {
  const corner = flipBoundsToLeaflet(flipCampusBounds(bounds[0]));
  const size = flipBoundsToLeaflet(flipCampusBounds(bounds[1]));
  const corner2: Point = [corner[0] + size[0], corner[1] + size[1]] as Point;
  return [corner, corner2];
}
