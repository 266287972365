import { getBbox } from '../utils/geometry/getBbox';
import { Polygon, TuplePoint2D } from '../utils/geometry/types';
import { BuildingFloorDto } from './api/BuildingFloorDto';

export class Building {
  id: number;
  name: string;
  type: string;
  bounds: Polygon;
  label_position: TuplePoint2D;
  center: TuplePoint2D;

  constructor(buildingFloor: BuildingFloorDto) {
    this.id = buildingFloor.building.id;
    this.bounds = buildingFloor.bounds;
    const bbox = getBbox(this.bounds);
    this.center = [(bbox.minX + bbox.maxX) / 2, (bbox.minY + bbox.maxY) / 2];
    this.label_position = buildingFloor.label_position || this.center;
    this.name = buildingFloor.building.name || '';
    this.type = buildingFloor.building.type;
  }

  getState = (
    selectedBuildingId,
    selectedFloor,
    routeStartFloor,
    routeStartBuildingId,
    routeEndFloor,
    routeEndBuildingId
  ) => {
    if (selectedBuildingId === this.id) return 'active';
    else if (
      routeStartFloor === selectedFloor &&
      routeStartBuildingId === this.id
    ) {
      return 'active';
    } else if (
      routeEndFloor === selectedFloor &&
      routeEndBuildingId === this.id
    ) {
      return 'active';
    }
    return 'inactive';
  };
}
