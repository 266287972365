import { StorageValueStore } from '@proscom/prostore-local-storage';
import { LOCAL_STORAGE_KEY_CAMPUS } from '../core/storageKeys';
import { AutoStorageAdapter } from '../core/AutoStorageAdapter';
import {
  canUseNativeStorageAppVersion,
  NativeStorageAdapter
} from '../core/NativeStorageAdapter';

export class LatestCampusStore extends StorageValueStore<string | null> {
  constructor() {
    super(
      new AutoStorageAdapter(
        LOCAL_STORAGE_KEY_CAMPUS,
        () => new NativeStorageAdapter(LOCAL_STORAGE_KEY_CAMPUS),
        canUseNativeStorageAppVersion
      ),
      (x) => {
        if (typeof x === 'string') {
          return x;
        }
        return null;
      }
    );
  }

  public on() {
    (this.adapter as AutoStorageAdapter).on();
  }

  public off() {
    (this.adapter as AutoStorageAdapter).off();
  }
}
