import { Link } from '@hse-design/react';
import React from 'react';
import { useLocale } from '../../hooks/useLocale';
import s from './RoomPerson.module.scss';

export interface RoomRersonProps {
  title: string;
  first_name: string;
  last_name: string;
  surname: string;
  full_name: string;
  email: string;
  profile_link: string;
  structure: string;
}

export function RoomPerson({
  title,
  first_name,
  last_name,
  surname,
  full_name,
  email,
  profile_link,
  structure
}: RoomRersonProps) {
  const locale = useLocale();
  return (
    <div className={s.RoomPerson}>
      <div className={s.RoomPerson__name}>
        {`${last_name} ${first_name} ${surname}` || full_name}
      </div>
      <div className={s.RoomPerson__title}>{title}</div>
      <div className={s.RoomPerson__structure}>{structure}</div>
      {email && false && (
        <div className={s.RoomPerson__contact}>
          <Link href={`mailto:${email}`}>{email}</Link>
        </div>
      )}

      {profile_link && (
        <div className={s.RoomPerson__profileLink}>
          <Link
            rel="noopener noreferrer"
            href={profile_link}
            target={'_blank'}
            variant={Link.Variant.secondary}
          >
            {locale.t('Profile')}
          </Link>
        </div>
      )}
    </div>
  );
}
