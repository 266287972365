import cmp from 'semver-compare';
import {
  IStorageValueAdapter,
  WebStorageAdapter
} from '@proscom/prostore-local-storage';
import { BehaviorSubject, defer, NEVER, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CustomError } from '@proscom/ui-utils';
import { rnMessenger } from '../../rnMessenger';

export class StorageNotInitializedError extends CustomError {
  public readonly name: string = 'StorageNotInitializedError';
}

export class AutoStorageAdapter implements IStorageValueAdapter<string> {
  public adapter$ = new BehaviorSubject<IStorageValueAdapter<string> | null>(
    null
  );
  public value$ = this.adapter$.pipe(
    switchMap((adapter) => (adapter ? adapter.value$ : NEVER))
  );
  protected sub: Subscription | null = null;

  constructor(
    protected readonly localStorageKey: string,
    protected readonly buildNativeAdapter: () => IStorageValueAdapter<string>,
    protected readonly minAppVersion: string
  ) {}

  public on() {
    const adapter$ = defer(async () => {
      if (rnMessenger.isActive) {
        const info = await rnMessenger.ready;
        return cmp(info.nativeInfo.appVersion, this.minAppVersion) >= 0;
      }

      return false;
    }).pipe(
      map((native) => {
        if (native) {
          return this.buildNativeAdapter();
        } else {
          return new WebStorageAdapter(
            window.localStorage,
            this.localStorageKey
          );
        }
      })
    );

    this.sub = adapter$.subscribe((value) => this.adapter$.next(value));
  }

  public off() {
    this.sub?.unsubscribe();
  }

  public setValue(value: string | null) {
    const adapter = this.adapter$.value;
    if (adapter) {
      adapter.setValue(value);
    } else {
      console.error(
        'StorageAdapter.setValue',
        new StorageNotInitializedError()
      );
    }
  }
}
