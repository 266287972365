import { LocationStore } from '@proscom/prostore-react-router';
import { map } from 'rxjs/operators';
import { ObservableStore } from '../../utils/prostore/ObservableStore';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import {
  QUERY_KEY_END_ROOM,
  QUERY_KEY_END_VERTEX,
  QUERY_KEY_START_ROOM,
  QUERY_KEY_START_VERTEX
} from '../core/queryKeys';

export class RouteActive extends ObservableStore<boolean> {
  constructor(location: LocationStore) {
    super(() => {
      return locationStoreGet$(
        location,
        QUERY_KEY_START_ROOM,
        QUERY_KEY_START_VERTEX,
        QUERY_KEY_END_ROOM,
        QUERY_KEY_END_VERTEX
      ).pipe(
        map((query) => {
          const queryStartRoom = query[QUERY_KEY_START_ROOM];
          const queryEndRoom = query[QUERY_KEY_END_ROOM];
          const queryStartVertex = query[QUERY_KEY_START_VERTEX];
          const queryEndVertex = query[QUERY_KEY_END_VERTEX];
          const isRouteActive =
            (queryStartRoom || queryStartVertex) &&
            (queryEndRoom || queryEndVertex);
          return !!isRouteActive;
        })
      );
    }, false);
  }
}
