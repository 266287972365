import { LocationStore } from '@proscom/prostore-react-router/lib/es/LocationStore';
import { combineLatest } from 'rxjs';
import { skipIfNull } from '@proscom/prostore';
import { map } from 'rxjs/operators';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { cleanObject } from '@proscom/ui-utils';
import { AxiosRequestConfig } from 'axios';
import { defaultClient } from '../../Client';
import { IStoreType } from '../../utils/types';
import { RouteDto } from '../api/RouteDto';
import { LocaleStore } from '../core/LocaleStore';
import {
  QUERY_KEY_END_ROOM,
  QUERY_KEY_END_VERTEX,
  QUERY_KEY_START_ROOM,
  QUERY_KEY_START_VERTEX
} from '../core/queryKeys';
import { parseVertexDto } from '../api/VertexDto';
import { IRoute } from '../Route';

export interface RouteQueryStoreArgs {
  locationStore: LocationStore;
  localeStore: LocaleStore;
}

export interface RouteQueryStoreVars extends AxiosRequestConfig {
  params: {
    start_room_id?: number;
    end_room_id?: number;
    start_vertex_id?: number;
    end_vertex_id?: number;
  };
}

export type IRouteQueryStore = IStoreType<RouteQueryStore>;

export class RouteQueryStore extends AxiosQueryStore<
  RouteQueryStoreVars | null,
  IRoute | null
> {
  constructor(protected readonly params: RouteQueryStoreArgs) {
    super({
      client: defaultClient,
      query: { method: 'get' },
      skipQuery: skipIfNull(null),
      mapData: (data: RouteDto) => ({
        route: data.route.map((edge) => ({
          ...edge,
          vertex: parseVertexDto(edge.vertex)
        })),
        directions: data.directions.map((direction) => ({
          ...direction,
          vertex: direction.vertex && parseVertexDto(direction.vertex)
        }))
      }),
      variables$: combineLatest([
        params.localeStore.state$,
        params.locationStore.get$(
          QUERY_KEY_START_ROOM,
          QUERY_KEY_END_ROOM,
          QUERY_KEY_START_VERTEX,
          QUERY_KEY_END_VERTEX
        )
      ]).pipe(
        map(([locale, query]) => {
          const start_room_id = query[QUERY_KEY_START_ROOM];
          const end_room_id = query[QUERY_KEY_END_ROOM];
          const start_vertex_id = query[QUERY_KEY_START_VERTEX];
          const end_vertex_id = query[QUERY_KEY_END_VERTEX];
          const hasRoute =
            (start_room_id || start_vertex_id) &&
            (end_room_id || end_vertex_id);
          return {
            variables: hasRoute
              ? {
                  params: {
                    ...cleanObject({
                      start_room_id,
                      end_room_id,
                      start_vertex_id,
                      end_vertex_id
                    }),
                    locale: locale.lang
                  },
                  url: `/api/route`
                }
              : null
          };
        })
      )
    });
  }
}
