import { useContextStore } from '@proscom/prostore-react';
import React from 'react';
import classNames from 'classnames';
import { STORE_ROUTE } from '../../../../data/stores';
import { useLocale } from '../../../../common/hooks/useLocale';
import { RouteStore } from '../../../../data/stores/RouteStore';
import s from './RouteNotFound.module.scss';

const mainClass = 'NotFound';

export interface DoneActionProps {
  handleClick?: () => void;
}

function DoneAction({ handleClick }: DoneActionProps) {
  const localeStore = useLocale();

  return (
    <div className={s.actions} onClick={handleClick}>
      <div className={s.actions__button}>
        {localeStore.t('routeResult.tryAgain')}
      </div>
    </div>
  );
}

export function RouteNotFound() {
  const locale = useLocale();
  const routeStore = useContextStore<RouteStore>(STORE_ROUTE);

  return (
    <div className={s[mainClass]}>
      <div className={s.head}>
        <div className={s.head__info}>
          <div className={s.info__title}>
            {locale.t('routeResult.notFound')}
          </div>
          <div className={classNames([s.info__item, s.info__desc])}>
            {locale.t('routeResult.notFoundCall')}
          </div>
        </div>
      </div>
      <DoneAction handleClick={routeStore.closeRoute} />
    </div>
  );
}
