import React from 'react';
import { Link } from '@hse-design/react';
import { Link as RouterLink } from 'react-router-dom';
import { ErrorMessage } from '../../common/ui/ErrorMessage/ErrorMessage';
import s from './NotFoundPage.module.scss';

export default function NotFoundPage() {
  return (
    <div className={s.NotFoundPage}>
      <ErrorMessage
        classes={{
          text: s.NotFoundPage__text,
          description: s.NotFoundPage__description,
          children: s.NotFoundPage__children
        }}
        text={'404'}
        description={'Страница не найдена'}
      >
        <Link variant={Link.Variant.secondary} component={RouterLink} to={'/'}>
          {' '}
          Вернуться на главную{' '}
        </Link>
      </ErrorMessage>
    </div>
  );
}
