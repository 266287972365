import {
  useContextStore,
  useStore,
  useStoreState
} from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { DivPropsWithoutRef } from '@hse-design/react';
import clsx from 'clsx';
import { useLocale } from '../../../hooks/useLocale';
import {
  STORE_CAMPUS,
  STORE_CAMPUSES,
  STORE_LATEST_CAMPUS_STORE,
  STORE_LOCATION
} from '../../../../data/stores';
import { CampusesStoreState } from '../../../../data/stores/CampusesStore';
import {
  QUERY_KEY_CAMPUS,
  QUERY_KEY_MY_LOCATION,
  QUERY_KEY_STEP,
  TransformedQueryParams
} from '../../../../data/core/queryKeys';
import { CampusDto } from '../../../../data/api/CampusDto';
import { CloseButton } from '../../../ui/CloseButton/CloseButton';
import { LatestCampusStore } from '../../../../data/stores/LatestCampusStore';
import { CampusesList } from './CampusesList/CampusesList';
import s from './CampusControl.module.scss';

export interface CampusControlProps extends DivPropsWithoutRef {
  isDesktopVersion: boolean;
}

export const CampusControl = ({
  className,
  isDesktopVersion,
  ...props
}: CampusControlProps) => {
  const locale = useLocale();
  const latestCampus = useContextStore<LatestCampusStore>(
    STORE_LATEST_CAMPUS_STORE
  );
  const [locationStoreState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const locationQuery = locationStoreState.query as TransformedQueryParams;
  const queryCampus = locationQuery[QUERY_KEY_CAMPUS];
  const campusesStoreState = useStoreState<CampusesStoreState>(STORE_CAMPUSES);
  const campuses = campusesStoreState.data && campusesStoreState.data.data;
  const selectedCampus = useStoreState<CampusDto>(STORE_CAMPUS);
  const [isSelectOpened, setIsSelectOpened] = useState<boolean>(false);

  const onCampusSelect = useCallback(
    (campusCode) => {
      if (queryCampus !== campusCode) {
        locationStore.changeQuery({
          [QUERY_KEY_STEP]: undefined,
          [QUERY_KEY_MY_LOCATION]: undefined,
          [QUERY_KEY_CAMPUS]: campusCode
        });
        latestCampus.setValue(campusCode);
        setIsSelectOpened(false);
      }
    },
    [latestCampus, locationStore, queryCampus]
  );

  const onSelectedClick = () => {
    setIsSelectOpened(!isSelectOpened);
  };

  const onClose = () => setIsSelectOpened(false);

  if (!campuses || !campuses.length || !selectedCampus) return null;

  return (
    <div className={clsx(s.CampusControl, className)} {...props}>
      {isDesktopVersion ? (
        <div
          onClick={onSelectedClick}
          className={s.CampusControl__selectedCampus}
        >
          {selectedCampus.name}
        </div>
      ) : (
        <Link
          to={`/select-campus${locationStoreState.location?.search}`}
          className={s.CampusControl__selectedCampus}
        >
          {selectedCampus.name}
        </Link>
      )}
      {isSelectOpened && isDesktopVersion && (
        <div className={s.CampusControl__dropdown}>
          <h2 className={s.CampusControl__dropdownHeader}>
            {locale.t('map_controls.campus')}
          </h2>
          <CloseButton
            className={s.CampusControl__dropdownClose}
            onClick={onClose}
          />
          <CampusesList
            onCampusSelect={onCampusSelect}
            selectedCampusCode={selectedCampus.code}
            campuses={campuses}
          />
        </div>
      )}
    </div>
  );
};
