import {
  initialRequestState,
  IRequestState,
  ObservableStore
} from '@proscom/prostore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { EventStore } from '../../stores/EventStore';
import { combineLatestMap } from '../../../utils/prostore/combineLatestMap';
import {
  CampusCategoriesQueryData,
  CampusCategoriesVars,
  ICampusCategoriesStore
} from '../../online/CampusCategoriesStore';
import { RoomCategoryDto } from '../../api/RoomCategoryDto';
import { IOfflineCampusInfo } from './CampusesStoreOffline';
import { CurrentCampusStoreOffline } from './CurrentCampusStoreOffline';

export interface CampusCategoriesState
  extends IRequestState<CampusCategoriesVars, CampusCategoriesQueryData> {}

export interface CampusCategoriesStoreOfflineArgs {
  categoriesStore: CategoriesStore;
  currentCampusStoreOffline: CurrentCampusStoreOffline;
  eventStore: EventStore;
}

/**
 * Стор хранящий доступные категории по текущему кампусу
 * @link CurrentCampusStoreOffline
 */
export class CampusCategoriesStoreOffline
  extends ObservableStore<CampusCategoriesState>
  implements ICampusCategoriesStore {
  constructor(params: CampusCategoriesStoreOfflineArgs) {
    super(
      combineLatestMap(
        [
          params.currentCampusStoreOffline.state$,
          params.categoriesStore.state$,
          params.eventStore?.state$
        ] as const,
        ([campusState, categories, event]) => {
          return this.handleArgsChange(
            campusState,
            categories.data,
            event.eventCode
          );
        }
      ),
      { ...initialRequestState }
    );
  }

  handleArgsChange(
    campusState: IOfflineCampusInfo | null,
    categories: RoomCategoryDto[] | null,
    event: string | null
  ): CampusCategoriesState {
    if (!campusState || !campusState.campus || !categories) {
      return { ...initialRequestState, data: null, loading: true };
    }

    let eventId;
    //todo Исправить когда с бэка будет возвращаться eventId
    if (event === 'dod2021') eventId = 1;
    else if (event === 'april2020') eventId = 2;
    else eventId = null;

    const eventCategories = campusState?.categories?.filter(
      (category) => category.event_id === eventId
    );

    const filteredCategories = categories?.filter((category) =>
      eventCategories?.some(
        (categoryEvent) => category.id === categoryEvent.category_id
      )
    );

    return { ...initialRequestState, data: filteredCategories, loaded: true };
  }
}
