import { PointExpression } from 'leaflet';
import { accentRedColor, grayColor } from '../data/theme';

export * from './bounds';

/**
 * Определяем, находится ли число x в границах min и max включительно (принадлежит ли x множеству [min; max])
 * @param x - number число для поиска в границах
 * @param min - range граница
 * @param max - range граница
 * @returns boolean - число находится в границах
 */
export function inRangeInclusive(x: number, min: number, max: number): boolean {
  return x >= min && x <= max;
}

export const tooltipOffset: PointExpression = [0, -16];
export const defaultRoomColor = grayColor;
export const selectedRoomColor = accentRedColor;
