import { LocationStore } from '@proscom/prostore-react-router';
import { SubscriptionManager } from '@proscom/prostore';
import { CustomError, isTruthy } from '@proscom/ui-utils';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Effect } from '../../utils/prostore/Effect';
import { CAMPUS_ORDYNKA } from '../Campus';
import { CampusDto } from '../api/CampusDto';
import type { LocaleStore } from '../core/LocaleStore';
import { QUERY_KEY_MY_LOCATION, QUERY_KEY_QR } from '../core/queryKeys';
import { ToastStore } from '../core/ToastStore';
import { IQrCodeResolver } from '../online/QrCodeResolver';
import { IVertex } from '../Vertex';
import { CurrentLocationStore } from './CurrentLocationStore';

export interface IQrInfo {
  qr: string;
  campus?: CampusDto | null;
  vertex?: IVertex | null;
}

export class QrCodeOrdynkaError extends CustomError {}
export class QrCodeNoInfoError extends CustomError {}
export class QrCodeNoVertexError extends CustomError {}

export class QrCodeNoRoomError extends CustomError {}

export class LocationQrEffect implements Effect {
  private readonly sub = new SubscriptionManager();

  constructor(
    private readonly localeStore: LocaleStore,
    private readonly toastStore: ToastStore,
    private readonly locationStore: LocationStore,
    private readonly currentLocationStore: CurrentLocationStore,
    private readonly resolver: IQrCodeResolver
  ) {
    this.localeStore = localeStore;
    this.toastStore = toastStore;
    this.locationStore = locationStore;
    this.currentLocationStore = currentLocationStore;
    this.resolver = resolver;
  }

  public on() {
    this.sub.subscribe(
      combineLatest([
        this.locationStore.get$(QUERY_KEY_QR).pipe(
          map((query) => query[QUERY_KEY_QR]),
          filter(isTruthy)
        ),
        // LocaleStore тут нужен, чтобы перевыполнить запрос при смене языка
        this.localeStore.state$
      ]).pipe(map(([qr, locale]) => qr)),
      this.loadData
    );
  }

  public off() {
    this.sub.destroy();
  }

  protected loadData = async (qr: string) => {
    try {
      console.log('LocationQrEffect loadData', qr);
      const result = await this.resolver.load(qr);

      if (!result) {
        throw new QrCodeNoInfoError('QR code is not registered');
      } else if (!result.vertex) {
        if (result.campus?.code === CAMPUS_ORDYNKA) {
          throw new QrCodeOrdynkaError('Ordynka is not yet launched');
        } else {
          throw new QrCodeNoVertexError('Vertex for QR code not found');
        }
      }

      this.updateLocation(result);
    } catch (e: any) {
      console.error(e);
      if (e instanceof QrCodeOrdynkaError) {
        this.toastStore.error(this.localeStore.t('qrScanner.ordynka'));
      } else if (e instanceof QrCodeNoInfoError) {
        this.toastStore.error(this.localeStore.t('qrScanner.badQrCode'));
      } else if (e instanceof QrCodeNoVertexError) {
        this.toastStore.error(this.localeStore.t('qrScanner.noVertex'));
      } else {
        this.toastStore.error(this.localeStore.t('qrScanner.loadError'));
      }
      this.locationStore.changeQuery(
        {
          [QUERY_KEY_QR]: undefined
        },
        true
      );
    }
  };

  protected updateLocation = (qr: IQrInfo) => {
    console.log('LocationQrEffect updateLocation', qr);
    if (qr.vertex) {
      if (!qr.vertex.campus) {
        qr.vertex.campus = qr.campus || undefined;
      }
      this.currentLocationStore.setLocation(qr.vertex);
      this.locationStore.changeQuery(
        {
          [QUERY_KEY_MY_LOCATION]: true,
          [QUERY_KEY_QR]: undefined
        },
        true
      );
    }
  };
}
