import { LocationStore } from '@proscom/prostore-react-router';
import {
  QUERY_KEY_EDGE,
  QUERY_KEY_FLOOR
} from '../../../../../data/core/queryKeys';
import { Action } from '../../../../../utils/prostore/Action';
import { cleanQuery } from './cleanQuery';

export interface SelectEdgeArgs {
  id: number;
  start_floor?: number;
}

export class SelectEdge implements Action<SelectEdgeArgs> {
  constructor(private readonly location: LocationStore) {}

  public run = (edge: SelectEdgeArgs) => {
    if (!edge) {
      return this.location.changeQuery({ [QUERY_KEY_EDGE]: undefined });
    }

    this.location.changeQuery({
      ...cleanQuery,
      [QUERY_KEY_EDGE]: edge.id,
      [QUERY_KEY_FLOOR]: edge.start_floor
    });
  };
}
