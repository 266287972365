import React from 'react';

export interface DescriptionProps {
  text: string;
}

export const Description = React.memo(function Description({
  text
}: DescriptionProps) {
  const lines = text.split('\n');
  return (
    <>
      {lines.map((line, iLine) => (
        <p key={iLine}>{line}</p>
      ))}
    </>
  );
});
