import React, { useMemo } from 'react';
import { NBSP } from '@proscom/ui-utils';
import { Button, RichInput, Space } from '@hse-design/react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableSmallCell,
  TableSmallHeadCell
} from '../../components/Table';
import type { IVertexData } from '../.././data/stores/NodesStore';
import type { IEdgeData } from '../.././data/stores/EdgesStore';
import { dangerColor } from '../../data/theme';
import { UpdateEdgeForm } from '../forms/UpdateEdge';
import { BasePopup, BasePopupProps } from './BasePopup';
import s from '../../MapperPage.module.scss';

export interface EdgePopupProps extends BasePopupProps {
  edge: IEdgeData;
  onOpenNode: (node: IVertexData) => void;
  nodesMap: Record<number, IVertexData>;
  onRemove: (edge: IEdgeData) => void;
  onEditEdge: (edge: Partial<IEdgeData>) => void;
}

type CoordinatesXY = { x?: string | number; y?: string | number };

interface CoordinatesTableProps {
  edge: IEdgeData;
  end: CoordinatesXY;
  start: CoordinatesXY;
}

function CoordinatesTable({ edge, start, end }: CoordinatesTableProps) {
  return (
    <Table className={s.EdgePopup__CoordinatesTable}>
      <TableHead>
        <TableRow>
          <TableSmallHeadCell>{NBSP}</TableSmallHeadCell>
          <TableSmallHeadCell>x</TableSmallHeadCell>
          <TableSmallHeadCell>y</TableSmallHeadCell>
          <TableSmallHeadCell>Этаж</TableSmallHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableSmallCell>Начало</TableSmallCell>
          <TableSmallCell>
            {start.x ? Number(start.x).toFixed(2) : '–'}
          </TableSmallCell>
          <TableSmallCell>
            {start.y ? Number(start.y).toFixed(2) : '–'}
          </TableSmallCell>
          <TableSmallCell>{edge.start_floor}</TableSmallCell>
        </TableRow>
        <TableRow>
          <TableSmallCell>Конец</TableSmallCell>
          <TableSmallCell>
            {end.x ? Number(end.x).toFixed(2) : '–'}
          </TableSmallCell>
          <TableSmallCell>
            {end.y ? Number(end.y).toFixed(2) : '–'}
          </TableSmallCell>
          <TableSmallCell>{edge.end_floor}</TableSmallCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export function EdgePopup({
  edge,
  onOpenNode,
  onClose,
  nodesMap,
  loading,
  onRemove,
  onEditEdge
}: EdgePopupProps) {
  const start =
    edge.start_vertex_id !== undefined
      ? nodesMap[edge.start_vertex_id]
      : undefined;
  const end =
    edge.end_vertex_id !== undefined ? nodesMap[edge.end_vertex_id] : undefined;
  const multiFloor = edge.start_floor !== edge.end_floor;
  const startTriangle =
    multiFloor && edge.start_floor !== undefined && edge.end_floor !== undefined
      ? edge.start_floor > edge.end_floor
        ? ' ▲'
        : ' ▼'
      : '';
  const endTriangle =
    multiFloor && edge.start_floor !== undefined && edge.end_floor !== undefined
      ? edge.end_floor > edge.start_floor
        ? ' ▲'
        : ' ▼'
      : '';

  const Edges = useMemo(
    () =>
      function Edges() {
        return (
          <>
            <Button
              variant={Button.Variant.tertiary}
              size={Button.Size.small}
              onClick={() => start && onOpenNode?.(start)}
            >
              Начальная вершина ({start?.id + startTriangle})
            </Button>
            <Button
              variant={Button.Variant.tertiary}
              size={Button.Size.small}
              onClick={() => end && onOpenNode?.(end)}
            >
              Конечная вершина ({end?.id + endTriangle})
            </Button>
          </>
        );
      },
    [start, startTriangle, end, endTriangle, onOpenNode]
  );

  return (
    <BasePopup loading={loading} title={`Ребро ${edge.id}`} onClose={onClose}>
      <UpdateEdgeForm
        values={{ cost: edge.cost, type: edge.type }}
        onSubmit={(newValues) => {
          onEditEdge({
            id: edge.id,
            ...newValues
          });
        }}
      >
        {start && end && (
          <RichInput<CoordinatesTableProps>
            label={'Координаты вершин'}
            component={CoordinatesTable}
            edge={edge}
            start={start}
            end={end}
          />
        )}
        <RichInput label={'Вершины'} component={Edges} />
        <div className={s.NodePopup__Actions}>
          <Button type={'submit'} variant={Button.Variant.secondary}>
            Сохранить
          </Button>
          <Space size={Space.Size.small_3x} vertical />
          <Button
            className={s.NodePopup__Action}
            color={dangerColor}
            type="button"
            variant={Button.Variant.secondary}
            onClick={() => onRemove(edge)}
          >
            Удалить
          </Button>
        </div>
      </UpdateEdgeForm>
    </BasePopup>
  );
}
