import React from 'react';
import clsx from 'clsx';
import { ActionsList } from '../../../../common/components/ActionsList/ActionsList';
import { RoomInfoActions } from './RoomInfoActions';
import s from './RoomInfoHead.module.scss';

export interface RoomInfoHeadProps {
  className?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  roomId: number;
}

export function RoomInfoHead({
  className,
  roomId,
  title,
  description
}: RoomInfoHeadProps) {
  return (
    <div className={clsx(s.RoomInfoHead, className)}>
      {title && <h2 className={s.RoomInfoHead__title}>{title}</h2>}
      {description && (
        <div className={s.RoomInfoHead__description}>{description}</div>
      )}
      <div className={s.RoomInfoHead__actions}>
        <ActionsList>
          <RoomInfoActions roomId={roomId} />
        </ActionsList>
      </div>
    </div>
  );
}
