import React from 'react';
import { useContextStore, useStore } from '@proscom/prostore-react';
import { BottomSheetContent } from '../../../../common/mobile/BottomSheetContent/BottomSheetContent';
import { SearchPanelContent } from '../../components/SearchPanelContent/SearchPanelContent';
import {
  STORE_ACTIVE_PANEL,
  STORE_ROOM_SEARCH_RESULTS
} from '../../../../data/stores';
import { ActivePanel } from '../../../../data/core/ActivePanel';
import { IRoomSearchResults } from '../../../../data/online/RoomSearchResults';
import { BottomSheetNew } from '../../../../common/mobile/BottomSheetNew/BottomSheetNew';
import { useLocale } from '../../../../common/hooks/useLocale';
import { useOnScrollBottom } from '../../../../utils/hooks/useOnScrollBottom';
import s from './SearchPanel.module.scss';

export function SearchPanel() {
  const locale = useLocale();
  const roomSearchResults = useContextStore<IRoomSearchResults>(
    STORE_ROOM_SEARCH_RESULTS
  );
  const [activePanel, activePanelStore] = useStore<ActivePanel>(
    STORE_ACTIVE_PANEL
  );

  const onScroll = useOnScrollBottom(roomSearchResults.requestNextPage);

  return (
    <BottomSheetNew
      open={activePanel === ActivePanel.PANEL_SEARCH}
      onDismiss={activePanelStore.closePanel}
      headerTitle={locale.t('searchPanel.title')}
      onScroll={onScroll}
      snapPoints={({ maxHeight }) => [maxHeight]}
    >
      <BottomSheetContent>
        <SearchPanelContent className={s.SearchPanel__content} />
      </BottomSheetContent>
    </BottomSheetNew>
  );
}
