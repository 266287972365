import React from 'react';
import clsx from 'clsx';
import s from './NodeMarker.module.scss';

export interface NodeMarkerIconProps {
  type: string;
  selected?: boolean;
  text?: React.ReactNode;
  className?: string;
  icon?: React.ComponentType;
}

export const NodeMarkerIcon = React.memo<NodeMarkerIconProps>(
  ({ type, selected, className, icon: Icon, text }) => {
    return (
      <div
        className={clsx(s.NodeMarkerIcon, s[type], className && s[className], {
          [s._selected]: selected
        })}
      >
        {Icon && (
          <i className={s.NodeMarkerIcon__icon}>
            <Icon />
          </i>
        )}
        {text && <span className={s.NodeMarkerIcon__text}>{text}</span>}
      </div>
    );
  }
);
