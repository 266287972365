import React from 'react';
import clsx from 'clsx';
import { ClickableIcon, IconSize } from '@hse-design/react';
import { ReactComponent as IconMapPin } from '../../../../assets/img/icons/i-map-pin.svg';
import s from './GoToPinControl.module.scss';

export const GoToPinControl = ({
  isDesktopVersion,
  selected,
  onSelect
}: {
  onSelect: () => void;
  selected?: boolean;
  isDesktopVersion: boolean;
}) => (
  <ClickableIcon
    className={clsx(
      s.GoToPinControl,
      selected && s.GoToPinControl_selected,
      !isDesktopVersion && s.GoToPinControl_mobile
    )}
    iconProps={{ icon: IconMapPin, size: IconSize.large }}
    onClick={onSelect}
  />
);
