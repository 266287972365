function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      resolve(image);
    };
  });
}

export class ImageManager {
  cache: { [src: string]: HTMLImageElement } = {};

  async getImage(src: string) {
    if (!this.cache[src]) {
      this.cache[src] = await loadImage(src);
    }
    return this.cache[src];
  }
}
