import React from 'react';
import { useStore } from '@proscom/prostore-react/lib/es/useStore';

import { ToastContainer } from 'react-toastify';
import { ProstoreContext } from '@proscom/prostore-react';
import { AxiosClientsContext } from '@proscom/prostore-axios-react';

import { ToastCloseButton } from '@hse-design/react';

import { LocationProvider } from '@proscom/prostore-react-router';
import { STORE_LOCATION } from './data/stores';
import App from './App';
import { clients, modeContext } from './indexStores';

export const AppContext = () => {
  const [modeStoresState] = useStore(modeContext);
  return (
    modeStoresState.stores && (
      <ProstoreContext.Provider value={modeStoresState.stores}>
        <AxiosClientsContext.Provider value={clients}>
          <LocationProvider storeOrName={STORE_LOCATION}>
            <App />
            <ToastContainer
              closeButton={ToastCloseButton}
              autoClose={5000}
              hideProgressBar={true}
              closeOnClick={false}
            />
          </LocationProvider>
        </AxiosClientsContext.Provider>
      </ProstoreContext.Provider>
    )
  );
};
