import {
  initialRequestState,
  IStoreState,
  ObservableStore
} from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { Portal } from '../../Portal';
import { Building } from '../../Building';
import { Room } from '../../Room';
import { QUERY_KEY_FLOOR } from '../../core/queryKeys';
import { combineLatestMap } from '../../../utils/prostore/combineLatestMap';
import { locationStoreGet$ } from '../../../common/hooks/useLocationQuery';
import { IFloorDataStore } from '../../online/FloorDataStore';
import { CurrentCampusStoreOffline } from './CurrentCampusStoreOffline';

export interface FloorDataStoreOfflineParams {
  locationStore: LocationStore;
  currentCampusStoreOffline: CurrentCampusStoreOffline;
}

export interface FloorDataStoreOfflineState
  extends IStoreState<IFloorDataStore> {}

/**
 * Стор хранит набор {rooms: {@link Room}[];buildings: {@link Building}[]; portals: {@link Portal}[];},
 * необходимый для отрисовки текущего этажа карты
 * @param locationStore - {@link LocationStore}
 * @param currentCampusStoreOffline - {@link CurrentCampusStoreOffline}
 */
export class FloorDataStoreOffline extends ObservableStore<FloorDataStoreOfflineState> {
  constructor(params: FloorDataStoreOfflineParams) {
    super(
      combineLatestMap(
        [
          locationStoreGet$(params.locationStore, QUERY_KEY_FLOOR),
          params.currentCampusStoreOffline.state$
        ] as const,
        ([query, currentCampus]) => {
          if (!currentCampus || !currentCampus.campus) {
            return { ...initialRequestState, loading: true };
          }

          const floorNumber = query[QUERY_KEY_FLOOR];

          if (floorNumber === undefined) {
            return { ...initialRequestState, loading: true };
          }

          const currentFloor = currentCampus.floors.get(floorNumber);

          if (!currentFloor) {
            return { ...initialRequestState, loading: false };
          }

          const rooms = currentFloor.rooms.map((room) => new Room(room));
          const buildings = currentFloor.buildings.map(
            (buildingFloor) => new Building(buildingFloor)
          );
          const portals = currentFloor.portals.map(
            (portalData) => new Portal(portalData)
          );

          return {
            ...initialRequestState,
            data: { rooms, buildings, portals },
            loaded: true
          };
        }
      ),
      { ...initialRequestState }
    );
  }
}
