import { skipIfNull } from '@proscom/prostore';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { LocationStore } from '@proscom/prostore-react-router';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { AxiosRequestConfig } from 'axios';
import { defaultClient } from '../../Client';
import { Room } from '../Room';
import { Portal } from '../Portal';
import { Building } from '../Building';
import { IStoreType } from '../../utils/types';
import { CampusStore } from '../stores/CampusStore';
import { QUERY_KEY_FLOOR } from '../core/queryKeys';
import { LocaleStore } from '../core/LocaleStore';
import { CampusesStore } from '../stores/CampusesStore';
import { ApiFloorDataResponse } from '../api/ApiFloorDataResponse';

export interface FloorDataVars extends AxiosRequestConfig {
  params: {
    campus_id: number;
    locale: string;
  };
}

export interface FloorDataQueryData {
  rooms: Room[];
  buildings: Building[];
  portals: Portal[];
}

export type IFloorDataStore = IStoreType<FloorDataStore>;

export class FloorDataStore extends AxiosQueryStore<
  FloorDataVars | null,
  FloorDataQueryData | null
> {
  constructor({
    locationStore,
    localeStore,
    campusStore,
    campusesStore
  }: {
    locationStore: LocationStore;
    localeStore: LocaleStore;
    campusStore: CampusStore;
    campusesStore: CampusesStore;
  }) {
    super({
      client: defaultClient,
      query: {
        method: 'get'
      },
      skipQuery: skipIfNull(null),
      mapData: (result: ApiFloorDataResponse) => {
        const rooms =
          result?.rooms &&
          result.rooms
            .sort((room1, room2) => {
              const r1ro = room1.room_category?.render_order;
              const r2ro = room2.room_category?.render_order;
              if (r1ro && r2ro) {
                if (r1ro < r2ro) {
                  return -1;
                } else if (r1ro > r2ro) {
                  return 1;
                }
              } else if (r1ro && !r2ro) {
                return -1;
              } else if (!r1ro && r2ro) {
                return 1;
              }

              const r1i = room1.icon;
              const r2i = room2.icon;
              if (r1i && !r2i) {
                return 1;
              } else if (!r1i && r2i) {
                return -1;
              }

              return 0;
            })
            .map((room) => new Room(room));

        const buildings = result?.buildingFloors?.map(
          (buildingFloor) => new Building(buildingFloor)
        );

        const portals = result?.portals?.map(
          (portalData) => new Portal(portalData)
        );

        return { rooms, buildings, portals };
      },
      variables$: combineLatest([
        locationStore.get$(QUERY_KEY_FLOOR),
        localeStore.state$,
        campusStore.state$,
        campusesStore.state$
      ]).pipe(
        map(([query, locale, campus, campusesQuery]) => {
          if (!campus || !locale.lang) return { variables: null };

          const campusesFloors = campusesQuery.data?.floors;
          const queryFloor = query[QUERY_KEY_FLOOR];
          const floor =
            (queryFloor || queryFloor === 0) &&
            campusesFloors &&
            campusesFloors[campus.code] &&
            campusesFloors[campus.code].indexOf(+queryFloor) !== -1
              ? +queryFloor
              : 1;

          return {
            variables: {
              params: {
                campus_id: campus.id,
                locale: locale.lang
              },
              url: `/api/front/floor/${floor}`
            }
          };
        }),
        distinctUntilChanged(isEqual)
      )
    });
  }
}
