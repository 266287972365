import type { AxiosInstance } from 'axios';
import type { LocationStore } from '@proscom/prostore-react-router';
import {
  BehaviorStore,
  skipIfNull,
  SubscriptionManager
} from '@proscom/prostore';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { combineLatest } from 'rxjs';
import immer from 'immer';
import { isNil } from 'lodash';
import type { CampusStore } from '../../../../data/stores/CampusStore';
import { EntityEchoCallbacks, MapEntityMutations } from '../entities/MapEntity';
import {
  QUERY_KEY_CAMPUS,
  QUERY_KEY_FLOOR
} from '../../../../data/core/queryKeys';
import { GetQueryVars } from '../../../../data/api/common';
import { inRangeInclusive } from '../../utils';
import { EdgeType } from '../../../../data/EdgeType';
import type { AuthStore } from './AuthStore';

const baseUrl = '/api/edges';

export interface IEdgesStoreState {
  campus_id: number | null;
  floor: number | null;
  data: Record<IEdgeData['id'], IEdgeData> | null;
}

interface EdgesQueryParams {
  floor: number;
  campus_id: number;
}

export type EdgesQueryVars = GetQueryVars<EdgesQueryParams>;

/**
 * Стор с нодами от сервера
 */
class EdgesQueryStore extends AxiosQueryStore<
  EdgesQueryVars | null,
  IEdgesStoreState['data']
> {
  constructor(axiosInstance: AxiosInstance) {
    super({
      client: axiosInstance,
      skipQuery: skipIfNull(null),
      mapData: ({ data }: { data: IEdgeData[] }) => {
        const map: IEdgesStoreState['data'] = {};
        for (const edge of data) {
          map[edge.id] = edge;
        }
        return map;
      },
      query: {
        url: baseUrl
      },
      initialData: null
    });
  }
}

/**
 * Колбеки для Echo
 */
class EdgesEchoCallbacks implements EntityEchoCallbacks<IEdgeData> {
  constructor(public store: BehaviorStore<IEdgesStoreState>) {}

  public onAdd(edge: IEdgeData) {
    // Проверяем, относится ли добавленная грань к текущему кампусу и этажу
    if (edge.campus_id && edge.campus_id !== this.store.state.campus_id) return;
    if (
      !isNil(edge.start_floor) &&
      !isNil(edge.end_floor) &&
      !isNil(this.store.state.floor) &&
      !inRangeInclusive(
        this.store.state.floor,
        edge.start_floor,
        edge.end_floor
      )
    ) {
      return;
    }
    this.store.setState({
      data: immer(this.store.state.data, (d) => {
        if (d) {
          d[edge.id] = edge;
        }
      })
    });
  }

  public onUpdate(edge: IEdgeData) {
    this.store.setState({
      data: immer(this.store.state.data, (d) => {
        if (d) {
          d[edge.id] = edge;
        }
      })
    });
  }

  public onDelete(edge: IEdgeData) {
    this.store.setState({
      data: immer(this.store.state.data, (d) => {
        if (d) {
          delete d[edge.id];
        }
      })
    });
  }
}

export interface IEdgeData {
  id: number;
  start_vertex_id: number;
  end_vertex_id: number;
  cost?: number;
  type?: EdgeType;
  start_floor?: number;
  end_floor?: number;
  campus_id?: number;
}

function edgeObjectToAddDto({ id, ...edge }: Partial<IEdgeData>) {
  return edgeObjectToDto(edge);
}

function edgeObjectToDto(edge: Partial<IEdgeData>) {
  return JSON.stringify(edge);
}

type AddEdgeInput = Omit<IEdgeData, 'id'>;

class EdgeMutations extends MapEntityMutations<IEdgeData> {
  axiosClient: AxiosInstance;

  constructor(axiosClient: AxiosInstance) {
    super();
    this.axiosClient = axiosClient;
  }

  public async add(edge: AddEdgeInput) {
    const { data } = await this.axiosClient.post(
      baseUrl,
      edgeObjectToAddDto(edge)
    );

    return data;
  }

  public async update({ id, ...edge }: Partial<IEdgeData>) {
    const { data } = await this.axiosClient.put(
      `${baseUrl}/${id}`,
      edgeObjectToDto(edge)
    );

    return data;
  }

  public async delete(id: IEdgeData['id']) {
    const { data } = await this.axiosClient.delete(`${baseUrl}/${id}`);

    return data;
  }
}

export class EdgesStore extends BehaviorStore<IEdgesStoreState> {
  /**
   * Методы для мутации данных (отправляют http запросы на сервер)
   */
  public readonly mutations: EdgeMutations;
  /**
   * Колбеки для подписок Echo (обновляют стейт)
   */
  public readonly echoCallbacks = new EdgesEchoCallbacks(this);

  /**
   * AxiosQuery стор для получения данных через http
   * Обновляет стейт при изменении этажа/кампуса
   * @protected
   */
  protected readonly query: EdgesQueryStore;
  protected readonly sub = new SubscriptionManager();

  private readonly authStore: AuthStore;
  private readonly locationStore: LocationStore;
  private readonly campusStore: CampusStore;

  constructor({
    authStore,
    locationStore,
    campusStore,
    axiosClient
  }: {
    authStore: AuthStore;
    locationStore: LocationStore;
    campusStore: CampusStore;
    axiosClient: AxiosInstance;
  }) {
    super({
      campus_id: null,
      floor: null,
      data: null
    });

    this.authStore = authStore;
    this.locationStore = locationStore;
    this.campusStore = campusStore;
    this.mutations = new EdgeMutations(axiosClient);
    this.query = new EdgesQueryStore(axiosClient);
  }

  onSubscribe() {
    this.sub.subscribe(
      combineLatest([
        this.locationStore.get$(QUERY_KEY_FLOOR, QUERY_KEY_CAMPUS),
        this.campusStore.state$,
        this.authStore.state$
      ]),
      async ([query, campus]) => {
        const floor = query[QUERY_KEY_FLOOR];
        const campus_id = campus?.id;
        // Храним актуальные значения floor и campus_id
        this.setState({
          floor,
          campus_id
        });
        this.query.loadData(
          !isNil(floor) && campus_id
            ? {
                params: {
                  floor,
                  campus_id
                }
              }
            : null
        );
      }
    );

    // Подписываемся на изменение квери стора и обновляем стейт
    this.sub.subscribe(this.query.state$, (queryState) => {
      this.setState({
        data: queryState.data
      });
    });
  }

  onUnsubscribe() {
    this.sub.destroy();
  }
}
