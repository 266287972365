import {
  initialRequestState,
  IRequestState,
  ObservableStore
} from '@proscom/prostore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class RequestProjectionStore<
  Vars,
  OldData,
  Data
> extends ObservableStore<IRequestState<Vars, Data>> {
  constructor(
    private request$: Observable<IRequestState<Vars, OldData>>,
    private project: (data: OldData) => Data
  ) {
    super(
      request$.pipe(
        map((state) => ({
          ...state,
          data: state.data ? project(state.data) : null
        }))
      ),
      initialRequestState
    );
  }
}
