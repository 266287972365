export function edgeExists(edges, startId, endId) {
  return edges.find(
    (edge) =>
      (edge.start_vertex_id === startId && edge.end_vertex_id === endId) ||
      (edge.end_vertex_id === startId && edge.start_vertex_id === endId)
  );
}

export function edgeGoesUp(edge, nodeId) {
  return (
    (edge.start_vertex_id === nodeId && edge.end_floor > edge.start_floor) ||
    (edge.end_vertex_id === nodeId && edge.start_floor > edge.end_floor)
  );
}

export function edgeGoesDown(edge, nodeId) {
  return (
    (edge.start_vertex_id === nodeId && edge.end_floor < edge.start_floor) ||
    (edge.end_vertex_id === nodeId && edge.start_floor < edge.end_floor)
  );
}
