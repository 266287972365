import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useContextStore, useStoreState } from '@proscom/prostore-react';
import ResizeObserver from 'react-resize-observer';
import { Spinner } from '@hse-design/react';
import {
  STORE_CAMPUS,
  STORE_CANVAS_MANIPULATION,
  STORE_CATEGORIES,
  STORE_CURRENT_LOCATION,
  STORE_ROUTE_GRAPH
} from '../../../data/stores';
import { useLocationQuery } from '../../hooks/useLocationQuery';
import {
  QUERY_KEY_BUILDING,
  QUERY_KEY_CATEGORY,
  QUERY_KEY_FLOOR,
  QUERY_KEY_MY_LOCATION,
  QUERY_KEY_ROOM,
  QUERY_KEY_STEP
} from '../../../data/core/queryKeys';
import { RouteStepType } from '../../../data/RouteStepType';
import CampusCanvas from './CanvasClasses/CampusCanvas';
import s2 from './MapBox.module.scss';

export const CampusCanvasComponent = ({
  rooms,
  overlayImages,
  routeSteps,
  selectedFloor,
  buildings,
  portals,
  mapRef,
  pin,
  isCurrentLocationSelected
}) => {
  const canvasRef = useRef();
  const containerRef = useRef();
  const canvasClass = useRef();

  const routeGraphStoreState = useStoreState(STORE_ROUTE_GRAPH);
  const selectedCampus = useStoreState(STORE_CAMPUS);
  const categoriesStoreState = useStoreState(STORE_CATEGORIES);
  const currentLocationStore = useContextStore(STORE_CURRENT_LOCATION);
  const canvasManipulationStore = useContextStore(STORE_CANVAS_MANIPULATION);
  const [query, locationStore] = useLocationQuery([
    QUERY_KEY_ROOM,
    QUERY_KEY_BUILDING,
    QUERY_KEY_STEP,
    QUERY_KEY_CATEGORY,
    QUERY_KEY_FLOOR
  ]);

  const [loading, setLoading] = useState(true);

  const resizeHandler = useCallback(() => {
    if (!canvasClass.current || !containerRef.current) {
      return;
    }

    const canvasWidth = containerRef.current.offsetWidth;
    const canvasHeight = containerRef.current.offsetHeight;

    const size = {
      width: canvasWidth,
      height: canvasHeight
    };
    canvasClass.current.setSize(size);
    canvasClass.current.canvasCenter = {
      x: size.width / 2,
      y: size.height / 2
    };
    mapRef.current = {
      zoomIn: () =>
        canvasClass.current.animateZoom(
          2 * canvasClass.current.scale,
          canvasClass.current.updateMapAfterZoom
        ),
      zoomOut: () =>
        canvasClass.current.animateZoom(
          0.5 * canvasClass.current.scale,
          canvasClass.current.updateMapAfterZoom
        )
    };

    if (canvasClass.current.contentSize) {
      const contentWidth = canvasClass.current.contentSize.width;
      const contentHeight = canvasClass.current.contentSize.height;

      canvasClass.current.minScale = Math.min(
        (0.8 * canvasHeight) / contentHeight,
        (0.8 * canvasWidth) / contentWidth
      );
      canvasClass.current.roomsShownMinScale =
        canvasClass.current.minScale / 0.8;

      if (
        !canvasClass.current.scale ||
        canvasClass.current.scale < canvasClass.current.minScale
      ) {
        canvasClass.current.scale = canvasClass.current.minScale;
        canvasClass.current.setFocus(contentWidth / 2, contentHeight / 2);
        canvasClass.current.needRender = true;
      }
    }

    if (!canvasClass.current.isOnTransfer) {
      canvasClass.current.updateDragLimits();
    }
  }, [mapRef]);

  useEffect(() => {
    canvasClass.current = new CampusCanvas({
      canvasDom: canvasRef.current
    });
    canvasClass.current.updateEventListeners();
    return () => {
      canvasClass.current.removeEventListeners();
      canvasClass.current.clearFrame();
    };
  }, []);

  useEffect(() => {
    canvasManipulationStore.setCanvas(canvasClass.current);
  });

  const queryRoom = query[QUERY_KEY_ROOM];
  const queryFloor = query[QUERY_KEY_FLOOR];
  const queryStep = query[QUERY_KEY_STEP];
  const queryBuilding = query[QUERY_KEY_BUILDING];
  const queryCategory = query[QUERY_KEY_CATEGORY];

  useEffect(() => {
    if (selectedCampus && rooms) {
      const campusChanged =
        canvasClass.current.selectedCampusCode !== selectedCampus.code &&
        canvasClass.current.selectedCampusCode !== -1;
      const floorChanged = canvasClass.current.selectedFloor !== selectedFloor;
      const roomChanged = canvasClass.current.selectedRoomId !== queryFloor;
      const routeStepChanged =
        canvasClass.current.selectedRouteStepId !== queryStep;

      if (campusChanged || floorChanged || roomChanged || floorChanged) {
        canvasClass.current.cancelMoveAnimation();
        canvasClass.current.cancelZoomAnimation();
      }

      const selectedRoom = rooms.find((r) => r.id === queryRoom);
      const selectedStep = routeSteps.find((s) => s.index === queryStep);

      const roomSelected = roomChanged && selectedRoom;
      const routeStepSelected =
        routeStepChanged &&
        selectedStep &&
        selectedStep.type !== RouteStepType.TYPE_TRANSFER;

      if (roomSelected || routeStepSelected) {
        const target = roomSelected ? selectedRoom.center : selectedStep.center;
        if (campusChanged || floorChanged) {
          canvasClass.current.scale = 0.6;
          canvasClass.current.setFocus(target[0], target[1]);
        } else canvasClass.current.animateMove(target[0], target[1]);
        canvasClass.current.needRender = true;
      }

      if (!roomSelected && !routeStepSelected && campusChanged) {
        const contentWidth = canvasClass.current.contentSize?.width;
        const contentHeight = canvasClass.current.contentSize?.height;
        canvasClass.current.scale = canvasClass.current?.minScale;
        canvasClass.current.setFocus(contentWidth / 2, contentHeight / 2);
        canvasClass.current.needRender = true;
      }
    }
    // todo почему нет queryFloor ?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampus, selectedFloor, rooms, queryRoom, routeSteps, queryStep]);

  useEffect(() => {
    canvasClass.current.selectRoom = (room) => {
      locationStore.changeQuery({
        [QUERY_KEY_ROOM]: room && room.id,
        [QUERY_KEY_MY_LOCATION]: undefined
      });
      if (room && (room.building_id || room.building_id === 0)) {
        canvasClass.current.selectedRoomBuildingId = room.building_id;
      } else {
        canvasClass.current.selectedRoomBuildingId = -1;
      }
    };
    canvasClass.current.selectBuilding = (buildingId) => {
      locationStore.changeQuery(
        {
          [QUERY_KEY_BUILDING]: buildingId
        },
        true
      );
    };
    canvasClass.current.updateEventListeners();
  }, [locationStore]);

  useEffect(() => {
    canvasClass.current.onSelectPin = () => {
      currentLocationStore.selectPin();
    };
  }, [currentLocationStore]);

  useEffect(() => {
    if (rooms && rooms.length) canvasClass.current.rooms = rooms;
    else canvasClass.current.rooms = [];
  }, [rooms]);

  useEffect(() => {
    if (portals && portals.length) canvasClass.current.portals = portals;
    else canvasClass.current.portals = [];
  }, [portals]);

  useEffect(() => {
    if (overlayImages && overlayImages.length) {
      canvasClass.current.overlayImages = overlayImages;
      if (overlayImages[2]) {
        canvasClass.current.contentSize = {
          width: overlayImages[2].size.width,
          height: overlayImages[2].size.height
        };
      }
    } else canvasClass.current.overlayImages = [];
  }, [overlayImages]);

  useEffect(() => {
    if (routeSteps && routeSteps.length && routeSteps.length > 1) {
      canvasClass.current.routeSteps = routeSteps;
    } else canvasClass.current.routeSteps = [];
  }, [routeSteps]);

  useEffect(() => {
    if (
      queryBuilding &&
      queryBuilding !== canvasClass.current.selectedBuildingId
    ) {
      canvasClass.current.selectedBuildingId = queryBuilding;
      canvasClass.current.updateBuildings();
      canvasClass.current.needRender = true;
    }
  }, [queryBuilding]);

  useEffect(() => {
    if (canvasClass.current.selectedRoomId === queryRoom) return;
    if (queryRoom) {
      let selectedRoom = canvasClass.current.rooms.find(
        (room) => room.id === queryRoom
      );
      if (selectedRoom) {
        canvasClass.current.selectedRoomBuildingId = selectedRoom.building_id;
        canvasClass.current.selectedBuildingId = selectedRoom.building_id;
        canvasClass.current.selectBuilding(selectedRoom.building_id);
      } else {
        canvasClass.current.selectedRoomBuildingId = -1;
        if (
          canvasClass.current.scale >= canvasClass.current.roomsShownMinScale
        ) {
          canvasClass.current.selectFocusedBuilding();
        } else {
          canvasClass.current.selectedBuildingId = -1;
          canvasClass.current.selectBuilding(undefined);
        }
      }
    } else canvasClass.current.selectedRoomId = -1;
    canvasClass.current.updateBuildings();
    canvasClass.current
      .updateRooms()
      .then(() => (canvasClass.current.needRender = true));
  }, [queryRoom]);

  useEffect(() => {
    if (buildings && buildings.length) {
      canvasClass.current.buildings = buildings;
    } else canvasClass.current.buildings = [];
  }, [buildings]);

  useEffect(() => {
    if (categoriesStoreState.loading) return;
    const selectedCategory =
      categoriesStoreState.data &&
      categoriesStoreState.data.find((c) => c.code === queryCategory);
    canvasClass.current.selectedCategoryId = selectedCategory
      ? selectedCategory.id
      : -1;
    canvasClass.current
      .updateRooms()
      .then(() => (canvasClass.current.needRender = true));
  }, [queryCategory, categoriesStoreState]);

  useEffect(() => {
    canvasClass.current.graph = routeGraphStoreState.data;
  }, [routeGraphStoreState]);

  useEffect(() => {
    if (!loading) {
      canvasClass.current.setPin(pin);
    }
  }, [pin, loading]);

  useEffect(() => {
    if (canvasClass.current && !loading && isCurrentLocationSelected) {
      canvasClass.current.goToPin();
    }
  }, [loading, isCurrentLocationSelected]);

  useEffect(() => {
    if (rooms) {
      resizeHandler();

      if (canvasClass.current.scale >= canvasClass.current.roomsShownMinScale) {
        canvasClass.current.selectFocusedBuilding();
      } else {
        canvasClass.current.selectedBuildingId =
          canvasClass.current.selectedRoomBuildingId;
        if (canvasClass.current.selectedBuildingId === -1) {
          canvasClass.current.selectBuilding(undefined);
        } else {
          canvasClass.current.selectBuilding(
            canvasClass.current.selectedRoomBuildingId
          );
        }
      }

      const floorsMatch =
        rooms.length === 0 || rooms[0].floor_number === selectedFloor;
      const campusesMatch =
        rooms.length === 0 ||
        rooms[0].campus_id === (selectedCampus && selectedCampus.id);

      if (floorsMatch && campusesMatch) {
        canvasClass.current.selectedCampusCode = selectedCampus.code;
        canvasClass.current.selectedFloor = selectedFloor;

        const selectedRoom = rooms.find((room) => room.id === queryRoom);
        if (selectedRoom && selectedRoom.floor_number === selectedFloor) {
          canvasClass.current.selectedRoomId = queryRoom;
        }

        const selectedStep = canvasClass.current.routeSteps.find(
          (s) => s.index === queryStep
        );
        if (selectedStep && selectedStep.type !== RouteStepType.TYPE_TRANSFER) {
          canvasClass.current.selectedRouteStepId = queryStep;
        }

        canvasClass.current.updateCanvas().then(() => {
          canvasClass.current.needRender = true;
          setLoading(false);
        });
      } else {
        setLoading(true);
      }
    }
  }, [
    rooms,
    queryRoom,
    queryStep,
    overlayImages,
    buildings,
    routeSteps,
    selectedFloor,
    portals,
    resizeHandler,
    selectedCampus,
    routeGraphStoreState
  ]);

  return (
    <div className={s2.MapCanvas} ref={containerRef}>
      <canvas ref={canvasRef} />
      <ResizeObserver onResize={resizeHandler} />
      {loading && (
        <div className={s2.MapCanvas__spinnerContainer}>
          <Spinner
            value={65}
            size="XXL"
            loading={true}
            className={s2.MapCanvas__spinner}
          />
        </div>
      )}
    </div>
  );
};
