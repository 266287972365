import React, { useMemo } from 'react';
import { useStore, useStoreState } from '@proscom/prostore-react';
import { IStoreState } from '@proscom/prostore';
import { Loader } from '../../../../common/ui/Loader/Loader';
import { RouteNotFound } from '../../components/RouteNotFound/RouteNotFound';
import { IRouteQueryStore } from '../../../../data/online/RouteQueryStore';
import {
  STORE_ROUTE,
  STORE_ROUTE_GRAPH_DIRECTION,
  STORE_ROUTE_INFO,
  STORE_ROUTE_STEPS
} from '../../../../data/stores';
import { RouteStepsState } from '../../../../data/stores/RouteSteps';
import { useLocationQuery } from '../../../../common/hooks/useLocationQuery';
import { QUERY_KEY_STEP } from '../../../../data/core/queryKeys';
import { RouteInfoState } from '../../../../data/stores/RouteInfo';
import { RouteSettingHead } from '../../components/RouteSteps/RouteSettingHead';
import { RouteStepsHead } from '../../components/RouteSteps/RouteStepsHead';
import { RouteStore } from '../../../../data/stores/RouteStore';
import { RouteSteps } from '../../components/RouteSteps/RouteSteps';
import { PanelSection } from '../../../../common/components/PanelSection/PanelSection';
import s from './RoutePanelResult.module.scss';

export interface RoutePanelResultProps {
  showFirstStep?: boolean;
  showStepsList?: boolean;
}

export function RoutePanelResult({
  showFirstStep,
  showStepsList
}: RoutePanelResultProps) {
  const routeStore = useStoreState<IStoreState<IRouteQueryStore>>(
    STORE_ROUTE_GRAPH_DIRECTION
  );
  const route = routeStore.data?.route;

  const [query] = useLocationQuery([QUERY_KEY_STEP] as const);
  const queryStep = query[QUERY_KEY_STEP]!;

  const routeInfo = useStoreState<RouteInfoState>(STORE_ROUTE_INFO);
  const [routeState, routeService] = useStore<RouteStore>(STORE_ROUTE);

  const routeSteps = useStoreState<RouteStepsState>(STORE_ROUTE_STEPS);
  const routeStepsShown = useMemo(() => {
    return (routeSteps || []).map((step, i) => ({
      ...step,
      shown: showFirstStep || i !== 0
    }));
  }, [routeSteps, showFirstStep]);

  return (
    <>
      {routeStore.loading || !routeStore.loaded ? (
        <Loader />
      ) : route && route.length > 0 ? (
        <>
          {routeState.isOnRoute && !showStepsList ? (
            <RouteStepsHead
              routeSteps={routeStepsShown}
              activeStepIndex={queryStep}
              onCloseClick={routeService.closeRoute}
              onStepChangeHandler={routeService.changeRouteStep}
              skipFirstStep={!showFirstStep}
            />
          ) : (
            <RouteSettingHead
              onStartClick={routeService.startRoute}
              onCloseClick={routeService.closeRoute}
              routeDist={routeInfo?.distance || 0}
              routeTime={routeInfo?.time || 0}
              showActions={!showStepsList}
            />
          )}
          {showStepsList && (
            <PanelSection className={s.RoutePanelResult__panel} dividerOutset>
              <RouteSteps
                routeSteps={routeStepsShown}
                activeStepIndex={queryStep}
                isOnRoute={true}
                onStepClickHandler={routeService.changeRouteStep}
              />
            </PanelSection>
          )}
        </>
      ) : (
        <RouteNotFound />
      )}
    </>
  );
}
