import inside from 'point-in-polygon';
import { findLast } from 'lodash-es';
import icons from '../../../../assets/img/icons/new';
import CanvasDraw from './CanvasDraw';
import { ImageManager } from './ImageManager';
import {
  buildingStyles,
  graphLineStyles,
  iconStyles,
  roomStyles,
  routeLineColors,
  routeLineStyles
} from './canvasObjectsStyles';

const CATEGORY_ROOM_ICON = 'CATEGORY_ROOM_ICON';
const CATEGORY_ROUTE_ICON = 'CATEGORY_ROUTE_ICON';
const CATEGORY_PORTAL_ICON = 'CATEGORY_PORTAL_ICON';
const CATEGORY_ROOM_POLYGON = 'CATEGORY_ROOM_POLYGON';
const CATEGORY_BUILDING_POLYGON = 'CATEGORY_BUILDING_POLYGON';
const CATEGORY_ROOM_LABEL = 'CATEGORY_ROOM_LABEL';
const CATEGORY_BUILDING_LABEL = 'CATEGORY_BUILDING_LABEL';
const CATEGORY_ROUTE_LINE = 'CATEGORY_ROUTE_LINE';
const CATEGORY_GRAPH_LINE = 'CATEGORY_GRAPH_LINE';

/**
 * zIndex-ы объектов на карте:
 * 0 - подложка с дорогами
 * 1 - подложка этажа
 * 2 - полигоны комнат
 * 3 - название комнаты, если она не выбрана
 * 4 - inactive иконка
 * 5 - active иконка
 * 6 - selected иконка, название корпуса при большом зуме, название комнаты,
 * если она выбрана
 * 7 - полигон корпуса
 * 8 - название корпуса при маленьком зуме
 * 9 - линия маршрута
 * 10 - иконки маршрута
 * 11 - пин
 */

/**
 * Docs
 *
 * bounds: Array<Array>, [[x, y], ...]
 */

export default class CampusCanvas extends CanvasDraw {
  constructor({ canvasDom }) {
    super(canvasDom);

    this.buildings = [];
    this.rooms = [];
    this.overlayImages = [];
    this.routeSteps = [];
    this.portals = [];
    this.graph = null;
    this.isOnTransfer = false;
    this.selectedRoomId = -1;
    this.selectedRoomBuildingId = -1;
    this.selectedCategoryId = -1;
    this.selectedRouteStepId = -1;
    this.roomsShownMinScale = 0.25;
    this.selectedBuildingId = -1;
    this.selectedCampusCode = -1;
    this.selectedFloor = -1;
    this.imageManager = new ImageManager();
  }

  updateBuildings = () => {
    this.polygons = this.polygons.filter(
      (polygon) => polygon.category === CATEGORY_ROOM_POLYGON
    );
    this.texts = this.texts.filter(
      (text) => text.category === CATEGORY_ROOM_LABEL
    );
    if (this.scale >= this.roomsShownMinScale) {
      this.buildings.forEach((building) => {
        if (!building.bounds) return;
        if (building.type === 'building') {
          this.addText({
            text: building.name,
            x: building.label_position[0],
            y: building.label_position[1],
            ...buildingStyles.highScale,
            zIndex: buildingStyles.highScale.labelZIndex,
            category: CATEGORY_BUILDING_LABEL
          });
        }
      });
    } else {
      const selectedRoom = this.rooms.find(
        (room) => room.id === this.selectedRoomId
      );
      const routeStartRoom = this.routeSteps.length
        ? this.routeSteps[0].room &&
          this.rooms.find((room) => this.routeSteps[0].room.id === room.id)
        : null;
      const routeEndRoom = this.routeSteps.length
        ? this.routeSteps[this.routeSteps.length - 1].room &&
          this.rooms.find(
            (room) =>
              this.routeSteps[this.routeSteps.length - 1].room.id === room.id
          )
        : null;

      this.buildings.forEach((building) => {
        if (!building.bounds) return;
        const type = buildingStyles[building.type] ? building.type : 'building';
        const buildingStyle =
          buildingStyles[type][
            building.getState(
              selectedRoom && selectedRoom.building_id,
              this.rooms.length ? this.rooms[0].floor_number : null,
              routeStartRoom && routeStartRoom.floor_number,
              routeStartRoom && routeStartRoom.building_id,
              routeEndRoom && routeEndRoom.floor_number,
              routeEndRoom && routeEndRoom.building_id
            )
          ];
        this.addPolygon({
          fill: buildingStyle.backgroundColor,
          borderColor: '#FFFFFF',
          borderWidth: 6,
          bounds: building.bounds,
          zIndex: buildingStyle.zIndex,
          category: CATEGORY_BUILDING_POLYGON
        });
        if (building.type === 'building') {
          this.addText({
            text: building.name,
            x: building.label_position[0],
            y: building.label_position[1],
            ...buildingStyle,
            zIndex: buildingStyle.labelZIndex,
            category: CATEGORY_BUILDING_LABEL
          });
        }
      });
    }
  };

  addRoomLabel = (room, roomStyle) => {
    const shownMinScale = Math.max(
      room.shown_min_scale,
      this.roomsShownMinScale
    );
    if (
      this.scale >= shownMinScale ||
      (room.id === this.selectedRoomId && this.scale >= this.roomsShownMinScale)
    ) {
      this.addText({
        text: room.map_name,
        x: room.label_position[0],
        y: room.label_position[1],
        size: 10,
        ...roomStyle,
        zIndex: roomStyle.labelZIndex,
        category: CATEGORY_ROOM_LABEL
      });
    }
  };

  addRoomIcon = (room, roomsIconsAggregator) => {
    const shownMinScale = Math.max(
      room.shown_min_scale,
      this.roomsShownMinScale
    );
    if (
      !this.isRouteStartOrEnd(room) &&
      ((room.is_object && this.scale >= this.roomsShownMinScale) ||
        (!room.is_object && this.scale >= shownMinScale) ||
        room.id === this.selectedRoomId)
    ) {
      const iconState = room.getIconState(
        this.selectedRoomId,
        this.selectedCategoryId,
        !!this.routeSteps.length
      );
      const { width, height, zIndex, ...iconStyle } = iconStyles[iconState];

      return new Promise((resolve) => {
        this.imageManager.getImage(icons[room.icon]).then((icon) => {
          roomsIconsAggregator.push({
            zIndex,
            width,
            height,
            x: room.position[0],
            y: room.position[1],
            centered: true,
            iconStyle: {
              ...iconStyle,
              borderRadius: width / 4
            },
            icon,
            category: CATEGORY_ROOM_ICON,
            roomId: room.id,
            isObjectIcon: room.is_object,
            type: 'icon'
          });
          resolve();
        });
      });
    }
  };

  isRouteStartOrEnd = (room) => {
    return (
      this.routeSteps.length &&
      ((this.routeSteps[0].room && room.id === this.routeSteps[0].room.id) ||
        (this.routeSteps[this.routeSteps.length - 1].room &&
          room.id === this.routeSteps[this.routeSteps.length - 1].room.id))
    );
  };

  getRoomStyle = (room) => {
    const roomState = room.getState(
      this.selectedRoomId,
      this.selectedCategoryId,
      !!this.routeSteps.length,
      this.isRouteStartOrEnd(room)
    );

    const style = roomStyles[roomState];

    if (room.color) {
      if (roomState === 'inactive') {
        return {
          ...style,
          backgroundColor: room.color
        };
      }
    }

    return style;
  };

  updateRooms = async () => {
    this.polygons = this.polygons.filter(
      (polygon) => polygon.category === CATEGORY_BUILDING_POLYGON
    );

    this.rooms.forEach((currentRoom) => {
      if (!currentRoom.is_object) {
        const roomStyle = this.getRoomStyle(currentRoom);
        this.addPolygon({
          fill: roomStyle.backgroundColor,
          bounds: currentRoom.bounds,
          zIndex: 2,
          category: CATEGORY_ROOM_POLYGON
        });
      }
    });

    await this.updateRoomsIconsAndLabels();
  };

  updateRoomsIconsAndLabels = async () => {
    this.texts = this.texts.filter(
      (text) => text.category === CATEGORY_BUILDING_LABEL
    );
    this.icons = this.icons.filter(
      (icon) => icon.category !== CATEGORY_ROOM_ICON
    );

    if (this.scale >= this.roomsShownMinScale) {
      const promises = [];
      const roomsIcons = [];

      this.rooms.forEach((room) => {
        const roomStyle = this.getRoomStyle(room);
        if (room.icon) promises.push(this.addRoomIcon(room, roomsIcons));
        else this.addRoomLabel(room, roomStyle);
      });

      await Promise.all(promises);
      this.icons = this.icons.concat(roomsIcons);
    }
  };

  updatePortals = async () => {
    this.icons = this.icons.filter(
      (icon) => icon.category !== CATEGORY_PORTAL_ICON
    );

    if (this.scale >= this.roomsShownMinScale) {
      const promises = [];
      const portalsIcons = [];

      this.portals.forEach((portal) => {
        const { width, height, zIndex, ...iconStyle } = iconStyles.inactive;

        promises.push(
          new Promise((resolve) => {
            this.imageManager.getImage(icons[portal.iconName]).then((icon) => {
              portalsIcons.push({
                zIndex: zIndex,
                width,
                height,
                x: portal.x,
                y: portal.y,
                centered: true,
                iconStyle: {
                  ...iconStyle,
                  borderRadius: width / 4
                },
                icon,
                category: CATEGORY_PORTAL_ICON,
                type: 'icon'
              });
              resolve();
            });
          })
        );
      });

      await Promise.all(promises);
      this.icons = this.icons.concat(portalsIcons);
    }
  };

  updateOverlayImages = async () => {
    this.images = [];
    const promises = this.overlayImages
      .filter((image) => image.src)
      .sort((imageA, imageB) => imageA.zIndex - imageB.zIndex)
      .map((currentImagesObj, currentImagesObjI) => {
        return new Promise((resolve) => {
          this.imageManager.getImage(currentImagesObj.src).then((image) => {
            this.addImage({
              zIndex: currentImagesObjI,
              width: currentImagesObj.size.width,
              height: currentImagesObj.size.height,
              x: currentImagesObj.size.x,
              y: currentImagesObj.size.y,
              image
            });

            resolve();
          });
        });
      });
    await Promise.all(promises);
  };

  updateRoute = async () => {
    this.lines = this.lines.filter(
      (line) => line.category !== CATEGORY_ROUTE_LINE
    );
    const promises = [];
    const routeIcons = [];

    this.routeSteps.forEach((routeStep) => {
      this.addLine({
        zIndex: 9,
        vertexes: routeStep.vertexes,
        strokeStyle:
          routeStep.index !== this.selectedRouteStepId &&
          this.selectedRouteStepId !== -1
            ? routeLineColors.notSelected
            : routeLineColors.selected,
        ...routeLineStyles,
        category: CATEGORY_ROUTE_LINE
      });

      routeStep.icons.forEach((icon) => {
        const iconState = routeStep.getIconState(this.selectedRouteStepId);
        const { width, height, ...iconStyle } = iconStyles[iconState];

        promises.push(
          new Promise((resolve) => {
            this.imageManager
              .getImage(icons[icon.iconName])
              .then((iconImage) => {
                routeIcons.push({
                  zIndex: 10,
                  width,
                  height,
                  x: icon.x,
                  y: icon.y,
                  centered: true,
                  iconStyle: {
                    ...iconStyle,
                    borderRadius:
                      icon.iconName === 'start' || icon.iconName === 'end'
                        ? width / 2
                        : width / 4
                  },
                  icon: iconImage,
                  category: CATEGORY_ROUTE_ICON,
                  type: 'icon'
                });

                resolve();
              });
          })
        );
      });
    });

    await Promise.all(promises);
    this.icons = this.icons
      .filter((icon) => icon.category !== CATEGORY_ROUTE_ICON)
      .concat(routeIcons);
  };

  moveToStep = (step) => {
    this.animateMove(step.center[0], step.center[1]);
  };

  updateGraph = () => {
    if (this.graph && this.graph.components) {
      this.lines = this.lines.filter(
        (line) => line.category !== CATEGORY_GRAPH_LINE
      );
      this.graph.components.forEach((component) =>
        component.edges.forEach((edge) => {
          this.addLine({
            zIndex: 9,
            vertexes: [
              {
                x: edge.start_vertex.x,
                y: edge.start_vertex.y
              },
              {
                x: edge.end_vertex.x,
                y: edge.end_vertex.y
              }
            ],
            ...graphLineStyles,
            strokeStyle: component.color,
            category: CATEGORY_GRAPH_LINE
          });
          this.addCircle({
            zIndex: 10,
            x: edge.start_vertex.x,
            y: edge.start_vertex.y,
            radius: 2,
            color: '#FF0000'
          });
          this.addCircle({
            zIndex: 10,
            x: edge.end_vertex.x,
            y: edge.end_vertex.y,
            radius: 2,
            color: '#FF0000'
          });
        })
      );
    }
  };

  updateCanvas = async () => {
    this.updateGraph();
    this.updateBuildings();
    await this.updateRooms();
    await this.updatePortals();
    await this.updateOverlayImages();
    await this.updateRoute();
  };

  selectRoom = () => {};

  onSelectPin = () => {};

  findClickedPin = (clickedPoint) => {
    if (this.pin) {
      const { x, y, width, height } = this.pin;

      const iconPolygon = [
        [x - (0.5 * width) / this.scale, y - (0.5 * height) / this.scale],
        [x + (0.5 * width) / this.scale, y - (0.5 * height) / this.scale],
        [x + (0.5 * width) / this.scale, y + (0.5 * height) / this.scale],
        [x - (0.5 * width) / this.scale, y + (0.5 * height) / this.scale]
      ];
      return inside(clickedPoint, iconPolygon);
    }
    return null;
  };

  findClickedRoom = (clickedPoint) => {
    return findLast(this.rooms, (room) => {
      if (room.is_object) {
        const { width, height } = iconStyles[
          room.getState(
            this.selectedRoomId,
            this.selectedCategoryId,
            !!this.routeSteps.length
          )
        ];
        const [x, y] = room.center;
        const iconPolygon = [
          [x - (0.5 * width) / this.scale, y - (0.5 * height) / this.scale],
          [x + (0.5 * width) / this.scale, y - (0.5 * height) / this.scale],
          [x + (0.5 * width) / this.scale, y + (0.5 * height) / this.scale],
          [x - (0.5 * width) / this.scale, y + (0.5 * height) / this.scale]
        ];
        return inside(clickedPoint, iconPolygon);
      }

      return room.isPointInside(clickedPoint);
    });
  };

  selectBuilding = () => {};

  findFocusedBuilding = () => {
    if (this.buildings.length) {
      const { width, height } = this.canvas.getBoundingClientRect();
      const focusedPoint = {
        x: -this.marginX + (0.5 * width) / this.scale,
        y: -this.marginY + (0.5 * height) / this.scale
      };
      let minDistance = null;
      let closestBuildingId = undefined;
      this.buildings
        .filter((building) => building.type === 'building')
        .forEach((building) => {
          if (minDistance && building.center) {
            const distance = this.getDistance(
              { x: building.center[0], y: building.center[1] },
              focusedPoint
            );
            if (distance < minDistance) {
              minDistance = distance;
              closestBuildingId = building.id;
            }
          } else if (building.center) {
            minDistance = this.getDistance(
              { x: building.center[0], y: building.center[1] },
              focusedPoint
            );
            closestBuildingId = building.id;
          }
        });
      return closestBuildingId;
    } else return undefined;
  };

  selectFocusedBuilding = () => {
    const focusedBuildingId = this.findFocusedBuilding();
    if (
      focusedBuildingId !== this.selectedBuildingId &&
      (focusedBuildingId || focusedBuildingId === 0)
    ) {
      this.selectedBuildingId = focusedBuildingId;
      this.selectBuilding(focusedBuildingId);
    }
  };

  updateDragLimits = () => {
    if (this.contentSize && this.canvasCenter && this.scale) {
      const xMax = this.canvasCenter.x / this.scale;
      const xMin = xMax - this.contentSize.width;
      const yMax = this.canvasCenter.y / this.scale;
      const yMin = yMax - this.contentSize.height;
      this.dragLimits = { xMax, xMin, yMax, yMin };
    }
  };

  updateMapAfterZoom = async () => {
    if (!this.isOnTransfer) this.updateDragLimits();
    if (this.scale >= this.roomsShownMinScale) {
      this.selectFocusedBuilding();
    } else if (this.selectedBuildingId !== this.selectedRoomBuildingId) {
      this.selectedBuildingId = this.selectedRoomBuildingId;

      if (this.selectedRoomBuildingId === -1) this.selectBuilding(undefined);
      else this.selectBuilding(this.selectedRoomBuildingId);
    }
    this.updateBuildings();
    await this.updateRoomsIconsAndLabels();
    await this.updatePortals();
  };

  handlers = {
    roomClick: (event) => {
      const targetPoint = {};
      if (event.changedTouches) {
        targetPoint.x = event.changedTouches[0].clientX;
        targetPoint.y = event.changedTouches[0].clientY;
      } else {
        targetPoint.x = event.clientX;
        targetPoint.y = event.clientY;
      }

      const rect = this.canvas.getBoundingClientRect();
      const x = (targetPoint.x - rect.left) / this.scale - this.marginX;
      const y = (targetPoint.y - rect.top) / this.scale - this.marginY;
      const isPinClicked = this.findClickedPin([x, y]);
      if (isPinClicked) {
        this.onSelectPin();
      } else {
        this.selectRoom(this.findClickedRoom([x, y]));
      }

      if (window.showClickCoords) console.log(JSON.stringify([x, y]));
    },
    mouseMove: (event) => {
      this.cancelMoveAnimation();
      this.cancelZoomAnimation();
      this.moveEventHandler(event);
      this.needRender = true;
    },
    touchMove: (event) => {
      this.cancelMoveAnimation();
      this.cancelZoomAnimation();
      this.touchMoveEventHandler(event, () =>
        this.updateMapAfterZoom().then(() => (this.needRender = true))
      );
      this.needRender = true;
    },
    mouseDown: (event) => {
      this.mouseDownEventHandler(event, this.handlers.mouseMove);
    },
    mouseUp: (event) => {
      if (this.scale >= this.roomsShownMinScale) this.selectFocusedBuilding();
      this.mouseUpEventHandler(
        event,
        this.handlers.mouseMove,
        this.handlers.roomClick
      );
      this.needRender = true;
    },
    touchStart: (event) => {
      this.touchStartEventHandler(event, this.handlers.touchMove);
    },
    touchEnd: (event) => {
      if (this.scale >= this.roomsShownMinScale) this.selectFocusedBuilding();
      this.touchEndEventHandler(
        event,
        this.handlers.touchMove,
        this.handlers.roomClick
      );
      this.needRender = true;
    },
    wheel: (event) => {
      this.cancelMoveAnimation();
      this.cancelZoomAnimation();
      this.wheelEventHandler(event, () =>
        this.updateMapAfterZoom().then(() => (this.needRender = true))
      );
    }
  };

  updateEventListeners = () => {
    this.removeEventListeners();
    this.canvas.addEventListener('mousedown', this.handlers.mouseDown);
    this.canvas.addEventListener('mouseup', this.handlers.mouseUp);
    this.canvas.addEventListener('touchstart', this.handlers.touchStart);
    this.canvas.addEventListener('touchend', this.handlers.touchEnd);
    this.canvas.addEventListener('wheel', this.handlers.wheel);
  };
  removeEventListeners = () => {
    this.canvas.removeEventListener('mousedown', this.handlers.mouseDown);
    this.canvas.removeEventListener('mouseup', this.handlers.mouseUp);
    this.canvas.removeEventListener('touchstart', this.handlers.touchStart);
    this.canvas.removeEventListener('touchend', this.handlers.touchEnd);
    this.canvas.removeEventListener('wheel', this.handlers.wheel);
  };
}
