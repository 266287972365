import { BehaviorStore, SubscriptionManager } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import { QUERY_KEY_SEARCH } from '../core/queryKeys';

export interface RoomSearchStoreState {
  search?: string;
  page: number;
}

export class RoomSearchStore extends BehaviorStore<RoomSearchStoreState> {
  sub = new SubscriptionManager();

  constructor(private location: LocationStore) {
    super({
      page: 1
    });
  }

  onSubscribe() {
    super.onSubscribe();
    this.sub.subscribe(
      locationStoreGet$(this.location, QUERY_KEY_SEARCH),
      (query) => {
        this.setState({ search: query[QUERY_KEY_SEARCH], page: 1 });
      }
    );
  }

  onUnsubscribe() {
    super.onUnsubscribe();
    this.sub.destroy();
  }

  setSearch = (search?: string) => {
    this.location.changeQuery({
      [QUERY_KEY_SEARCH]: search
    });
  };

  setPage = (page: number) => {
    this.setState({ page });
  };
}
