import { isFloorChangingVertexType } from '../../../data/Vertex';
import { isFloorChangingEdgeType } from '../../../data/Edge';
import { LocaleStore } from '../../../data/core/LocaleStore';
import { SegmentType } from '../../../data/SegmentType';
import { VertexType } from '../../../data/VertexType';
import { IRouteEdge } from '../../../data/RouteEdge';
import { Segment } from './Segment';

export class SegmentFloorChange extends Segment {
  type: SegmentType;
  start_floor: number;
  end_floor?: number;

  constructor(start_index: number, part: IRouteEdge) {
    super(start_index, part);

    if (part.vertex.type === VertexType.elevator) {
      this.type = SegmentType.elevator;
    } else {
      this.type = SegmentType.stairs;
    }
    this.start_floor = part.vertex.floor_number;
  }

  setEndNode(part: IRouteEdge) {
    this.end_floor = part.vertex.floor_number;
  }

  processNext(part: IRouteEdge) {
    if (
      isFloorChangingVertexType(part.vertex.type) &&
      part.edge &&
      part.edge.type &&
      isFloorChangingEdgeType(part.edge.type)
    ) {
      this.cost_time += +part.cost_time!;
      this.cost += +part.cost!;
      return true;
    }

    return false;
  }

  setHint(locale: LocaleStore) {
    const up =
      this.end_floor !== undefined && this.end_floor >= this.start_floor
        ? 'up'
        : 'down';
    this.hint = locale.t(`route.segment.floor_change.${this.type}.${up}`, {
      floor: String(this.end_floor)
    });
  }
}
