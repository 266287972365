import { Icon } from '@hse-design/react';
import React from 'react';
import s from './SideMenuLink.module.scss';

export interface SideMenuLinkIconProps {
  className: string;
}

export interface SideMenuLinkProps<Props> {
  icon?: React.ComponentType<any>;
  renderIcon?: (props: SideMenuLinkIconProps) => JSX.Element;
  text?: React.ReactNode;
  component: React.ComponentType<Props>;
  props: Props;
}

export function SideMenuLink<Props>({
  icon,
  renderIcon,
  text,
  component: Component,
  props
}: SideMenuLinkProps<Props>) {
  return (
    <Component {...props} className={s.SideMenuLink}>
      {renderIcon ? (
        renderIcon({ className: s.SideMenuLink__icon })
      ) : (
        <Icon icon={icon} className={s.SideMenuLink__icon} />
      )}
      <p className={s.SideMenuLink__text}>{text}</p>
    </Component>
  );
}
