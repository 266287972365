import { LeftPane } from '../../../../common/desktop/LeftPane/LeftPane';
import { LeftPaneContent } from '../../../../common/desktop/LeftPaneContent/LeftPaneContent';
import {
  SelectOnMapPanelContent,
  SelectOnMapPanelContentProps
} from '../../components/SelectOnMapPanelContent/SelectOnMapPanelContent';

export interface DesktopSelectOnMapPanelProps
  extends SelectOnMapPanelContentProps {}

export function DesktopSelectOnMapPanel(props: DesktopSelectOnMapPanelProps) {
  return (
    <LeftPane>
      <LeftPaneContent>
        <SelectOnMapPanelContent {...props} />
      </LeftPaneContent>
    </LeftPane>
  );
}
