import Echo from 'laravel-echo';
import 'pusher-js';

/**
 * Создает инстанс Echo и подключается к каналу channelName
 *
 * @param authToken
 * @param channelName
 * @param additionalOptions
 */
export function createEcho(
  authToken: string,
  channelName: string,
  additionalOptions: Partial<Echo['options']> = {}
) {
  const echoInstance = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    forceTLS: true,
    authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
    auth: {
      headers: {
        authorization: authToken
      }
    },
    ...additionalOptions
  });
  echoInstance.join(channelName);
  return echoInstance;
}
