import type { IOption } from '@hse-design/core';
import { RoomCategoryDto } from '../../data/api/RoomCategoryDto';

export const EDGE_TYPE_CORRIDOR = 'corridor' as const;
export const EDGE_TYPE_STAIRS = 'stairs' as const;
export const EDGE_TYPE_ELEVATOR = 'elevator' as const;
export const EDGE_TYPE_TRANSFER = 'transfer' as const;

// Массив options для Select
export function getRoomCategoriesOptions<Cat extends RoomCategoryDto>(
  rooms: Cat[] = []
): SelectOptions<string, Cat['id']> {
  return rooms.map((r) => ({
    label: `${r.name} (${r.code})`,
    value: r.id
  }));
}

export type SelectOptions<
  L extends string | number,
  V extends string | number = L
> = (Omit<IOption, 'label' | 'value'> & { label: L; value: V })[];
