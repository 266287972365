import { BehaviorStore } from '@proscom/prostore';
import { distinctUntilChanged, map } from 'rxjs/operators';

export enum WindowSizeBreakpoint {
  desktop = 'desktop',
  mobile = 'mobile'
}

export interface IWindowSizeStoreState {
  width: number;
  height: number;
  breakpoint: WindowSizeBreakpoint;
}

function getWindowSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

function getBreakpoint(width) {
  if (width >= 1024) {
    return WindowSizeBreakpoint.desktop;
  }
  return WindowSizeBreakpoint.mobile;
}

function getWindowSizeState() {
  const state: Partial<IWindowSizeStoreState> = getWindowSize();
  state.breakpoint = getBreakpoint(state.width);
  return state as IWindowSizeStoreState;
}

export class WindowSizeStore extends BehaviorStore<IWindowSizeStoreState> {
  constructor() {
    super(getWindowSizeState());
  }

  onSubscribe() {
    super.onSubscribe();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  onUnsubscribe() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState(getWindowSizeState());
  };

  breakpoint$ = this.state$.pipe(
    map((state) => state.breakpoint),
    distinctUntilChanged()
  );
}
