import React from 'react';
import clsx from 'clsx';
import s from './ActionsList.module.scss';

export interface ActionsListProps {
  className?: string;
  children?: React.ReactNode;
}

export function ActionsList({ className, children }: ActionsListProps) {
  return <div className={clsx(s.ActionsList, className)}>{children}</div>;
}
