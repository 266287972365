import { ValueStore } from '@proscom/prostore';
import { ToastStore } from '../../../../../data/core/ToastStore';
import { NodesStore } from '../NodesStore';
import { ModeSetRoom } from '../ModeSetRoom';
import { EditMode } from '../../types';
import { tryOperation } from '../../../utils/tryOperation';
import { AsyncOperationStore } from '../../../../../utils/prostore/AsyncOperationStore';

export class FinishSetRoom extends AsyncOperationStore<[number]> {
  constructor(
    private readonly toast: ToastStore,
    private readonly nodes: NodesStore,
    private readonly modeSetRoom: ModeSetRoom,
    private readonly editorMode: ValueStore<EditMode>
  ) {
    super((...args) => this.perform(...args));
  }

  perform = async (roomId: number) => {
    if (!this.modeSetRoom.state) return;

    const newNode = {
      id: this.modeSetRoom.state.id,
      room_id: roomId
    };

    await tryOperation(
      async () => {
        await this.nodes.mutations.update(newNode);
        this.editorMode.setState(EditMode.DEFAULT);
      },
      {
        name: this.constructor.name,
        toast: this.toast,
        successMsg: 'Комната привязана к вершине',
        errorMsg: 'Ошибка при привязке комнаты к вершине'
      }
    );
  };
}
