import { useCallback } from 'react';

export function useBindCallback<
  BindArgs extends any[],
  LeftArgs extends any[],
  Return
>(
  callback: ((...args: [...BindArgs, ...LeftArgs]) => Return) | undefined,
  ...bindArgs: BindArgs
) {
  return useCallback(
    (...leftArgs: LeftArgs) => callback?.(...bindArgs, ...leftArgs),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, ...bindArgs]
  );
}
