import { useContextStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import React from 'react';
import { Button } from '@hse-design/react';
import { STORE_ACTIVE_PANEL, STORE_LOCATION } from '../../../../data/stores';
import { useLocale } from '../../../../common/hooks/useLocale';
import {
  QUERY_KEY_END_ROOM,
  QUERY_KEY_END_SEARCH,
  QUERY_KEY_ROOM,
  QUERY_KEY_START_ROOM,
  QUERY_KEY_START_SEARCH
} from '../../../../data/core/queryKeys';
import { ActivePanel } from '../../../../data/core/ActivePanel';
import { ReactComponent as IconFromToPin } from '../../../../assets/img/icons/i-pin-from-to.svg';

export interface RoomInfoActionsProps {
  roomId: number;
}

export const RoomInfoActions = React.memo(function RoomInfoActions({
  roomId
}: RoomInfoActionsProps) {
  const locationStore = useContextStore<LocationStore>(STORE_LOCATION);
  const activePanelStore = useContextStore<ActivePanel>(STORE_ACTIVE_PANEL);
  const locale = useLocale();

  const handleRouteStartSelect = () => {
    activePanelStore.setPanel(ActivePanel.PANEL_ROUTE);
    locationStore.changeQuery({
      [QUERY_KEY_START_ROOM]: roomId,
      [QUERY_KEY_START_SEARCH]: undefined,
      [QUERY_KEY_ROOM]: undefined
    });
  };

  const handleRouteEndSelect = () => {
    activePanelStore.setPanel(ActivePanel.PANEL_ROUTE);
    locationStore.changeQuery({
      [QUERY_KEY_END_ROOM]: roomId,
      [QUERY_KEY_END_SEARCH]: undefined,
      [QUERY_KEY_ROOM]: undefined
    });
  };

  return (
    <>
      <Button
        size={Button.Size.large}
        onClick={handleRouteStartSelect}
        leftIcon={IconFromToPin}
      >
        {locale.t('location.fromHere')}
      </Button>
      <Button
        size={Button.Size.large}
        onClick={handleRouteEndSelect}
        leftIcon={IconFromToPin}
      >
        {locale.t('location.toHere')}
      </Button>
    </>
  );
});
