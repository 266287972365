import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { DivPropsWithoutRef } from '@hse-design/react';
import s from './Page.module.scss';

export interface PageProps extends DivPropsWithoutRef {}

export function Page({ className, style, children, ...props }: PageProps) {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handler = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  return (
    <div
      className={clsx(s.Page, className)}
      style={{ height, ...style }}
      {...props}
    >
      {children}
    </div>
  );
}
