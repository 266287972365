import { ObservableStore } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { combineLatestMap } from '../../utils/prostore/combineLatestMap';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import { CampusDto } from '../api/CampusDto';
import { QUERY_KEY_CAMPUS } from '../core/queryKeys';
import { CampusesStore } from './CampusesStore';

export interface CampusStoreArgs {
  campusesStore: CampusesStore;
  locationStore: LocationStore;
}

export class CampusStore extends ObservableStore<CampusDto | null> {
  constructor(private deps: CampusStoreArgs) {
    super(
      combineLatestMap(
        [
          deps.campusesStore.state$,
          locationStoreGet$(deps.locationStore, QUERY_KEY_CAMPUS)
        ] as const,
        ([campuses, query]) => {
          const queryCampus = query[QUERY_KEY_CAMPUS];
          return (
            campuses.data?.data.find((c) => c.code === queryCampus) || null
          );
        }
      ),
      null
    );
  }
}
