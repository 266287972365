import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from '@proscom/prostore-react';
import Modal from 'react-modal';
import QrScanner from 'qr-scanner';
import { useTimeoutRef } from '@proscom/ui-react';
import { STORE_LOCALE } from '../../../data/stores';
import { rnMessenger } from '../../../rnMessenger';
import { NativeMethods } from '../../../utils/ReactNativeMessenger';
import { ReactComponent as IconClose } from '../../../assets/img/icons/i-close.svg';
// Импортируем файл по ссылке чтобы библиотека могла его динамически загрузить
import { LocaleStore } from '../../../data/core/LocaleStore';
// @ts-ignore
import { useLocale } from '../../hooks/useLocale';
import s from './QrModal.module.scss';

const QrScannerWorkerPath = require('!!file-loader!qr-scanner/qr-scanner-worker.min.js');

QrScanner.WORKER_PATH = QrScannerWorkerPath;

function getErrorMessage(error) {
  if (!error) return null;
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error.getMessage === 'function') {
    return error.getMessage();
  }
  return JSON.stringify(error);
}

function QrErrorMessage({ error }) {
  const [, localeStore] = useStore<LocaleStore>(STORE_LOCALE);
  return (
    <div className={s.QrErrorMessage}>
      <p className={s.QrErrorMessage__title}>
        {localeStore.t('qrScanner.errorOccurred')}
      </p>
      <p>{getErrorMessage(error)}</p>
    </div>
  );
}

const STATE_ACCESS = 'ACCESS';
const STATE_HAS_CAMERA = 'HAS_CAMERA';
const STATE_NO_CAMERA = 'NO_CAMERA';

const PERMISSION_STATUS_GRANTED = 'granted';

export interface QrModalProps {
  isOpen?: boolean;
  onClose: () => void;
  onQrScanned: (qr: string) => void;
}

export const QrModal = React.memo(function QrModal({
  isOpen,
  onClose: onCloseProp,
  onQrScanned
}: QrModalProps) {
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null
  );
  const [error, setError] = useState<unknown>(undefined);
  const [state, setState] = useState<string>(STATE_ACCESS);
  const localeStore = useLocale();
  const defaultMessage = localeStore.t('qrScanner.pointAndScan');
  const [overlayMessage, setOverlayMessage] = useState(defaultMessage);
  const overlayTimeoutRef = useTimeoutRef();

  const onClose = useCallback(() => {
    onCloseProp?.();
  }, [onCloseProp]);

  useEffect(() => {
    Promise.resolve()
      .then(() =>
        // Если мы в нативном окружении, то просим доступ к камере
        // Иначе браузер сам запросит
        rnMessenger.isActive
          ? rnMessenger.ready.then(() =>
              rnMessenger.callTimed(NativeMethods.requestCameraPermission, null)
            )
          : PERMISSION_STATUS_GRANTED
      )
      .then((permissionResult) => {
        if (
          permissionResult === PERMISSION_STATUS_GRANTED &&
          navigator.mediaDevices
        ) {
          return QrScanner.hasCamera();
        } else {
          return false;
        }
      })
      .then((result) => {
        if (result) {
          setState(STATE_HAS_CAMERA);
        } else {
          setState(STATE_NO_CAMERA);
        }
      })
      .catch((error) => {
        console.error(error);
        setError(error);
      });
  }, []);

  useEffect(() => {
    if (videoElement && state === STATE_HAS_CAMERA) {
      const qrScanner = new QrScanner(videoElement, (result) => {
        const matches = result.match(/\?qr=(.+)/);
        if (matches) {
          const code = matches[1];
          onQrScanned(code);
          onClose();
        } else {
          setOverlayMessage(localeStore.t('qrScanner.badQrCode'));
          overlayTimeoutRef.set(() => {
            setOverlayMessage(defaultMessage);
          }, 5000);
        }
      });

      qrScanner.start().catch((e) => {
        console.error(e);
        setError(e);
      });

      return () => {
        qrScanner?.destroy();
      };
    }
  }, [
    defaultMessage,
    localeStore,
    onClose,
    onQrScanned,
    overlayTimeoutRef,
    state,
    videoElement
  ]);

  return (
    <Modal
      overlayClassName={s.ModalOverlay}
      className={s.Modal}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      {error ? (
        <div className={s.Modal__container}>
          <QrErrorMessage error={error} />
        </div>
      ) : state === STATE_ACCESS ? (
        <div className={s.Modal__container}>
          <div className={s.QrErrorMessage}>
            <p>{localeStore.t('qrScanner.pleaseGivePermission')}</p>
          </div>
        </div>
      ) : state === STATE_NO_CAMERA ? (
        <div className={s.Modal__container}>
          <QrErrorMessage error={localeStore.t('qrScanner.cameraNotFound')} />
        </div>
      ) : state === STATE_HAS_CAMERA ? (
        <div className={s.Modal__videoContainer}>
          <video className={s.Modal__video} ref={setVideoElement} />
          <div className={s.Modal__overlayTop} />
          {overlayMessage && (
            <div className={s.Modal__overlayMessage}>{overlayMessage}</div>
          )}
        </div>
      ) : (
        false
      )}
      <button className={s.Modal__close} onClick={onClose}>
        <IconClose />
      </button>
    </Modal>
  );
});
