import React from 'react';
import { ReactComponent as IconRoute } from '../../../assets/img/icons/i-direction.svg';
import { ReactComponent as IconClose } from '../../../assets/img/icons/i-close-round.svg';
import { useLocale } from '../../hooks/useLocale';
import { Distance } from '../../../data/Distance';
import s from './RouteInfo.module.scss';

export interface RouteGeneralProps {
  routeDist: number;
  routeTime: number;
  onCloseClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export function RouteGeneral({
  routeDist,
  routeTime,
  onCloseClick
}: RouteGeneralProps) {
  const localeStore = useLocale();
  return (
    <div className={s.RouteGeneral}>
      <div className={s.RouteGeneral__icon}>
        <IconRoute />
      </div>
      <div className={s.RouteGeneral__info}>
        <div className={s.RouteGeneral__title}>
          <p>
            {Distance.formatTime(localeStore, routeTime)}
            {' · '}
            {Distance.formatDistance(localeStore, routeDist)}
          </p>
        </div>
        {/*<div className={s.RouteGeneral__ext}>*/}
        {/*<p>{extInfo}</p>*/}
        {/*</div>*/}
      </div>
      <div className={s.RouteGeneral__close} onClick={onCloseClick}>
        <IconClose />
      </div>
    </div>
  );
}
