import { AxiosInstance } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { IProstoreContext } from '@proscom/prostore-react';
import { ValueStore } from '@proscom/prostore';
import { createAuthClient } from '../axios';
import {
  STORE_CAMPUS,
  STORE_LOCATION,
  STORE_SELECTED_CAMPUS_FLOORS,
  STORE_TOAST
} from '../../../../data/stores';
import { EditMode } from '../types';
import { requireEnv } from '../../../../utils/environment';
import { AuthStore } from './AuthStore';
import { EchoStore } from './EchoStore';
import { NodesStore } from './NodesStore';
import { EdgesStore } from './EdgesStore';
import { MapperFloorsStore } from './MapperFloorsStore';
import { RoomsStore } from './RoomsStore';
import { ConfigStore } from './ConfigStore';
import { MapperQrStore } from './MapperQrStore';
import { SelectedRoom } from './SelectedRoom';
import { SelectedNode } from './SelectedNode';
import { SelectedNodeQrs } from './SelectedNodeQrs';
import { LogoutOnPusherFail } from './effects/LogoutOnPusherFail';
import { NodeUpdateOperation } from './NodeUpdateOperation';
import { NodeRemoveOperation } from './NodeRemoveOperation';
import { NodeAdjacentEdges } from './NodeAdjacentEdges';
import { SelectEdge } from './actions/SelectEdge';
import {
  ACTION_SELECT_EDGE,
  ACTION_SELECT_FLOOR,
  ACTION_SELECT_NODE,
  ACTION_SELECT_ROOM,
  ACTION_START_CONNECT_NODE,
  ACTION_START_SET_ROOM,
  STORE_ACTIVE_EDITOR_MODE,
  STORE_AUTH,
  STORE_ECHO,
  STORE_EDGE_ADD_OPERATION,
  STORE_EDGES,
  STORE_EDITOR_MODE,
  STORE_FINISH_SET_ROOM,
  STORE_HOVER_EDGE_NODES,
  STORE_HOVER_ITEM,
  STORE_HOVER_NODE_EDGES,
  STORE_MAPPER_CONFIG,
  STORE_MAPPER_FLOORS,
  STORE_MAPPER_QR,
  STORE_MODE_CONNECT_NODES,
  STORE_MODE_SET_ROOM,
  STORE_NODE_ADJACENT_EDGES,
  STORE_NODE_REMOVE_OPERATION,
  STORE_NODE_UPDATE_OPERATION,
  STORE_NODES,
  STORE_ROOMS,
  STORE_SELECTED_NODE,
  STORE_SELECTED_NODE_QRS,
  STORE_SELECTED_ROOM
} from './stores';
import { SelectNode } from './actions/SelectNode';
import { SelectRoom } from './actions/SelectRoom';
import { SelectFloor } from './actions/SelectFloor';
import { StartSetRoom } from './actions/StartSetRoom';
import { ModeSetRoom } from './ModeSetRoom';
import { FinishSetRoom } from './actions/FinishSetRoom';
import { StartConnectNode } from './actions/StartConnectNode';
import { ModeConnectNodes } from './ModeConnectNodes';
import { ActiveEditorMode } from './ActiveEditorMode';
import { EdgeAddOperation } from './EdgeAddOperation';
import { HoverItem } from './HoverItem';
import { HoverNodeEdges } from './HoverNodeEdges';
import { HoverEdgeNodes } from './HoverEdgeNodes';

export function initializeMapperPageContext({
  setLoading,
  axiosClient,
  stores
}: {
  setLoading: Dispatch<SetStateAction<number>>;
  axiosClient: AxiosInstance;
  stores: IProstoreContext;
}) {
  const authStore = new AuthStore({
    axiosClient
  });

  const axiosAuthClient = createAuthClient(authStore);

  const echoStore = new EchoStore({
    authStore,
    channelName: requireEnv(
      process.env.REACT_APP_PUSHER_CHANNEL,
      'REACT_APP_PUSHER_CHANNEL'
    ),
    presence: true
  });

  const nodesStore = new NodesStore({
    authStore,
    axiosClient: axiosAuthClient,
    locationStore: stores[STORE_LOCATION] as any,
    campusStore: stores[STORE_CAMPUS] as any
  });

  const edgesStore = new EdgesStore({
    authStore,
    axiosClient: axiosAuthClient,
    locationStore: stores[STORE_LOCATION] as any,
    campusStore: stores[STORE_CAMPUS] as any
  });

  const mapperFloorsStore = new MapperFloorsStore({
    authStore,
    axiosClient: axiosAuthClient
  });

  const roomsStore = new RoomsStore({
    authStore,
    axiosClient: axiosAuthClient,
    locationStore: stores[STORE_LOCATION] as any,
    campusStore: stores[STORE_CAMPUS] as any,
    mapperFloorsStore
  });

  const configStore = new ConfigStore();

  const mapperQrStore = new MapperQrStore({
    authStore,
    axiosClient: axiosAuthClient
  });

  const selectedRoom = new SelectedRoom({
    location: stores[STORE_LOCATION] as any,
    rooms: roomsStore
  });

  const selectedNode = new SelectedNode({
    location: stores[STORE_LOCATION] as any,
    nodes: nodesStore
  });

  const selectedNodeQrs = new SelectedNodeQrs({
    mapperQr: mapperQrStore,
    selectedNode
  });

  const nodeUpdateOperation = new NodeUpdateOperation({
    mapperQr: mapperQrStore,
    nodes: nodesStore,
    selectedNodeQrs: selectedNodeQrs,
    toast: stores[STORE_TOAST] as any
  });

  const nodeRemoveOperation = new NodeRemoveOperation({
    toast: stores[STORE_TOAST] as any,
    nodes: nodesStore,
    location: stores[STORE_LOCATION] as any
  });

  const edgeAddOperation = new EdgeAddOperation(
    stores[STORE_TOAST] as any,
    stores[STORE_CAMPUS] as any,
    edgesStore
  );

  const nodeAdjacentEdges = new NodeAdjacentEdges({
    edges: edgesStore,
    node: selectedNode
  });

  const logoutOnPusherFail = new LogoutOnPusherFail({
    echo: echoStore,
    auth: authStore
  });

  const editorMode = new ValueStore<EditMode>(EditMode.DEFAULT);
  const modeSetRoom = new ModeSetRoom();
  const modeConnectNodes = new ModeConnectNodes(
    editorMode,
    stores[STORE_TOAST] as any,
    edgeAddOperation
  );

  const selectEdge = new SelectEdge(stores[STORE_LOCATION] as any);
  const selectNode = new SelectNode(stores[STORE_LOCATION] as any);
  const selectRoom = new SelectRoom(stores[STORE_LOCATION] as any);
  const selectFloor = new SelectFloor(
    stores[STORE_LOCATION] as any,
    stores[STORE_SELECTED_CAMPUS_FLOORS] as any
  );
  const startSetRoom = new StartSetRoom(editorMode, modeSetRoom);
  const finishSetRoom = new FinishSetRoom(
    stores[STORE_TOAST] as any,
    nodesStore,
    modeSetRoom,
    editorMode
  );
  const startConnectNode = new StartConnectNode(editorMode, modeConnectNodes);

  const activeEditorMode = new ActiveEditorMode(editorMode, (mode) => {
    return {
      [EditMode.SET_ROOM]: modeSetRoom,
      [EditMode.ADD_EDGE]: modeConnectNodes
    }[mode];
  });

  const hoverItem = new HoverItem();
  const hoverNodeEdges = new HoverNodeEdges(edgesStore, hoverItem);
  const hoverEdgeNodes = new HoverEdgeNodes(nodesStore, edgesStore, hoverItem);

  axiosAuthClient.interceptors.request.use(
    (config) => {
      setLoading((s) => s + 1);
      return config;
    },
    (error) => {
      setLoading((s) => s - 1);
      return Promise.reject(error);
    }
  );

  axiosAuthClient.interceptors.response.use(
    (response) => {
      setLoading((s) => s - 1);
      return response;
    },
    (error) => {
      setLoading((s) => s - 1);
      const errorMessage = error.response?.data?.message || error.message;
      if (errorMessage) error.message = errorMessage;
      return Promise.reject(error);
    }
  );

  return {
    axiosAuthClient,
    stores: {
      [STORE_AUTH]: authStore,
      [STORE_ECHO]: echoStore,
      [STORE_NODES]: nodesStore,
      [STORE_EDGES]: edgesStore,
      [STORE_MAPPER_FLOORS]: mapperFloorsStore,
      [STORE_ROOMS]: roomsStore,
      [STORE_MAPPER_CONFIG]: configStore,
      [STORE_MAPPER_QR]: mapperQrStore,
      [STORE_SELECTED_ROOM]: selectedRoom,
      [STORE_SELECTED_NODE]: selectedNode,
      [STORE_SELECTED_NODE_QRS]: selectedNodeQrs,
      [STORE_NODE_UPDATE_OPERATION]: nodeUpdateOperation,
      [STORE_NODE_REMOVE_OPERATION]: nodeRemoveOperation,
      [STORE_NODE_ADJACENT_EDGES]: nodeAdjacentEdges,
      [STORE_EDITOR_MODE]: editorMode,
      [STORE_FINISH_SET_ROOM]: finishSetRoom,
      [STORE_MODE_CONNECT_NODES]: modeConnectNodes,
      [STORE_MODE_SET_ROOM]: modeSetRoom,
      [STORE_ACTIVE_EDITOR_MODE]: activeEditorMode,
      [STORE_EDGE_ADD_OPERATION]: edgeAddOperation,
      [STORE_HOVER_ITEM]: hoverItem,
      [STORE_HOVER_NODE_EDGES]: hoverNodeEdges,
      [STORE_HOVER_EDGE_NODES]: hoverEdgeNodes
    },
    effects: {
      logoutOnPusherFail
    },
    actions: {
      [ACTION_SELECT_EDGE]: selectEdge,
      [ACTION_SELECT_NODE]: selectNode,
      [ACTION_SELECT_ROOM]: selectRoom,
      [ACTION_SELECT_FLOOR]: selectFloor,
      [ACTION_START_SET_ROOM]: startSetRoom,
      [ACTION_START_CONNECT_NODE]: startConnectNode
    }
  };
}
