import { BehaviorStore, SubscriptionManager } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import { RoomCategoryDto } from '../api/RoomCategoryDto';
import { QUERY_KEY_CATEGORY } from '../core/queryKeys';
import { CategoriesStore } from './CategoriesStore';

export interface SearchStoreState {
  selectedCategoryId: number | undefined;
}

const initialState: SearchStoreState = {
  selectedCategoryId: undefined
};

export interface SearchStoreArgs {
  locationStore: LocationStore;
  categoriesStore: CategoriesStore;
}

export class SearchStore extends BehaviorStore<SearchStoreState> {
  protected readonly locationStore: LocationStore;
  protected readonly categoriesStore: CategoriesStore;
  protected readonly sub = new SubscriptionManager();
  public readonly selectedCategory$: Observable<RoomCategoryDto | undefined>;

  constructor({ locationStore, categoriesStore }: SearchStoreArgs) {
    super(initialState);
    this.locationStore = locationStore;
    this.categoriesStore = categoriesStore;

    this.selectedCategory$ = combineLatest([
      locationStoreGet$(this.locationStore, QUERY_KEY_CATEGORY),
      this.categoriesStore.state$
    ]).pipe(
      map(([query, categoriesState]) => {
        const catCode = query[QUERY_KEY_CATEGORY];
        return catCode
          ? categoriesState.data?.find((cat) => cat.code === catCode)
          : undefined;
      })
    );
  }

  onSubscribe() {
    this.sub.subscribe(this.selectedCategory$, (cat) => {
      this.setState({
        selectedCategoryId: cat?.id
      });
    });
  }

  onUnsubscribe() {
    this.sub.destroy();
  }

  setSelectedCategory(catCode: string | undefined) {
    const { query } = this.locationStore.state;
    if (query) {
      if (query[QUERY_KEY_CATEGORY] !== catCode) {
        this.locationStore.changeQuery({
          [QUERY_KEY_CATEGORY]: catCode
        });
      }
    }
  }

  unsetSelectedCategory() {
    this.setSelectedCategory(undefined);
  }
}
