import { LocationStore } from '@proscom/prostore-react-router';
import {
  QUERY_KEY_FLOOR,
  QUERY_KEY_NODE
} from '../../../../../data/core/queryKeys';
import { Action } from '../../../../../utils/prostore/Action';
import { cleanQuery } from './cleanQuery';

export interface SelectNodeArgs {
  id: number;
  floor_number?: number;
}

export class SelectNode implements Action<SelectNodeArgs | null> {
  constructor(private readonly location: LocationStore) {}

  public run = (node: SelectNodeArgs | null) => {
    if (!node) {
      return this.location.changeQuery({ [QUERY_KEY_NODE]: undefined });
    }

    this.location.changeQuery({
      ...cleanQuery,
      [QUERY_KEY_NODE]: node.id,
      [QUERY_KEY_FLOOR]: node.floor_number
    });
  };
}
