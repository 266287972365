import { PartialKey } from '@proscom/prostore';

export interface MapEntity {
  id: number | string;
  [key: string]: any;
}

export interface IMapEntityMutations<T extends MapEntity> {
  add(item: PartialKey<T, 'id'>): void;

  update(item: Partial<T>): void;

  delete(itemId: T['id']): void;
}

export abstract class MapEntityMutations<ItemType extends MapEntity>
  implements IMapEntityMutations<ItemType>
{
  abstract add(item: PartialKey<ItemType, 'id'>);

  abstract update(item: Partial<ItemType>);

  abstract delete(itemId: ItemType['id']);
}

export interface EntityEchoCallbacks<T> {
  onAdd(entity: T): void;
  onUpdate(entity: T): void;
  onDelete(entity: T): void;
}
