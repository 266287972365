import clsx from 'clsx';
import { useStoreState } from '@proscom/prostore-react';
import { STORE_ROOM_SEARCH_RESULTS_GROUPED } from '../../../../data/stores';
import { requestLoader } from '../../../../common/utils/requestLoader';
import { RoomList } from '../../mobile/RoomsList/RoomList';
import { RoomSearchResultsGroupedState } from '../../../../data/stores/RoomSearchResultsGrouped';
import { useLocale } from '../../../../common/hooks/useLocale';
import { RoomDto } from '../../../../data/api/RoomDto';
import s from './RoomSearchResults.module.scss';

export interface RoomSearchResultsProps {
  className?: string;
  onRoomClick?: (room: RoomDto) => void;
}

export function RoomSearchResults({
  className,
  onRoomClick
}: RoomSearchResultsProps) {
  const locale = useLocale();
  const rooms = useStoreState<RoomSearchResultsGroupedState>(
    STORE_ROOM_SEARCH_RESULTS_GROUPED
  );

  if (!rooms.loading && !rooms.loaded) {
    return null;
  }

  return (
    <div className={clsx(s.RoomSearchResults, className)}>
      <div className={s.RoomSearchResults__title}>
        {locale.t('roomSearchResults.title')}
      </div>
      <RoomList
        roomGroups={rooms.data || []}
        searchValue={rooms.loadedVariables?.search}
        onRoomClick={onRoomClick}
      />
      {requestLoader(rooms)}
    </div>
  );
}
