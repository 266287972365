import { ObservableStore } from '@proscom/prostore';
import { makeComparator } from '@proscom/ui-utils';
import { combineLatestMap } from '../../utils/prostore/combineLatestMap';
import {
  extendExtendedRequestState,
  extendRequestState,
  IExtendedRequestState,
  initialExtendedRequestState
} from '../../utils/prostore/ExtendedRequestStore';
import { RoomDto } from '../api/RoomDto';
import { IRoomSearchResults } from '../online/RoomSearchResults';
import { RouteStore } from './RouteStore';
import { CampusStore } from './CampusStore';
import { CampusesStore } from './CampusesStore';
import { RoomSearchVars } from './RoomSearchVarsStore';

export interface RoomSearchResultGroup {
  id: number;
  name: string;
  rooms: RoomDto[];
}

export type RoomSearchResultsGroupedData = RoomSearchResultGroup[];

export type RoomSearchResultsGroupedState = IExtendedRequestState<
  RoomSearchVars,
  RoomSearchResultsGroupedData
>;

export class RoomSearchResultsGrouped extends ObservableStore<RoomSearchResultsGroupedState> {
  constructor(
    private roomSearchResults: IRoomSearchResults,
    private route: RouteStore,
    private campus: CampusStore,
    private campuses: CampusesStore
  ) {
    super(
      combineLatestMap(
        [
          roomSearchResults.state$,
          route.state$,
          campus.state$,
          campuses.state$
        ] as const,
        ([results, route, campus, campuses]) => {
          if (!results.data) {
            return extendExtendedRequestState(results, {
              loadedVariables: null,
              data: null
            });
          }
          if (!campuses.data) {
            return extendRequestState(campuses, {
              variables: null,
              loadedVariables: null,
              data: null
            });
          }
          if (!campus) {
            return {
              ...initialExtendedRequestState,
              loading: true
            };
          }

          const filteredRooms = results.data.filter(
            (room) =>
              !(
                (route.routeStartPoint &&
                  route.routeStartPoint.id === room.id) ||
                (route.routeEndPoint && route.routeEndPoint.id === room.id)
              )
          );

          const grouped: { [key: number]: RoomDto[] } = {};

          for (const room of filteredRooms) {
            if (!grouped[room.campus_id]) {
              grouped[room.campus_id] = [room];
            } else {
              grouped[room.campus_id].push(room);
            }
          }

          const sortedCampuses = campuses.data.data.slice().sort(
            makeComparator(
              (a: number, b: number) => {
                const isA = a === campus.id;
                const isB = b === campus.id;
                return isA && isB ? 0 : isA ? -1 : isB ? 1 : 0;
              },
              (c) => c.id
            )
          );

          const roomGroups = sortedCampuses
            .map((campus) => {
              return {
                id: campus.id,
                name: campus.name,
                rooms: grouped[campus.id]
              };
            })
            .filter((group) => group.rooms && group.rooms.length > 0);

          return {
            ...results,
            data: roomGroups
          };
        }
      ),
      initialExtendedRequestState
    );
  }
}
