import React from 'react';
import { RoomInfoHead } from '../../../routes/index/components/RoomInfoHead/RoomInfoHead';
import { getCategoryName } from '../../../data/Room';
import { PanelSection } from '../PanelSection/PanelSection';
import { RoomExtSection } from '../RoomExt/RoomExtSection';
import { RoomExtDescription } from '../RoomExt/RoomExtDescription';
import { RoomExtStaff } from '../RoomExt/RoomExtStaff';
import { RoomDto } from '../../../data/api/RoomDto';
import { useLocale } from '../../hooks/useLocale';
import s from './RoomPanelContent.module.scss';

export interface RoomPanelContentProps {
  room: RoomDto;
  headRef?: React.Ref<HTMLDivElement>;
}

export function RoomPanelContent({ room, headRef }: RoomPanelContentProps) {
  const locale = useLocale();
  const notExpandable =
    (!room.staff || !room.staff.length) && !room.description;

  return (
    <>
      <div className={s.RoomPanelContent__head} ref={headRef}>
        <RoomInfoHead description={getCategoryName(room)} roomId={room.id} />
      </div>
      {!notExpandable && (
        <PanelSection dividerOutset>
          <div className={s.RoomPanelContent__roomExt}>
            {room.description && (
              <RoomExtSection title={locale.t('pane.room.general_information')}>
                <RoomExtDescription description={room.description} />
              </RoomExtSection>
            )}
            {room.staff?.length ? (
              <RoomExtSection title={locale.t('pane.room.employees_title')}>
                <RoomExtStaff staff={room.staff} />
              </RoomExtSection>
            ) : null}
          </div>
        </PanelSection>
      )}
    </>
  );
}
