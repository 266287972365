import React, { useEffect, useState } from 'react';
import { useLocationQuery } from '../../../../common/hooks/useLocationQuery';
import { getRoomName } from '../../../../data/Room';
import { useLocale } from '../../../../common/hooks/useLocale';
import { QUERY_KEY_SEARCH } from '../../../../data/core/queryKeys';
import { RoomDto } from '../../../../data/api/RoomDto';
import { RouteSearchPoint, RouteSearchPointProps } from './RouteSearchPoint';

export interface RouteSearchPointConnectedProps
  extends Omit<RouteSearchPointProps, 'value'> {
  queryKeySearch: string;
  queryKeyRoom: string;
  queryKeyVertex: string;
  room?: RoomDto;
}

export function RouteSearchPointConnected({
  onChange,
  queryKeySearch,
  queryKeyRoom,
  queryKeyVertex,
  room,
  ...props
}: RouteSearchPointConnectedProps) {
  const locale = useLocale();
  const [query, locationStore] = useLocationQuery([
    queryKeySearch,
    queryKeyRoom,
    queryKeyVertex
  ]);
  const querySearch = query[queryKeySearch] as string;
  const queryRoom = query[queryKeyRoom] as string;
  const queryVertex = query[queryKeyVertex] as string;
  const propValue = queryRoom
    ? room
      ? getRoomName(room, true, locale)
      : '...'
    : queryVertex
    ? locale.t('currentLocation.title')
    : querySearch || '';

  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const handleChange = (value: string) => {
    setValue(value);
    locationStore.changeQuery(
      {
        [queryKeySearch]: value,
        [queryKeyRoom]: undefined,
        [queryKeyVertex]: undefined,
        [QUERY_KEY_SEARCH]: value
      },
      true
    );
    onChange?.(value);
  };

  return <RouteSearchPoint {...props} value={value} onChange={handleChange} />;
}
