import { skipIfNull } from '@proscom/prostore';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { LocationStore } from '@proscom/prostore-react-router';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { AxiosRequestConfig } from 'axios';
import { defaultClient } from '../../Client';
import { RouteGraph } from '../RouteGraph';
import { CampusStore } from '../stores/CampusStore';
import { ShowGraphStore } from '../core/ShowGraphStore';
import { QUERY_KEY_FLOOR } from '../core/queryKeys';

export class RouteGraphStore extends AxiosQueryStore<
  AxiosRequestConfig | null,
  RouteGraph | null
> {
  constructor({
    locationStore,
    campusStore,
    showGraphStore
  }: {
    locationStore: LocationStore;
    campusStore: CampusStore;
    showGraphStore: ShowGraphStore;
  }) {
    super({
      client: defaultClient,
      query: {
        method: 'get',
        url: `/api/front/edges`
      },
      skipQuery: skipIfNull(null),
      mapData: (result) =>
        new RouteGraph(result.data.filter((edge) => edge.type === 'corridor')),
      variables$: combineLatest([
        locationStore.get$(QUERY_KEY_FLOOR),
        campusStore.state$,
        showGraphStore.state$
      ]).pipe(
        map(([query, campus, showGraphStoreState]) => {
          if (!showGraphStoreState.showGraph || !campus || !campus.id) {
            return { variables: null };
          }

          return {
            variables: {
              params: {
                floor: query[QUERY_KEY_FLOOR],
                campus_id: campus.id
              }
            }
          };
        }),
        distinctUntilChanged(isEqual)
      )
    });
  }
}
