import React from 'react';
import {
  IRouteStepListItem,
  RouteStepList
} from '../../../../common/components/RouteInfo/RouteStepList';
import s from '../../IndexPage.module.scss';

export interface RouteStepsProps {
  isOnRoute?: boolean;
  routeSteps: IRouteStepListItem[];
  activeStepIndex: number;
  onStepClickHandler?: (step: number) => void;
}

export function RouteSteps({
  isOnRoute,
  routeSteps,
  activeStepIndex,
  onStepClickHandler
}: RouteStepsProps) {
  return (
    <div className={s.RouteSteps}>
      <RouteStepList
        isOnRoute={isOnRoute}
        routeSteps={routeSteps}
        activeStepIndex={activeStepIndex}
        onStepClickHandler={onStepClickHandler}
      />
    </div>
  );
}
