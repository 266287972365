import { BehaviorStore } from '@proscom/prostore';

export interface ShowGraphStoreState {
  showGraph: boolean;
}

export class ShowGraphStore extends BehaviorStore<ShowGraphStoreState> {
  constructor() {
    super({
      showGraph: false
    });
  }

  setShowGraph = (showGraph: boolean) => {
    this.setState({ showGraph });
  };
}
