import { LocationStore } from '@proscom/prostore-react-router';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { findMinInArray } from '@proscom/ui-utils';
import { ObservableStore } from '@proscom/prostore';
import { locationStoreGet$ } from '../../common/hooks/useLocationQuery';
import { QUERY_KEY_FLOOR } from '../core/queryKeys';
import { SelectedCampusFloors } from './SelectedCampusFloors';

export class SelectedFloor extends ObservableStore<number> {
  constructor(
    private deps: {
      campusFloors: SelectedCampusFloors;
      location: LocationStore;
    }
  ) {
    super(
      combineLatest([
        deps.campusFloors.state$,
        locationStoreGet$(deps.location, QUERY_KEY_FLOOR)
      ]).pipe(
        map(([campusFloors, query]) => {
          const queryFloor = query[QUERY_KEY_FLOOR];
          if (queryFloor !== undefined) {
            return queryFloor;
          }

          // Выбираем ближайший этаж к первому
          return (
            findMinInArray(campusFloors, (floor) =>
              floor > 0 ? floor : 100 - floor
            ) ?? 1
          );
        })
      ),
      1
    );
  }
}
