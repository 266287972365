import { ValueStore } from '@proscom/prostore';
import CampusCanvas from '../../common/components/MapBox/CanvasClasses/CampusCanvas';

export class CanvasManipulationStore extends ValueStore<{}> {
  canvas?: CampusCanvas;

  constructor() {
    super({});
  }

  setCanvas(canvas: CampusCanvas) {
    this.canvas = canvas;
  }

  goToPin() {
    if (!this.canvas) return;
    this.canvas.goToPin();
  }
}
