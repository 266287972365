import { BehaviorStore, SubscriptionManager } from '@proscom/prostore';
import type Echo from 'laravel-echo';
import { createEcho } from '../echo';
import type { AuthStore } from './AuthStore';

export interface IEchoStoreState {
  pusherState: PusherState | null;
  instance: Echo | null;
  channelName: string;
  presence?: boolean;
  authToken?: string;
}

export enum PusherState {
  Initialized = 'initialized',
  Connecting = 'connecting',
  Connected = 'connected',
  Unavailable = 'unavailable',
  Failed = 'failed',
  Disconnected = 'disconnected'
}

export class EchoStore extends BehaviorStore<IEchoStoreState> {
  sub = new SubscriptionManager();
  authStore: AuthStore;
  channelName: string;

  constructor({
    authStore,
    channelName,
    presence
  }: {
    authStore: AuthStore;
    channelName: string;
    presence: boolean;
  }) {
    super({
      pusherState: null,
      instance: null,
      channelName,
      presence
    });
    this.authStore = authStore;
    this.channelName = channelName;
  }

  register() {
    this.sub.subscribe(this.authStore.state$, ({ value: token }) => {
      if (this.state.instance) {
        this.state.instance.disconnect();
      }
      if (token) {
        this.setState({
          pusherState: null,
          instance: this.createEchoInstance(token, this.channelName)
        });
      } else {
        this.setState({ instance: null, pusherState: null });
      }
    });
  }

  destroy() {
    this.sub.destroy();
    this.disconnect();
  }

  get isConnected(): boolean {
    return this.state.pusherState === PusherState.Connected;
  }

  protected createEchoInstance(authToken: string, channelName: string): Echo {
    const echo = createEcho(authToken, channelName);
    Object.values<PusherState>(PusherState).forEach((eventName) => {
      echo.connector.pusher.connection.bind(eventName, () => {
        this.setState({ pusherState: eventName });
      });
    });
    echo.channel(this.getChannelName()).error((e) => this.onEchoError(e));
    return echo;
  }

  getChannelName() {
    const prefix = this.state.presence ? `presence-` : '';
    return prefix + this.state.channelName;
  }

  disconnect() {
    if (this.isConnected) this.state.instance?.disconnect();
  }

  /**
   * Listen for event in current channel
   * @param eventName
   * @param cb
   */
  on(eventName: string, cb: Function) {
    if (this.state.instance && this.isConnected) {
      this.state.instance.channel(this.getChannelName()).listen(eventName, cb);
    }
    return this;
  }

  /**
   * Listen for event only once. If there are any existing subscribers to eventName – unsubscribe from them
   * @param eventName
   * @param cb
   */
  once(eventName: string, cb: Function) {
    if (this.state.instance && this.isConnected) {
      this.off(eventName);
      this.on(eventName, cb);
    }
    return this;
  }

  /**
   * Stop listening for event in current channel
   * @param eventName
   * @param cb
   */
  off(eventName: string, cb?: Function) {
    if (this.state.instance && this.isConnected) {
      this.state.instance
        .channel(this.getChannelName())
        .stopListening(eventName, cb);
    }
    return this;
  }

  onEchoError(error: any) {
    console.error('EchoStore. Echo error', error);
    if (error.type === 'AuthError') this.authStore.logout();
  }
}
