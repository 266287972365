import React from 'react';
import {
  CircleMarker as LeafletCircleMarker,
  LatLngExpression,
  Marker as LeafletMarker
} from 'leaflet';
import { flipBoundsToLeaflet } from '../../utils';
import { IVertexData } from '../../data/stores/NodesStore';
import { VertexType } from '../../../../data/VertexType';
import { JsxMarker, JsxMarkerProps } from './JsxMarker';
import { CircleMarker } from './CircleMarker';
import { NodeMarkerIcon } from './NodeMarkerIcon';
import { mapperIcons } from './nodeMarkerIcons';

export interface NodeMarkerProps extends Partial<JsxMarkerProps> {
  leafletRef?: React.Ref<LeafletMarker | LeafletCircleMarker>;
  position?: LatLngExpression;
  node: IVertexData;
  selected?: boolean;
  nodeFirst?: boolean;
  nodeLast?: boolean;
  hasUpEdge?: boolean;
  hasDownEdge?: boolean;
  text?: React.ReactNode;
}

const iconProps = {
  iconSize: [16, 16],
  iconAnchor: [8, 8]
};

export const NodeMarker = React.memo(function NodeMarker({
  leafletRef,
  node,
  selected,
  hasUpEdge,
  hasDownEdge,
  text,
  position: positionProp,
  ...props
}: NodeMarkerProps) {
  const position = positionProp || flipBoundsToLeaflet([node.x, node.y]);
  const iconName =
    node.type === VertexType.roomDoor
      ? 'default'
      : hasUpEdge || hasDownEdge
      ? hasUpEdge && hasDownEdge
        ? 'updown'
        : hasUpEdge
        ? 'up'
        : hasDownEdge
        ? 'down'
        : null
      : 'default';

  const nodeIcons = mapperIcons[node.type];
  const style = nodeIcons && (nodeIcons[iconName] || nodeIcons['default']);

  if (props.draggable || text || props.icon || style) {
    return (
      <JsxMarker
        ref={leafletRef}
        position={position}
        iconProps={iconProps}
        content={
          <>
            <NodeMarkerIcon
              type={node.type}
              selected={selected}
              text={text}
              className={style?.className}
              icon={style?.icon}
            />
          </>
        }
        {...props}
      />
    );
  }

  return (
    <CircleMarker
      leafletRef={leafletRef as any}
      center={position}
      selected={selected}
      {...props}
    />
  );
});
