import { WebStorageValueStore } from '@proscom/prostore-local-storage';
import { ModeStoreModeEnum } from '../core/ModeContext';
import { LOCAL_STORAGE_KEY_MODE } from '../core/storageKeys';

export class ModeStore extends WebStorageValueStore<ModeStoreModeEnum> {
  constructor() {
    super(window.localStorage, LOCAL_STORAGE_KEY_MODE, (x) =>
      x === ModeStoreModeEnum.offline
        ? ModeStoreModeEnum.offline
        : ModeStoreModeEnum.online
    );
  }
}

export type IModeStore = ModeStore;
