import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useContextStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { RoomSearchResults } from '../RoomSearchResults/RoomSearchResults';
import { SearchCategories } from '../SearchCategories/SearchCategories';
import { SearchInput } from '../../mobile/SearchInput';
import { STORE_LOCATION } from '../../../../data/stores';
import {
  QUERY_KEY_PANEL,
  QUERY_KEY_ROOM
} from '../../../../data/core/queryKeys';
import { CategoriesSlider } from '../../mobile/CategoriesSlider/CategoriesSlider';
import { RoomDto } from '../../../../data/api/RoomDto';
import s from './SearchPanelContent.module.scss';

export interface SearchPanelContentProps {
  className?: string;
}

export function SearchPanelContent({ className }: SearchPanelContentProps) {
  const locationStore = useContextStore<LocationStore>(STORE_LOCATION);

  const handleRoomClick = useCallback(
    (room: RoomDto) => {
      locationStore.changeQuery(
        { [QUERY_KEY_ROOM]: room.id, [QUERY_KEY_PANEL]: undefined },
        true
      );
    },
    [locationStore]
  );

  return (
    <div className={clsx(s.SearchPanelContent, className)}>
      <SearchInput className={s.SearchPanelContent__input} />
      <SearchCategories
        className={s.SearchPanelContent__categories}
        base={CategoriesSlider}
      />
      <RoomSearchResults
        className={s.SearchPanelContent__searchResults}
        onRoomClick={handleRoomClick}
      />
    </div>
  );
}
