import { createContext, useContext } from 'react';

export interface DiContextValue {
  [key: string]: any;
}

export const DiContext = createContext<DiContextValue>({});

export function useDi<T>(name: string) {
  const context = useContext(DiContext);
  if (!context[name]) {
    throw new Error(
      `useDi. Expected key "${name}" to be provided through DiContext`
    );
  }
  return context[name] as T;
}
