const wordbook = {
  All: 'All',
  'search.placeholder': 'Find a room, person, unit',
  From: 'From',
  To: 'To',
  'location.fromHere': 'From',
  'location.toHere': 'To',
  'routeMapper.from': 'From',
  'routeMapper.to': 'To',
  'routeMapper.selectPointOnMap': 'Select point {activePoint} on map',
  'routeMapper.useMyCurrentLocation': 'My location',
  'selectInMap.room': 'Room',
  'selectInMap.done': 'Done',
  'routeResult.tryAgain': 'Try again',
  'routeResult.notFound': 'Route not found',
  'routeResult.notFoundCall': 'Maybe try searching differently',
  'Scan QR-code': 'Scan QR-code',
  Done: 'Done',
  Room: 'Room',
  'Nothing found on request': 'Nothing found',
  'qrScanner.cameraNotFound': 'Camera not found',
  'qrScanner.errorOccurred': 'Error occurred',
  'qrScanner.pleaseGivePermission':
    'Please give access to your camera to scan QR code',
  'qrScanner.badQrCode':
    "This doesn't seem to be a navigation QR code. Please try a different one",
  'qrScanner.pointAndScan':
    'Point the camera at a QR code to determine your current location',
  'qrScanner.noVertex':
    'This QR code temporarily cannot be used to determine your location. Please try again later',
  'qrScanner.loadError': 'Error loading QR code information. Please try again',
  'qrScanner.ordynka':
    'HSE.Navigation in Ordynka is planned for launch in 2022',
  'We not found any routes': 'We not found any routes',
  'Maybe try searching differently': 'Maybe try searching differently',
  'Try again': 'Try again',
  Navigation: 'Navigation',
  'unit.minutes': 'min',
  'unit.meters': 'm',
  'unit.kilometers': 'km',
  min: 'min',
  m: 'm',
  Schedule: 'Schedule',
  Profile: 'Profile',
  'html.title':
    'Pokrovka and Shabolovka campuses Navigator (web version) - HSE University',
  'header.title': 'HSE University → Campus navigation',
  'header.logo_alt': 'HSE Logo',
  'header.back_alt': 'Link to main site',
  'header.offline': 'Download data to work offline',
  'nav.menu': 'Menu',
  'nav.search': 'Search',
  'nav.route': 'Route',
  'nav.qr': 'QR Scanner',
  'menu.icon_alt': 'Logo',
  'menu.title': 'HSE Navigation',
  'menu.item.language': 'Language',
  'menu.item.feedback': 'Give feedback',
  'page.language.title': 'Language',
  'pane.search.placeholder': 'Search rooms or employees',
  'pane.room.employees_title': 'Employees',
  'pane.room.general_information': 'General Information',
  'map_controls.campus': 'Campus',
  'button.confirm': 'Confirm',
  'event.dod2021.title': 'Open Doors Day 2021',
  'route_step.from': 'From',
  'route_step.to': 'To',
  'route_step.yandex_link': 'Open in Yandex.Maps',
  'route.lets_go': "Let's hit the road",
  'currentLocation.remove': 'Remove',
  'currentLocation.title': 'My location',
  'roomSearchResults.title': 'Search results',
  'routePanelBuild.title': 'Route',
  'searchPanel.title': 'Search',
  'route.segment.corridor': 'Walk {cost} m',
  'route.segment.floor_change.stairs.up':
    'Proceed to the {floor} floor via stairs',
  'route.segment.floor_change.stairs.down':
    'Proceed to the {floor} floor via stairs',
  'route.segment.floor_change.elevator.up':
    'Proceed to the {floor} floor via elevator',
  'route.segment.floor_change.elevator.down':
    'Proceed to the {floor} floor via elevator',
  'offline.status.online': 'Setup offline mode',
  'offline.status.loading': 'Downloading offline data',
  'offline.status.offline': 'Works offline',
  'offline.modal.title': 'Offline mode',
  'offline.modal.introduction':
    'Application can work offline if you setup offline mode. Doing so will\n' +
    'download the required campus data (around 50 MB) and store it on your\n' +
    'device. The data will be periodically updated if you are online.',
  'offline.modal.loading': 'Downloading campus data. This can take a while',
  'offline.modal.offline':
    'Campus data is stored on your device and the application can work\n' +
    'offline.',
  'offline.modal.setup': 'Setup',
  'offline.modal.disable': 'Disable',
  'offline.modal.ok': 'Ok'
};

export default wordbook;
