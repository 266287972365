export const STORE_LAYOUT = 'STORE_LAYOUT';
export const STORE_SEARCH = 'STORE_SEARCH';
export const STORE_ROOM = 'STORE_ROOM';
export const STORE_ROUTE = 'STORE_ROUTE';
export const STORE_FLOOR_DATA = 'STORE_FLOOR_DATA';
export const STORE_LOCALE = 'STORE_LOCALE';
export const STORE_NAV = 'STORE_NAV';
export const STORE_LOCATION = 'STORE_LOCATION';
export const STORE_STATISTICS = 'STORE_STATISTICS';
export const STORE_SHOW_GRAPH = 'STORE_SHOW_GRAPH';
export const STORE_ROUTE_GRAPH = 'STORE_ROUTE_GRAPH';
export const STORE_ROUTE_GRAPH_DIRECTION = 'STORE_ROUTE_GRAPH_DIRECTION';
export const STORE_CAMPUSES = 'STORE_CAMPUSES';
export const STORE_CAMPUS = 'STORE_CAMPUS';
export const STORE_CANVAS_MANIPULATION = 'STORE_CANVAS_MANIPULATION';
export const STORE_CURRENT_LOCATION = 'STORE_CURRENT_LOCATION';
export const STORE_WINDOW_SIZE = 'STORE_WINDOW_SIZE';
export const STORE_CATEGORIES = 'STORE_CATEGORIES';
export const STORE_CAMPUS_CATEGORIES = 'STORE_CAMPUS_CATEGORIES';
export const STORE_CATEGORIES_VISIBLE = 'STORE_CATEGORIES_VISIBLE';
export const STORE_EVENT = 'STORE_EVENT';
export const STORE_TOAST = 'STORE_TOAST';
export const STORE_SELECTED_CAMPUS_FLOORS = 'STORE_SELECTED_CAMPUS_FLOORS';
export const STORE_SELECTED_FLOOR = 'STORE_SELECTED_FLOOR';
export const STORE_SHOW_HIDDEN_CAMPUSES = 'STORE_SHOW_HIDDEN_CAMPUSES';
export const STORE_ACTIVE_PANEL = 'STORE_ACTIVE_PANEL';
export const STORE_ROOM_SEARCH = 'STORE_ROOM_SEARCH';
export const STORE_ROOM_SEARCH_RESULTS = 'STORE_ROOM_SEARCH_RESULTS';
export const STORE_ROOM_SEARCH_RESULTS_GROUPED =
  'STORE_ROOM_SEARCH_RESULTS_GROUPED';
export const STORE_MODE = 'STORE_MODE';
export const STORE_QR_SCANNER = 'STORE_QR_SCANNER';
export const STORE_ROUTE_STEPS = 'STORE_ROUTE_STEPS';
export const STORE_ROUTE_ACTIVE = 'STORE_ROUTE_ACTIVE';
export const STORE_ROUTE_INFO = 'STORE_ROUTE_INFO';
export const STORE_DATA_OFFLINE = 'STORE_DATA_OFFLINE';
export const STORE_OFFLINE_MODE_AVAILABLE = 'STORE_OFFLINE_MODE_AVAILABLE';
export const STORE_LATEST_CAMPUS_STORE = 'STORE_LATEST_CAMPUS_STORE';

export const EFFECT_LOCATION_QR = 'EFFECT_LOCATION_QR';
