import React from 'react';
import { define } from '@proscom/ui-utils';
import { ReactComponent as IconArrowUp } from '../../../../assets/img/icons/i-arr-up.svg';
import { ReactComponent as IconArrowDown } from '../../../../assets/img/icons/i-arr-down.svg';
import { ReactComponent as IconArrowUpDown } from '../../../../assets/img/icons/i-arr-updown.svg';
import { ReactComponent as IconStairs } from '../../../../assets/img/icons/i-stairs.svg';
import { ReactComponent as IconStairsUp } from '../../../../assets/img/icons/i-stairs-up.svg';
import { ReactComponent as IconStairsDown } from '../../../../assets/img/icons/i-stairs-down.svg';
import { ReactComponent as IconElev } from '../../../../assets/img/icons/i-elevator.svg';
import { ReactComponent as IconElevUp } from '../../../../assets/img/icons/i-elevator-up.svg';
import { ReactComponent as IconElevDown } from '../../../../assets/img/icons/i-elevator-down.svg';
import { ReactComponent as IconExit } from '../../../../assets/img/icons/i-directions-walk.svg';
import { ReactComponent as IconPhone } from '../../../../assets/img/icons/i-phone.svg';
import { VertexType } from '../../../../data/VertexType';

export interface NodeIconDef {
  icon?: React.ComponentType;
  className?: string;
}

export type NodeIconsMapItem = Record<string, NodeIconDef>;
export type NodeIconsMap = Partial<Record<VertexType, NodeIconsMapItem>>;
export const mapperIcons = define<NodeIconsMap>()({
  corridor: {
    up: { icon: IconArrowUp, className: '_error' },
    down: { icon: IconArrowDown, className: '_error' },
    updown: { icon: IconArrowUpDown, className: '_error' }
  },
  stairs: {
    default: { icon: IconStairs, className: '_error' },
    up: { icon: IconStairsUp },
    down: { icon: IconStairsDown },
    updown: { icon: IconStairs }
  },
  elevator: {
    default: { icon: IconElev, className: '_error' },
    up: { icon: IconElevUp },
    down: { icon: IconElevDown },
    updown: { icon: IconElev }
  },
  buildingExit: {
    default: { icon: IconExit }
  },
  roomDoor: {
    default: { icon: IconPhone }
  }
});
