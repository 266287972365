import {
  Icon,
  IconActionCheck,
  IconActionDownload,
  Spinner,
  SpinnerSize
} from '@hse-design/react';
import { useStore, useStoreState } from '@proscom/prostore-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { SideMenuLink } from '../MobileNavigation/SideMenuLink/SideMenuLink';
import { useLocale } from '../../hooks/useLocale';
import { STORE_DATA_OFFLINE, STORE_MODE } from '../../../data/stores';
import { DataStoreOfflineState } from '../../../data/offline/stores/DataStoreOffline';
import { ModeStoreModeEnum } from '../../../data/core/ModeContext';
import { IModeStore } from '../../../data/stores/ModeStore';

export interface OfflineModeStatusProps {
  search?: string;
}

export function OfflineModeStatus({ search }: OfflineModeStatusProps) {
  const [mode, modeStore] = useStore<IModeStore>(STORE_MODE);
  const dataStoreOffline = useStoreState<DataStoreOfflineState>(
    STORE_DATA_OFFLINE
  );
  const locale = useLocale();

  return (
    <SideMenuLink
      component={Link}
      props={{
        to: '/settings/offline' + search
      }}
      renderIcon={(props) => {
        if (mode.value === ModeStoreModeEnum.offline) {
          if (dataStoreOffline.loading) {
            return (
              <Spinner size={SpinnerSize.medium} indeterminate {...props} />
            );
          } else {
            return <Icon icon={IconActionCheck} {...props} />;
          }
        } else {
          return <Icon icon={IconActionDownload} {...props} />;
        }
      }}
      text={
        mode.value === ModeStoreModeEnum.offline ? (
          dataStoreOffline.loading ? (
            <>{locale.t('offline.status.loading')}</>
          ) : (
            <>{locale.t('offline.status.offline')}</>
          )
        ) : (
          <>{locale.t('offline.status.online')}</>
        )
      }
    />
  );
}
