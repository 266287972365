import {
  MinPriorityQueue,
  PriorityQueueItem
} from '@datastructures-js/priority-queue';
import { IOfflineCampusInfo } from '../../data/offline/stores/CampusesStoreOffline';
import { PathGraph } from '../../data/PathGraph';
import { LocaleStore } from '../../data/core/LocaleStore';
import { IRoute } from '../../data/Route';
import { restructurePath } from './restructurePath';
import { DirectionsBuilder } from './directions/DirectionsBuilder';

export interface IPathToItem {
  parent?: IPathToItem;
  vertexId: number;
  edgeId?: number;
  cost: number;
  costTime: number;
}

export interface FindVertexRouteParams {
  startRoom?: number;
  endRoom?: number;
  endVertex?: number;
  startVertex?: number;
  graph: PathGraph;
}

export interface PathInfo {
  path: IPathToItem[];
  cost: number;
  costTime: number;
}

export function findPath(params: FindVertexRouteParams): PathInfo | null {
  const { startRoom, endRoom, graph, startVertex, endVertex } = params;

  const startVertices: Set<number> = new Set(
    startRoom
      ? graph.getRoomVertices(startRoom).map((v) => v.id)
      : startVertex
      ? [startVertex]
      : []
  );

  const endVertices: Set<number> = new Set(
    endRoom
      ? graph.getRoomVertices(endRoom).map((v) => v.id)
      : endVertex
      ? [endVertex]
      : []
  );

  if (startVertices?.size === 0 || endVertices?.size === 0) return null;

  const pathTo = new Map<number, IPathToItem>();
  const checkedVertexQueue = new MinPriorityQueue<IPathToItem>({
    priority: (bid: IPathToItem) => bid.costTime
  });

  for (const vertexId of startVertices) {
    pathTo.set(vertexId, {
      parent: undefined,
      edgeId: undefined,
      vertexId,
      cost: 0,
      costTime: 0
    });

    checkedVertexQueue.enqueue({
      vertexId,
      cost: 0,
      costTime: 0
    });
  }

  while (!checkedVertexQueue.isEmpty()) {
    const queueBid = (checkedVertexQueue.dequeue() as PriorityQueueItem<IPathToItem>)
      .element;
    const vertexId = queueBid.vertexId;

    // Если уже посетили вершину и она не начальная, то игнорируем
    const vertexPath = pathTo.get(vertexId);
    if (vertexPath?.parent) continue;

    // Помечаем вершину, как посещенную
    pathTo.set(vertexId, queueBid);

    // Пробегаем по ребрам и добавляем соседей в очередь
    const edgesSet = graph.getEdgesIds(vertexId);

    if (typeof edgesSet?.values() !== 'undefined') {
      for (const edgeId of edgesSet.values()) {
        const edge = graph.getEdge(edgeId);
        if (edge) {
          checkedVertexQueue.enqueue({
            parent: queueBid,
            vertexId: edge.end_vertex_id,
            edgeId: edge.id,
            cost: queueBid.cost + Number(edge.cost),
            costTime: queueBid.costTime + Number(edge.cost_time)
          });
        }
      }
    }

    // Если нашли конечную вершину
    if (endVertices.has(vertexId)) {
      if (queueBid) {
        const pathToVertex: IPathToItem[] = [];
        let pathNode: IPathToItem | undefined = queueBid;
        while (pathNode) {
          pathToVertex.push(pathNode);
          pathNode = pathNode.parent;
        }
        return {
          path: pathToVertex.reverse(),
          cost: queueBid.cost,
          costTime: queueBid.costTime
        };
      }
      return null;
    }
  }

  return null;
}

export interface IFindPathParams {
  startRoom?: number;
  endRoom?: number;
  startVertex?: number;
  endVertex?: number;
  currentCampus: IOfflineCampusInfo;
  locale: LocaleStore;
}

export function findRoute({
  startRoom,
  endRoom,
  startVertex,
  endVertex,
  currentCampus,
  locale
}: IFindPathParams): IRoute | null {
  const { graph } = currentCampus;
  const path = findPath({
    startRoom,
    endRoom,
    endVertex,
    startVertex,
    graph
  });

  if (path?.path) {
    const route = restructurePath(path.path, currentCampus);
    const builder = new DirectionsBuilder(locale, route);
    const directions = builder.build();
    return { route, directions };
  }
  return null;
}

interface FindPathToAllVerticesParams {
  startRoomId?: number;
  startVertexId?: number;
  endVertexId?: number;
  graph: PathGraph;
}

export function findPathToAllVertices(params: FindPathToAllVerticesParams) {
  const { startRoomId, startVertexId, endVertexId, graph } = params;
  if (!graph) return;

  const startVertices: Set<number> = new Set(
    startRoomId
      ? graph.getRoomVertices(startRoomId).map((v) => v.id)
      : startVertexId
      ? [startVertexId]
      : endVertexId
      ? [endVertexId]
      : []
  );

  if (startVertices.size === 0) return;

  const pathTo = new Map<number, IPathToItem>();
  const checkedVertexQueue = new MinPriorityQueue<IPathToItem>({
    priority: (bid: IPathToItem) => bid.costTime
  });

  for (const vertexId of startVertices) {
    pathTo.set(vertexId, {
      parent: undefined,
      vertexId,
      cost: 0,
      costTime: 0
    });

    checkedVertexQueue.enqueue({
      vertexId,
      cost: 0,
      costTime: 0
    });
  }

  while (!checkedVertexQueue.isEmpty()) {
    const queuedItem = checkedVertexQueue.dequeue() as PriorityQueueItem<IPathToItem>;
    const queueBid = queuedItem.element;
    const vertexId = queueBid.vertexId;

    // Если уже посетили вершину и она не начальная, то игнорируем
    const vertexPath = pathTo.get(vertexId);

    if (vertexPath?.parent) continue;

    // Помечаем вершину, как посещенную
    pathTo.set(vertexId, queueBid);

    // Пробегаем по ребрам и добавляем соседей в очередь
    const edgesSet = graph.getEdgesIds(vertexId);
    if (typeof edgesSet?.values() !== 'undefined') {
      for (const edgeId of edgesSet?.values()) {
        const edge = graph.getEdge(edgeId);
        if (edge) {
          checkedVertexQueue.enqueue({
            parent: queueBid,
            vertexId: edge.end_vertex_id,
            cost: queueBid.cost + Number(edge.cost),
            costTime: queueBid.costTime + Number(edge.cost_time)
          });
        }
      }
    }
  }

  return pathTo;
}
