import React from 'react';
import { ErrorBoundary } from '../common/utils/ErrorBoundary';

export function DefaultLayout<Props>(Comp: React.ComponentType<Props>) {
  return function DefaultLayout(props: Props) {
    return (
      <ErrorBoundary>
        <Comp {...props} />
      </ErrorBoundary>
    );
  };
}
