import { initialRequestState, ObservableStore } from '@proscom/prostore';
import { LocationStore } from '@proscom/prostore-react-router';
import {
  QUERY_KEY_END_ROOM,
  QUERY_KEY_END_VERTEX,
  QUERY_KEY_START_ROOM,
  QUERY_KEY_START_VERTEX
} from '../../core/queryKeys';
import { LocaleStore } from '../../core/LocaleStore';
import { locationStoreGet$ } from '../../../common/hooks/useLocationQuery';
import { findRoute } from '../../../utils/utilsRouteStoreOffline/findPath';
import { combineLatestMap } from '../../../utils/prostore/combineLatestMap';
import {
  IRouteQueryStore,
  RouteQueryStoreVars
} from '../../online/RouteQueryStore';
import { IRoute } from '../../Route';
import { CurrentCampusStoreOffline } from './CurrentCampusStoreOffline';

export interface RouteStoreOfflineArgs {
  locationStore: LocationStore;
  currentCampusStoreOffline: CurrentCampusStoreOffline;
  localeStore: LocaleStore;
}

export interface RouteStoreOfflineState {
  loading: boolean;
  loaded: boolean;
  error: any;
  variables: RouteQueryStoreVars;
  data: null | IRoute;
}

export class RouteStoreOffline
  extends ObservableStore<RouteStoreOfflineState>
  implements IRouteQueryStore {
  constructor(params: RouteStoreOfflineArgs) {
    super(
      combineLatestMap(
        [
          locationStoreGet$(
            params.locationStore,
            QUERY_KEY_START_ROOM,
            QUERY_KEY_END_ROOM,
            QUERY_KEY_START_VERTEX,
            QUERY_KEY_END_VERTEX
          ),
          params.currentCampusStoreOffline.state$,
          // Нужно чтобы перерендериться при изменении localeId
          params.localeStore.state$
        ] as const,
        ([query, currentCampus]) => {
          const startRoom = query[QUERY_KEY_START_ROOM];
          const endRoom = query[QUERY_KEY_END_ROOM];
          const startVertex = query[QUERY_KEY_START_VERTEX];
          const endVertex = query[QUERY_KEY_END_VERTEX];

          const variables: RouteQueryStoreVars = {
            params: {
              start_room_id: startRoom,
              end_room_id: endRoom,
              start_vertex_id: startVertex,
              end_vertex_id: endVertex
            }
          };

          if (!currentCampus || !currentCampus.campus) {
            return {
              data: null,
              loading: true,
              loaded: false,
              error: null,
              variables
            };
          }

          if (
            (startRoom || startVertex) &&
            (endRoom || endVertex) &&
            currentCampus
          ) {
            const data = findRoute({
              startRoom,
              endRoom,
              endVertex,
              startVertex,
              currentCampus,
              locale: params.localeStore
            });

            console.log(data);

            return {
              data,
              loading: false,
              loaded: true,
              error: null,
              variables
            };
          }

          return {
            data: null,
            loading: false,
            loaded: true,
            error: null,
            variables
          };
        }
      ),
      {
        ...initialRequestState
      }
    );
  }
}
