import { ToastStore } from '../../../data/core/ToastStore';

export interface TryOperationParams {
  toast: ToastStore;
  successMsg: string;
  errorMsg: string;
  name: string;
}

export async function tryOperation<T>(
  callback: () => Promise<T>,
  params: TryOperationParams
) {
  try {
    const result = await callback();
    params.toast.success(params.successMsg);
    return result;
  } catch (e) {
    console.error(`${params.name} error`, e);
    params.toast.error({
      title: params.errorMsg,
      description: (e as Error).message
    });
  }
}
