import React from 'react';
import classNames from 'classnames';
import {
  Icon,
  IconGlobalLanguage,
  IconGlobalMessage,
  IconSize,
  Logo
} from '@hse-design/react';
import { Link } from 'react-router-dom';
import { useStoreState } from '@proscom/prostore-react';
import { ILocationStoreState } from '@proscom/prostore-react-router';
import {
  STORE_LOCATION,
  STORE_OFFLINE_MODE_AVAILABLE
} from '../../../../data/stores';
import { FEED_EMAIL } from '../../../../utils/consts';
import { useLocale } from '../../../hooks/useLocale';
import { SideMenuLink } from '../SideMenuLink/SideMenuLink';
import { OfflineModeStatus } from '../../OfflineModeStatus/OfflineModeStatus';
import { Anchor } from '../../../ui/DOM/Anchor';
import { OfflineModeAvailableState } from '../../../../data/offline/stores/OfflineModeAvailable';
import s from './SideMenu.module.scss';

function SideMenuHeader() {
  const locale = useLocale();

  return (
    <div className={s.SideMenu__mainContent}>
      <div className={s.SideMenu__header}>
        <Icon
          icon={Logo}
          size={IconSize.large}
          className={s.SideMenu__headerLogo}
        />
        <div className={s.SideMenu__headerTitle}>{locale.t('menu.title')}</div>
      </div>
    </div>
  );
}

export interface SideMenuProps {
  isActive?: boolean;
  onClose?: () => void;
}

export function SideMenu({ isActive, onClose }: SideMenuProps) {
  const locale = useLocale();
  const { location } = useStoreState<ILocationStoreState>(STORE_LOCATION);
  const offlineAvailable = useStoreState<OfflineModeAvailableState>(
    STORE_OFFLINE_MODE_AVAILABLE
  );

  return (
    <>
      <div
        className={classNames(s.SideMenu__overlay, {
          [s._active]: isActive
        })}
        onClick={onClose}
      />
      <div
        className={classNames(s.SideMenu, {
          [s._active]: isActive
        })}
      >
        <div className={s.SideMenu__scroll}>
          <div className={s.SideMenu__content}>
            <SideMenuHeader />
            <ul>
              <SideMenuLink
                icon={IconGlobalLanguage}
                text={locale.t('menu.item.language')}
                component={Link}
                props={{
                  to: '/settings/language' + location?.search
                }}
              />
              <SideMenuLink
                icon={IconGlobalMessage}
                text={locale.t('menu.item.feedback')}
                component={Anchor}
                props={{
                  href: `mailto:${FEED_EMAIL}`,
                  target: '_blank',
                  rel: 'noopener noreferrer'
                }}
              />
            </ul>
            {offlineAvailable.value === true && (
              <OfflineModeStatus search={location?.search} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
