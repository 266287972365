import { Subscribable, Unsubscribable } from 'rxjs';
import { ValueStore } from '@proscom/prostore';

export class ObservableStore<Data> extends ValueStore<Data> {
  private unsub?: Unsubscribable;

  constructor(
    private readonly factory: () => Subscribable<Data>,
    initialValue: Data
  ) {
    super(initialValue);
  }

  onSubscribe() {
    this.unsub = this.factory().subscribe(this.state$);
  }

  onUnsubscribe() {
    this.unsub?.unsubscribe();
  }
}
