import theme from '../styles/theme.module.scss';

const {
  accentRedColor,
  lightBrandColor,
  lighterBrandColor,
  brandColor,
  errorColor,
  successColor,
  dangerColor,
  grayColor
} = theme;

export {
  accentRedColor,
  lightBrandColor,
  lighterBrandColor,
  brandColor,
  errorColor,
  successColor,
  dangerColor,
  grayColor
};
