import immer from 'immer';
import React, { useMemo, useRef } from 'react';
import { Polygon, PolygonProps, Popup } from 'react-leaflet';
import {
  Button,
  ButtonVariant,
  IconActionDelete,
  IconButton,
  IconButtonVariant
} from '@hse-design/react';
import { divIcon, Polygon as LeafletPolygon } from 'leaflet';
import { Point } from './utils';
import { dangerColor, successColor } from './data/theme';
import {
  DraggableMarker,
  DraggableMarkerProps
} from './components/Map/DraggableMarker';

export interface ResizablePolygonProps {
  deletable?: boolean;
  polygonProps?: Partial<PolygonProps>;
  markerProps?: Partial<DraggableMarkerProps>;
  positions: Point[];
  children?: React.ReactNode;
  showPopup?: boolean;
  onChange?: (newPositions: Point[] | null) => void;
  onClick?: Exclude<PolygonProps['eventHandlers'], undefined>['click'];
}

const polygonDefaultColor = successColor;
const pathOptions = { stroke: true, weight: 2, fill: true };
const markerIcon = divIcon({
  iconSize: [16, 16],
  iconAnchor: [8, 8]
});

export const ResizablePolygon = React.memo(function ResizablePolygon({
  deletable = true,
  polygonProps = {},
  markerProps = {},
  positions,
  children,
  showPopup = true,
  onChange,
  onClick
}: ResizablePolygonProps) {
  const eventHandlers = useMemo(
    () =>
      onClick
        ? {
            click: onClick
          }
        : {},
    [onClick]
  );
  const polyRef = useRef<LeafletPolygon | null>(null);
  return positions.length ? (
    <>
      <Polygon
        ref={polyRef}
        color={polygonDefaultColor}
        pathOptions={pathOptions}
        positions={positions}
        eventHandlers={eventHandlers}
        {...polygonProps}
      >
        {showPopup && (
          <Popup>
            {deletable && (
              <Button
                color={dangerColor}
                leftIcon={IconActionDelete}
                variant={ButtonVariant.secondary}
                onClick={() => onChange?.(null)}
              >
                Удалить
              </Button>
            )}
            {children}
          </Popup>
        )}
      </Polygon>
      {positions.map((pos, posI) => (
        <DraggableMarker
          key={posI}
          {...markerProps}
          position={pos}
          icon={markerIcon}
          onChange={(point) => {
            onChange?.(
              immer(positions, (d) => {
                if (!point) {
                  d.splice(posI, 1);
                } else {
                  d[posI] = point;
                }
              })
            );
          }}
        >
          <Popup>
            <IconButton
              variant={IconButtonVariant.ghost}
              iconColor={dangerColor}
              icon={IconActionDelete}
              onClick={() => {
                onChange?.(
                  immer(positions, (d) => {
                    d.splice(posI, 1);
                  })
                );
              }}
            />
          </Popup>
        </DraggableMarker>
      ))}
    </>
  ) : null;
});
