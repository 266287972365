import React, { useMemo } from 'react';
import { useStoreState } from '@proscom/prostore-react';
import { EMDASH } from '@proscom/ui-utils';
import { getCategoryName, getRoomName } from '../../../data/Room';
import { EventStoreState } from '../../../data/stores/EventStore';
import { STORE_EVENT } from '../../../data/stores';
import { useLocale } from '../../hooks/useLocale';
import { RoomDto } from '../../../data/api/RoomDto';
import { StaffDto } from '../../../data/api/StaffDto';
import { RoomExtSection } from '../RoomExt/RoomExtSection';
import { RoomExtStaff } from '../RoomExt/RoomExtStaff';
import s from './RoomListItem.module.scss';

function getMatching(text: string, matchValue: string) {
  return text.toLowerCase().indexOf(matchValue.toLowerCase()) !== -1;
}

function getSearchingStaff(staffArray: StaffDto[], searchValue: string) {
  let newStaffArray: StaffDto[] = [];

  for (let staff of staffArray) {
    let arrayToMatch: string[] = [];
    if (staff.first_name) arrayToMatch.push(staff.first_name);
    if (staff.last_name) arrayToMatch.push(staff.last_name);
    if (staff.email) arrayToMatch.push(staff.email);

    const isMatching = arrayToMatch.some((item) => {
      return getMatching(item, searchValue);
    });
    if (isMatching) newStaffArray.push(staff);
  }

  return newStaffArray;
}

export interface RoomListItemProps {
  room: RoomDto;
  searchValue?: string;
  isExpanded?: boolean;
  onClickHandler?: () => void;
}

export function RoomListItem({
  room,
  searchValue,
  isExpanded,
  onClickHandler
}: RoomListItemProps) {
  const locale = useLocale();
  const eventStoreState = useStoreState<EventStoreState>(STORE_EVENT);
  const { staff, distance } = room;

  let searchingStaff: StaffDto[] = [];
  if (searchValue && searchValue.length && staff && staff.length) {
    // todo подумать как унифицировать с оффлайн поиском
    searchingStaff = getSearchingStaff(staff, searchValue[0]);
    isExpanded = !!searchingStaff.length;
  }

  const title = useMemo(() => getRoomName(room, false), [room]);

  const description = getCategoryName(room, locale);

  // todo это костыль, надо брать значения с бекенда,
  //  или как минимум вынести константы
  const routeTime = Math.ceil((distance || 0) / (4000 / 60));

  const staffList = searchingStaff.length ? searchingStaff : staff;

  return (
    <div className={s.Room} onClick={onClickHandler}>
      <div className={s.Room__titleBlock}>
        <p className={s.titleBlock__title}>{title || EMDASH}</p>
        {distance && (
          <p className={s.titleBlock__distance}>
            {Math.ceil(distance)} {locale.t('m')}, {routeTime} {locale.t('min')}
          </p>
        )}
      </div>
      {description && <div className={s.Room__desc}>{description}</div>}
      {isExpanded && !eventStoreState.params.hideRoomExt && (
        <RoomExtSection title={locale.t('pane.room.employees_title')}>
          <RoomExtStaff staff={staffList} />
        </RoomExtSection>
      )}
    </div>
  );
}
