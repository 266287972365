import {
  Button,
  ButtonVariant,
  ModalForm,
  Spinner,
  SpinnerSize
} from '@hse-design/react';
import { useStore, useStoreState } from '@proscom/prostore-react';
import { STORE_DATA_OFFLINE, STORE_MODE } from '../../../data/stores';
import { ModeStoreModeEnum } from '../../../data/core/ModeContext';
import { DataStoreOfflineState } from '../../../data/offline/stores/DataStoreOffline';
import { useLocale } from '../../hooks/useLocale';
import { IModeStore } from '../../../data/stores/ModeStore';
import s from './OfflineModeModal.module.scss';

export interface OfflineModePopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export function OfflineModeModal({ isOpen, onClose }: OfflineModePopupProps) {
  const locale = useLocale();
  const [mode, modeStore] = useStore<IModeStore>(STORE_MODE);
  const handleSetup = () => modeStore.setValue(ModeStoreModeEnum.offline);
  const handleDisable = () => modeStore.setValue(ModeStoreModeEnum.online);
  const dataStoreOffline = useStoreState<DataStoreOfflineState>(
    STORE_DATA_OFFLINE
  );

  return (
    <ModalForm
      className={s.OfflineModeModal}
      onCloseClick={onClose}
      isOpen={isOpen}
      title={locale.t('offline.modal.title')}
      actions={
        mode.value === ModeStoreModeEnum.offline ? (
          <div className={s.OfflineModeModal__actions}>
            <Button variant={ButtonVariant.secondary} onClick={handleDisable}>
              {locale.t('offline.modal.disable')}
            </Button>
            <Button onClick={onClose}>{locale.t('offline.modal.ok')}</Button>
          </div>
        ) : (
          <Button onClick={handleSetup}>
            {locale.t('offline.modal.setup')}
          </Button>
        )
      }
    >
      {mode.value === ModeStoreModeEnum.offline ? (
        dataStoreOffline.loading ? (
          <>
            <p>{locale.t('offline.modal.loading')}</p>
            <Spinner
              className={s.OfflineModeModal__spinner}
              size={SpinnerSize.XL}
              indeterminate
            />
          </>
        ) : (
          <p>{locale.t('offline.modal.offline')}</p>
        )
      ) : (
        <p>{locale.t('offline.modal.introduction')}</p>
      )}
    </ModalForm>
  );
}
