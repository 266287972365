import { ObservableStore } from '@proscom/prostore';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { EdgesStore, IEdgeData } from './EdgesStore';
import { SelectedNode } from './SelectedNode';

export class NodeAdjacentEdges extends ObservableStore<IEdgeData[]> {
  constructor(
    private deps: {
      edges: EdgesStore;
      node: SelectedNode;
    }
  ) {
    super(
      combineLatest([deps.edges.state$, deps.node.state$]).pipe(
        map(([edges, node]) => {
          if (!edges.data || !node) return [];

          const nodeId = node.id;
          return Object.values(edges.data).filter(
            (e) => e.start_vertex_id === nodeId || e.end_vertex_id === nodeId
          );
        })
      ),
      []
    );
  }
}
