import {
  defineQueryTransformers,
  ExtractTransformedQueryParams
} from '@proscom/prostore-react-router';
import {
  booleanTransformer,
  floorTransformer,
  numberTransformer,
  stringifySimple,
  stringTransformer
} from '../../utils/queryTransformers';

export const QUERY_KEY_QR = 'qr';
export const QUERY_KEY_MY_LOCATION = 'myLocation';
export const QUERY_KEY_ROOM = 'room';
export const QUERY_KEY_CODE = 'code';
export const QUERY_KEY_FLOOR = 'floor';
export const QUERY_KEY_LNG = 'lng';
export const QUERY_KEY_STEP = 'step';
export const QUERY_KEY_BUILDING = 'building';
export const QUERY_KEY_CAMPUS = 'campus';
export const QUERY_KEY_BACK_LINK = 'backlink';
export const QUERY_KEY_START_ROOM = 'startRoom';
export const QUERY_KEY_END_ROOM = 'endRoom';
export const QUERY_KEY_START_VERTEX = 'startVertex';
export const QUERY_KEY_END_VERTEX = 'endVertex';
export const QUERY_KEY_SEARCH = 'search';
export const QUERY_KEY_START_SEARCH = 'startSearch';
export const QUERY_KEY_END_SEARCH = 'endSearch';
export const QUERY_KEY_CATEGORY = 'category';
export const QUERY_KEY_EVENT = 'event';
export const QUERY_KEY_EDGE = 'edge';
export const QUERY_KEY_NODE = 'node';
export const QUERY_KEY_PANEL = 'panel';
export const QUERY_KEY_LOAD_OFFLINE_DATA = 'loadOfflineModeData';

const backLinkWhiteList = ['https://nav.hseapp.ru/back', 'hseappx://back'];

export const queryTransformers = defineQueryTransformers({
  [QUERY_KEY_QR]: stringTransformer,
  [QUERY_KEY_ROOM]: numberTransformer,
  [QUERY_KEY_CODE]: {
    parse: (queryValue) => {
      if (typeof queryValue === 'string') {
        return queryValue.toUpperCase();
      }
      return undefined;
    },
    stringify: stringifySimple
  },
  [QUERY_KEY_FLOOR]: floorTransformer,
  [QUERY_KEY_CAMPUS]: stringTransformer,
  [QUERY_KEY_LNG]: stringTransformer,
  [QUERY_KEY_MY_LOCATION]: booleanTransformer,
  [QUERY_KEY_BACK_LINK]: {
    parse: (queryValue) => {
      if (
        typeof queryValue === 'string' &&
        backLinkWhiteList.includes(queryValue)
      ) {
        return queryValue;
      }
      return undefined;
    },
    stringify: stringifySimple
  },
  [QUERY_KEY_START_ROOM]: numberTransformer,
  [QUERY_KEY_END_ROOM]: numberTransformer,
  [QUERY_KEY_START_VERTEX]: numberTransformer,
  [QUERY_KEY_END_VERTEX]: numberTransformer,
  [QUERY_KEY_SEARCH]: stringTransformer,
  [QUERY_KEY_LOAD_OFFLINE_DATA]: booleanTransformer,
  [QUERY_KEY_START_SEARCH]: stringTransformer,
  [QUERY_KEY_END_SEARCH]: stringTransformer,
  [QUERY_KEY_CATEGORY]: stringTransformer,
  [QUERY_KEY_STEP]: numberTransformer,
  [QUERY_KEY_BUILDING]: numberTransformer,
  [QUERY_KEY_EVENT]: stringTransformer,
  [QUERY_KEY_NODE]: numberTransformer,
  [QUERY_KEY_EDGE]: numberTransformer,
  [QUERY_KEY_NODE]: numberTransformer,
  [QUERY_KEY_PANEL]: stringTransformer
});

type QueryTransformersType = typeof queryTransformers;

export type TransformedQueryParams = ExtractTransformedQueryParams<QueryTransformersType>;
