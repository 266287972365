import { Marker as LeafletMarker } from 'leaflet';
import { Marker, MarkerProps } from 'react-leaflet';
import React, { useRef } from 'react';
import { useLatestCallbacksRef } from '@proscom/ui-react';

export interface DraggableMarkerProps extends Omit<MarkerProps, 'draggable'> {
  onChange?: (newCoords: [number, number]) => void;
}

export function DraggableMarker({ onChange, ...props }: DraggableMarkerProps) {
  const markerRef = useRef<LeafletMarker | null>(null);
  const eventHandlers = useLatestCallbacksRef({
    dragend() {
      const marker = markerRef.current;
      if (marker != null) {
        const { lat, lng } = marker.getLatLng();
        onChange?.([lat, lng]);
      }
    }
  });

  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      ref={markerRef}
      {...props}
    />
  );
}
