import React from 'react';
import clsx from 'clsx';
import { RouteStep, RouteStepProps } from './RouteStep';
import s from './RouteInfo.module.scss';

export interface IRouteStepListItem extends RouteStepProps {
  shown?: boolean;
}

export interface RouteStepListProps {
  isOnRoute?: boolean;
  routeSteps?: IRouteStepListItem[];
  activeStepIndex: number;
  onStepClickHandler?: (step: number) => void;
  className?: string;
}

export function RouteStepList({
  isOnRoute,
  routeSteps,
  activeStepIndex,
  onStepClickHandler,
  className
}: RouteStepListProps) {
  return (
    <ul className={clsx(s.RouteStepList, className)}>
      {routeSteps &&
        routeSteps.map(
          (step, i) =>
            step.shown && (
              <li key={i} className={s.RouteStepList__item}>
                {isOnRoute ? (
                  <RouteStep
                    onClick={() => onStepClickHandler?.(i)}
                    isActive={activeStepIndex === i}
                    {...step}
                  />
                ) : (
                  <RouteStep {...step} />
                )}
              </li>
            )
        )}
    </ul>
  );
}
