import { LocationStore } from '@proscom/prostore-react-router';
import { AsyncOperationStore } from '../../../../utils/prostore/AsyncOperationStore';
import { ToastStore } from '../../../../data/core/ToastStore';
import { QUERY_KEY_NODE } from '../../../../data/core/queryKeys';
import { tryOperation } from '../../utils/tryOperation';
import { IVertexData, NodesStore } from './NodesStore';

export class NodeRemoveOperation extends AsyncOperationStore<
  [IVertexData],
  void
> {
  constructor(
    private deps: {
      nodes: NodesStore;
      toast: ToastStore;
      location: LocationStore;
    }
  ) {
    super((...args) => this.perform(...args));
  }

  async perform(node: IVertexData) {
    await tryOperation(
      async () => {
        this.deps.location.changeQuery({
          [QUERY_KEY_NODE]: undefined
        });
        await this.deps.nodes.mutations.delete(node.id);
      },
      {
        name: this.constructor.name,
        successMsg: 'Вершина удалена',
        errorMsg: 'Произошла ошибка при удалении вершины',
        toast: this.deps.toast
      }
    );
  }
}
