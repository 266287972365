export enum RouteStepType {
  TYPE_START = 'TYPE_START',
  TYPE_END = 'TYPE_END',
  TYPE_LEFT = 'TYPE_LEFT',
  TYPE_RIGHT = 'TYPE_RIGHT',
  TYPE_FORWARD = 'TYPE_FORWARD',
  TYPE_ELEVATOR_UP = 'TYPE_ELEVATOR_UP',
  TYPE_ELEVATOR_DOWN = 'TYPE_ELEVATOR_DOWN',
  TYPE_STAIRS_UP = 'TYPE_STAIRS_UP',
  TYPE_STAIRS_DOWN = 'TYPE_STAIRS_DOWN',
  TYPE_TRANSFER = 'TYPE_TRANSFER'
}
