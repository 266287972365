import React from 'react';
import { CategoriesList } from '../../components/CategoriesList/CategoriesList';
import { SearchCategoriesBaseProps } from '../../components/SearchCategories/SearchCategories';
import s from './CategoriesSlider.module.scss';

export interface CategoriesSliderProps extends SearchCategoriesBaseProps {}

export function CategoriesSlider({
  categories,
  activeCategoryId,
  onCategoryClick
}: CategoriesSliderProps) {
  return (
    <CategoriesList
      className={s.CategoriesSlider}
      categories={categories}
      activeCategoryId={activeCategoryId}
      onCategoryClick={onCategoryClick}
    />
  );
}
