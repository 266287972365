import axios, { AxiosInstance } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import { setupCache } from 'axios-cache-adapter';

const clientCache = setupCache({
  // Время жизни в кеше - 10 минут
  maxAge: 10 * 60 * 1000,
  // Задаем лимит количества сохраненных записей в кеше, чтобы вся память не съедалась
  limit: 100 as any, // Тут проблема с типами в библиотеке
  exclude: {
    // Кешируем запросы вместе с квери-параметрами
    query: false
  }
});

export const defaultClient = withRetry(
  axios.create({
    // Используем кеширующий адаптер
    adapter: clientCache.adapter
  })
);

/**
 * Повторяем запросы при ошибке сети до тех пор, пока она не исчезнет
 * @param axiosClient
 */
export function withRetry<Client extends AxiosInstance>(
  axiosClient: Client,
  config: Partial<IAxiosRetryConfig> = {
    // Бесконечное количество повторов
    retries: Infinity,
    // Повторный запрос через 5 секунд
    retryDelay: () => 5000
  }
): Client {
  axiosRetry(axiosClient, config);
  return axiosClient;
}
