import { LocaleStore } from '../../../data/core/LocaleStore';
import { SegmentType } from '../../../data/SegmentType';
import { IRouteEdge } from '../../../data/RouteEdge';

export abstract class Segment {
  abstract type: SegmentType;
  end_index?: number;
  cost: number;
  cost_time: number;
  hint?: string;

  constructor(public start_index: number, part: IRouteEdge) {
    // todo not typesafe
    this.cost = +part.cost!;
    this.cost_time = +part.cost_time!;
  }

  abstract setEndNode(part: IRouteEdge): void;

  abstract processNext(part: IRouteEdge): boolean;

  abstract setHint(locale: LocaleStore): void;
}
