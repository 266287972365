import clsx from 'clsx';
import { BottomSheet as LibBottomSheet } from 'react-spring-bottom-sheet';
import React, { ComponentProps } from 'react';
import { BottomSheetTitle } from '../BottomSheetTitle/BottomSheetTitle';
import { useBottomSheetOnScroll } from './useBottomSheetOnScroll';
import s from './BottomSheet.module.scss';

export interface BottomSheetProps
  extends Omit<ComponentProps<typeof LibBottomSheet>, 'onScroll'> {
  headerTitle?: React.ReactNode;
  onScroll?: (e: Event) => void;
}

export function BottomSheetNew({
  onScroll,
  id: idProp,
  className,
  headerTitle,
  header,
  ...props
}: BottomSheetProps) {
  const id = useBottomSheetOnScroll(onScroll, idProp);

  return (
    <LibBottomSheet
      id={id}
      className={clsx(className, s.BottomSheet)}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight]}
      initialFocusRef={false}
      {...props}
      header={
        header ??
        (headerTitle && (
          <BottomSheetTitle onClose={props.onDismiss}>
            {headerTitle}
          </BottomSheetTitle>
        ))
      }
    />
  );
}
