export function groupMapBy<T, Key>(collection: T[], fieldFn: (x: T) => Key) {
  const result = new Map<Key, T[]>();
  for (const item of collection) {
    const field = fieldFn(item);
    const array = result.get(field);
    if (array) {
      array.push(item);
    } else {
      result.set(fieldFn(item), [item]);
    }
  }
  return result;
}
