import React, { useMemo } from 'react';
import { Polygon, Tooltip } from 'react-leaflet';
import { Building } from '../../data/Building';
import { flipBoundsToLeaflet, tooltipOffset } from './utils';

export interface MapBuildingProps {
  building: Building;
}

export function MapBuilding({ building }: MapBuildingProps) {
  const positions = useMemo(() => {
    return building.bounds.map((poly) => poly.map(flipBoundsToLeaflet));
  }, [building]);

  return (
    <Polygon key={building.id} positions={positions}>
      <Tooltip direction="top" offset={tooltipOffset} opacity={1}>
        <div>
          Корпус {building.id} - {building.name}
        </div>
        <div>Тип {building.type}</div>
      </Tooltip>
    </Polygon>
  );
}
