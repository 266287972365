import React from 'react';
import clsx from 'clsx';
import s from './BottomSheetContent.module.scss';

export interface BottomSheetContentProps {
  className?: string;
  tightTop?: boolean;
  children?: React.ReactNode;
}

export function BottomSheetContent({
  className,
  tightTop,
  children
}: BottomSheetContentProps) {
  return (
    <div
      className={clsx(s.BottomSheetContent, className, {
        [s.BottomSheetContent_tightTop]: tightTop
      })}
    >
      {children}
    </div>
  );
}
