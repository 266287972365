import { AxiosQueryStore } from '@proscom/prostore-axios';
import { AxiosInstance } from 'axios';
import { Observable } from 'rxjs';
import { skipIfNull } from '@proscom/prostore';
import { map } from 'rxjs/operators';
import { QueryResult } from '../../data/api/common';

export class AxiosSimpleQuery<Vars, Data> extends AxiosQueryStore<
  Vars | null,
  Data | null
> {
  constructor(client: AxiosInstance, variables$: Observable<Vars | null>) {
    super({
      client,
      query: {
        method: 'get'
      },
      mapData: (result: QueryResult<Data>) => result.data as Data,
      skipQuery: skipIfNull(null),
      variables$: variables$.pipe(map((variables) => ({ variables })))
    });
  }
}
