import { LocationStore } from '@proscom/prostore-react-router';
import { IStore, ObservableStore } from '@proscom/prostore';
import { map, share } from 'rxjs/operators';
import { OfflineModeEnabled } from '../offline/stores/OfflineModeEnabled';

export enum ModeStoreModeEnum {
  online = 'online',
  offline = 'offline'
}

export interface ModeStoreState {
  stores: { [key: string]: IStore<any> } | null;
}

export interface ModeStoreArgs {
  locationStore: LocationStore;
  offlineModeEnabled: OfflineModeEnabled;
  factory: (
    mode: ModeStoreModeEnum
  ) => {
    stores: ModeStoreState['stores'];
    teardown: () => void;
  };
}

/**
 * Стор хранит набор сторов для текущего режима использования
 */
export class ModeContext extends ObservableStore<ModeStoreState> {
  private teardown?: () => void;

  constructor(protected readonly params: ModeStoreArgs) {
    super(
      params.offlineModeEnabled.state$.pipe(
        map((mode) => {
          this.teardown?.();
          this.teardown = undefined;

          if (!mode.loaded || !mode.value) {
            return { stores: null };
          }

          return this.handleModeChange(mode.value);
        }),
        share()
      ),
      { stores: null }
    );
  }

  private handleModeChange(mode: ModeStoreModeEnum) {
    const { stores, teardown } = this.params.factory(mode);
    this.teardown = teardown;
    return { stores };
  }
}
