import React from 'react';
import { useStore } from '@proscom/prostore-react';
import { CurrentLocationStore } from '../../../data/stores/CurrentLocationStore';
import { STORE_CURRENT_LOCATION } from '../../../data/stores';
import { CampusControl } from './CampusControl/CampusControl';
import { FloorControl } from './FloorControl/FloorControl';
import { ZoomAction } from './ZoomAction/ZoomAction';
import { GoToPinControl } from './GoToPinControl/GoToPinControl';
import s from './MapControls.module.scss';

export interface zoomActions {
  zoomIn: () => void;
  zoomOut: () => void;
}

export interface MapControlsProps {
  mapRef: React.RefObject<zoomActions>;
  isDesktopVersion: boolean;
}

export const MapControls = ({ mapRef, isDesktopVersion }: MapControlsProps) => {
  const [
    currentLocationState,
    currentLocationStore
  ] = useStore<CurrentLocationStore>(STORE_CURRENT_LOCATION);
  const showPin = Boolean(currentLocationState.location);
  return (
    <div className={s.MapControls}>
      <FloorControl additionalBottomMargin={showPin} />
      {showPin && (
        <GoToPinControl
          selected={currentLocationState.isSelected}
          onSelect={currentLocationStore.selectPin}
          isDesktopVersion={isDesktopVersion}
        />
      )}
      <CampusControl isDesktopVersion={isDesktopVersion} />
      <ZoomAction mapRef={mapRef} />
    </div>
  );
};
