import React from 'react';
import s from './RoomExtSection.module.scss';

export interface RoomExtSectionProps {
  title: React.ReactNode;
  children?: React.ReactNode;
}

export function RoomExtSection({ title, children }: RoomExtSectionProps) {
  return (
    <div className={s.RoomExtSection}>
      <div className={s.RoomExtSection__title}>{title}</div>
      {children}
    </div>
  );
}
