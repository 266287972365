import ResizeObserver from 'react-resize-observer';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLatestCallbackRef } from '@proscom/ui-react';
import moscow from '../../../assets/map/Moscow.png';
import { ReactComponent as IconStart } from '../../../assets/img/icons/new/start.svg';
import { ReactComponent as IconEnd } from '../../../assets/img/icons/new/end.svg';
import {
  iconStyles,
  routeLineColors,
  routeLineStyles
} from './CanvasClasses/canvasObjectsStyles';
import s from './MapBox.module.scss';

export type Point = [number, number];

export interface TransferData {
  startCampusMapPosition: [number, number];
  endCampusMapPosition: [number, number];
}

function convertPoint(point: Point) {
  return { x: point[0], y: point[1] };
}

export interface Size {
  width: number;
  height: number;
}

export interface CenteredImageProps {
  icon: React.ComponentType<React.ComponentProps<'svg'>>;
  x: number;
  y: number;
  size: number;
}

export function Icon({ icon: Icon, x, y, size }: CenteredImageProps) {
  return (
    <g transform={`translate(${x},${y})`}>
      <circle
        cx={0}
        cy={0}
        r={size / 2}
        fill={iconStyles.selected.backgroundColor}
      />
      <Icon
        x={-size / 2}
        y={-size / 2}
        width={size}
        height={size}
        style={{ color: iconStyles.selected.iconColor }}
      />
    </g>
  );
}

export interface TransferProps {
  transferData: TransferData;
  isDesktopVersion: boolean;
}

export function Transfer({ transferData, isDesktopVersion }: TransferProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState<Size | null>(null);

  const handleResize = useLatestCallbackRef(() => {
    if (!containerRef.current) return;

    const newSize = {
      width: containerRef.current.offsetWidth,
      height: containerRef.current.offsetHeight
    };

    if (
      !size ||
      newSize.width !== size.width ||
      size.height !== newSize.height
    ) {
      setSize(newSize);
    }
  });

  useEffect(() => {
    handleResize();
  });

  const { startCampusMapPosition, endCampusMapPosition } = transferData || {};
  const [imageSize, setImageSize] = useState<Size | null>(null);

  useEffect(() => {
    let active = true;

    const transferMapImage = new Image();
    transferMapImage.src = moscow;
    transferMapImage.onload = () => {
      if (!active) return;
      setImageSize({
        width: transferMapImage.width,
        height: transferMapImage.height
      });
    };

    return () => {
      active = false;
    };
  }, []);

  const transform = useMemo(() => {
    if (!imageSize || !size || !transferData) return undefined;

    const imageRatio = imageSize.width / imageSize.height;

    if (!isDesktopVersion) {
      const start = convertPoint(transferData.startCampusMapPosition);
      const end = convertPoint(transferData.endCampusMapPosition);
      const centroid = { x: (start.x + end.x) / 2, y: (start.y + end.y) / 2 };
      const lineBox = {
        x: Math.abs(end.x - start.x),
        y: Math.abs(end.y - start.y)
      };

      const padding = 32;
      const sheetPadding = 265;
      const lineBoxRatio = lineBox.x / lineBox.y;
      const canvasWidthPadded = size.width - padding * 2;
      const canvasHeightPadded = size.height - sheetPadding - padding * 2;
      const canvasRatio = canvasWidthPadded / canvasHeightPadded;
      const scale =
        lineBoxRatio > canvasRatio
          ? canvasWidthPadded / lineBox.x
          : canvasHeightPadded / lineBox.y;
      const center = { x: size.width / 2, y: (size.height - sheetPadding) / 2 };

      const x = center.x - centroid.x * scale;
      const y = center.y - centroid.y * scale;

      return { x, y, scale };
    }
    const canvasRatio = size.width / size.height;
    const scale =
      imageRatio < canvasRatio
        ? size.width / imageSize.width
        : size.height / imageSize.height;
    const center = { x: size.width / 2, y: size.height / 2 };
    const centroid = { x: imageSize.width / 2, y: imageSize.height / 2 };

    const x = center.x - centroid.x * scale;
    const y = center.y - centroid.y * scale;

    return { x, y, scale };
  }, [imageSize, isDesktopVersion, size, transferData]);

  const transformString = transform
    ? `translate(${transform.x}, ${transform.y}) scale(${transform.scale})`
    : undefined;

  const transformPoint = (point: Point) =>
    transform
      ? ([
          point[0] * transform.scale + transform.x,
          point[1] * transform.scale + transform.y
        ] as const)
      : point;

  const start = transformPoint(startCampusMapPosition);
  const end = transformPoint(endCampusMapPosition);

  return (
    <div className={s.MapTransferCanvas} ref={containerRef}>
      {size && imageSize && transferData && (
        <svg width={size.width} height={size.height}>
          <g transform={transformString}>
            <image
              href={moscow}
              width={imageSize.width}
              height={imageSize.height}
            />
          </g>
          <g>
            <line
              x1={start[0]}
              y1={start[1]}
              x2={end[0]}
              y2={end[1]}
              stroke={routeLineColors.selected}
              strokeWidth={routeLineStyles.lineWidth}
            />
            <Icon
              icon={IconStart}
              size={iconStyles.selected.width}
              x={start[0]}
              y={start[1]}
            />
            <Icon
              icon={IconEnd}
              size={iconStyles.selected.width}
              x={end[0]}
              y={end[1]}
            />
          </g>
        </svg>
      )}
      <ResizeObserver onResize={handleResize} />
    </div>
  );
}
