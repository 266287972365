import { AxiosQueryStore } from '@proscom/prostore-axios';
import { map } from 'rxjs/operators';
import { AxiosInstance } from 'axios';
import { GetQueryVars, LocaleQueryParams } from '../api/common';
import { RoomCategoryDto } from '../api/RoomCategoryDto';
import { LocaleStore } from '../core/LocaleStore';

export type CategoriesQueryVars = GetQueryVars<LocaleQueryParams>;
export type CategoriesQueryData = RoomCategoryDto[];

export class CategoriesStore extends AxiosQueryStore<
  CategoriesQueryVars,
  CategoriesQueryData
> {
  constructor(client: AxiosInstance, localeStore: LocaleStore) {
    super({
      client,
      query: {
        method: 'get',
        url: `/api/front/categories`
      },
      mapData: (result) => result.data,
      variables$: localeStore.state$.pipe(
        map((locale) => ({
          variables: { params: { locale: locale.lang } }
        }))
      )
    });
  }
}
