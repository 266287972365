import React, { useCallback, useState } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { RoomSearchResults } from '../../components/RoomSearchResults/RoomSearchResults';
import { SearchCategories } from '../../components/SearchCategories/SearchCategories';
import { STORE_ACTIVE_PANEL, STORE_LOCATION } from '../../../../data/stores';
import { ActivePanel } from '../../../../data/core/ActivePanel';
import {
  QUERY_KEY_END_ROOM,
  QUERY_KEY_END_SEARCH,
  QUERY_KEY_END_VERTEX,
  QUERY_KEY_SEARCH,
  QUERY_KEY_START_ROOM,
  QUERY_KEY_START_SEARCH,
  QUERY_KEY_START_VERTEX
} from '../../../../data/core/queryKeys';
import { CategoriesSlider } from '../CategoriesSlider/CategoriesSlider';
import { RouteSearchConnected } from '../../components/RouteSearch/RouteSearchConnected';
import { RoomDto } from '../../../../data/api/RoomDto';
import s from './RoutePanel.module.scss';

export interface RoutePanelBuilderProps {
  onSelectOnMap?: (activePoint: 'A' | 'B') => void;
  onSelectCurrentLocation?: (activePoint: 'A' | 'B') => void;
}

export function RoutePanelBuilder({
  onSelectOnMap,
  onSelectCurrentLocation
}: RoutePanelBuilderProps) {
  const locationStore = useContextStore<LocationStore>(STORE_LOCATION);
  const activePanelStore = useContextStore<ActivePanel>(STORE_ACTIVE_PANEL);
  const [activePoint, setActivePoint] = useState<'A' | 'B' | undefined>(
    undefined
  );

  const handleRoomClick = useCallback(
    (room: RoomDto) => {
      if (activePoint === 'B') {
        locationStore.changeQuery({
          [QUERY_KEY_END_ROOM]: room.id,
          [QUERY_KEY_END_VERTEX]: undefined,
          [QUERY_KEY_END_SEARCH]: undefined,
          [QUERY_KEY_SEARCH]: undefined
        });
      } else if (activePoint === 'A') {
        locationStore.changeQuery({
          [QUERY_KEY_START_ROOM]: room.id,
          [QUERY_KEY_START_SEARCH]: undefined,
          [QUERY_KEY_START_VERTEX]: undefined,
          [QUERY_KEY_SEARCH]: undefined
        });
      }
    },
    [activePoint, locationStore]
  );

  const handleSelectOnMap = (point: 'A' | 'B') => {
    onSelectOnMap?.(point);
    activePanelStore.setPanel(ActivePanel.PANEL_SELECT_ON_MAP);
  };

  return (
    <>
      <RouteSearchConnected
        activePoint={activePoint}
        onChangeActivePoint={setActivePoint}
        onSelectOnMap={handleSelectOnMap}
        onSelectCurrentLocation={onSelectCurrentLocation}
      />
      <SearchCategories
        className={s.RoutePanel__categories}
        base={CategoriesSlider}
      />
      <RoomSearchResults
        className={s.RoutePanel__searchResults}
        onRoomClick={handleRoomClick}
      />
    </>
  );
}
