import { AsyncOperationStore } from '../../../../utils/prostore/AsyncOperationStore';
import { tryOperation } from '../../utils/tryOperation';
import { ToastStore } from '../../../../data/core/ToastStore';
import { CampusStore } from '../../../../data/stores/CampusStore';
import { EdgeType } from '../../../../data/EdgeType';
import { EdgesStore, IEdgeData } from './EdgesStore';
import { IVertexData } from './NodesStore';

export class EdgeAddOperation extends AsyncOperationStore<[IVertexData[]]> {
  constructor(
    private readonly toast: ToastStore,
    private readonly selectedCampus: CampusStore,
    private readonly edges: EdgesStore
  ) {
    super((...args) => this.perform(...args));
  }

  perform = async (nodes: IVertexData[]) => {
    const newEdge: Omit<IEdgeData, 'id'> = {
      campus_id: this.selectedCampus.state?.id,
      start_vertex_id: nodes[0].id,
      end_vertex_id: nodes[1].id,
      start_floor: nodes[0].floor_number,
      end_floor: nodes[1].floor_number,
      type: EdgeType.corridor,
      cost: 0 // Бэк расчитывает сам, если мы передаем 0. Если передаем число больше 0 – сохраняет нужное нам число
    };

    await tryOperation(
      async () => {
        await this.edges.mutations.add(newEdge);
      },
      {
        name: this.constructor.name,
        toast: this.toast,
        successMsg: 'Ребро создано',
        errorMsg: 'Ошибка при создании ребра'
      }
    );
  };
}
