import { TuplePoint2D } from './types';

const earthRadiusKm = 6371;

function degToRad(deg: number) {
  return (deg * Math.PI) / 180;
}

export function distanceBetweenLatLng(a: TuplePoint2D, b: TuplePoint2D) {
  const dLat = degToRad(b[0] - a[0]);
  const dLng = degToRad(b[1] - a[1]);
  const aLat = degToRad(a[0]);
  const bLat = degToRad(b[0]);
  const alpha =
    Math.sin(dLat / 2) ** 2 +
    Math.sin(dLng / 2) ** 2 * Math.cos(aLat) * Math.cos(bLat);
  const dist = 2 * Math.atan2(Math.sqrt(alpha), Math.sqrt(1 - alpha));
  return earthRadiusKm * dist;
}
