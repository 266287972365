import { useStore } from '@proscom/prostore-react';
import { STORE_LOCALE } from '../../data/stores';
import { LocaleStore } from '../../data/core/LocaleStore';

export function useLocale() {
  // Необходимо подписаться на изменения стейта, чтобы при смене языка
  // компонент перерендерился и обновились вызовы метода `t`
  const [, localeStore] = useStore<LocaleStore>(STORE_LOCALE);
  return localeStore;
}
