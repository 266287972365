import {
  CompareFn,
  compareSimple,
  makeMultiComparator
} from '@proscom/ui-utils';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import {
  IRoomSearchResults,
  RoomSearchResultsState
} from '../../online/RoomSearchResults';
import {
  RoomSearchVars,
  RoomSearchVarsStore
} from '../../stores/RoomSearchVarsStore';
import { combineLatestMap } from '../../../utils/prostore/combineLatestMap';
import { initialExtendedRequestState } from '../../../utils/prostore/ExtendedRequestStore';
import {
  findRoomsByWords,
  RoomSearchResult
} from '../../../utils/utilsSearchStoreOffline/findRoomsByWords';
import { ObservableStore } from '../../../utils/prostore/ObservableStore';
import { RoomSearchStore } from '../../stores/RoomSearchStore';
import { RoomDto } from '../../api/RoomDto';
import { IOfflineCampusInfo } from './CampusesStoreOffline';
import { CurrentCampusStoreOffline } from './CurrentCampusStoreOffline';

interface IOrder<T, K> {
  field: (x: T) => K;
  comparator: CompareFn<K>;
}

type MeaningfulVars = Pick<
  RoomSearchVars,
  | 'room_id'
  | 'vertex_id'
  | 'room_category_id'
  | 'search'
  | 'sort_building'
  | 'sort_floor'
>;

export class RoomSearchResultsOffline
  extends ObservableStore<RoomSearchResultsState>
  implements IRoomSearchResults {
  constructor(
    private readonly roomSearch: RoomSearchStore,
    private readonly roomSearchVars: RoomSearchVarsStore,
    private readonly currentCampusStoreOffline: CurrentCampusStoreOffline
  ) {
    super(() => this.init(), { ...initialExtendedRequestState, maxPage: null });
  }

  private init() {
    const meaningfulVars = this.roomSearchVars.state$.pipe(
      map(
        (vars) =>
          vars && {
            room_id: vars.room_id,
            vertex_id: vars.vertex_id,
            room_category_id: vars.room_category_id,
            search: vars.search,
            sort_building: vars.sort_building,
            sort_floor: vars.sort_floor
          }
      ),
      distinctUntilChanged(isEqual)
    );

    const rooms = this.createSearch(meaningfulVars);
    return this.createPaginated(rooms);
  }

  private createSearch(meaningfulVars: Observable<MeaningfulVars | null>) {
    return combineLatestMap(
      [meaningfulVars, this.currentCampusStoreOffline.state$] as const,
      ([vars, campus]) => {
        if (!vars || !campus) return null;

        const startVertices = vars.room_id
          ? campus.graph.getRoomVertices(vars.room_id).map((v) => v.id)
          : vars.vertex_id
          ? [vars.vertex_id]
          : [];

        if (false && startVertices.length > 0) {
          // traverse graph and filter rooms based on search conditions
        } else {
          return this.searchRoomsTraditionally(vars, campus);
        }

        return [];
      }
    );
  }

  private createPaginated(rooms: Observable<RoomDto[] | null>) {
    return combineLatestMap(
      [this.roomSearchVars.state$, rooms] as const,
      ([vars, rooms]) => {
        if (!rooms) return { ...initialExtendedRequestState };
        if (!vars) {
          return {
            ...initialExtendedRequestState,
            loaded: true,
            variables: vars,
            loadedVariables: vars,
            maxPage: 1
          };
        }

        const maxPage = Math.ceil(rooms.length / vars.perPage) || 1;
        const slice = rooms.slice(0, vars.page * vars.perPage);
        return {
          ...initialExtendedRequestState,
          loaded: true,
          data: slice,
          variables: vars,
          loadedVariables: vars,
          maxPage
        };
      }
    );
  }

  private searchRoomsTraditionally(
    vars: MeaningfulVars,
    campus: IOfflineCampusInfo
  ) {
    const orders: IOrder<RoomDto, any>[] = [];

    let rooms = campus.rooms;

    if (vars.room_category_id) {
      rooms = rooms.filter((r) => r.room_category_id === vars.room_category_id);
    }

    if (vars.search) {
      rooms = findRoomsByWords(
        rooms,
        vars.search,
        ['name', 'map_name', 'description', 'code'] as const,
        [
          'first_name',
          'last_name',
          'surname',
          'email',
          'phone',
          'title'
        ] as const,
        0.81
      );

      orders.push({
        field: (room: RoomDto) => (room as RoomSearchResult).similarity,
        comparator: compareSimple(-1)
      });
    }

    if (vars.sort_building !== undefined) {
      orders.push({
        field: (room: RoomDto) =>
          room.building_id === vars.sort_building ? 0 : 1,
        comparator: compareSimple(1)
      });
    }

    if (vars.sort_floor !== undefined) {
      orders.push({
        field: (room: RoomDto) => Math.abs(room.floor_number - vars.sort_floor),
        comparator: compareSimple(1)
      });
    }

    orders.push({
      field: (room: RoomDto) => room.name,
      comparator: compareSimple(1)
    });

    return rooms.sort(
      makeMultiComparator(...orders.map((o) => [o.comparator, o.field] as any))
    );
  }

  public requestNextPage = () => {
    if (this.state.loading || this.state.error) return;
    const { page } = this.roomSearch.state;
    if (this.state.maxPage && page >= this.state.maxPage) return;
    this.roomSearch.setPage(this.roomSearch.state.page + 1);
  };
}
