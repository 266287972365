import React, { MouseEventHandler } from 'react';
import {
  Button,
  DivPropsWithoutRef,
  IconActionLogout,
  IconButton,
  IconButtonVariant,
  IconGlobalSettings
} from '@hse-design/react';
import clsx from 'clsx';
import { IConfigStoreState } from './data/stores/ConfigStore';
import { dangerColor } from './data/theme';
import s from './MapperConfig.module.scss';

export interface MapperConfigProps
  extends Omit<DivPropsWithoutRef, 'onChange'> {
  isActive?: boolean;
  showHints?: boolean;
  showEdges?: boolean;
  showNodes?: boolean;
  showRooms?: boolean;
  showBuildings?: boolean;
  onChange: (changes?: Partial<IConfigStoreState>) => void;
  onLogout: MouseEventHandler<'button'>;
}

export const MapperConfig = React.memo(function MapperConfig({
  isActive,
  className,
  showHints,
  showEdges,
  showNodes,
  showRooms,
  showBuildings,
  onChange,
  onLogout,
  ...props
}: MapperConfigProps) {
  return (
    <div className={clsx(s.MapperConfig, className)} {...props}>
      {isActive && (
        <>
          <div className={s.MapperConfig__title}>Настройки</div>
          <div className={s.MapperConfig__container}>
            <Button
              variant={Button.Variant.tertiary}
              size={Button.Size.small}
              onClick={() => onChange({ showHints: !showHints })}
            >
              {showHints ? 'Не показывать' : 'Показывать'} подсказки
            </Button>
            <Button
              variant={Button.Variant.tertiary}
              size={Button.Size.small}
              onClick={() => onChange({ showEdges: !showEdges })}
            >
              {showEdges ? 'Скрыть' : 'Показать'} рёбра
            </Button>
            <Button
              variant={Button.Variant.tertiary}
              size={Button.Size.small}
              onClick={() => onChange({ showNodes: !showNodes })}
            >
              {showNodes ? 'Скрыть' : 'Показать'} вершины
            </Button>
            <Button
              variant={Button.Variant.tertiary}
              size={Button.Size.small}
              onClick={() => onChange({ showRooms: !showRooms })}
            >
              {showRooms ? 'Скрыть' : 'Показать'} комнаты
            </Button>
            <Button
              variant={Button.Variant.tertiary}
              size={Button.Size.small}
              onClick={() => onChange({ showBuildings: !showBuildings })}
            >
              {showBuildings ? 'Скрыть' : 'Показать'} корпусы
            </Button>
          </div>
          <Button
            variant={Button.Variant.tertiary}
            onClick={onLogout}
            leftIcon={IconActionLogout}
            iconColor={dangerColor}
          >
            Выйти
          </Button>
        </>
      )}
      <IconButton
        className={s.MapperConfig__toggleVisibilityButton}
        icon={IconGlobalSettings}
        size={IconButton.Size.small}
        selected={isActive}
        variant={IconButtonVariant.ghost}
        onClick={() => onChange({ showConfig: !isActive })}
      />
    </div>
  );
});
